import { useEffect, useState } from "react";
import { ButtonComponent } from "../../../../components/Shared/ButtonComponent/component";
import { CardGroupComponent } from "../../../../components/Shared/CardComponent/component";
import { FullImageContentComponent } from "../../../../components/Shared/FullImageContent/component";
import { IconContainerComponent } from "../../../../components/Shared/IconContainerComponent/component";
import { IconContainerMobileComponent } from "../../../../components/Shared/IconContainerMobileComponent/component";
import { ImageComponent } from "../../../../components/Shared/ImageComponent/component";
import { SliderProductsComponent } from "../../../../components/Shared/SliderProductsComponent/component";
import { TabComponent } from "../../../../components/Shared/TabComponent/component";
import { SliderComponent } from "../../../../components/SliderComponent";
import {
  CarouselProductsAppMoniWay,
  IconContent2MoniWay,
  IconContentMoniWay,
  IconContentMoniWayMobile1,
  IconContentMoniWayMobile2,
  MoniWayContent,
  SliderMoniWaySeries,
} from "../../../../constants/datas";
import theme from "../../../../constants/theme";
import useWindowDimensions from "../../../../services/getWindowSize.service";
import { TranslationService } from "../../../../services/translation.service";
import { AppTabPage } from "../../app/component";
import { Style } from "./style";
import "./style.css";
import { Props } from "./types";

export function ScreenComponent(props: Props) {
  const t = TranslationService.translate;
  const isMobile = props.isMobile;

  const windowSize = useWindowDimensions().width;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const translationKey = "moniWay";

  const button = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"right"}
        />
      </div>
    );
  };
  //selected lang
  const lang = TranslationService.getLangFromStorage().code;
  const [sliderItem, setSliderItem] = useState(SliderMoniWaySeries);
  useEffect(() => {
    let xss = sliderItem;
    lang === "en"
      ? xss.map((item) => {
          item.secondaryIsBtn = false;
        })
      : xss.map((item) => {
          item.secondaryIsBtn = true;
        });

    setSliderItem(xss);
  }, [lang]);

  const children = [
    {
      title: t("moniWay", "overview"),
      body: (
        <div style={{ backgroundColor: "white", marginTop: isMobile ? 75 : 0 }}>
          <SliderComponent sliderItems={sliderItem} />

          {isMobile ? (
            <div className="d-flex justify-content-center my-4">
              <div style={{ width: "80%" }}>
                <SliderProductsComponent
                  textPosition="bottom"
                  sliderBottomItems={MoniWayContent}
                  minHeight={402}
                />
              </div>
            </div>
          ) : (
            <div
              style={{ marginBottom: 50, marginTop: 50, textAlign: "center" }}
            >
              <CardGroupComponent
                cards={MoniWayContent}
                backgroundImage={!isMobile && theme.IMAGES.bgLineFull}
                width={"70%"}
              />
            </div>
          )}
          <FullImageContentComponent
            imgUrl=""
            imgAlt="Text"
            title={t(translationKey, "fullTextTitle")}
            text={t(translationKey, "fullTextText")}
            hStyle={{ fontSize: theme.FONT_SIZE.xlarge }}
            pStyle={{ fontSize: theme.FONT_SIZE.medium }}
            textPosition="center"
          />
          <IconContainerComponent
            space={20}
            icons={IconContentMoniWay}
            isNotResponsive={true}
          />

          {!isMobile && (
            <>
              {" "}
              <div className="d-flex justify-content-center position-relative">
                <ImageComponent
                  src={theme.IMAGES.products.airQuality.moniWay.moniWayBus}
                  alt={t(translationKey, "sliderAlt")}
                  width={88}
                />
                <div
                  className="absolute d-flex align-items-center"
                  style={
                    windowSize < 1320
                      ? { top: 315, right: 122 }
                      : windowSize < 1440
                      ? { top: 324, right: 122 }
                      : windowSize < 1441
                      ? { top: 339, right: 122 }
                      : windowSize < 1600
                      ? { top: 350, right: 122 }
                      : windowSize === 1920
                      ? { top: 337, right: 253 }
                      : windowSize < 1920
                      ? { top: 363, right: 122 }
                      : { top: 425, right: 253 }
                  }
                >
                  <div style={Style.line} />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t(translationKey, "busText1"),
                    }}
                  />
                </div>

                <div
                  className="absolute d-flex align-items-center"
                  style={
                    windowSize < 1320
                      ? { top: 163, left: 135 }
                      : windowSize < 1440
                      ? { top: 171, left: 135 }
                      : windowSize < 1441
                      ? { top: 197, left: 135 }
                      : windowSize < 1600
                      ? { top: 207, left: 135 }
                      : windowSize === 1920
                      ? { top: 301, left: 192 }
                      : windowSize < 1920
                      ? { top: 257, left: 135 }
                      : { top: 355, left: 239 }
                  }
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t(translationKey, "busText2"),
                    }}
                  />

                  <div style={Style.line} />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="d-flex justify-content-center position-relative">
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1440
                        ? { top: 23, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 23, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 23, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 23, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 35, right: 137, width: 305 }
                        : { top: 37, right: 174, width: 305 }
                    }
                  >
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: t(translationKey, "moniTec1"),
                      }}
                    /> */}
                  </div>
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1440
                        ? { top: 52, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 56, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 60, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 60, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 78, right: 137, width: 305 }
                        : { top: 84, right: 174, width: 305 }
                    }
                  >
                    {/*  */}
                  </div>

                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1440
                        ? { top: 76, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 80, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 90, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 95, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 111, right: 137, width: 305 }
                        : { top: 124, right: 174, width: 305 }
                    }
                  ></div>
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1320
                        ? { top: 134, right: 9, width: 305 }
                        : windowSize < 1440
                        ? { top: 139, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 148, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 157, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 177, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 202, right: 139, width: 305 }
                        : { top: 223, right: 174, width: 305 }
                    }
                  ></div>
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1320
                        ? { top: 183, right: 9, width: 305 }
                        : windowSize < 1440
                        ? { top: 188, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 202, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 217, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 236, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 274, right: 139, width: 305 }
                        : { top: 302, right: 174, width: 305 }
                    }
                  ></div>
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1320
                        ? { top: 207, right: 9, width: 305 }
                        : windowSize < 1440
                        ? { top: 215, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 230, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 244, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 273, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 311, right: 139, width: 305 }
                        : { top: 341, right: 174, width: 305 }
                    }
                  ></div>
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1320
                        ? { top: 294, right: 9, width: 305 }
                        : windowSize < 1400
                        ? { top: 305, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 338, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 385, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 384, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 437, right: 139, width: 305 }
                        : { top: 480, right: 174, width: 305 }
                    }
                  ></div>
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1320
                        ? { top: 404, right: 9, width: 305 }
                        : windowSize < 1400
                        ? { top: 420, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 446, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 469, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 524, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 592, right: 139, width: 305 }
                        : { top: 660, right: 174, width: 305 }
                    }
                  ></div>
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1320
                        ? { top: 478, right: 9, width: 305 }
                        : windowSize < 1400
                        ? { top: 489, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 524, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 554, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 620, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 698, right: 139, width: 305 }
                        : { top: 780, right: 174, width: 305 }
                    }
                  ></div>
                  <div style={{ width: "88%" }}>
                    <FullImageContentComponent
                      imgUrl={
                        theme.IMAGES.products.airQuality.moniWay.moniParticle
                      }
                      imgAlt={t(translationKey, "sliderAlt")}
                      title={
                        window.screen.width < 1500
                          ? t(translationKey, "particleTitle").replace(
                              /<br\s*\/?>/gi,
                              ""
                            )
                          : t(translationKey, "particleTitle")
                      }
                      text={
                        window.screen.width < 1500
                          ? t(translationKey, "particleText").replace(
                              /<br\s*\/?>/gi,
                              ""
                            )
                          : t(translationKey, "particleText")
                      }
                      textPosition="left"
                      titleColor={theme.COLORS.blue}
                      hStyle={{ fontSize: "2rem" }}
                      textImg={
                        !isMobile &&
                        theme.IMAGES.products.airQuality.moniWay
                          .moniAfterParticle
                      }
                    />
                  </div>
                </div>
              </div>{" "}
            </>
          )}

          <div className="d-flex justify-content-center">
            <div style={{ width: "88%" }}>
              <FullImageContentComponent
                imgUrl={theme.IMAGES.products.airQuality.moniWay.filter}
                imgAlt={t(translationKey, "sliderAlt")}
                title={t(translationKey, "filterTitle")}
                text={
                  window.screen.width < 1500
                    ? t(translationKey, "filterText").replace(
                        /<br\s*\/?>/gi,
                        ""
                      )
                    : t(translationKey, "filterText")
                }
                isBtn={false}
                btnText={t(translationKey, "filterBtn")}
                buttonComponent={button}
                textPosition="right"
                style={{ textAlign: "right" }}
                titleColor={theme.COLORS.blue}
              />
            </div>{" "}
          </div>
          <IconContainerComponent
            icons={IconContent2MoniWay}
            space={20}
            isNotResponsive={true}
            style={
              isMobile
                ? { backgroundColor: theme.COLORS.blue40 }
                : { backgroundColor: theme.COLORS.blue40, height: 300 }
            }
          />
          <FullImageContentComponent
            imgUrl=""
            imgAlt="App"
            text={t(translationKey, "appTitle")}
            textPosition="center"
            style={{ fontSize: "19pt", fontWeight: "bold", marginTop: 60 }}
          />
          <FullImageContentComponent
            imgUrl=""
            imgAlt="App"
            text={t(translationKey, "appTextMobile")}
            textPosition="center"
            style={{ fontSize: "12pt" }}
          />

          {isMobile ? (
            <SliderProductsComponent
              sliderTopItems={CarouselProductsAppMoniWay}
              textPosition={"top"}
              key={translationKey}
            />
          ) : (
            <div style={{ marginBottom: 150 }}>
              <ImageComponent
                src={
                  TranslationService.getLang().code == "tr"
                    ? theme.IMAGES.products.airQuality.moniWay.sliderImgtr
                    : theme.IMAGES.products.airQuality.moniWay.sliderImgen
                }
                alt={t(translationKey, "sliderAlt")}
              />{" "}
            </div>
          )}
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.airQuality.moniWay.poisonousGas1}
            imgAlt="sercair"
            text={
              window.screen.width < 1500
                ? t(translationKey, "poisonousGas1").replace(/<br\s*\/?>/gi, "")
                : t(translationKey, "poisonousGas1")
            }
            textPosition="right"
            pStyle={{ fontSize: "20px" }}
          />
          <div style={{ marginBottom: isMobile ? 50 : 0 }}>
            <FullImageContentComponent
              imgUrl={theme.IMAGES.products.airQuality.moniWay.poisonousGas2_1}
              imgAlt="sercair"
              textBackgroundRepeat={true}
              textBackgroundImg={
                theme.IMAGES.products.airQuality.moniWay.poisonousGas2_2
              }
              textPosition="left"
            />
          </div>
          <FullImageContentComponent
            pStyle={{ fontSize: "20px" }}
            imgUrl={theme.IMAGES.products.airQuality.moniWay.poisonousGas3}
            imgAlt="sercair"
            text={
              window.screen.width < 1500
                ? t(translationKey, "poisonousGas3").replace(/<br\s*\/?>/gi, "")
                : t(translationKey, "poisonousGas3")
            }
            textPosition="right"
          />
          <div
            style={{ marginBottom: !isMobile ? -170 : 0, paddingRight: 144 }}
          >
            <FullImageContentComponent
              imgUrl={theme.IMAGES.products.airQuality.moniWay.adminPanel}
              imgAlt="sercair"
              title={
                window.screen.width < 1500
                  ? t(translationKey, "adminPanelTitle").replace(
                      /<br\s*\/?>/gi,
                      ""
                    )
                  : t(translationKey, "adminPanelTitle")
              }
              text={
                window.screen.width < 1500
                  ? t(translationKey, "adminPanelText").replace(
                      /<br\s*\/?>/gi,
                      ""
                    )
                  : t(translationKey, "adminPanelText")
              }
              textPosition="left"
              hStyle={{ fontSize: "21pt" }}
              pStyle={{ fontSize: "18px" }}
              titleColor={theme.COLORS.blue}
            />
          </div>
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.airQuality.moniWay.mapScreen}
            imgAlt="sercair"
            title={t(translationKey, "mapTitle")}
            text={
              window.screen.width < 1500
                ? t(translationKey, "mapText").replace(/<br\s*\/?>/gi, "")
                : t(translationKey, "mapText")
            }
            textPosition="right"
            hStyle={{ fontSize: "21pt" }}
            pStyle={{ fontSize: "18px" }}
            titleColor={theme.COLORS.blue}
          />
        </div>
      ),
    },
    {
      title: t("PureSeries", "tabTitle3"),
      body: (
        <div>
          <AppTabPage isMobile={props.isMobile} />
        </div>
      ),
    },
  ];
  const childrenMobile = [
    {
      title: t("moniWay", "overview"),
      body: (
        <div
          className="mobile-slider-way"
          style={{ backgroundColor: "white", marginTop: isMobile ? 75 : 0 }}
        >
          <SliderComponent sliderItems={sliderItem} />

          <div className="d-flex justify-content-center my-4">
            <div style={{ width: "80%" }}>
              <SliderProductsComponent
                textPosition="bottom"
                sliderBottomItems={MoniWayContent}
                minHeight={402}
              />
            </div>
          </div>

          <IconContainerMobileComponent
            icons={IconContentMoniWayMobile1}
            isSpirit={true}
            iconWidth={100}
            textWidth={100}
          />
          <IconContainerMobileComponent
            icons={IconContentMoniWayMobile2}
            isSpirit={true}
            iconWidth={100}
            textWidth={100}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: 12,
                fontFamily: theme.FONTS.GothamBook,
                width: 160,
                margin: "auto",
                textAlign: "center",
              }}
            >
              {t(translationKey, "busText2Mobile")}
            </div>
          </div>
          <ImageComponent
            src={theme.IMAGES.products.airQuality.moniWay.moniWayBusMobile}
            alt={t(translationKey, "sliderAlt")}
            width={100}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: 20,
            }}
          >
            <div
              style={{
                fontSize: 12,
                fontFamily: theme.FONTS.GothamBook,
                width: 160,
                margin: "auto",
                textAlign: "center",
              }}
            >
              {t(translationKey, "busText1Mobile")}
            </div>
          </div>
          <div style={{ marginBottom: 30 }}>
            <FullImageContentComponent
              imgUrl={
                t(translationKey, "particleTitle") ==
                "Toz ve alerjenleri yakalar.<br/>  Bakteri ve virüsleri yok eder."
                  ? theme.IMAGES.products.airQuality.moniWay
                      .moniParticleMobileTR
                  : theme.IMAGES.products.airQuality.moniWay
                      .moniParticleMobileEN
              }
              imgAlt="Sercair"
            />
          </div>
          {/* <FullImageContentComponent
            imgUrl={""}
            imgAlt=""
            buttonComponent={button}
            btnText={t(translationKey, "filterBtn")}
            
            hStyle={{marginTop: 20,paddingBottom: 10, textAlign: 'center', margin: 'auto'}}
            btnUrl={
              "https://www.sercairstore.com/urun/sercair-monime-hava-kalitesi-olcum-cihazi"
            }
           
            title={t(translationKey, "filterTitle")}
            textPosition={"left"}
            isBtn={true}
          />  */}
          {!isMobile && (
            <>
              {" "}
              <div className="d-flex justify-content-center position-relative">
                <ImageComponent
                  src={theme.IMAGES.products.airQuality.moniWay.moniWayBus}
                  alt={t(translationKey, "sliderAlt")}
                  width={88}
                />
                <div
                  className="absolute d-flex align-items-center"
                  style={
                    windowSize < 1320
                      ? { top: 315, right: 122 }
                      : windowSize < 1440
                      ? { top: 324, right: 122 }
                      : windowSize < 1441
                      ? { top: 339, right: 122 }
                      : windowSize < 1600
                      ? { top: 350, right: 122 }
                      : windowSize === 1920
                      ? { top: 337, right: 253 }
                      : windowSize < 1920
                      ? { top: 363, right: 122 }
                      : { top: 425, right: 253 }
                  }
                >
                  <div style={Style.line} />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t(translationKey, "busText1"),
                    }}
                  />
                </div>

                <div
                  className="absolute d-flex align-items-center"
                  style={
                    windowSize < 1320
                      ? { top: 163, left: 135 }
                      : windowSize < 1440
                      ? { top: 171, left: 135 }
                      : windowSize < 1441
                      ? { top: 197, left: 135 }
                      : windowSize < 1600
                      ? { top: 207, left: 135 }
                      : windowSize === 1920
                      ? { top: 301, left: 192 }
                      : windowSize < 1920
                      ? { top: 257, left: 135 }
                      : { top: 355, left: 239 }
                  }
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t(translationKey, "busText2"),
                    }}
                  />

                  <div style={Style.line} />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="d-flex justify-content-center position-relative">
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1440
                        ? { top: 23, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 23, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 23, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 23, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 35, right: 137, width: 305 }
                        : { top: 37, right: 174, width: 305 }
                    }
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t(translationKey, "moniTec1"),
                      }}
                    />
                  </div>
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1440
                        ? { top: 52, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 56, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 60, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 60, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 78, right: 137, width: 305 }
                        : { top: 84, right: 174, width: 305 }
                    }
                  ></div>

                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1440
                        ? { top: 76, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 80, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 90, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 95, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 111, right: 137, width: 305 }
                        : { top: 124, right: 174, width: 305 }
                    }
                  ></div>
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1320
                        ? { top: 134, right: 9, width: 305 }
                        : windowSize < 1440
                        ? { top: 139, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 148, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 157, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 177, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 202, right: 139, width: 305 }
                        : { top: 223, right: 174, width: 305 }
                    }
                  ></div>
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1320
                        ? { top: 183, right: 9, width: 305 }
                        : windowSize < 1440
                        ? { top: 188, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 202, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 217, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 236, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 274, right: 139, width: 305 }
                        : { top: 302, right: 174, width: 305 }
                    }
                  ></div>
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1320
                        ? { top: 207, right: 9, width: 305 }
                        : windowSize < 1440
                        ? { top: 215, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 230, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 244, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 273, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 311, right: 139, width: 305 }
                        : { top: 341, right: 174, width: 305 }
                    }
                  ></div>
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1320
                        ? { top: 294, right: 9, width: 305 }
                        : windowSize < 1400
                        ? { top: 305, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 338, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 385, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 384, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 437, right: 139, width: 305 }
                        : { top: 480, right: 174, width: 305 }
                    }
                  ></div>
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1320
                        ? { top: 404, right: 9, width: 305 }
                        : windowSize < 1400
                        ? { top: 420, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 446, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 469, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 524, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 592, right: 139, width: 305 }
                        : { top: 660, right: 174, width: 305 }
                    }
                  ></div>
                  <div
                    className="absolute d-flex align-items-center"
                    style={
                      windowSize < 1320
                        ? { top: 478, right: 9, width: 305 }
                        : windowSize < 1400
                        ? { top: 489, right: 9, width: 305 }
                        : windowSize < 1441
                        ? { top: 524, right: 37, width: 305 }
                        : windowSize < 1600
                        ? { top: 554, right: 51, width: 305 }
                        : windowSize < 1920
                        ? { top: 620, right: 83, width: 305 }
                        : windowSize === 1920
                        ? { top: 698, right: 139, width: 305 }
                        : { top: 780, right: 174, width: 305 }
                    }
                  ></div>
                  <div style={{ width: "88%" }}>
                    <FullImageContentComponent
                      imgUrl={
                        theme.IMAGES.products.airQuality.moniWay.moniParticle
                      }
                      imgAlt={t(translationKey, "sliderAlt")}
                      title={
                        window.screen.width < 1500
                          ? t(translationKey, "particleTitle").replace(
                              /<br\s*\/?>/gi,
                              ""
                            )
                          : t(translationKey, "sliderTitle")
                      }
                      text={
                        window.screen.width < 1500
                          ? t(translationKey, "particleText").replace(
                              /<br\s*\/?>/gi,
                              ""
                            )
                          : t(translationKey, "sliderText")
                      }
                      textPosition="left"
                      titleColor={theme.COLORS.blue}
                      hStyle={{ fontSize: "2rem" }}
                      textImg={
                        !isMobile &&
                        theme.IMAGES.products.airQuality.moniWay
                          .moniAfterParticle
                      }
                    />
                  </div>
                </div>
              </div>{" "}
            </>
          )}

          <div className="d-flex justify-content-center">
            <div style={{ width: "88%" }}>
              <FullImageContentComponent
                imgUrl={theme.IMAGES.products.airQuality.moniWay.filter}
                imgAlt={t(translationKey, "sliderAlt")}
                title={t(translationKey, "filterTitle")}
                text={
                  window.screen.width < 1500
                    ? t(translationKey, "filterText").replace(
                        /<br\s*\/?>/gi,
                        ""
                      )
                    : t(translationKey, "filterText")
                }
                isBtn={false}
                btnText={t(translationKey, "filterBtn")}
                buttonComponent={button}
                textPosition="left"
                style={{ textAlign: "right" }}
                pStyle={{ width: 250 }}
                titleColor={theme.COLORS.blue}
              />
            </div>{" "}
          </div>
          <div style={{ backgroundColor: theme.COLORS.blue40 }}>
            <IconContainerMobileComponent
              icons={IconContent2MoniWay}
              isSpirit={true}
              iconWidth={65}
              textWidth={65}
            />
          </div>
          {/* <IconContainerComponent
            icons={IconContent2MoniWay}
            space={20}
            isNotResponsive={true}
            style={{ backgroundColor: theme.COLORS.blue40 }}
          /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <div
              style={{
                fontSize: 18,
                fontFamily: theme.FONTS.GothamMedium,
                width: 300,
                margin: "auto",
                textAlign: "center",
              }}
            >
              {t(translationKey, "appTitle")}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <div
              style={{
                fontSize: 13,
                fontFamily: theme.FONTS.GothamBook,
                width: 300,
                margin: "auto",
                textAlign: "center",
              }}
            >
              {t(translationKey, "appTextMobile")}
            </div>
          </div>

          <SliderProductsComponent
            sliderTopItems={CarouselProductsAppMoniWay}
            textPosition={"top"}
            key={translationKey}
          />

          <FullImageContentComponent
            pStyle={{ fontSize: "20px" }}
            imgUrl={theme.IMAGES.products.airQuality.moniWay.poisonousGas3}
            imgAlt="sercair"
            text={
              window.screen.width < 1500
                ? t(translationKey, "poisonousGas3").replace(/<br\s*\/?>/gi, "")
                : t(translationKey, "poisonousGas3")
            }
            textPosition="left"
          />
          <div style={{ marginBottom: 60 }}>
            <FullImageContentComponent
              imgUrl={theme.IMAGES.products.airQuality.moniWay.poisonousGas2_1}
              imgAlt="sercair"
              textBackgroundRepeat={true}
              textBackgroundImg={
                theme.IMAGES.products.airQuality.moniWay.poisonousGas2_2
              }
              textPosition="left"
            />
          </div>
          <div style={{ marginTop: -20 }}>
            <FullImageContentComponent
              imgUrl={theme.IMAGES.products.airQuality.moniWay.adminPanel}
              imgAlt="sercair"
              title={t(translationKey, "adminPanelTitle")}
              textPosition="left"
              hStyle={{ fontSize: "17pt" }}
              titleColor={theme.COLORS.blue}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: -20,
              color: theme.COLORS.blue,
            }}
          >
            <div
              style={{
                fontSize: 15,
                fontFamily: theme.FONTS.GothamMedium,
                width: 300,
                margin: "auto",
                textAlign: "center",
              }}
            >
              {t(translationKey, "mapTitle")}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <div
              style={{
                fontSize: 12,
                fontFamily: theme.FONTS.GothamMedium,
                width: 320,
                margin: "auto",
                textAlign: "center",
              }}
            >
              {" "}
              <p
                dangerouslySetInnerHTML={{
                  __html: t(translationKey, "adminPanelTextMobile"),
                }}
              ></p>
            </div>
          </div>
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.airQuality.moniWay.mapScreen}
            imgAlt="sercair"
          />
        </div>
      ),
    },
    {
      title: t("PureSeries", "tabTitle3"),
      body: (
        <div>
          <AppTabPage isMobile={props.isMobile} />
        </div>
      ),
    },
  ];

  return (
    <div>
      <TabComponent
        children={isMobile ? childrenMobile : children}
        componentKey={"PureSeries"}
      />
    </div>
  );
}
