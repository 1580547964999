import { Props } from "./types";
import { Style } from "./style";
import { store } from "../../../../store";
import React from "react";
export function CenterTextComponent(props: Props) {
  //set style for text
  const pStyle = {
    ...Style.maxWidth,
    ...props.pStyle,
  };
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
  }, []);
  return (
    <div style={props.style}>
      <div className="mt-2" style={Style.textCentered}>
        <h1 style={ {fontSize: "30px"}}   dangerouslySetInnerHTML={{
                  __html: isMobile
                    ? props.title && props.title.replace(/<br\s*[\/]?>/gi, "")
                    : (props.title as any),
                }} />
      </div>
      <div className="mt-3" style={Style.textCentered}>
        <p
          style={pStyle}
          dangerouslySetInnerHTML={{
            __html: isMobile
              ? props.text && props.text.replace(/<br\s*[\/]?>/gi, "")
              : (props.text as any),
          }}
        />
      </div>
      {props.isBtn && (
        <div className="mt-5 d-flex justify-content-center">
          {props.buttonComponent(props.btnText, props.btnUrl)}
        </div>
      )}
    </div>
  );
}
