import { APIURLs } from "../consts/APIURLs";
import { APIService } from "../../services/api.service";

export class MailService {
  //get Translation code

  static async sendMail(
    name: string,
    lastname: string,
    mailFrom: string,
    phone: string,
    shortDest: string,
    dest: string,
    countryCode: string
  ): Promise<any> {
    const url = APIURLs.BASE_URL + APIURLs.POST_MAIL;
    var body = {
      name: name,
      lastname: lastname,
      mailFrom: mailFrom,
      phone: phone,
      shortDest: shortDest,
      dest: dest,
      countryCode: countryCode,
    };
    return await APIService.getInstance()
      .httpPost<any>(url)
      .setBody(body)
      .execute()
      .then((response) => response.getContent());
  }
}
