export const data = [
  {
    title: "title1",
    texts: ["text1.1", "text1.2", "text1.3"],
  },
  {
    title: "title2",
    texts: ["text2.1", "text2.2", "text2.3", "text2.4"],
  },
  {
    title: "title3",
    texts: ["text3.1"],
  },
  {
    title: "title4",
    texts: ["text4.1", "text4.2", "text4.3"],
  },
  {
    title: "title5",
    texts: ["text5.1"],
  },
  {
    title: "title6",
    texts: ["text6.1", "text6.2"],
  },
  {
    title: "title7",
    texts: ["text7.1"],
  },
  {
    title: "title8",
    texts: ["text8.1", "text8.2"],
  },
];
