import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import theme from "../../../../constants/theme";
import { TranslationService } from "../../../../services/translation.service";
import { store } from "../../../../store";
import { IconContainerComponent } from "../../../Shared/IconContainerComponent/component";
import { ButtonComponentPurple } from "../../ButtonComponentPurple/component";
import { Props } from "./types";
import React from "react";
export function Component(props: Props) {
 
  const [index, setIndex] = useState(0);
  const [isMobile, setIsMobile] = React.useState(false);
  const langCheck = localStorage.getItem("lang");
  React.useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
  }, []);
  const t = TranslationService.translate;
  const handleSelect = (
    selectedIndex: React.SetStateAction<number>,
    e: any
  ) => {
    setIndex(selectedIndex);
  };
  const buttonPurple = () => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: isMobile ? "center" : "end",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponentPurple
          url={
            langCheck === "tr"
              ? "/tr/iletisim"
              : langCheck === "en"
              ? "/contact"
              : langCheck === "nl"
              ? "/nl/contact"
              : langCheck === "fr"
              ? "/fr/contact"
              : langCheck === "uk"
              ? "/uk/contact"
              : langCheck === "ro"
              ? "/ro/contactati"
              : "/rs/kontakt"
          }
          text={t("PureSeries", "btnMoreInfo")}
          isLong={false}
          position={"left"}
        />
      </div>
    );
  };
  return (
    <div>
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        pause={"hover"}
        controls={false}
      >
        {props.sliderRightItems &&
          props.sliderRightItems.map((item, index) => {
            return (
              <Carousel.Item
                interval={2000}
                key={index + "sliderRightItems"}
                style={{
                  backgroundColor: "#67b7ec",
                  color: theme.COLORS.white,
                  borderRadius: "50px",
                }}
              >
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex text-justify-responsive justify-content-between align-items-center">
                    <img
                      className="d-block w-100"
                      style={{
                        borderBottomLeftRadius: isMobile ? 0 : "50px",
                        borderTopLeftRadius: "50px",
                        borderTopRightRadius: isMobile ? 50 : 0,
                        height: "100%",
                      }}
                      src={item.imgUrl}
                      alt={t(item.keyComponent, item.imgAlt)}
                    />
                  </div>
                  <div
                    style={{ marginBottom: 30 }}
                    className="col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center justify-content-center align-items-center d-flex flex-column"
                  >
                    <h3
                      className="GothamMedium mt-4"
                      style={{
                        fontSize: "1.5rem",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: t(item.keyComponent, item.title),
                      }}
                    ></h3>
                    <p
                      style={{ width: "80%", height: 70, fontSize: "12px" }}
                      dangerouslySetInnerHTML={{
                        __html: t(item.keyComponent, item.text),
                      }}
                    ></p>
                    {item.icons && (
                      <div className="GothamBook">
                        <IconContainerComponent
                          icons={item.icons}
                          style={{ marginBottom: 0 }}
                          isNotResponsive={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Carousel.Item>
            );
          })}
      </Carousel>
      {buttonPurple()}
    </div>
  );
}
