import { Props } from "./types";
import { Style } from "./style";
import { Card, CardGroup } from "react-bootstrap";
import { TranslationService } from "../../../services/translation.service";
import { ModalSwitcher } from "../../../utils/modal-switcher";
export function CardGroupComponent(props: Props) {
  const cards = props.cards;
  const cardCondition: Boolean = cards.length === 1;
  const t = TranslationService.translate;
  return (
    <>
      {cardCondition ? (
        <Card style={{ width: "18rem" }}>
          <Card.Img
            // image position is not working
            variant="top"
            src={cards[0].imgUrl}
            alt={
              !props.isAPI
                ? t(cards[0].keyComponent, cards[0].imgAlt)
                : cards[0].imgAlt
            }
            style={{ cursor: cards[0].imgUrl ? "pointer" : "default" }}
            onClick={() =>
              cards[0].videoId
                ? ModalSwitcher.ShowVideoModal(
                    !props.isAPI
                      ? t(cards[0].keyComponent, cards[0].videoId)
                      : cards[0].videoId
                  )
                : null
            }
          />
          <Card.Body>
            <>
              <Card.Title
                className="fs-12 GothamMedium"
                dangerouslySetInnerHTML={{
                  __html: !props.isAPI
                    ? t(cards[0].keyComponent, cards[0].title)
                    : cards[0].title,
                }}
              ></Card.Title>
              {cards[0].imgUrl && <div style={{ marginBottom: 25 }}></div>}
              {cards[0].text && (
                <Card.Text
                  className="fs-10"
                  dangerouslySetInnerHTML={{
                    __html: !props.isAPI
                      ? t(cards[0].keyComponent, cards[0].text)
                      : cards[0].text,
                  }}
                ></Card.Text>
              )}
            </>{" "}
            {cards[0].isBtn &&
              props.buttonComponent(
                t(cards[0].keyComponent, cards[0].btnText),
                cards[0].btnUrl,
                cards[0].btnIsLong
              )}
          </Card.Body>
        </Card>
      ) : (
        <div
          className="d-flex text-align-center justify-content-center"
          style={
            props.backgroundImage
              ? {
                  backgroundImage: 'url("' + props.backgroundImage + '")',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "50% 50%",
                  width: "100%",
                }
              : { backgroundColor: "white" }
          }
        >
          <CardGroup
            style={
              props.width
                ? { ...Style.cardGroup, width: props.width }
                : Style.cardGroup
            }
          >
            {cards.map((card, index) => {
              let cardStyleCustom = { ...Style.card };
              card.backgroundColor &&
                (cardStyleCustom.backgroundColor = card.backgroundColor);
              card.textColor && (cardStyleCustom.color = card.textColor);
              card.isShadow &&
                (cardStyleCustom.boxShadow =
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)");
              return (
                <Card key={index + "Cards"} style={cardStyleCustom}>
                  <Card.Img
                    // image position is not working
                    variant="top"
                    src={card.imgUrl}
                    alt={card.imgAlt}
                    style={{ cursor: card.videoId ? "pointer" : "default" }}
                    onClick={() =>
                      card.videoId
                        ? ModalSwitcher.ShowVideoModal(card.videoId)
                        : null
                    }
                  />
                  <Card.Body>
                    <>
                      <Card.Title
                        className={
                          card.titleClass ? card.titleClass : "GothamBook"
                        }
                        style={{
                          color: card.titleColor ? card.titleColor : "black",
                          fontSize: card.titleSize ? card.titleSize : 16,
                          marginTop: 25,
                          marginBottom: 25,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: !props.isAPI
                            ? t(card.keyComponent, card.title)
                            : card.title,
                        }}
                      ></Card.Title>
                      {card.text && (
                        <Card.Text
                          className="fs-9"
                          dangerouslySetInnerHTML={{
                            __html: !props.isAPI
                              ? t(card.keyComponent, card.text)
                              : card.text,
                          }}
                        ></Card.Text>
                      )}
                    </>
                  </Card.Body>
                  {card.isBtn &&
                    props.buttonComponent(
                      t(card.keyComponent, card.btnText),
                      card.btnUrl,
                      card.btnIsLong
                    )}
                </Card>
              );
            })}
          </CardGroup>
        </div>
      )}
    </>
  );
}
