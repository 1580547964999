import { Col, Container, Row } from "react-bootstrap";
import theme from "../../../constants/theme";
import { TranslationService } from "../../../services/translation.service";
import { Props } from "./types";
export function TextContainerComponent(props: Props) {
  const t = TranslationService.translate;
  return (
    <Container className="d-flex justify-content-center" style={props.style}>
      <Row className="d-flex justify-content-center" style={{width:props.width ? props.width : "75%"}}>
        {props.texts.map((text, index) => {
          return (
            <Col
              className="text-center py-4 "
              key={index+ "Texts"}
              xs={12}
              sm={6}
              md={props.columnNumber ? 12 / props.columnNumber : 3}
              lg={props.columnNumber ? 12 / props.columnNumber : 4}
            >
              <div
                style={{
                  fontSize: text.titleSize
                    ? text.titleSize
                    : theme.FONT_SIZE.xlarge,
                  color: text.titleColor ? text.titleColor : theme.COLORS.blue,
                  fontWeight: text.titleWeight ? text.titleWeight : "Gotham",
                  width: "100%"
                }}
                dangerouslySetInnerHTML={{
                  __html: t(text.keyComponent, text.title),
                }}
              ></div>
              <div
                className="GothamLight"
                style={{
                  fontSize: text.textSize
                    ? text.textSize
                    : theme.FONT_SIZE.large,
                  color: text.textColor ? text.textColor : theme.COLORS.blue,
                  marginTop: 10,
                  width: "100%"
                }}
                dangerouslySetInnerHTML={{
                  __html: t(text.keyComponent, text.text),
                }}
              ></div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
