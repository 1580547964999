import { AssemblyScreenComponent } from "../../screens/Support/Assembly";
import { RequestScreenComponent } from "../../screens/Support/Requests";
import { ReturnsScreenComponent } from "../../screens/Support/Returns";
import { SSSScreenComponent } from "../../screens/Support/SSS";
import { Userguide } from "../../screens/Support/Userguide";
import { WarrantyScreenComponent } from "../../screens/Support/Warranty";

export const SupportScreenNavigator = [
  {
    path: "support/assembly",
    exact: true,
    screen: <AssemblyScreenComponent />,
  },
  {
    path: "/tr/destek/montaj",
    exact: true,
    screen: <AssemblyScreenComponent />,
  },
  {
    path: "/nl/steun/montage",
    exact: true,
    screen: <AssemblyScreenComponent />,
  },
  {
    path: "/rs/podrska/skupstina",
    exact: true,
    screen: <AssemblyScreenComponent />,
  },
  {
    path: "/fr/soutien/assemblee",
    exact: true,
    screen: <AssemblyScreenComponent />,
  },
  {
    path: "/uk/support/assembly",
    exact: true,
    screen: <AssemblyScreenComponent />,
  },
  {
    path: "/ro/sustine/asamblare",
    exact: true,
    screen: <AssemblyScreenComponent />,
  },
  {
    path: "support/request",
    exact: true,
    screen: <RequestScreenComponent />,
  },
  {
    path: "/tr/destek/istekler",
    exact: true,
    screen: <RequestScreenComponent />,
  },
  {
    path: "/nl/steun/aanvragen",
    exact: true,
    screen: <RequestScreenComponent />,
  },
  {
    path: "rs/podrska/zahteva",
    exact: true,
    screen: <RequestScreenComponent />,
  },
  {
    path: "fr/soutien/demandes",
    exact: true,
    screen: <RequestScreenComponent />,
  },
  {
    path: "uk/support/requests",
    exact: true,
    screen: <RequestScreenComponent />,
  },
  {
    path: "ro/sustine/cereri",
    exact: true,
    screen: <RequestScreenComponent />,
  },
  {
    path: "support/returns",
    exact: true,
    screen: <ReturnsScreenComponent />,
  },
  {
    path: "/tr/destek/iadeler",
    exact: true,
    screen: <ReturnsScreenComponent />,
  },
  {
    path: "/nl/steun/geeft-terug",
    exact: true,
    screen: <ReturnsScreenComponent />,
  },
  {
    path: "/rs/podrska/vraca",
    exact: true,
    screen: <ReturnsScreenComponent />,
  },
  {
    path: "/fr/soutien/retour",
    exact: true,
    screen: <ReturnsScreenComponent />,
  },
  {
    path: "/uk/support/returns",
    exact: true,
    screen: <ReturnsScreenComponent />,
  },
  {
    path: "/ro/sustine/intoarce",
    exact: true,
    screen: <ReturnsScreenComponent />,
  },
  {
    path: "/support/sss",
    exact: true,
    screen: <SSSScreenComponent />,
  },
  {
    path: "/tr/destek/sss",
    exact: true,
    screen: <SSSScreenComponent />,
  },
  {
    path: "/nl/steun/sss",
    exact: true,
    screen: <SSSScreenComponent />,
  },
  {
    path: "rs/podrska/sss",
    exact: true,
    screen: <SSSScreenComponent />,
  },
  {
    path: "fr/soutien/sss",
    exact: true,
    screen: <SSSScreenComponent />,
  },
  {
    path: "uk/support/sss",
    exact: true,
    screen: <SSSScreenComponent />,
  },
  {
    path: "ro/sustine/sss",
    exact: true,
    screen: <SSSScreenComponent />,
  },
  {
    path: "/support/userguide",
    exact: true,
    screen: <Userguide />,
  },
  {
    path: "/tr/destek/kullanici-rehberi",
    exact: true,
    screen: <Userguide />,
  },
  {
    path: "/nl/steun/gebruikershandleiding",
    exact: true,
    screen: <Userguide />,
  },
  {
    path: "/rs/podrska/uputstvo-za-upotrebu",
    exact: true,
    screen: <Userguide />,
  },
  {
    path: "/fr/soutien/uputstvo-za-upotrebu",
    exact: true,
    screen: <Userguide />,
  },
  {
    path: "/uk/support/userguide",
    exact: true,
    screen: <Userguide />,
  },
  {
    path: "/ro/sustine/manualul-utilizatorului",
    exact: true,
    screen: <Userguide />,
  },
  {
    path: "/support/warranty",
    exact: true,
    screen: <WarrantyScreenComponent />,
  },
  {
    path: "/tr/destek/garanti",
    exact: true,
    screen: <WarrantyScreenComponent />,
  },
  {
    path: "/nl/steun/garantie",
    exact: true,
    screen: <WarrantyScreenComponent />,
  },
  {
    path: "/rs/podrska/garancija",
    exact: true,
    screen: <WarrantyScreenComponent />,
  },
  {
    path: "/fr/soutien/garantie",
    exact: true,
    screen: <WarrantyScreenComponent />,
  },
  {
    path: "/uk/support/warranty",
    exact: true,
    screen: <WarrantyScreenComponent />,
  },
  {
    path: "/ro/sustine/garantie",
    exact: true,
    screen: <WarrantyScreenComponent />,
  },
];
