import React, { useEffect } from "react";

function Calendar() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

  return (
    <div>
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/suatsoyyigit/s?hide_gdpr_banner=1&primary_color=00b2ff"
        style={{ minWidth: "320px", height: "700px" }}
      ></div>
      <script
        type="text/javascript"
        src="https://assets.calendly.com/assets/external/widget.js"
        async
      ></script>
    </div>
  );
}

export default Calendar;
