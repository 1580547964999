import { Style } from "./style";
import { useState, useEffect } from "react";
import theme from "../../../../constants/theme";
import { HelpAndSupports } from "../../../../constants/helpAndSupport";
import { TranslationService } from "../../../../services/translation.service";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import { store } from "../../../../store";
export function Component() {
  const t = TranslationService.translate;
  const history = createBrowserHistory();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);
  return isMobile ? (
    <div className="text-center text-white d-none">
      <div className="p-8 pb-4" style={Style.helpAndSupport}>
        <div className="container inside">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-3">
              <img
                alt="serciar"
                src={theme.ICONS.logoBlue}
                style={theme.ICON_SIZE.size120}
              />
            </div>
          </div>
          <div className="row p-0 align-items-center justify-content-center">
            <div className="col-auto p-0-m-0">
              <div className="GothamBold fs-20" style={Style.title}>
                {t("Footer", "helpAndSupport")}
              </div>
            </div>
          </div>

          <div
            style={{
              justifyContent: "space-around",
              flexDirection: "row",
              display: "flex",
              marginTop: 20,
            }}
          >
            {HelpAndSupports.map(function (data, index) {
              return (
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <Link to={data.url}>
                    <div style={{}}>
                      <div style={{ flexDirection: "column", display: "flex" }}>
                        <div style={{}}>
                          <img
                            alt="serciar"
                            src={data.icon}
                            style={theme.ICON_SIZE.size64}
                            onClick={() => {
                              //history push
                              history.push(data.url);
                            }}
                          />
                        </div>

                        <div
                          style={{ fontSize: 10, marginTop: 10 }}
                          className="GothamBold"
                          onClick={() => {
                            //history push
                            history.push(data.url);
                          }}
                        >
                          {t("Footer", data.title)}
                        </div>
                        {/* <p>content</p> */}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="row p-0 m-0 pt-4 pt-sm-5">
            {/* {HelpAndSupports.map(function (data, index) {
              return (
                
                <div
                  className="col-sm-12 col-md-12 col-lg-4 col-xl-4 pt-3 pt-sm-0 text-center"
                  key={index + "helpAndSupport"}
                  onClick={() => {
                    //history push
                    history.push(data.url);
                  }}
                >
                  
                  <Link to={data.url}>
                    
                    <div className="col align-items-center justify-content-center pb-3">
                    
                      <div>
                        <img
                          alt="serciar"
                          src={data.icon}
                          style={theme.ICON_SIZE.size64}
                          onClick={() => {
                            //history push
                            history.push(data.url);
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="GothamBold fs-14"
                      onClick={() => {
                        //history push
                        history.push(data.url);
                      }}
                    >
                      {t("Footer", data.title)}
                    </div>
                    <div
                      className="GothamBook fs-14"
                      dangerouslySetInnerHTML={{
                        __html: t("Footer", data.text),
                      }}
                    />
                  </Link>
                </div>

                
              );
            })} */}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="text-center text-white d-none">
      <div className="p-8 pb-4" style={Style.helpAndSupport}>
        <div className="container inside">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-3">
              <img
                alt="serciar"
                src={theme.ICONS.logoBlue}
                style={theme.ICON_SIZE.size120}
              />
            </div>
          </div>
          <div className="row p-0 align-items-center justify-content-center">
            <div className="col-auto p-0-m-0">
              <div className="GothamBold fs-20" style={Style.title}>
                {t("Footer", "helpAndSupport")}
              </div>
            </div>
          </div>
          <div className="row p-0 m-0 pt-4 pt-sm-5 justify-content-center">
            {HelpAndSupports.map(function (data, index) {
              return (
                <div
                  className="col-sm-12 col-md-12 col-lg-4 col-xl-4 pt-3 pt-sm-0 text-center"
                  key={index + "helpAndSupport"}
                  onClick={() => {
                    //history push
                    history.push(data.url);
                  }}
                >
                  <Link to={data.url}>
                    <div className="row align-items-center justify-content-center pb-3">
                      <div>
                        <img
                          alt="serciar"
                          src={data.icon}
                          style={theme.ICON_SIZE.size64}
                          onClick={() => {
                            //history push
                            history.push(data.url);
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="GothamBold fs-14"
                      onClick={() => {
                        //history push
                        history.push(data.url);
                      }}
                    >
                      {t("Footer", data.title)}
                    </div>
                    <div
                      className="GothamBook fs-14"
                      dangerouslySetInnerHTML={{
                        __html: t("Footer", data.text),
                      }}
                    />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
