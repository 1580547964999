import { height } from "@mui/system";
import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import useWindowDimensions from "../../../../services/getWindowSize.service";
import { TranslationService } from "../../../../services/translation.service";
import { store } from "../../../../store";
import { Props } from "./types";
export function Component(props: Props) {
  const [index, setIndex] = useState(0);
  const t = TranslationService.translate;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);
  const handleSelect = (
    selectedIndex: React.SetStateAction<number>,
    e: any
  ) => {
    setIndex(selectedIndex);
  };
  const screenSize = useWindowDimensions().width;
  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      pause={"hover"}
      controls={false}
    >
      {props.sliderTopItems &&
        props.sliderTopItems.map((item, index) => {
          return (
            <Carousel.Item
              style={{
                backgroundColor: props.backgroundColor
                  ? props.backgroundColor
                  : "white",
              }}
              interval={2000}
              key={index + "sliderTopItems"}
            >
              <div
                className={
                  "text-center mt-4 " + props.isNotContainer ? "" : "container"
                }
                style={{ marginBottom: 40, textAlign: "center" }}
              >
                <Container>
                  <h3
                    className="GothamMedium mt-4"
                    style={{
                      fontSize: isMobile ? 20 : 30,
                    }}
                  >
                    {item.title &&
                      t("ProductsApp", item.title).replace(
                        /<br\s*[\/]?>/gi,
                        ""
                      )}
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        item.text &&
                        t("ProductsApp", item.text).replace(
                          /<br\s*[\/]?>/gi,
                          ""
                        ),
                    }}
                  ></p>
                </Container>
                <div className="justify-content-center d-flex">
                  <img
                    className="d-block"
                    style={
                      //width 95 ve height yoktu. pure sercair app kısmı için ayarlandı
                      props.isPhone
                        ? screenSize < 600
                          ? { width: "50%", height: 364 }
                          : item.witdh
                          ? {
                              width:
                                window.screen.width < 1500
                                  ? item.witdh * 0.9
                                  : item.witdh,
                            }
                          : { width: "100%" }
                        : screenSize < 600
                        ? { width: "95%" }
                        : item.witdh
                        ? {
                            width:
                              window.screen.width < 1500
                                ? item.witdh * 0.9
                                : item.witdh,
                          }
                        : { width: "100%" }
                    }
                    src={item.imgUrl}
                    alt={t("ProductsApp", item.imgAlt)}
                  />
                </div>
              </div>
            </Carousel.Item>
          );
        })}
    </Carousel>
  );
}
