import theme from "./theme";

const langCheck = localStorage.getItem("lang");

export interface HelpAndSupport {
  title: string;
  text: string;
  url: string;
  icon: string;
}
export const HelpAndSupports: HelpAndSupport[] = [
  {
    title: "videosTitle",
    text: "videosText",
    url:
      langCheck === "en"
        ? "/videos"
        : langCheck === "tr"
        ? "/tr/videolar"
        : langCheck === "nl"
        ? "/nl/videos"
        : langCheck === "fr"
        ? "/fr/videos"
        : langCheck === "uk"
        ? "/uk/videos"
        : langCheck === "ro"
        ? "/ro/videoclipuri"
        : "/rs/video-snimci",
    icon: theme.IMAGES.footer.video,
  },
  {
    title: "phoneTitle",
    text: "phoneText",
    url:
      langCheck === "en"
        ? "/contact"
        : langCheck === "tr"
        ? "/tr/iletisim"
        : langCheck === "nl"
        ? "/nl/contact"
        : langCheck === "fr"
        ? "/fr/contact"
        : langCheck === "uk"
        ? "/uk/contact"
        : langCheck === "ro"
        ? "/ro/contactati"
        : "/rs/kontakt",
    icon: theme.IMAGES.footer.phone,
  },
];
