const langCheck = localStorage.getItem("lang");
export interface Footer {
  key: string;
  url: any;
}
export const Footers: Footer[] = [
  {
    key: "cookiesTitle",
    url:
      langCheck == "en"
        ? "/agreements/cookie"
        : langCheck === "tr"
        ? "/tr/anlasmalar/cerezler"
        : langCheck === "nl"
        ? "/nl/overeenkomsten/cookie"
        : langCheck === "fr"
        ? "/fr/accords/cookies"
        : langCheck === "uk"
        ? "/uk/agreements/cookie"
        : langCheck === "ro"
        ? "/ro/acorduri/cookie"
        : "/rs/sporazumi/kolacic",
  },
  {
    key: "privacyTitle",
    url:
      langCheck == "en"
        ? "/agreements/privacy"
        : langCheck === "tr"
        ? "/tr/anlasmalar/gizlilik"
        : langCheck === "nl"
        ? "/nl/overeenkomsten/privacy"
        : langCheck === "fr"
        ? "/fr/accords/vie-privee"
        : langCheck === "uk"
        ? "/uk/agreements/privacy"
        : langCheck === "ro"
        ? "/ro/acorduri/intimitate"
        : "/rs/sporazumi/privatnost",
  },
  {
    key: "returnTitle",
    url:
      langCheck == "en"
        ? "/agreements/return"
        : langCheck === "tr"
        ? "/tr/anlasmalar/geri-iade"
        : langCheck === "nl"
        ? "/nl/overeenkomsten/opbrengst"
        : langCheck === "fr"
        ? "/fr/accords/retour"
        : langCheck === "uk"
        ? "/uk/agreements/return"
        : langCheck === "ro"
        ? "/ro/acorduri/intoarcere"
        : "/rs/sporazumi/return",
  },
  {
    key: "distanceTitle",
    url:
      langCheck == "en"
        ? "/agreements/distance"
        : langCheck === "tr"
        ? "/tr/anlasmalar/mesafe"
        : langCheck === "nl"
        ? "/nl/overeenkomsten/afstand"
        : langCheck === "fr"
        ? "/fr/accords/distance"
        : langCheck === "uk"
        ? "/uk/agreements/distance"
        : langCheck === "ro"
        ? "/ro/acorduri/distanta"
        : "/rs/sporazumi/udaljenost",
  },
];
