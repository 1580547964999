import { Lang } from '../constants/langs';

export class StorageService {

    public static setSomething(model: any){
        return localStorage.setItem('any', JSON.stringify(model));
    }

    public static getSomething(): Promise<Lang> {
        return JSON.parse(localStorage.getItem('any') as any);
    }

    public static getMainVideoDate(name:string): Promise<any> {
        return JSON.parse(localStorage.getItem(name) as any);
    }

    public static setMainVideoDate(name: string,model: any){
        return localStorage.setItem(name, JSON.stringify(model));
    }

    public static getLanguageCode() {
        let x = JSON.parse(localStorage.getItem('sercair') as any);
        return x.code;
    }
   
}