export class APIURLs {
    public static readonly BASE_URL = 'https://apierp.sercair.com:3001/api/V1';

    public static readonly MEDIA_URL = 'https://apierp.sercair.com:3001/assets/webAdmin/';

    static USER_GUIDE = "/userGuide/list";  //ok

    static GET_VIDEOCARDS = "/videoCard/list"; //ok

    static GET_CATALOGS = "/catalog/list"; //ok

    static POST_MAIL = "/webMail/send"; //ok

    //salesPoints list

    static GET_SALESPOINTS = "/salePoint/getSalePoints";
    static GET_COUNTRIES = "/country/list"
    static GET_DISTRINCTS = "/district/createDistrict"
    static GET_CUBEPOINTS = "/cubePoint/getCubePoints"

    static GET_CAMPAIGNS = "/campaign/getCampaigns" //ok
    static GET_OPENINGIMGS = "/openingImg/list" //ok
    static GET_OPENINGVIDEO = "/videoModal/list"
}