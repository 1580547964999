import { Row } from "react-bootstrap";
import { SliderComponent } from "../../components/SliderComponent";
import { SliderContactApp } from "../../constants/datas";
import theme from "../../constants/theme";
import { TranslationService } from "../../services/translation.service";
import { store } from "../../store";
import { Props } from "./types";
import React, { useEffect } from "react";
import "./contact.css";

export function ScreenComponent(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "Contact";
  const imgStyle = { width: 20, marginRight: 20 };
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ marginTop: 50 }}>
      <SliderComponent sliderItems={SliderContactApp} />
      <div className="d-flex justify-content-center slidercomp-mobile">
        <Row
          className={
            isMobile
              ? "d-flex justify-content-around align-items-left mx-4 mobile-contact"
              : "d-flex justify-content-around text-center align-items-center mx-4"
          }
          style={{ height: 250, width: "90%" }}
        >
          <div
            className={
              isMobile
                ? "d-flex  justify-content-left col-sm-12 col-md-12 col-lg-6 col-xl-3"
                : "d-flex  justify-content-center col-sm-12 col-md-12 col-lg-6 col-xl-3"
            }
          >
            <img
              style={imgStyle}
              alt="sercair"
              src={theme.ICONS.contact.phone}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: t(translationKey, "subPhone"),
              }}
            />
          </div>
          <div
            className={
              isMobile
                ? "d-flex  justify-content-left col-sm-12 col-md-12 col-lg-6 col-xl-3"
                : "d-flex  justify-content-center col-sm-12 col-md-12 col-lg-6 col-xl-3"
            }
          >
            <img
              style={imgStyle}
              alt="sercair"
              src={theme.ICONS.contact.mail}
            />
            <div
              dangerouslySetInnerHTML={{ __html: t(translationKey, "subMail") }}
            />
          </div>
          <div className="d-flex  justify-content-center col-sm-12 col-md-12 col-lg-6 col-xl-3">
            <img
              style={imgStyle}
              alt="sercair"
              src={theme.ICONS.contact.location}
            />
            <div
              style={{ marginTop: isMobile ? 10 : 0 }}
              dangerouslySetInnerHTML={{
                __html: t(translationKey, "subAddress"),
              }}
            />
          </div>

          {/* <div className="d-flex justify-content-center col-sm-12 col-md-12 col-lg-6 col-xl-3">
            <img
              style={imgStyle}
              alt="sercair"
              src={theme.ICONS.contact.location}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: t(translationKey, "subAddress2"),
              }}
            />
          </div> */}
        </Row>
      </div>
    </div>
  );
}
