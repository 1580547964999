import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { TranslationService } from "../../../../services/translation.service";
import { store } from "../../../../store";
import { Style } from "./style";
import { Props } from "./types";

export function Component(props: Props) {
  const [index, setIndex] = useState(0);
  const t = TranslationService.translate;
  const handleSelect = (
    selectedIndex: React.SetStateAction<number>,
    e: any
  ) => {
    setIndex(selectedIndex);
  };
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);

    //getVideoController(); --> video değişecek sonra açılacak
  }, []);
  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      pause={"hover"}
      controls={false}
    >
      {props.sliderBottomItems &&
        props.sliderBottomItems.map((card, index) => {
          let cardStyleCustom = { ...Style.card };
          card.backgroundColor &&
            (cardStyleCustom.backgroundColor = card.backgroundColor);
          card.textColor && (cardStyleCustom.color = card.textColor);
          card.isShadow &&
            (cardStyleCustom.boxShadow =
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)");
          return (
            <Carousel.Item
              style={{
                backgroundColor: "white",
              }}
              interval={200000}
              key={index + "Carousel"}
            >
              <Card
                key={index}
                style={{
                  ...cardStyleCustom,
                  minHeight: isMobile ? 10 : props.minHeight ? props.minHeight : 0,
                }}
              >
                <Card.Img
                  // image position is not working
                  variant="top"
                  src={card.imgUrl}
                  alt={card.imgAlt}
                />
                <Card.Body>
                  <>
                    <Card.Title
                      className={
                        card.titleClass ? card.titleClass : "GothamBook"
                      }
                      style={{
                        textAlign: "center",
                        color: card.titleColor ? card.titleColor : "black",
                        fontSize: isMobile ? 14 : card.titleSize ? card.titleSize : 16,
                        marginTop: isMobile ? 0 : 25,
                        marginBottom: isMobile ? 15 : 25,
                        fontWeight: "bold",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: t(card.keyComponent, card.title),
                      }}
                    ></Card.Title>
                    
                    {props.textEnable && card.text && (
                      <Card.Text
                        className="fs-9"
                        dangerouslySetInnerHTML={{
                          __html: t(card.keyComponent, card.text),
                        }}
                      ></Card.Text>
                    )}
                  </>
                </Card.Body>
                {card.isBtn &&
                  props.buttonComponent(
                    t(card.keyComponent, card.btnText),
                    card.btnUrl,
                    card.btnIsLong
                  )}
              </Card>
            </Carousel.Item>
          );
        })}
    </Carousel>
  );
}
