import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import "./AirQualityIndex.css";

function AirQualityIndex(props) {
  const [contain, setContain] = useState(props.container);
  const [isYellow, setIsYellow] = useState(true);
  useEffect(() => {
    setContain(props.container);
    setIsYellow(props.bgcolor == "#F2EB18" ? true : false);
  }, [props.toggle]);
  return (
    <div className="Container" style={{ backgroundColor: `${props.bgcolor}` }}>
      <div
        className="Title"
        style={{
          color: props.isPassive ? "#949494" : isYellow ? "black" : "white",
        }}
      >
        Hava Kalite Indeksi
      </div>
      <div className="InnerContainer">
        <div className="Slider">
          <div className="InnerSlider">
            <CircularProgressbar
              //  value={!props.t ? props.in : (100 * (props.value / props.max1)) }
              value={props.value}
              text={props.value}
              maxValue={props.max1}
              minValue={props.min}
              size={150}
              strokeWidth={7}
              progresWidth={15}
              disabled={true}
              knobRadius={0}
              circleColor={"#25e4db"}
              circleWidth={2}
              styles={buildStyles({
                // Rotation of path and trail, in number of turns (0-1)
                rotation: 0.5,

                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: "round",

                // Text size
                textSize: "28px",
                textColor: "#fff",

                // How long animation takes to go from one percentage to another, in seconds
                pathTransitionDuration: 0.5,

                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',

                // Colors
                //${ (1000 * (props.value / props.max1)) / 100}
                pathColor: "#ffffff",
                textColor: isYellow ? "#000" : "#fff",
                trailColor: "rgba(0, 0, 0, 0.15)",
                backgroundColor: "#ffffff",
              })}
            />
          </div>
        </div>
        <div className="Info">
          <div
            className="Situation"
            style={{ color: isYellow ? "black" : "white" }}
          >
            {props.airSituation}
          </div>
          <div className="Text" style={{ color: isYellow ? "black" : "white" }}>
            {" "}
            {props.text}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AirQualityIndex;
