import axios from "axios";
import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import Artboard1 from "../../../../assets/images/WeatherImages/SVG/Artboard1.svg";
import Artboard3 from "../../../../assets/images/WeatherImages/SVG/Artboard3.svg";
import Artboard2 from "../../../../assets/images/WeatherImages/SVG/Artboard2.svg";
import Artboard4 from "../../../../assets/images/WeatherImages/SVG/Artboard4.svg";
import Artboard5 from "../../../../assets/images/WeatherImages/SVG/Artboard5.svg";
import Artboard6 from "../../../../assets/images/WeatherImages/SVG/Artboard6.svg";
import Artboard7 from "../../../../assets/images/WeatherImages/SVG/Artboard7.svg";
import Artboard8 from "../../../../assets/images/WeatherImages/SVG/Artboard8.svg";
import Artboard9 from "../../../../assets/images/WeatherImages/SVG/Artboard9.svg";
import Artboard10 from "../../../../assets/images/WeatherImages/SVG/Artboard10.svg";
import Artboard11 from "../../../../assets/images/WeatherImages/SVG/Artboard11.svg";
import windspeed from "../../../../assets/images/WeatherImages/SVG/windspeed.gif";
import humidity3 from "../../../../assets/images/WeatherImages/SVG/humidity3.gif";

import Info from "../../Info";
import NH3 from "../../../../assets/images/WeatherImages/SVG/new_nh3.svg";
import O3 from "../../../../assets/images/WeatherImages/SVG/O3.svg";
import NO from "../../../../assets/images/WeatherImages/SVG/NO.svg";
import NO2 from "../../../../assets/images/WeatherImages/SVG/NO2.svg";
import SO2 from "../../../../assets/images/WeatherImages/SVG/SO2.svg";
import Detail from "../../Detail";

import "./AvgYearList.css";
import Moment from "../../moments/moment";
import WeatherCard from "../../weatherCard";
import Button from "../../Button/Button";

const AvgYearList12 = (props) => {
  const [moment, setMoment] = React.useState(null);
  const [touch, setTouch] = React.useState(null);

  return (
    <Fragment>
      <div className="scroll_AvgYearList2">
        <div>
          {" "}
          {/*1. data gelmesi için*/}
          <div className="AvgYear_AvgYearList2">
            <Info
              icon={Artboard1}
              //  butt={props.temp}
              variable="Sıcaklık"
              value={props.Temparature}
              info={props.temp_pre}
              togglePressable={false}
              type={"°C"}
            />
          </div>
          <div className="year_AvgYearList">
            <Info
              icon={humidity3}
              //  butt={props.hum}
              variable="Nem"
              value={"%" + props.Hummidty}
              info={props.hum_pre}
              togglePressable={false}
            />
          </div>
          <div className="year_AvgYearList">
            <Info
              icon={Artboard3}
              // butt={props.wd}
              variable="Rüzgar yönü"
              value={props.WindDirection}
              info={props.wd_pre}
              togglePressable={false}
              type={"°"}
            />
          </div>
          <div className="year_AvgYearList">
            <Info
              icon={windspeed}
              //butt={props.ws}
              variable="Rüzgar Hızı"
              value={props.WindSpeed}
              info={props.ws_pre}
              togglePressable={false}
              type={"m/s"}
            />
          </div>
          <div className="year_AvgYearList">
            <Info
              icon={Artboard5}
              //butt={props.pres}
              variable="Basınç"
              value={props.Pressure}
              info={props.pres_pre}
              togglePressable={false}
              type={"p"}
            />
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </Fragment>
  );
};

export default AvgYearList12;
