import React from "react";
import Button from "../../components/WeatherComponents/Button/Button";
import theme from "../../constants/theme";
import "./info.css";

const Info = (props) => {
  return (
    <Button
      type="button"
      onClick={props.butt}
      pressable={props.togglePressable}
    >
      <div
        key={props.keyy}
        className={
          props.isPassive ? "infoPassive" : props.info ? "pressed" : "info"
        }
      >
        <div className="leftcontainer">
          <div>
            <img
              style={{ height: 60, padding: 10, flex: 3 }}
              src={props.icon}
            />
          </div>

          <div className="var_style">
            <div
              style={{ fontWeight: "100", fontFamily: theme.FONTS.GothamBook }}
              className={props.isPassive ? "variablePassive" : "variable"}
            >
              {props.variable}
            </div>
          </div>
        </div>
        {props.value && (
          <div
            className={props.style ? "value" : "valueCentered"}
            style={{ flexDirection: "row", display: "flex" }}
          >
            <p>{props.value}&nbsp; </p>
            <p style={{fontSize: 10}}>{props.type}</p>
          </div>
        )}
      </div>
    </Button>
  );
};

export default Info;
