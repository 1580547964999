import { useEffect, useState } from "react";
import { FullImageContentComponent } from "../../../components/Shared/FullImageContent/component";
import { SliderProductsComponent } from "../../../components/Shared/SliderProductsComponent/component";
import { SliderComponent } from "../../../components/SliderComponent";
import {
  CarouselProductsApp,
  SliderProductsApp,
} from "../../../constants/datas";
import theme from "../../../constants/theme";
import { TranslationService } from "../../../services/translation.service";
import { store } from "../../../store";
import { Props } from "./types";

export function AppTabPage(props: Props) {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
  }, []);
  const t = TranslationService.translate;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{
        backgroundColor: theme.COLORS.white,
        marginTop: props.isMobile ? 80 : 0,
      }}
    >
      <SliderComponent sliderItems={SliderProductsApp} />
      <SliderProductsComponent
        sliderTopItems={CarouselProductsApp}
        textPosition="top"
        isNotContainer={false}
        isPhone={true}
      />
      <div style={{ marginTop: 50 }} />
      <FullImageContentComponent
        imgUrl={theme.IMAGES.products.app.airQuality}
        imgAlt={t("ProductsApp", "imgAltSlider")}
        isBtn={false}
        pStyle={{ width: isMobile ? 280 : 500 }}
        hStyle={{ width: isMobile ? 300 : 500, fontSize: 25 }}
        textPosition={isMobile ? "left" : "right"}
        title={
          window.screen.width < 1500
            ? t("ProductsApp", "airQualityTitle").replace(/<br\s*\/?>/gi, "")
            : t("ProductsApp", "airQualityTitle")
        }
        text={t("ProductsApp", "airQualityText")}
        titleColor={theme.COLORS.blue}
      />
      <div style={{ marginTop: 0 }} />

      {isMobile ? (
        <FullImageContentComponent
          imgUrl={theme.IMAGES.products.app.handPhoneWithMount}
          imgAlt={t("ProductsApp", "dailyAirQualityAlt")}
          isBtn={false}
          textPosition={isMobile ? "left" : "right"}
          pStyle={{ width: isMobile ? 280 : 500, color: theme.COLORS.black }}
          hStyle={{ width: isMobile ? 300 : 500, fontSize: 25 }}
          title={t("ProductsApp", "dailyAirQualityTitle")}
          text={t("ProductsApp", "dailyAirQualityText")}
          titleColor={theme.COLORS.black}

          //textBackgroundImg={theme.IMAGES.products.app.mount}
        />
      ) : (
        <FullImageContentComponent
          hStyle={{ fontSize: 25 }}
          imgUrl={theme.IMAGES.products.app.handPhone}
          imgAlt={t("ProductsApp", "dailyAirQualityAlt")}
          isBtn={false}
          textPosition="left"
          title={t("ProductsApp", "dailyAirQualityTitle")}
          text={t("ProductsApp", "dailyAirQualityText")}
          titleColor={theme.COLORS.white}
          pStyle={{ color: theme.COLORS.white }}
          textBackgroundImg={theme.IMAGES.products.app.mount}
        />
      )}

      <div style={{ marginTop: 50 }} />
      <FullImageContentComponent
        pStyle={{ width: isMobile ? 290 : 500 }}
        hStyle={{ width: isMobile ? 300 : 500, marginTop: -50 }}
        imgUrl={theme.IMAGES.products.app.emergency}
        imgAlt={t("ProductsApp", "emergencyServiceAlt")}
        //textBackgroundImg={theme.IMAGES.}
        isBtn={false}
        textPosition={isMobile ? "left" : "right"}
        title={t("ProductsApp", "emergencyServiceTitle")}
        text={t("ProductsApp", "emergencyServiceText")}
      />
    </div>
  );
}
