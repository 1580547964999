import React, { useEffect } from "react";
import { Fragment } from "react";

import AirQualityIndex from "../AirQualityIndex/AirQualityIndex";

import AvgYearList11 from "../Lists/AvgYearList/AvgYearList11";
import AvgYearList12 from "../Lists/AvgYearList/AvgYearList12";
import "./AvgYear.css";

import axios from "axios";
import YearlySummary from "../YearlySummary/YearlySummary";
import AvgYearPassive from "./AvgYearPassive";

const AvgYear = (props) => {
  const [avgYear, setAvgYear] = React.useState(null);
  const [situation, setSituation] = React.useState(null);
  const [maxval, setMaxval] = React.useState();
  const [valuee, setValuee] = React.useState();
  const [int, setInt] = React.useState();
  const [unit, setUnit] = React.useState();
  const [change, setChange] = React.useState(true);
  const [ins, setIns] = React.useState(true);
  const [tog, setTog] = React.useState(false);
  const [sit, setSit] = React.useState();
  const [toggle, setToggle] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [moment, setMoment] = React.useState(null);
  const [minval, setMinval] = React.useState();
  const [color, setColor] = React.useState();
  const [temptoggle, setTemptoggle] = React.useState(false);
  const [humtoggle, setHumtoggle] = React.useState(false);
  const [wdtoggle, setWdtoggle] = React.useState(false);
  const [wstoggle, setWstoggle] = React.useState(false);
  const [no2toggle, setNo2toggle] = React.useState(false);
  const [so2toggle, setSo2toggle] = React.useState(false);
  const [pm2_5toggle, setPm2_5toggle] = React.useState(false);
  const [pm10toggle, setPm10toggle] = React.useState(false);
  const [co2toggle, setCo2toggle] = React.useState(false);
  const [cotoggle, setCotoggle] = React.useState(false);
  const [vocstoggle, setVocstoggle] = React.useState(false);
  const [nh3toggle, setNh3toggle] = React.useState(false);
  const [notoggle, setNotoggle] = React.useState(false);
  const [o3toggle, setO3toggle] = React.useState(false);
  const [prestoggle, setPresstoggle] = React.useState(false);
  const [col, setCol] = React.useState();

  const [secondsit, setSecondSit] = React.useState();
  const [secondvaluee, setSecondValuee] = React.useState("Sıcaklık");
  const [secondint, setSecondInt] = React.useState(null);
  const [secondminval, setSecondMinval] = React.useState();
  const [secondmaxval, setSecondMaxval] = React.useState();
  const [secondunit, setSecondUnit] = React.useState();
  const [secondchange, setSecondChange] = React.useState(true);
  const [secondins, setSecondIns] = React.useState(true);
  const [secondtog, setSecondTog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [secondopen, setSecondOpen] = React.useState(false);
  const [secondcolor, setSecondColor] = React.useState();
  const [aqi, setAqi] = React.useState();
  const [messagee, setMessagee] = React.useState("Spring");
  const [avgStatus, setAvgStatus] = React.useState();
  const [isSeason, setIsSeason] = React.useState();
  const [seasonStatus, setSeasonStatus] = React.useState();
  const [winter, setWinter] = React.useState();
  const [autumn, setAutumn] = React.useState();
  const [spring, setSpring] = React.useState();
  const [summer, setSummer] = React.useState();

  React.useEffect(() => {

    var config = {
      method: "get",
      url: `http://78.186.56.6:9005/api/v1/season/getSeasonExistSaved?KE=${props.lang}&KB=${props.long}`,
    };

    axios(config)
      .then(function (response) {
        
        setSeasonStatus(response.status)(
          seasonStatus !== 500
            ? (setIsSeason(response.data),
              setAutumn(response.data.data.Autumn),
              setWinter(response.data.data.Winter),
              setSpring(response.data.data.Spring),
              setSummer(response.data.data.Summer))
            : (setIsSeason(null),
              setAutumn(null),
              setWinter(null),
              setSpring(null),
              setSummer(null))
        );
      })
      .catch(function (error) {});
  }, []);

  React.useEffect(() => {
    var config = {
      method: "get",
      url: `http://78.186.56.6:9005/api/v1/season/getSeasonSaved?KE=${props.lang}&KB=${props.long}&checkSeason=${messagee}`,
    };

    axios(config)
      .then((response) => response.data)
      .then((response) => {
        setAvgStatus(response.status)(
          avgStatus !== 500
            ? (setAvgYear(response.data[0]),
              setSituation(response.data[1]),
              setCol(response.data[2]),
              setAqi(response.data[3]),
              setOpen(false))
            : (setAvgYear(null),
              setSituation(null),
              setCol(null),
              setAqi(null),
              setOpen(null))
        );
      })
      .catch(function (error) {
        // if(open !== false) {setAvgStatus(500);}
      })
      .finally(() => setLoading(false));
  }, [messagee]);

  const chooseMessage = (message) => {
    setMessagee(message);
  };

  if (avgStatus == 200) {
    if (!avgYear) return null;
    if (!situation) return null;
    if (!col) return null;
    if (!aqi) return null;
  }

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const days = [
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ];
  const day = days[current.getDay()];
  const timer = current.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  const updateTemp = () => {
    setMaxval(50);
    setValuee("Sıcaklık");
    setInt(avgYear.Temp.toFixed(0));
    setUnit("°C");
    setChange(true);
    setIns(false);
    setTog(false);
    setSit(situation.Temp);
    setToggle(false);
    setTemptoggle(true);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPresstoggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
    setPm10toggle(false);

    setSecondMaxval(
      -20 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= -4
        ? -4
        : -4 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 6
        ? 6
        : 6 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 14
        ? 14
        : 14 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 17
        ? 17
        : 17 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 19
        ? 19
        : 19 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 23
        ? 23
        : 23 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 25
        ? 25
        : 25 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 28
        ? 28
        : 28 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 37
        ? 37
        : 37 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 46
        ? 46
        : 46 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 50
        ? 50
        : 50
    );
    setSecondMinval(
      -20 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= -4
        ? -20
        : -4 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 6
        ? -4
        : 6 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 14
        ? 6
        : 14 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 17
        ? 14
        : 17 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 19
        ? 17
        : 19 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 23
        ? 19
        : 23 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 25
        ? 23
        : 25 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 28
        ? 25
        : 28 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 37
        ? 28
        : 37 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 46
        ? 37
        : 46 < avgYear.Temp.toFixed(0) && avgYear.Temp.toFixed(0) <= 50
        ? 46
        : 0
    );
    setSecondValuee("Sıcaklık");
    setSecondInt(avgYear.Temp.toFixed(0));
    setSecondUnit("°C");
    setSecondChange(true);
    setSecondIns(false);
    setSecondTog(false);
    setSecondSit(situation.Temp);
    setTemptoggle(true);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);

    setSecondColor(col.Temp);
    setPresstoggle(false);
    setSecondOpen(true);
  };
  const updateHum = () => {
    setValuee("Nem");
    setInt(avgYear.Hum.toFixed(0));
    setUnit("%");
    setMaxval(1000);
    setChange(false);
    setIns(false);
    setTog(true);
    setSit(situation.Hum);
    setToggle(true);
    setTemptoggle(false);
    setHumtoggle(true);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setPresstoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
    setPm10toggle(false);
    setSecondOpen(true);
    setSecondValuee("Nem");
    setSecondInt(avgYear.Hum.toFixed(0));
    setSecondSit(situation.Hum);
    setSecondMaxval(100);
    setSecondMinval(0);
    setSecondIns(false);
    setSecondUnit("%");
    setSecondTog(true);
    setSecondChange(true);
    setSecondColor(col.Hum);
    setTemptoggle(false);
    setPresstoggle(false);
    setHumtoggle(true);
    setWdtoggle(false);
    setWstoggle(false);
  };
  const updateWD = () => {
    setValuee("Rüzgar Yönü");
    setInt(avgYear.WD.toFixed(0));
    setPm10toggle(false);
    setMaxval(360);
    setIns(false);
    setUnit("°");
    setTog(true);
    setChange(true);
    setSit(situation.WD);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(true);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setPresstoggle(false);
    setNotoggle(false);
    setO3toggle(false);

    setSecondValuee("Rüzgar Yönü");
    setSecondInt(avgYear.WD.toFixed(0));
    setSecondOpen(true);
    setSecondSit();
    setSecondMaxval(360);
    setSecondMinval(0);
    setSecondIns(false);
    setSecondUnit("°");
    setSecondTog(true);
    setSecondChange(true);
    setSecondColor(col.WS);
    setTemptoggle(false);
    setPresstoggle(false);
    setHumtoggle(false);
    setWdtoggle(true);
    setWstoggle(false);
  };
  const updateWS = () => {
    setValuee("Rüzgar Hızı");
    setInt(avgYear.WS.toFixed(2));
    setChange(true);
    setMaxval(50);
    setIns(false);
    setUnit("m/s");
    setTog(true);
    setSit(situation.WS);
    setPresstoggle(false);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(true);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);

    setSecondValuee("Rüzgar Hızı");
    setSecondInt(avgYear.WS.toFixed(0));
    setSecondSit(situation.WS);
    setPresstoggle(false);
    setSecondIns(false);
    setSecondOpen(true);
    setSecondUnit("m/s");
    setSecondTog(true);
    setSecondChange(true);
    setSecondColor(col.WS);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(true);

    setPresstoggle(false);
  };
  const updatePres = () => {
    setValuee("Basınç");
    setInt(avgYear.Pres.toFixed(0));
    setChange(true);
    setMaxval(1500);
    setUnit("p");
    setIns(false);
    setPresstoggle(true);
    setTog(true);
    setSit(situation.Pres);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setPresstoggle(true);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateNO2 = () => {
    setValuee("NO2");
    setInt(avgYear.NO2.toFixed(0));
    setOpen(true);
    setMaxval(
      0 < avgYear.NO2.toFixed(0) && avgYear.NO2.toFixed(0) <= 10
        ? 10
        : 10 < avgYear.NO2.toFixed(0) && avgYear.NO2.toFixed(0) <= 25
        ? 25
        : 25 < avgYear.NO2.toFixed(0) && avgYear.NO2.toFixed(0) <= 40
        ? 40
        : 40 < avgYear.NO2.toFixed(0) && avgYear.NO2.toFixed(0) <= 60
        ? 60
        : 60 < avgYear.NO2.toFixed(0) && avgYear.NO2.toFixed(0) <= 120
        ? 120
        : 300
    );
    setMinval(
      0 < avgYear.NO2.toFixed(0) && avgYear.NO2.toFixed(0) <= 10
        ? 0
        : 10 < avgYear.NO2.toFixed(0) && avgYear.NO2.toFixed(0) <= 25
        ? 10
        : 25 < avgYear.NO2.toFixed(0) && avgYear.NO2.toFixed(0) <= 40
        ? 25
        : 40 < avgYear.NO2.toFixed(0) && avgYear.NO2.toFixed(0) <= 60
        ? 40
        : 60 < avgYear.NO2.toFixed(0) && avgYear.NO2.toFixed(0) <= 120
        ? 60
        : 120
    );
    setColor(col.NO2);
    setChange(true);

    setUnit("μg/m3");
    setIns(false);
    setTog(true);
    setSit(situation.NO2);
    setPresstoggle(false);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(true);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateSO2 = () => {
    setValuee("SO2");
    setInt(avgYear.SO2.toFixed(0));
    setMaxval(
      0 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 10
        ? 10
        : 10 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 15
        ? 15
        : 15 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 30
        ? 30
        : 30 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 100
        ? 100
        : 100 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 250
        ? 250
        : 450
    );
    setMinval(
      0 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 10
        ? 0
        : 10 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 15
        ? 10
        : 15 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 30
        ? 15
        : 30 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 100
        ? 30
        : 100 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 250
        ? 100
        : 250
    );
    setOpen(true);
    setColor(col.SO2);
    setChange(true);

    setUnit("μg/m3");
    setIns(false);
    setTog(true);
    setSit(situation.SO2);
    setPresstoggle(false);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(true);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updatePM2_5 = () => {
    setValuee("PM2_5");
    setInt(avgYear.PM2_5.toFixed(0));
    setMaxval(
      0 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 50
        ? 50
        : 50 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 100
        ? 100
        : 100 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 180
        ? 180
        : 180 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 500
        ? 100
        : 500 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 900
        ? 900
        : 900
    );
    setMinval(
      0 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 50
        ? 0
        : 50 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 100
        ? 50
        : 100 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 180
        ? 100
        : 180 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 500
        ? 180
        : 500 < avgYear.SO2.toFixed(0) && avgYear.SO2.toFixed(0) <= 900
        ? 500
        : 500
    );
    setOpen(true);
    setColor(col.PM2_5);
    setChange(true);

    setUnit("μg/m3");
    setIns(false);
    setTog(true);
    setSit(situation.PM2_5);
    setPresstoggle(false);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(true);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updatePM10 = () => {
    setValuee("PM10");
    setInt(avgYear.PM10.toFixed(0));
    setMaxval(
      0 < avgYear.PM10.toFixed(0) && avgYear.PM10.toFixed(0) <= 90
        ? 90
        : 90 < avgYear.PM10.toFixed(0) && avgYear.PM10.toFixed(0) <= 180
        ? 180
        : 180 < avgYear.PM10.toFixed(0) && avgYear.PM10.toFixed(0) <= 324
        ? 324
        : 324 < avgYear.PM10.toFixed(0) && avgYear.PM10.toFixed(0) <= 900
        ? 900
        : 900 < avgYear.PM10.toFixed(0) && avgYear.PM10.toFixed(0) <= 1620
        ? 1620
        : 1620
    );
    setMinval(
      0 < avgYear.PM10.toFixed(0) && avgYear.PM10.toFixed(0) <= 90
        ? 0
        : 90 < avgYear.PM10.toFixed(0) && avgYear.PM10.toFixed(0) <= 180
        ? 50
        : 180 < avgYear.PM10.toFixed(0) && avgYear.PM10.toFixed(0) <= 324
        ? 100
        : 324 < avgYear.PM10.toFixed(0) && avgYear.PM10.toFixed(0) <= 900
        ? 324
        : 900 < avgYear.PM10.toFixed(0) && avgYear.PM10.toFixed(0) <= 1620
        ? 900
        : 900
    );
    setOpen(true);
    setColor(col.PM10);
    setChange(true);
    setPm10toggle(true);
    setUnit("μg/m3");
    setIns(false);
    setTog(true);
    setSit(situation.PM10);
    setPresstoggle(false);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm10toggle(true);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
    setPm2_5toggle(false);
  };
  const updateCO = () => {
    setValuee("CO");
    setInt(avgYear.CO.toFixed(0));
    setMaxval(
      0 < avgYear.CO.toFixed(0) && avgYear.CO.toFixed(0) <= 200
        ? 200
        : 200 < avgYear.CO.toFixed(0) && avgYear.CO.toFixed(0) <= 400
        ? 400
        : 400 < avgYear.CO.toFixed(0) && avgYear.CO.toFixed(0) <= 1000
        ? 1000
        : 1000 < avgYear.CO.toFixed(0) && avgYear.CO.toFixed(0) <= 3000
        ? 3000
        : 3000 < avgYear.CO.toFixed(0) && avgYear.CO.toFixed(0) <= 5000
        ? 5000
        : 10000
    );
    setMinval(
      0 < avgYear.CO.toFixed(0) && avgYear.CO.toFixed(0) <= 200
        ? 0
        : 200 < avgYear.CO.toFixed(0) && avgYear.CO.toFixed(0) <= 400
        ? 200
        : 400 < avgYear.CO.toFixed(0) && avgYear.CO.toFixed(0) <= 1000
        ? 400
        : 1000 < avgYear.CO.toFixed(0) && avgYear.CO.toFixed(0) <= 3000
        ? 1000
        : 3000 < avgYear.CO.toFixed(0) && avgYear.CO.toFixed(0) <= 5000
        ? 3000
        : 5000
    );
    setOpen(true);
    setColor(col.CO);
    setChange(true);

    setUnit("μg/m3");
    setIns(false);
    setTog(true);
    setSit(situation.CO);
    setPresstoggle(false);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(true);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateCO2 = () => {
    setValuee("CO2");
    setInt(avgYear.CO2.toFixed(0));
    setColor(col.CO2);
    setOpen(true);
    setChange(true);
    setMaxval(
      0 < avgYear.CO2.toFixed(0) && avgYear.CO2.toFixed(0) <= 700
        ? 700
        : 700 < avgYear.CO2.toFixed(0) && avgYear.CO2.toFixed(0) <= 1100
        ? 1100
        : 1100 < avgYear.CO2.toFixed(0) && avgYear.CO2.toFixed(0) <= 1500
        ? 1500
        : 1500 < avgYear.CO2.toFixed(0) && avgYear.CO2.toFixed(0) <= 5000
        ? 5000
        : 5000 < avgYear.CO2.toFixed(0) && avgYear.CO2.toFixed(0) <= 50000
        ? 50000
        : 50000
    );
    setMinval(
      0 < avgYear.CO2.toFixed(0) && avgYear.CO2.toFixed(0) <= 700
        ? 350
        : 700 < avgYear.CO2.toFixed(0) && avgYear.CO2.toFixed(0) <= 1100
        ? 700
        : 1100 < avgYear.CO2.toFixed(0) && avgYear.CO2.toFixed(0) <= 1500
        ? 1100
        : 1500 < avgYear.CO2.toFixed(0) && avgYear.CO2.toFixed(0) <= 5000
        ? 1500
        : 5000 < avgYear.CO2.toFixed(0) && avgYear.CO2.toFixed(0) <= 5000
        ? 5000
        : 5000
    );

    setUnit("PPM");
    setIns(false);
    setTog(true);
    setSit(situation.CO2);
    setPresstoggle(false);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(true);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateVOCS = () => {
    setValuee("VOCS");
    setInt(avgYear.VOCS.toFixed(0));
    setMaxval(
      0 < avgYear.VOCS.toFixed(0) && avgYear.VOCS.toFixed(0) <= 100
        ? 100
        : 100 < avgYear.VOCS.toFixed(0) && avgYear.VOCS.toFixed(0) <= 1000
        ? 1000
        : 1000 < avgYear.VOCS.toFixed(0) && avgYear.VOCS.toFixed(0) <= 3000
        ? 3000
        : 3000 < avgYear.VOCS.toFixed(0) && avgYear.VOCS.toFixed(0) <= 35000
        ? 35000
        : 35000 < avgYear.VOCS.toFixed(0) && avgYear.VOCS.toFixed(0) <= 70000
        ? 70000
        : 70000
    );
    setMinval(
      0 < avgYear.VOCS.toFixed(0) && avgYear.VOCS.toFixed(0) <= 100
        ? 0
        : 100 < avgYear.VOCS.toFixed(0) && avgYear.VOCS.toFixed(0) <= 1000
        ? 100
        : 1000 < avgYear.VOCS.toFixed(0) && avgYear.VOCS.toFixed(0) <= 3000
        ? 1000
        : 3000 < avgYear.VOCS.toFixed(0) && avgYear.VOCS.toFixed(0) <= 35000
        ? 3000
        : 35000 < avgYear.VOCS.toFixed(0) && avgYear.VOCS.toFixed(0) <= 70000
        ? 35000
        : 35000
    );
    setOpen(true);
    setColor(col.VOCS);
    setChange(true);

    setUnit("NM");
    setTog(true);
    setSit(situation.VOCS);
    setPresstoggle(false);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(true);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
    setIns(false);
  };
  const updateNH3 = () => {
    setValuee("NH3");
    setInt(avgYear.NH3.toFixed(0));
    setMaxval(
      0 < avgYear.NH3.toFixed(0) && avgYear.NH3.toFixed(0) <= 5
        ? 5
        : 5 < avgYear.NH3.toFixed(0) && avgYear.NH3.toFixed(0) <= 8
        ? 8
        : 8 < avgYear.NH3.toFixed(0) && avgYear.NH3.toFixed(0) <= 16
        ? 16
        : 16 < avgYear.NH3.toFixed(0) && avgYear.NH3.toFixed(0) <= 25
        ? 25
        : 25 < avgYear.NH3.toFixed(0) && avgYear.NH3.toFixed(0) <= 40
        ? 40
        : 150
    );
    setMinval(
      0 < avgYear.NH3.toFixed(0) && avgYear.NH3.toFixed(0) <= 5
        ? 0
        : 5 < avgYear.NH3.toFixed(0) && avgYear.NH3.toFixed(0) <= 8
        ? 5
        : 8 < avgYear.NH3.toFixed(0) && avgYear.NH3.toFixed(0) <= 16
        ? 8
        : 16 < avgYear.NH3.toFixed(0) && avgYear.NH3.toFixed(0) <= 25
        ? 16
        : 25 < avgYear.NH3.toFixed(0) && avgYear.NH3.toFixed(0) <= 40
        ? 25
        : 40
    );
    setColor(col.NH3);
    setOpen(true);
    setChange(true);

    setUnit("μg/m3");
    setTog(true);
    setSit(situation.NH3);
    setPresstoggle(false);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(true);
    setNotoggle(false);
    setO3toggle(false);
    setIns(false);
  };
  const updateNO = () => {
    setValuee("NO");
    setInt(avgYear.NO.toFixed(0));
    setMaxval(
      0 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 20
        ? 20
        : 20 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 40
        ? 40
        : 40 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 65
        ? 65
        : 65 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 250
        ? 250
        : 250 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 600
        ? 600
        : 5000
    );
    setMinval(
      0 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 20
        ? 0
        : 20 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 40
        ? 20
        : 40 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 65
        ? 40
        : 65 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 250
        ? 250
        : 250 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 600
        ? 250
        : 600
    );
    setColor(col.NO);
    setOpen(true);
    setChange(true);

    setUnit("μg/m3");
    setTog(true);
    setSit(situation.NO);
    setPresstoggle(false);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(true);
    setO3toggle(false);
    setIns(false);
  };
  const updateO3 = () => {
    setValuee("O3");
    setInt(avgYear.O3.toFixed(0));
    setMaxval(
      0 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 20
        ? 20
        : 20 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 50
        ? 50
        : 50 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 75
        ? 75
        : 75 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 100
        ? 100
        : 100 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 150
        ? 150
        : 500
    );
    setMinval(
      0 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 20
        ? 0
        : 20 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 50
        ? 20
        : 50 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 75
        ? 50
        : 75 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 100
        ? 75
        : 100 < avgYear.NO.toFixed(0) && avgYear.NO.toFixed(0) <= 150
        ? 100
        : 150
    );
    setOpen(true);
    setColor(col.O3);
    setChange(true);

    setUnit("μg/m3");
    setTog(true);
    setIns(false);
    setSit(situation.O3);
    setPresstoggle(false);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(true);
  };

  const unSelected = () => {
    setOpen(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };

  const unSelected2 = () => {
    setSecondOpen(false);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setPresstoggle(false);
  };

  return (
    <Fragment>
      {true ? (
        <div className="paralel_AvgYear">
          {avgStatus !== 200 ? (
            <AvgYearPassive />
          ) : (
            <div style={{ paddingBottom: 0, marginBottom: 0 }}>
              <p className="content_header1">
                Mevsimsel Hava Kalite Değer Ortalamaları
              </p>
              <div className="avgDetail">
                <YearlySummary
                  chooseMessage={chooseMessage}
                  isAutumn={autumn}
                  isWinter={winter}
                  isSpring={spring}
                  isSummer={summer}
                  aqi={aqi.AqiDesc}
                />
              </div>
              <AirQualityIndex
                value={aqi.AqiValue}
                max1={500}
                min1={0}
                bgcolor={aqi.AqiColor}
                airSituation={aqi.AqiDesc}
                text={
                  aqi.AqiDesc == "Hassas"
                    ? "Hava Kalitesi yaşlılar, çocuklar ve solunum rahatsızlığı olanları etkileyebilecek seviyededir"
                    : aqi.AqiDesc == "Orta"
                    ? "Hava kalitesi sağlık açısından uygun seviyededir"
                    : aqi.AqiDesc == "İyi"
                    ? "Hava kalitesi iyi seviyededir"
                    : aqi.AqiDesc == "Sağlıksız"
                    ? "Sağlıksız"
                    : aqi.AqiDesc == "Kötü"
                    ? "Kötü"
                    : aqi.AqiDesc == "Tehlikeli"
                    ? "Tehlikeli"
                    : " Hava Kalitesi sağlık açısından uygun seviyededir"
                }
                toggle={aqi.AqiValue}
              />
              <div className="scroll_AvgYear">
                <div className="column_AvgYear">
                  <AvgYearList11
                    Detail_Header={valuee}
                    Detail_Value={ins ? avgYear.Temp + "°C" : int}
                    Detail_Text="Bölgedeki değer uygun koşullarda gözükmektedir"
                    Detail_Time={timer}
                    Detail_MAX={maxval}
                    Detail_MIN={minval}
                    Detail_Situation={ins ? situation.Temp : sit}
                    Detail_Unit={unit}
                    Detail_Change={change}
                    Detail_Initial={ins}
                    Detail_In={avgYear.Temp}
                    Detail_Toggle={tog}
                    Detail_Color={color}
                    Detail_Click={unSelected}
                    opened={open}
                    open={open}
                    no2={updateNO2}
                    so2={updateSO2}
                    pm2_5={updatePM2_5}
                    pm10={updatePM10}
                    co={updateCO}
                    co2={updateCO2}
                    vocs={updateVOCS}
                    nh3={updateNH3}
                    no={updateNO}
                    o3={updateO3}
                    co2_pre={co2toggle}
                    no2_pre={no2toggle}
                    so2_pre={so2toggle}
                    pm2_5_pre={pm2_5toggle}
                    pm10_pre={pm10toggle}
                    co_pre={cotoggle}
                    vocs_pre={vocstoggle}
                    nh3_pre={nh3toggle}
                    no_pre={notoggle}
                    o3_pre={o3toggle}
                    WindSpeed={avgYear.WS}
                    NO2={avgYear.NO2}
                    SO2={avgYear.SO2}
                    PM2_5={avgYear.PM2_5}
                    PM10={avgYear.PM10}
                    avgCO={avgYear.CO}
                    avgCO2={avgYear.CO2}
                    VOCs={avgYear.VOCS}
                    NH3={avgYear.NH3}
                    NO={avgYear.NO}
                    O3={avgYear.O3}
                  />
                </div>
              </div>
              <div className="scroll_AvgYear1">
                <div className="column_AvgYear1">
                  <AvgYearList12
                    Detail_Header={secondvaluee}
                    Detail_Value={secondins ? avgYear.Temp + "°C" : secondint}
                    Detail_Text="Bölgedeki değer uygun koşullarda gözükmektedir"
                    Detail_Time={timer}
                    Detail_MAX={secondmaxval}
                    Detail_MIN={secondminval}
                    Detail_Situation={secondins ? situation.Temp : secondsit}
                    Detail_Unit={secondunit}
                    Detail_Change={secondchange}
                    Detail_Initial={secondins}
                    Detail_In={avgYear.Temp}
                    Detail_Toggle={secondtog}
                    Detail_Color={secondcolor}
                    Detail_Click={unSelected2}
                    opened={secondopen}
                    open={secondopen}
                    temp={updateTemp}
                    hum={updateHum}
                    wd={updateWD}
                    ws={updateWS}
                    pres={updatePres}
                    pres_pre={prestoggle}
                    temp_pre={temptoggle}
                    hum_pre={humtoggle}
                    wd_pre={wdtoggle}
                    ws_pre={wstoggle}
                    Temparature={avgYear.Temp}
                    Hummidty={avgYear.Hum}
                    WindSpeed={avgYear.WS}
                    WindDirection={avgYear.WD}
                    Pressure={avgYear.Pres}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <p style={{ color: "white" }}>LOADİNG</p>
      )}
    </Fragment>
  );
};

export default AvgYear;
