import { Catalogs } from "../../screens/Catalogs";
import { Videos } from "../../screens/Videos";

export const CatalogScreenNavigator = [
  {
    path: "/catalogs",
    exact: true,
    screen: <Catalogs />,
  },
  {
    path: "/tr/kataloglar",
    exact: true,
    screen: <Catalogs />,
  },
  {
    path: "/nl/catalogi",
    exact: true,
    screen: <Catalogs />,
  },
  {
    path: "/rs/katalozi",
    exact: true,
    screen: <Catalogs />,
  },
  {
    path: "/fr/catalogues",
    exact: true,
    screen: <Catalogs />,
  },
  {
    path: "/uk/catalogs",
    exact: true,
    screen: <Catalogs />,
  },
  {
    path: "/ro/cataloage",
    exact: true,
    screen: <Catalogs />,
  },
  {
    path: "/videos",
    exact: true,
    screen: <Videos />,
  },
  {
    path: "/tr/videolar",
    exact: true,
    screen: <Videos />,
  },
  {
    path: "/nl/videos",
    exact: true,
    screen: <Videos />,
  },
  {
    path: "/rs/video-snimci",
    exact: true,
    screen: <Videos />,
  },
  {
    path: "/fr/videos",
    exact: true,
    screen: <Videos />,
  },
  {
    path: "/uk/videos",
    exact: true,
    screen: <Videos />,
  },
  {
    path: "/ro/videoclipuri",
    exact: true,
    screen: <Videos />,
  },
];
