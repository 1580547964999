import { Dropdown } from "react-bootstrap";
import { Props } from "./types";
import { RiArrowDropDownLine } from "react-icons/ri";
import "./style.css";
import { Style } from "./style";
import theme from "../../../constants/theme";
export function DropdownComponent(props: Props) {
  return (
    <Dropdown style={{ paddingLeft: "3px"}}>
      <Dropdown.Toggle style={Style.button} className={props.className}>
        <div className="d-flex d-column justify-content-between item-align-center">
          <p className="mobile-margin">{props.selectedValue}</p>
          <RiArrowDropDownLine
            style={{ fontSize: 30, color: theme.COLORS.blue, }}
            height={10}
          />
        </div>
        <hr style={{ marginTop: "-6px" }} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props.values.map((value, index) => (
          <Dropdown.Item
            className={props.className}
            active={value.label === props.selectedValue}
            key={index+"dropdown"}
            onClick={() => props.onChange(value)}
          >
            {value.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
