import React from "react";
import AirQualityIndex from "../AirQualityIndex/AirQualityIndex";
import TomorrowList11 from "../Lists/TomorrowList/TomorrowList11";
import TomorrowList12 from "../Lists/TomorrowList/TomorrowList12";
import WeatherCard from "../weatherCard";
import YearlySummary from "../YearlySummary/YearlySummary";
import one from "../../../assets/images/WeatherImages/Weather/one.png";
import Weather from "../../../assets/images/WeatherImages/Weather/Weather.png";

import AutumnDisabled from "../../../assets/images/WeatherImages/SVG/AutumnDisabled.svg";
import WinterDisabled from "../../../assets/images/WeatherImages/SVG/WinterDisabled.svg";
import SpringDisabled from "../../../assets/images/WeatherImages/SVG/SpringDisabled.svg";
import SummerDisabled from "../../../assets/images/WeatherImages/SVG/SummerDisabled.svg";
function AvgYearPassive(props) {
  return (
    <div style={{ width: 411 }}>
      <p className="content_header">
        Mevsimsel Hava Kalite Değerleri Bulunmamaktadır
      </p>

      <div className="yearlyContainerPassive">
        <div
          style={{
            justifyContent: "center",
            flexDirection: "row",
            display: "flex",
            marginBottom: -25,
            justifyContent: "space-evenly",
          }}
        >
          <div>
            <img
              style={{
                width: 65,
                height: 65,
                resize: "contain",
                display: "flex",
                padding: 10,
              }}
              src={AutumnDisabled}
            />
          </div>

          <div>
            <img
              style={{
                width: 65,
                height: 65,
                resize: "contain",
                display: "flex",
                padding: 10,
              }}
              src={WinterDisabled}
            />
          </div>

          <div>
            <img
              style={{
                width: 65,
                height: 65,
                resize: "contain",
                display: "flex",
                padding: 10,
              }}
              src={SpringDisabled}
            />
          </div>

          <div>
            <img
              style={{
                width: 65,
                height: 65,
                resize: "contain",
                display: "flex",
                padding: 10,
              }}
              src={SummerDisabled}
            />
          </div>
        </div>

        <div className="yearlyHeaderPassive"> Hava Kalitesi Özeti</div>
        <div className="yearlyTextPassive">
          {" "}
          {" Hava Kalitesi sağlık açısından uygun seviyededir"}
        </div>
      </div>
      <div style={{ paddingTop: 15 }}>
        <AirQualityIndex
          value={0}
          max1={500}
          min1={0}
          bgcolor={"rgba(255,255,255,0.15)"}
          airSituation={""}
          text={""}
          isPassive={true}
        />
      </div>
      <div className="scroll_TomorrowPassive">
        <div className="column_TomorrowPassive">
          <TomorrowList11
            isPassive={true}
            avgCO2={"-"}
            NO2={"-"}
            SO2={"-"}
            PM2_5={"-"}
            PM10={"-"}
            avgCO={"-"}
            VOCs={"-"}
            NH3={"-"}
            NO={"-"}
            O3={"-"}
          />
        </div>
      </div>
      <div className="scroll_Tomorrow1_Passive">
        <div className="column_Tomorrow1">
          <TomorrowList12
            isPassive={true}
            Temparature={"-"}
            Hummidty={"-"}
            WindSpeed={"-"}
            WindDirection={"-"}
            Pressure={"-"}
          />
        </div>
      </div>
    </div>
  );
}

export default AvgYearPassive;
