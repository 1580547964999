import { Props } from "./types";
import { TopSideComponent } from "./TopSideText";
import { RightSideComponent } from "./RightSideText";
import { BottomSideComponent } from "./BotSideText";

export function SliderProductsComponent(props: Props) {
  return (
    <>
      {props.textPosition === "top" ? (
        <TopSideComponent
          sliderTopItems={props.sliderTopItems}
          backgroundColor={props.backgroundColor}
          isNotContainer={props.isNotContainer}
          isPhone={props.isPhone}
        />
      ) : props.textPosition === "bottom" ? (
        <BottomSideComponent
          sliderBottomItems={props.sliderBottomItems}
          buttonComponent={props.buttonComponent}
          textEnable={props.textEnable ? props.textEnable : false}
          minHeight={props.minHeight}
        />
      ) : (
        <RightSideComponent sliderRightItems={props.sliderRightItems} />
      )}
    </>
  );
}
