import { AirPurifersScreenNavigator } from "./AirPurifers";
import { AirQualityScreenNavigator } from "./AirQuality";
import { CubeScreenNavigator } from "./Cube";
import { PureScreenNavigator } from "./PureScreenNavigator";

export const allProductsNavigator = PureScreenNavigator.concat(
  AirPurifersScreenNavigator,
  AirQualityScreenNavigator,
  CubeScreenNavigator
);
