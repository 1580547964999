import theme from "../../../constants/theme";

export const Style = {
  button:{
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.COLORS.black,
    textAlign: 'left' as 'left',
    paddingInline:0
  }
};
