import { bottomNavigationActionClasses } from "@mui/material";
import React from "react";
import { useState } from "react";





import "./Button.css";

const Button = (props) => {
const [changePhoto, setChangePhoto] = useState(true);
const [touch, setTouch] = useState(false);
const [ispressed, setIspressed] = useState(false);
const [pressedChanged, setPressedChanged] = useState(false);
  const className = touch ? 'btn touched' : 'btn';
  
  const press = touch ? 'btn touched' : 'btn touched pressed';

const touchHandle = () => {

     setTimeout(() => {
         setTouch(true)
        
     }, 150);
     setTouch(false)
  
}

const touchHandleUp = () => {
     setTimeout(() => {
         setTouch(false)
     }, 150);
     setTouch(false)
    
};

const pres = () => {
    setIspressed(true)
}


    return(
        
        <button
        className={className}
        onMouseDown={true && touchHandle}
        onMouseUp={true && touchHandleUp}
        type={props.type || 'button'}
        onClick={props.onClick}
        
        >
            <div className="presseds">
            {props.children}
            </div>
        </button>
    );
};

export default Button;