import { Tab, Tabs } from "react-bootstrap";
import { Props } from "./types";
import "./style.css";
import { Style } from "./style";
import { useEffect, useState } from "react";
import { TranslationService } from "../../../services/translation.service";
import useWindowDimensions from "../../../services/getWindowSize.service";
export function TabComponent(props: Props) {
  const t = TranslationService.translate;
  const [activeItem, setActiveItem] = useState(0);
  const children = props.children;
  const componentKey = props.componentKey ? props.componentKey : "";
  const screenSize = useWindowDimensions().width;
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.paddingTop = "100px";

    //temize çekileblir
    // if props child lenght is 1 remove nav-tabs in technicalTable
    if (children.length === 1) {
      // delete technicalTable > nav nav-tabs
      const technicalTable =
        document.getElementsByClassName("technicalTable")![0];
      const nav =
        technicalTable && technicalTable.getElementsByClassName("nav")[0];
      nav && nav.classList.add("d-none");
    } else if (children.length === 4 && screenSize < 1200) {
      //nav tabs add font size style
      const nav = document.getElementsByClassName("nav")[0];
      //set font size
      nav && nav.classList.add("nav-tabs-font-size");
    } else {
      const nav = document.getElementsByClassName("nav")[0];
      nav && nav.classList.remove("nav-tabs-font-size");
    }

    return () => {
      document.body.style.paddingTop = "75px";

      // if props child lenght is 1 remove nav-tabs in technicalTable
      if (children.length === 1) {
        // delete technicalTable > nav nav-tabs
        const technicalTable =
          document.getElementsByClassName("technicalTable")![0];
        const nav =
          technicalTable && technicalTable.getElementsByClassName("nav")[0];
        nav && nav.classList.remove("d-none");
      }

      if (children.length === 4) {
        //nav tabs add font size style
        const nav = document.getElementsByClassName("nav")[0];
        //set font size
        nav && nav.classList.remove("nav-tabs-font-size");
      }
    };
  }, []);

  useEffect(() => {
    //go to (0,0) when change tab
    window.scrollTo(0, 0);
  }, [activeItem]);

  return (
    <div
      style={Style.tab}
      id="tab"
      className={props.className ? props.className : ""}
    >
      <Tabs defaultActiveKey={0}>
        {children.map((child, index) => (
          <Tab
            eventKey={index}
            title={t(componentKey, child.title)}
            key={index + "table"}
            tabClassName={activeItem === index ? "activeTab" : ""}
            tabAttrs={{
              onClick: () => {
                setActiveItem(index);
              },
            }}
          >
            {child.body}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
