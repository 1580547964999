import { TranslationService } from "../services/translation.service";

export interface Lang {
  display: string;
  country: string;
  code: string;
  flag: string;
  isRTL: boolean;
  zone: string;
  url: string;
  event?: string;
}

export const Langs = {
  EN: {
    display: "USA",
    code: "en",
    flag: require("../assets/images/langs/enUSA.png"),
    isRTL: false,
    country: "USA",
    zone: "North America",
    event: "1",
    url: "https://www.sercair.com",
  },
  TR: {
    display: "TR",
    country: "TR",
    code: "tr",
    flag: require("../assets/images/langs/tr.png"),
    isRTL: false,
    zone: "tr-TR",
    url: "https://www.sercair.com/tr",
  },
  NL: {
    display: "NL",
    country: "NL",
    code: "nl",
    flag: require("../assets/images/langs/nl.png"),
    isRTL: false,
    zone: "nl-NL",
    url: "https://www.sercair.com/nl",
  },
  RS: {
    display: "RS",
    country: "RS",
    code: "rs",
    flag: require("../assets/images/langs/rs.png"),
    isRTL: false,
    zone: "rs-RS",
    url: "https://www.sercair.com/rs",
  },
  FR: {
    display: "FR",
    country: "FR",
    code: "fr",
    flag: require("../assets/images/langs/fr.png"),
    isRTL: false,
    zone: "fr-FR",
    url: "https://www.sercair.com/fr",
  },
  UK: {
    display: "UK",
    country: "UK",
    code: "uk",
    flag: require("../assets/images/langs/enUK.png"),
    isRTL: false,
    zone: "uk-UK",
    url: "https://www.sercair.com/uk",
  },
  RO: {
    display: "RO",
    country: "RO",
    code: "ro",
    flag: require("../assets/images/langs/enUK.png"),
    isRTL: false,
    zone: "ro-RO",
    url: "https://www.sercair.com/RO",
  },
};

export const defaultLang: Lang = {
  //get local storage lang
  display: TranslationService.getLangFromStorage().display,
  code: TranslationService.getLangFromStorage().code,
  country: TranslationService.getLangFromStorage().country,
  flag: TranslationService.getLangFromStorage().flag,
  isRTL: TranslationService.getLangFromStorage().isRTL,
  zone: TranslationService.getLangFromStorage().zone,
  url: TranslationService.getLangFromStorage().url,
};

export const langs: Lang[] = [
  {
    display: "USA",
    code: "en",
    flag: require("../assets/images/langs/enUSA.png"),
    isRTL: false,
    country: "USA",
    zone: "North America",
    event: "1",
    url: "https://www.sercair.com",
  },
  {
    display: "USA",
    code: "tr",
    flag: require("../assets/images/langs/tr.png"),
    isRTL: false,
    country: "USA",
    zone: "Europe",
    url: "https://www.sercair.com/tr",
  },
  {
    code: "nl",
    country: "Holland(Netherlands)",
    display: "Holland(Netherlands)",
    flag: require("../assets/images/langs/nl.png"),
    isRTL: false,
    zone: "Europe",
    url: "https://www.sercair.com/nl",
  },
  {
    code: "rs",
    country: "Serbia",
    display: "Serbia",
    flag: require("../assets/images/langs/rs.png"),
    isRTL: false,
    zone: "Europe",
    url: "https://www.sercair.com/rs",
  },
  {
    code: "fr",
    country: "France",
    display: "France",
    flag: require("../assets/images/langs/fr.png"),
    isRTL: false,
    zone: "Europe",
    url: "https://www.sercair.com/fr",
  },
  {
    code: "uk",
    country: "United Kingdom",
    display: "United Kingdom",
    flag: require("../assets/images/langs/enUK.png"),
    isRTL: false,
    zone: "Europe",
    url: "https://www.sercair.com/uk",
  },
  {
    code: "ro",
    country: "Romania",
    display: "Romania",
    flag: require("../assets/images/langs/enUK.png"),
    isRTL: false,
    zone: "Europe",
    url: "https://www.sercair.com/ro",
  },
];

export const langsAll: Lang[] = [
  {
    display: "Türkiye",
    code: "en",
    flag: require("../assets/images/langs/enUSA.png"),
    isRTL: false,
    country: "USA",
    zone: "Europe",
    url: "https://www.sercair.com/tr",
    event: "0",
  },
  {
    display: "Netherlands",
    code: "en",
    flag: require("../assets/images/langs/enUSA.png"),
    isRTL: false,
    country: "USA",
    zone: "Europe",
    url: "https://www.sercair.com/nl",
    event: "0",
  },
  {
    display: "United Kingdom",
    code: "uk",
    flag: require("../assets/images/langs/enUK.png"),
    isRTL: false,
    country: "United Kingdom",
    zone: "Europe",
    event: "0",
    url: "https://www.sercair.com/uk",
  },
  {
    code: "rs",
    country: "Serbia",
    display: "Serbia",
    flag: require("../assets/images/langs/rs.png"),
    isRTL: false,
    zone: "Europe",
    url: "https://www.sercair.com/rs",
  },
  {
    code: "fr",
    country: "France",
    display: "France",
    flag: require("../assets/images/langs/fr.png"),
    isRTL: false,
    zone: "Europe",
    url: "https://www.sercair.com/fr",
  },
  {
    code: "ro",
    country: "Romania",
    display: "Romania",
    flag: require("../assets/images/langs/fr.png"),
    isRTL: false,
    zone: "Europe",
    url: "https://www.sercair.com/ro",
  },

  {
    display: "USA",
    code: "en",
    flag: require("../assets/images/langs/enUSA.png"),
    isRTL: false,
    country: "USA",
    zone: "North America",
    event: "1",
    url: "https://www.sercair.com",
  },
];

export const getLangFile = (screen: string, lang: string) => {
  switch (screen) {
    case "MainScreen":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Main/tr.json");
        case "en":
          return require("../assets/translations/Main/en.json");
        case "uk":
          return require("../assets/translations/Main/uk.json");
        case "de":
          return require("../assets/translations/Main/de.json");
        case "fr":
          return require("../assets/translations/Main/fr.json");
        case "gr":
          return require("../assets/translations/Main/gr.json");
        case "ru":
          return require("../assets/translations/Main/ru.json");
        case "sa":
          return require("../assets/translations/Main/sa.json");
        case "nl":
          return require("../assets/translations/Main/nl.json");
        case "rs":
          return require("../assets/translations/Main/rs.json");
        case "ro":
          return require("../assets/translations/Main/ro.json");
      }
      break;
    case "Footer":
      switch (lang) {
        case "tr":
          return require("../assets/translations/SharedComponents/Footer/tr.json");
        case "en":
          return require("../assets/translations/SharedComponents/Footer/en.json");
        case "uk":
          return require("../assets/translations/SharedComponents/Footer/uk.json");
        case "de":
          return require("../assets/translations/SharedComponents/Footer/de.json");
        case "fr":
          return require("../assets/translations/SharedComponents/Footer/fr.json");
        case "gr":
          return require("../assets/translations/SharedComponents/Footer/gr.json");
        case "ru":
          return require("../assets/translations/SharedComponents/Footer/ru.json");
        case "sa":
          return require("../assets/translations/SharedComponents/Footer/sa.json");
        case "nl":
          return require("../assets/translations/SharedComponents/Footer/nl.json");
        case "rs":
          return require("../assets/translations/SharedComponents/Footer/rs.json");
        case "ro":
          return require("../assets/translations/SharedComponents/Footer/ro.json");
      }
      break;
    case "Navbar":
      switch (lang) {
        case "tr":
          return require("../assets/translations/SharedComponents/Navbar/tr.json");
        case "en":
          return require("../assets/translations/SharedComponents/Navbar/en.json");
        case "uk":
          return require("../assets/translations/SharedComponents/Navbar/uk.json");
        case "de":
          return require("../assets/translations/SharedComponents/Navbar/de.json");
        case "fr":
          return require("../assets/translations/SharedComponents/Navbar/fr.json");
        case "gr":
          return require("../assets/translations/SharedComponents/Navbar/gr.json");
        case "ru":
          return require("../assets/translations/SharedComponents/Navbar/ru.json");
        case "sa":
          return require("../assets/translations/SharedComponents/Navbar/sa.json");
        case "nl":
          return require("../assets/translations/SharedComponents/Navbar/nl.json");
        case "rs":
          return require("../assets/translations/SharedComponents/Navbar/rs.json");
        case "ro":
          return require("../assets/translations/SharedComponents/Navbar/ro.json");
      }
      break;
    case "LanguageModal":
      switch (lang) {
        case "tr":
          return require("../assets/translations/SharedComponents/Modals/LanguageModal/tr.json");
        case "en":
          return require("../assets/translations/SharedComponents/Modals/LanguageModal/en.json");
        case "uk":
          return require("../assets/translations/SharedComponents/Modals/LanguageModal/uk.json");
        case "de":
          return require("../assets/translations/SharedComponents/Modals/LanguageModal/de.json");
        case "fr":
          return require("../assets/translations/SharedComponents/Modals/LanguageModal/fr.json");
        case "gr":
          return require("../assets/translations/SharedComponents/Modals/LanguageModal/gr.json");
        case "ru":
          return require("../assets/translations/SharedComponents/Modals/LanguageModal/ru.json");
        case "sa":
          return require("../assets/translations/SharedComponents/Modals/LanguageModal/sa.json");
        case "nl":
          return require("../assets/translations/SharedComponents/Modals/LanguageModal/nl.json");
        case "rs":
          return require("../assets/translations/SharedComponents/Modals/LanguageModal/rs.json");
        case "ro":
          return require("../assets/translations/SharedComponents/Modals/LanguageModal/ro.json");
      }
      break;
    case "PureSeries":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Products/Pure/PureSeries/tr.json");
        case "en":
          return require("../assets/translations/Products/Pure/PureSeries/en.json");
        case "uk":
          return require("../assets/translations/Products/Pure/PureSeries/uk.json");
        case "de":
          return require("../assets/translations/Products/Pure/PureSeries/de.json");
        case "fr":
          return require("../assets/translations/Products/Pure/PureSeries/fr.json");
        case "gr":
          return require("../assets/translations/Products/Pure/PureSeries/gr.json");
        case "ru":
          return require("../assets/translations/Products/Pure/PureSeries/ru.json");
        case "sa":
          return require("../assets/translations/Products/Pure/PureSeries/sa.json");
        case "nl":
          return require("../assets/translations/Products/Pure/PureSeries/nl.json");
        case "rs":
          return require("../assets/translations/Products/Pure/PureSeries/rs.json");
        case "ro":
          return require("../assets/translations/Products/Pure/PureSeries/ro.json");
      }
      break;
    case "Cube":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Products/Cube/tr.json");
        case "en":
          return require("../assets/translations/Products/Cube/en.json");
        case "uk":
          return require("../assets/translations/Products/Cube/uk.json");
        case "de":
          return require("../assets/translations/Products/Cube/de.json");
        case "fr":
          return require("../assets/translations/Products/Cube/fr.json");
        case "gr":
          return require("../assets/translations/Products/Cube/gr.json");
        case "ru":
          return require("../assets/translations/Products/Cube/ru.json");
        case "sa":
          return require("../assets/translations/Products/Cube/sa.json");
        case "nl":
          return require("../assets/translations/Products/Cube/nl.json");
        case "rs":
          return require("../assets/translations/Products/Cube/rs.json");
        case "ro":
          return require("../assets/translations/Products/Cube/ro.json");
      }
      break;
    case "PureHealthySeries":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Products/Pure/PureHealthy/tr.json");
        case "en":
          return require("../assets/translations/Products/Pure/PureHealthy/en.json");
        case "uk":
          return require("../assets/translations/Products/Pure/PureHealthy/uk.json");
        case "de":
          return require("../assets/translations/Products/Pure/PureHealthy/de.json");
        case "fr":
          return require("../assets/translations/Products/Pure/PureHealthy/fr.json");
        case "gr":
          return require("../assets/translations/Products/Pure/PureHealthy/gr.json");
        case "ru":
          return require("../assets/translations/Products/Pure/PureHealthy/ru.json");
        case "sa":
          return require("../assets/translations/Products/Pure/PureHealthy/sa.json");
        case "nl":
          return require("../assets/translations/Products/Pure/PureHealthy/nl.json");
        case "rs":
          return require("../assets/translations/Products/Pure/PureHealthy/rs.json");
        case "ro":
          return require("../assets/translations/Products/Pure/PureHealthy/ro.json");
      }
      break;
    case "PureCoreSeries":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Products/Pure/PureCore/tr.json");
        case "en":
          return require("../assets/translations/Products/Pure/PureCore/en.json");
        case "uk":
          return require("../assets/translations/Products/Pure/PureCore/uk.json");
        case "de":
          return require("../assets/translations/Products/Pure/PureCore/de.json");
        case "fr":
          return require("../assets/translations/Products/Pure/PureCore/fr.json");
        case "gr":
          return require("../assets/translations/Products/Pure/PureCore/gr.json");
        case "ru":
          return require("../assets/translations/Products/Pure/PureCore/ru.json");
        case "sa":
          return require("../assets/translations/Products/Pure/PureCore/sa.json");
        case "nl":
          return require("../assets/translations/Products/Pure/PureCore/nl.json");
        case "rs":
          return require("../assets/translations/Products/Pure/PureCore/rs.json");
        case "ro":
          return require("../assets/translations/Products/Pure/PureCore/ro.json");
      }
      break;
    case "SpiritSeries":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Products/AirPurifiers/Spirit/tr.json");
        case "en":
          return require("../assets/translations/Products/AirPurifiers/Spirit/en.json");
        case "uk":
          return require("../assets/translations/Products/AirPurifiers/Spirit/uk.json");
        case "de":
          return require("../assets/translations/Products/AirPurifiers/Spirit/de.json");
        case "fr":
          return require("../assets/translations/Products/AirPurifiers/Spirit/fr.json");
        case "gr":
          return require("../assets/translations/Products/AirPurifiers/Spirit/gr.json");
        case "ru":
          return require("../assets/translations/Products/AirPurifiers/Spirit/ru.json");
        case "sa":
          return require("../assets/translations/Products/AirPurifiers/Spirit/sa.json");
        case "nl":
          return require("../assets/translations/Products/AirPurifiers/Spirit/nl.json");
        case "rs":
          return require("../assets/translations/Products/AirPurifiers/Spirit/rs.json");
        case "ro":
          return require("../assets/translations/Products/AirPurifiers/Spirit/ro.json");
      }
      break;
    case "PiatraSeries":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Products/AirPurifiers/Piatra/tr.json");
        case "en":
          return require("../assets/translations/Products/AirPurifiers/Piatra/en.json");
        case "uk":
          return require("../assets/translations/Products/AirPurifiers/Piatra/uk.json");
        case "de":
          return require("../assets/translations/Products/AirPurifiers/Piatra/de.json");
        case "fr":
          return require("../assets/translations/Products/AirPurifiers/Piatra/fr.json");
        case "gr":
          return require("../assets/translations/Products/AirPurifiers/Piatra/gr.json");
        case "ru":
          return require("../assets/translations/Products/AirPurifiers/Piatra/ru.json");
        case "sa":
          return require("../assets/translations/Products/AirPurifiers/Piatra/sa.json");
        case "nl":
          return require("../assets/translations/Products/AirPurifiers/Piatra/nl.json");
        case "rs":
          return require("../assets/translations/Products/AirPurifiers/Piatra/rs.json");
        case "ro":
          return require("../assets/translations/Products/AirPurifiers/Piatra/ro.json");
      }
      break;
    case "moniMe":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Products/AirQuality/MoniMe/tr.json");
        case "en":
          return require("../assets/translations/Products/AirQuality/MoniMe/en.json");
        case "uk":
          return require("../assets/translations/Products/AirQuality/MoniMe/uk.json");
        case "de":
          return require("../assets/translations/Products/AirQuality/MoniMe/fr.json");
        case "fr":
          return require("../assets/translations/Products/AirQuality/MoniMe/fr.json");
        case "gr":
          return require("../assets/translations/Products/AirQuality/MoniMe/ru.json");
        case "ru":
          return require("../assets/translations/Products/AirQuality/MoniMe/ru.json");
        case "sa":
          return require("../assets/translations/Products/AirQuality/MoniMe/sa.json");
        case "nl":
          return require("../assets/translations/Products/AirQuality/MoniMe/nl.json");
        case "rs":
          return require("../assets/translations/Products/AirQuality/MoniMe/rs.json");
        case "ro":
          return require("../assets/translations/Products/AirQuality/MoniMe/ro.json");
      }
      break;
    case "moniWay":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Products/AirQuality/MoniWay/tr.json");
        case "en":
          return require("../assets/translations/Products/AirQuality/MoniWay/en.json");
        case "uk":
          return require("../assets/translations/Products/AirQuality/MoniWay/uk.json");
        case "de":
          return require("../assets/translations/Products/AirQuality/MoniWay/fr.json");
        case "fr":
          return require("../assets/translations/Products/AirQuality/MoniWay/fr.json");
        case "gr":
          return require("../assets/translations/Products/AirQuality/MoniWay/ru.json");
        case "ru":
          return require("../assets/translations/Products/AirQuality/MoniWay/ru.json");
        case "sa":
          return require("../assets/translations/Products/AirQuality/MoniWay/sa.json");
        case "nl":
          return require("../assets/translations/Products/AirQuality/MoniWay/nl.json");
        case "rs":
          return require("../assets/translations/Products/AirQuality/MoniWay/rs.json");
        case "ro":
          return require("../assets/translations/Products/AirQuality/MoniWay/ro.json");
      }
      break;
    case "ProductsApp":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Products/App/tr.json");
        case "en":
          return require("../assets/translations/Products/App/en.json");
        case "uk":
          return require("../assets/translations/Products/App/uk.json");
        case "de":
          return require("../assets/translations/Products/App/de.json");
        case "fr":
          return require("../assets/translations/Products/App/fr.json");
        case "gr":
          return require("../assets/translations/Products/App/gr.json");
        case "ru":
          return require("../assets/translations/Products/App/ru.json");
        case "sa":
          return require("../assets/translations/Products/App/sa.json");
        case "nl":
          return require("../assets/translations/Products/App/nl.json");
        case "rs":
          return require("../assets/translations/Products/App/rs.json");
        case "ro":
          return require("../assets/translations/Products/App/ro.json");
      }
      break;
    case "OverViewPureSlider":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Products/Pure/OverView/tr.json");
        case "en":
          return require("../assets/translations/Products/Pure/OverView/en.json");
        case "uk":
          return require("../assets/translations/Products/Pure/OverView/uk.json");
        case "de":
          return require("../assets/translations/Products/Pure/OverView/de.json");
        case "fr":
          return require("../assets/translations/Products/Pure/OverView/fr.json");
        case "gr":
          return require("../assets/translations/Products/Pure/OverView/gr.json");
        case "ru":
          return require("../assets/translations/Products/Pure/OverView/ru.json");
        case "sa":
          return require("../assets/translations/Products/Pure/OverView/sa.json");
        case "nl":
          return require("../assets/translations/Products/Pure/OverView/nl.json");
        case "rs":
          return require("../assets/translations/Products/Pure/OverView/rs.json");
        case "ro":
          return require("../assets/translations/Products/Pure/OverView/ro.json");
      }
      break;
    case "Collapse":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Collapse/tr.json");
        case "en":
          return require("../assets/translations/Collapse/en.json");
        case "uk":
          return require("../assets/translations/Collapse/uk.json");
        case "de":
          return require("../assets/translations/Collapse/de.json");
        case "fr":
          return require("../assets/translations/Collapse/fr.json");
        case "gr":
          return require("../assets/translations/Collapse/gr.json");
        case "ru":
          return require("../assets/translations/Collapse/ru.json");
        case "sa":
          return require("../assets/translations/Collapse/sa.json");
        case "nl":
          return require("../assets/translations/Collapse/nl.json");
        case "rs":
          return require("../assets/translations/Collapse/rs.json");
        case "ro":
          return require("../assets/translations/Collapse/ro.json");
      }
      break;
    case "CollapseHelp":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Collapse/Help/tr.json");
        case "en":
          return require("../assets/translations/Collapse/Help/en.json");
        case "uk":
          return require("../assets/translations/Collapse/Help/uk.json");
        case "de":
          return require("../assets/translations/Collapse/Help/de.json");
        case "fr":
          return require("../assets/translations/Collapse/Help/fr.json");
        case "gr":
          return require("../assets/translations/Collapse/Help/gr.json");
        case "ru":
          return require("../assets/translations/Collapse/Help/ru.json");
        case "sa":
          return require("../assets/translations/Collapse/Help/sa.json");
        case "nl":
          return require("../assets/translations/Collapse/Help/nl.json");
        case "rs":
          return require("../assets/translations/Collapse/Help/rs.json");
        case "ro":
          return require("../assets/translations/Collapse/Help/ro.json");
      }
      break;
    case "Returns":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Supports/Returns/tr.json");
        case "en":
          return require("../assets/translations/Supports/Returns/en.json");
        case "uk":
          return require("../assets/translations/Supports/Returns/uk.json");
        case "de":
          return require("../assets/translations/Supports/Returns/fr.json");
        case "fr":
          return require("../assets/translations/Supports/Returns/fr.json");
        case "gr":
          return require("../assets/translations/Supports/Returns/ru.json");
        case "ru":
          return require("../assets/translations/Supports/Returns/ru.json");
        case "sa":
          return require("../assets/translations/Supports/Returns/sa.json");
        case "nl":
          return require("../assets/translations/Supports/Returns/nl.json");
        case "rs":
          return require("../assets/translations/Supports/Returns/rs.json");
        case "ro":
          return require("../assets/translations/Supports/Returns/ro.json");
      }
      break;
    case "campaigns":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Campaigns/tr.json");
        case "en":
          return require("../assets/translations/Campaigns/en.json");
        case "uk":
          return require("../assets/translations/Campaigns/uk.json");
        case "de":
          return require("../assets/translations/Campaigns/fr.json");
        case "fr":
          return require("../assets/translations/Campaigns/fr.json");
        case "gr":
          return require("../assets/translations/Campaigns/ru.json");
        case "ru":
          return require("../assets/translations/Campaigns/ru.json");
        case "sa":
          return require("../assets/translations/Campaigns/sa.json");
        case "nl":
          return require("../assets/translations/Campaigns/nl.json");
        case "rs":
          return require("../assets/translations/Campaigns/rs.json");
        case "ro":
          return require("../assets/translations/Campaigns/ro.json");
      }
      break;
    case "Userguide":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Supports/Userguide/tr.json");
        case "en":
          return require("../assets/translations/Supports/Userguide/en.json");
        case "uk":
          return require("../assets/translations/Supports/Userguide/uk.json");
        case "de":
          return require("../assets/translations/Supports/Userguide/fr.json");
        case "fr":
          return require("../assets/translations/Supports/Userguide/fr.json");
        case "gr":
          return require("../assets/translations/Supports/Userguide/ru.json");
        case "ru":
          return require("../assets/translations/Supports/Userguide/ru.json");
        case "sa":
          return require("../assets/translations/Supports/Userguide/sa.json");
        case "nl":
          return require("../assets/translations/Supports/Userguide/nl.json");
        case "rs":
          return require("../assets/translations/Supports/Userguide/rs.json");
        case "ro":
          return require("../assets/translations/Supports/Userguide/ro.json");
      }
      break;
    case "Requests":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Supports/Requests/tr.json");
        case "en":
          return require("../assets/translations/Supports/Requests/en.json");
        case "uk":
          return require("../assets/translations/Supports/Requests/uk.json");
        case "de":
          return require("../assets/translations/Supports/Requests/de.json");
        case "fr":
          return require("../assets/translations/Supports/Requests/fr.json");
        case "gr":
          return require("../assets/translations/Supports/Requests/gr.json");
        case "ru":
          return require("../assets/translations/Supports/Requests/ru.json");
        case "sa":
          return require("../assets/translations/Supports/Requests/sa.json");
        case "nl":
          return require("../assets/translations/Supports/Requests/nl.json");
        case "rs":
          return require("../assets/translations/Supports/Requests/rs.json");
        case "ro":
          return require("../assets/translations/Supports/Requests/ro.json");
      }
      break;
    case "Warranty":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Supports/Warranty/tr.json");
        case "en":
          return require("../assets/translations/Supports/Warranty/en.json");
        case "uk":
          return require("../assets/translations/Supports/Warranty/uk.json");
        case "de":
          return require("../assets/translations/Supports/Warranty/de.json");
        case "fr":
          return require("../assets/translations/Supports/Warranty/fr.json");
        case "gr":
          return require("../assets/translations/Supports/Warranty/gr.json");
        case "ru":
          return require("../assets/translations/Supports/Warranty/ru.json");
        case "sa":
          return require("../assets/translations/Supports/Warranty/sa.json");
        case "nl":
          return require("../assets/translations/Supports/Warranty/nl.json");
        case "rs":
          return require("../assets/translations/Supports/Warranty/rs.json");
        case "ro":
          return require("../assets/translations/Supports/Warranty/ro.json");
      }
      break;
    case "Frequently":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Supports/Frequently/tr.json");
        case "en":
          return require("../assets/translations/Supports/Frequently/en.json");
        case "uk":
          return require("../assets/translations/Supports/Frequently/uk.json");
        case "de":
          return require("../assets/translations/Supports/Frequently/de.json");
        case "fr":
          return require("../assets/translations/Supports/Frequently/fr.json");
        case "gr":
          return require("../assets/translations/Supports/Frequently/gr.json");
        case "ru":
          return require("../assets/translations/Supports/Frequently/ru.json");
        case "sa":
          return require("../assets/translations/Supports/Frequently/sa.json");
        case "nl":
          return require("../assets/translations/Supports/Frequently/nl.json");
        case "rs":
          return require("../assets/translations/Supports/Frequently/rs.json");
        case "ro":
          return require("../assets/translations/Supports/Frequently/ro.json");
      }
      break;
    case "Assembly":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Supports/Assembly/tr.json");
        case "en":
          return require("../assets/translations/Supports/Assembly/en.json");
        case "uk":
          return require("../assets/translations/Supports/Assembly/uk.json");
        case "de":
          return require("../assets/translations/Supports/Assembly/de.json");
        case "fr":
          return require("../assets/translations/Supports/Assembly/fr.json");
        case "gr":
          return require("../assets/translations/Supports/Assembly/gr.json");
        case "ru":
          return require("../assets/translations/Supports/Assembly/ru.json");
        case "sa":
          return require("../assets/translations/Supports/Assembly/sa.json");
        case "nl":
          return require("../assets/translations/Supports/Assembly/nl.json");
        case "rs":
          return require("../assets/translations/Supports/Assembly/rs.json");
        case "ro":
          return require("../assets/translations/Supports/Assembly/ro.json");
      }
      break;
    case "SalesPoints":
      switch (lang) {
        case "tr":
          return require("../assets/translations/SalesPoints/tr.json");
        case "en":
          return require("../assets/translations/SalesPoints/en.json");
        case "uk":
          return require("../assets/translations/SalesPoints/uk.json");
        case "de":
          return require("../assets/translations/SalesPoints/de.json");
        case "fr":
          return require("../assets/translations/SalesPoints/fr.json");
        case "gr":
          return require("../assets/translations/SalesPoints/gr.json");
        case "ru":
          return require("../assets/translations/SalesPoints/ru.json");
        case "sa":
          return require("../assets/translations/SalesPoints/sa.json");
        case "nl":
          return require("../assets/translations/SalesPoints/nl.json");
        case "rs":
          return require("../assets/translations/SalesPoints/rs.json");
        case "ro":
          return require("../assets/translations/SalesPoints/ro.json");
      }
      break;
    case "Pricing":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Pricing/tr.json");
        case "en":
          return require("../assets/translations/Pricing/en.json");
        case "uk":
          return require("../assets/translations/Pricing/uk.json");
        case "de":
          return require("../assets/translations/Pricing/de.json");
        case "fr":
          return require("../assets/translations/Pricing/fr.json");
        case "gr":
          return require("../assets/translations/Pricing/gr.json");
        case "ru":
          return require("../assets/translations/Pricing/ru.json");
        case "sa":
          return require("../assets/translations/Pricing/sa.json");
        case "nl":
          return require("../assets/translations/Pricing/nl.json");
        case "rs":
          return require("../assets/translations/Pricing/rs.json");
        case "ro":
          return require("../assets/translations/Pricing/ro.json");
      }
      break;
    case "Confidentiality":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Agreements/Confidentiality/tr.json");
        case "en":
          return require("../assets/translations/Agreements/Confidentiality/en.json");
        case "uk":
          return require("../assets/translations/Agreements/Confidentiality/uk.json");
        case "de":
          return require("../assets/translations/Agreements/Confidentiality/de.json");
        case "fr":
          return require("../assets/translations/Agreements/Confidentiality/fr.json");
        case "gr":
          return require("../assets/translations/Agreements/Confidentiality/gr.json");
        case "ru":
          return require("../assets/translations/Agreements/Confidentiality/ru.json");
        case "sa":
          return require("../assets/translations/Agreements/Confidentiality/sa.json");
        case "nl":
          return require("../assets/translations/Agreements/Confidentiality/nl.json");
        case "rs":
          return require("../assets/translations/Agreements/Confidentiality/rs.json");
        case "ro":
          return require("../assets/translations/Agreements/Confidentiality/ro.json");
      }
      break;
    case "Cookie":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Agreements/Cookie/tr.json");
        case "en":
          return require("../assets/translations/Agreements/Cookie/en.json");
        case "uk":
          return require("../assets/translations/Agreements/Cookie/uk.json");
        case "de":
          return require("../assets/translations/Agreements/Cookie/de.json");
        case "fr":
          return require("../assets/translations/Agreements/Cookie/fr.json");
        case "gr":
          return require("../assets/translations/Agreements/Cookie/ru.json");
        case "ru":
          return require("../assets/translations/Agreements/Cookie/ru.json");
        case "sa":
          return require("../assets/translations/Agreements/Cookie/sa.json");
        case "nl":
          return require("../assets/translations/Agreements/Cookie/nl.json");
        case "rs":
          return require("../assets/translations/Agreements/Cookie/rs.json");
        case "ro":
          return require("../assets/translations/Agreements/Cookie/ro.json");
      }
      break;
    case "DistanceSelling":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Agreements/DistanceSelling/tr.json");
        case "en":
          return require("../assets/translations/Agreements/DistanceSelling/en.json");
        case "uk":
          return require("../assets/translations/Agreements/DistanceSelling/uk.json");
        case "de":
          return require("../assets/translations/Agreements/DistanceSelling/de.json");
        case "fr":
          return require("../assets/translations/Agreements/DistanceSelling/fr.json");
        case "gr":
          return require("../assets/translations/Agreements/DistanceSelling/gr.json");
        case "ru":
          return require("../assets/translations/Agreements/DistanceSelling/ru.json");
        case "sa":
          return require("../assets/translations/Agreements/DistanceSelling/sa.json");
        case "nl":
          return require("../assets/translations/Agreements/DistanceSelling/nl.json");
        case "rs":
          return require("../assets/translations/Agreements/DistanceSelling/rs.json");
        case "ro":
          return require("../assets/translations/Agreements/DistanceSelling/ro.json");
      }
      break;
    case "Catalogs":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Catalogs/tr.json");
        case "en":
          return require("../assets/translations/Catalogs/en.json");
        case "uk":
          return require("../assets/translations/Catalogs/uk.json");
        case "de":
          return require("../assets/translations/Catalogs/de.json");
        case "fr":
          return require("../assets/translations/Catalogs/fr.json");
        case "gr":
          return require("../assets/translations/Catalogs/gr.json");
        case "ru":
          return require("../assets/translations/Catalogs/ru.json");
        case "sa":
          return require("../assets/translations/Catalogs/sa.json");
        case "nl":
          return require("../assets/translations/Catalogs/nl.json");
        case "rs":
          return require("../assets/translations/Catalogs/rs.json");
        case "ro":
          return require("../assets/translations/Catalogs/ro.json");
      }
      break;
    case "Contact":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Contact/tr.json");
        case "en":
          return require("../assets/translations/Contact/en.json");
        case "uk":
          return require("../assets/translations/Contact/uk.json");
        case "de":
          return require("../assets/translations/Contact/de.json");
        case "fr":
          return require("../assets/translations/Contact/fr.json");
        case "gr":
          return require("../assets/translations/Contact/gr.json");
        case "ru":
          return require("../assets/translations/Contact/ru.json");
        case "sa":
          return require("../assets/translations/Contact/sa.json");
        case "nl":
          return require("../assets/translations/Contact/nl.json");
        case "rs":
          return require("../assets/translations/Contact/rs.json");
        case "ro":
          return require("../assets/translations/Contact/ro.json");
      }
      break;
    case "Videos":
      switch (lang) {
        case "tr":
          return require("../assets/translations/Videos/tr.json");
        case "en":
          return require("../assets/translations/Videos/en.json");
        case "uk":
          return require("../assets/translations/Videos/uk.json");
        case "de":
          return require("../assets/translations/Videos/de.json");
        case "fr":
          return require("../assets/translations/Videos/fr.json");
        case "gr":
          return require("../assets/translations/Videos/gr.json");
        case "ru":
          return require("../assets/translations/Videos/ru.json");
        case "sa":
          return require("../assets/translations/Videos/sa.json");
        case "nl":
          return require("../assets/translations/Videos/nl.json");
        case "rs":
          return require("../assets/translations/Videos/rs.json");
        case "ro":
          return require("../assets/translations/Videos/ro.json");
      }
      break;
    case "DealershipPageLogin":
      switch (lang) {
        case "tr":
          return require("../assets/translations/DealershipPages/Login/tr.json");
        case "en":
          return require("../assets/translations/DealershipPages/Login/en.json");
        case "uk":
          return require("../assets/translations/DealershipPages/Login/uk.json");
        case "de":
          return require("../assets/translations/DealershipPages/Login/de.json");
        case "fr":
          return require("../assets/translations/DealershipPages/Login/fr.json");
        case "gr":
          return require("../assets/translations/DealershipPages/Login/gr.json");
        case "ru":
          return require("../assets/translations/DealershipPages/Login/ru.json");
        case "sa":
          return require("../assets/translations/DealershipPages/Login/sa.json");
        case "nl":
          return require("../assets/translations/DealershipPages/Login/nl.json");
        case "rs":
          return require("../assets/translations/DealershipPages/Login/rs.json");
        case "ro":
          return require("../assets/translations/DealershipPages/Login/ro.json");
      }
      break;
  }
};
