import axios from "axios";
import React, { Fragment } from "react";

import AvgYear from "../../../components/WeatherComponents/AvgYear/AvgYear";

import Moment from "../../../components/WeatherComponents/moments/moment";

import Tomorrow1 from "../../../components/WeatherComponents/tomorrow/Tomorrow1";

import { useLocation } from "react-router-dom";
import Header1 from "../../../components/WeatherComponents/header/header1";
import InfoText from "../../../components/WeatherComponents/infoText/infoText";
import "./mainScreen.css";
import { useNavigate } from "react-router-dom";

function MainScreenW() {
  const baseURL = `http://78.186.56.6:9000/getAdress?ke=41.01431589487826&kb=28.89210887562714`;

  const config = {
    headers: {
      Authorization:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6InRlc3RAZ21haWwuY29tIn0.Y-iOGZPutGUODJshuimE-Fi78O77YRdBbcrWOkqKwII",
    },
  };
  const { state } = useLocation();
  const { latt, longg } = state;
  const [locations, setLocations] = React.useState();
  const [loc, setLoc] = React.useState(false);
  const [locat, setLocat] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [pass, setPass] = React.useState();
  const [word, setWord] = React.useState();
  const navigate = useNavigate();
  // Read values passed on state
  React.useEffect(() => {
    setLoading(true);
    setLoc(false);
    axios
      .get(baseURL, config)
      .then((response) => {
        setLocations(response.data.district);

        //   setSituation(response.data[1]);
      })
      .finally(() => setLoading(false), setLoc(true));
  }, []);

  // if (!locations) return null;

  return (
    <Fragment>
      {true ? (
        <div className="mainScreen_App">
          <Header1
            la={latt}
            lo={longg}
            click={() =>
              navigate("/MapScreen", { state: { latt: latt, longg: longg } })
            }
            ResponsivePage={() =>
              navigate("/MapScreen/MainScreenW/MainScreenR", {
                state: { latt: latt, longg: longg },
              })
            }
          />
          <div
            style={{
            
              position: "absolute",
              marginBottom: 1390,
              marginLeft: 500,
            }}
          >
            {/* <div>{latt}</div>
            <div>{word}</div>
            <div>{longg}</div> */}
          </div>
          <div className="Titles">
            Sercair Yapay Zeka Destekli Hava Kalite Tahmin Algoritması
          </div>
          <div className="mainScreen_Main">
            <div>
              <Moment lang={latt} long={longg} load={loc} />
            </div>

            <Tomorrow1 lang={latt} long={longg} load={loc} />
            <AvgYear lang={latt} long={longg} load={loc} />
          </div>
          <div>
            <InfoText />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}

export default MainScreenW;
