import { useEffect } from "react";
import { Container } from "react-bootstrap";
import theme from "../../../constants/theme";
import { TranslationService } from "../../../services/translation.service";
import { data } from "./data";
import { Props } from "./types";

export function ScreenComponent(props: Props) {
  const t = TranslationService.translate;

  const translationKey = "Returns";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container style={{ marginTop: 60, marginBottom: 100, maxWidth: "80vw" }}>
      <div className="d-flex align-items-center">
        <img
          className="mobile-img"
          src={theme.IMAGES.support.returnsImg}
          alt={"sercair"}
        />
        <h2
          className="ms-4 gothamMedium mobile-font"
          style={{ color: theme.COLORS.blue }}
        >
          {t(translationKey, "titleMain")}
        </h2>
      </div>
      {data.map((item, index) => {
        return (
          <div key={"returnsKey" + index}>
            <h3
              key={index}
              className="GothamBook my-4"
              style={{ color: theme.COLORS.blue }}
            >
              {t(translationKey, item.title)}
            </h3>
            {item.texts.map((text, i) => {
              return (
                <p
                  className="my-4 GothamLight"
                  key={index + "-" + i}
                  dangerouslySetInnerHTML={{ __html: t(translationKey, text) }}
                ></p>
              );
            })}
          </div>
        );
      })}
    </Container>
  );
}
