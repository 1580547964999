import { connect } from "react-redux";
import { Component } from "./component";
import { GlobalState } from "../../../../store/state";

const mapStateToProps = (state: GlobalState) => {
  return {
    lang: state.ui.lang,
    showCountryModal: state.ui.showCountryModal,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const CountryModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
