import { connect } from 'react-redux';
import { Component } from './component';
import { GlobalState } from '../../../../store/state';

const mapStateToProps = (state: GlobalState) => {
  return {
    lang: state.ui.lang,
    showLanguageModal: state.ui.showLanguageModal,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
   
  };
};

export const LanguageModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
