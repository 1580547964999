import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { MailService } from "../../../API/services/mail.service";
import { ButtonComponent } from "../../../components/Shared/ButtonComponent/component";
import { config } from "../../../config/config";
import theme from "../../../constants/theme";
import { notify } from "../../../services/toastHandler.service";
import { TranslationService } from "../../../services/translation.service";
import { store } from "../../../store";
import { Style } from "./style";
import { Props } from "./types";

export function ScreenComponent(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "Requests";

  const [formDatas, setFormDatas] = useState({
    name: { txt: "", error: false },
    surname: { txt: "", error: false },
    email: { txt: "", error: false },
    phone: { txt: "", error: false },
    desp: { txt: "", error: false },
    request: { txt: "", error: false },
  });

  const [isMobile, setIsMobile] = useState(false);

  const sendMail = () => {
    const { name, surname, email, phone, desp, request } = formDatas;

    if (name.txt === "") {
      notify(t(translationKey, "nameError"), "error");
      setFormDatas({ ...formDatas, name: { ...name, error: true } });
      return;
    }
    if (surname.txt === "") {
      notify(t(translationKey, "surnameError"), "error");
      setFormDatas({ ...formDatas, surname: { ...surname, error: true } });
      return;
    }
    if (email.txt === "") {
      notify(t(translationKey, "emailError"), "error");
      setFormDatas({ ...formDatas, email: { ...email, error: true } });
      return;
    }
    if (phone.txt === "") {
      notify(t(translationKey, "phoneError"), "error");
      setFormDatas({ ...formDatas, phone: { ...phone, error: true } });
      return;
    }
    if (desp.txt === "") {
      notify(t(translationKey, "despError"), "error");
      setFormDatas({ ...formDatas, desp: { ...desp, error: true } });
      return;
    }
    if (request.txt === "") {
      notify(t(translationKey, "requestError"), "error");
      setFormDatas({ ...formDatas, request: { ...request, error: true } });
      return;
    }

    MailService.sendMail(
      name.txt,
      surname.txt,
      email.txt,
      phone.txt,
      desp.txt,
      request.txt,
      config.countryForAPI
    ).then((res) => {
      if (res.status === 201) {
        notify(t(translationKey, "success"), "success");
        setFormDatas({
          name: { txt: "", error: false },
          surname: { txt: "", error: false },
          email: { txt: "", error: false },
          phone: { txt: "", error: false },
          desp: { txt: "", error: false },
          request: { txt: "", error: false },
        });
      } else {
        notify(t(translationKey, "error"), "error");
      }
    });
  };

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);
  const button = (text: string, url: string, isLong: boolean, onClick: any) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"center"}
          onClick={onClick}
        />
      </div>
    );
  };
  return (
    <Container
      style={{
        marginTop: 60,
        marginBottom: 100,
        maxWidth: "80vw",
      }}
    >
      <div className="d-flex align-items-center">
        <img className="mobile-img" src={theme.IMAGES.support.returnsImg} alt={"sercair"} />
        <h2 className="ms-4 gothamMedium mobile-font" style={Style.mainTitle}>
          {t(translationKey, "mainTitle")}
        </h2>
      </div>
      <p
        className="my-4"
        style={{
          //@ts-ignore
          maxWidth: "80vw",
        }}
        dangerouslySetInnerHTML={{ __html: t(translationKey, "text") }}
      ></p>

      <div className="row m-0 p-0">
        <div
          className="col-sm-12 col-md-12 col-lg-6 col-xl-6 row m-0 p-0"
          id="form"
          style={Style.form}
        >
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "name")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              type="text"
              value={formDatas.name.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  name: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.name.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  name: { txt: "", error: true },
                })
              }
            />
            {formDatas.name.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "lastName")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              type="text"
              value={formDatas.surname.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  surname: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.surname.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  surname: { txt: "", error: true },
                })
              }
            />
            {formDatas.surname.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "email")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              value={formDatas.email.txt}
              type="text"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  email: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.email.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  email: { txt: "", error: true },
                })
              }
            />
            {formDatas.email.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "phone")}
            </p>
            <input
            value={formDatas.phone.txt}
              style={Style.inputs}
              className="p-3"
              type="text"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  phone: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.phone.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  phone: { txt: "", error: true },
                })
              }
            />
            {formDatas.phone.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "sortDesp")}
            </p>
            <textarea
            value={formDatas.desp.txt}
              style={Style.inputs}
              className="p-3"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  desp: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.desp.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  desp: { txt: "", error: true },
                })
              }
            />
            {formDatas.desp.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "request")}
            </p>
            <textarea
              className="p-3"
              style={Style.request}
              value={formDatas.request.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  request: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.request.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  request: { txt: "", error: true },
                })
              }
            />
            {formDatas.request.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>

          {button(
            t(translationKey, "send"),
            "/support/requests",
            false,
            sendMail
          )}
        </div>
      </div>
    </Container>
  );
}

export function ScreenComponentTR(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "Requests";

  const [formDatas, setFormDatas] = useState({
    name: { txt: "", error: false },
    surname: { txt: "", error: false },
    email: { txt: "", error: false },
    phone: { txt: "", error: false },
    desp: { txt: "", error: false },
    request: { txt: "", error: false },
  });

  const [isMobile, setIsMobile] = useState(false);

  const sendMail = () => {
    const { name, surname, email, phone, desp, request } = formDatas;

    if (name.txt === "") {
      notify(t(translationKey, "nameError"), "error");
      setFormDatas({ ...formDatas, name: { ...name, error: true } });
      return;
    }
    if (surname.txt === "") {
      notify(t(translationKey, "surnameError"), "error");
      setFormDatas({ ...formDatas, surname: { ...surname, error: true } });
      return;
    }
    if (email.txt === "") {
      notify(t(translationKey, "emailError"), "error");
      setFormDatas({ ...formDatas, email: { ...email, error: true } });
      return;
    }
    if (phone.txt === "") {
      notify(t(translationKey, "phoneError"), "error");
      setFormDatas({ ...formDatas, phone: { ...phone, error: true } });
      return;
    }
    if (desp.txt === "") {
      notify(t(translationKey, "despError"), "error");
      setFormDatas({ ...formDatas, desp: { ...desp, error: true } });
      return;
    }
    if (request.txt === "") {
      notify(t(translationKey, "requestError"), "error");
      setFormDatas({ ...formDatas, request: { ...request, error: true } });
      return;
    }

    MailService.sendMail(
      name.txt,
      surname.txt,
      email.txt,
      phone.txt,
      desp.txt,
      request.txt,
      config.countryForAPI
    ).then((res) => {
      if (res.status === 201) {
        notify(t(translationKey, "success"), "success");
        setFormDatas({
          name: { txt: "", error: false },
          surname: { txt: "", error: false },
          email: { txt: "", error: false },
          phone: { txt: "", error: false },
          desp: { txt: "", error: false },
          request: { txt: "", error: false },
        });
      } else {
        notify(t(translationKey, "error"), "error");
      }
    });
  };

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);
  const button = (text: string, url: string, isLong: boolean, onClick: any) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"center"}
          onClick={onClick}
        />
      </div>
    );
  };
  return (
    <Container
      style={{
        marginTop: 60,
        marginBottom: 100,
        maxWidth: "80vw",
      }}
    >
      <div className="d-flex align-items-center">
        <img className="mobile-img" src={theme.IMAGES.support.returnsImg} alt={"sercair"} />
        <h2 className="ms-4 gothamMedium mobile-font" style={Style.mainTitle}>
          {t(translationKey, "mainTitle")}
        </h2>
      </div>
      <p
        className="my-4"
        style={{
          //@ts-ignore
          maxWidth: "80vw",
        }}
        dangerouslySetInnerHTML={{ __html: t(translationKey, "text") }}
      ></p>

      <div className="row m-0 p-0">
        <div
          className="col-sm-12 col-md-12 col-lg-6 col-xl-6 row m-0 p-0"
          id="form"
          style={Style.form}
        >
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "name")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              type="text"
              value={formDatas.name.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  name: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.name.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  name: { txt: "", error: true },
                })
              }
            />
            {formDatas.name.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "lastName")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              type="text"
              value={formDatas.surname.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  surname: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.surname.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  surname: { txt: "", error: true },
                })
              }
            />
            {formDatas.surname.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "email")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              value={formDatas.email.txt}
              type="text"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  email: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.email.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  email: { txt: "", error: true },
                })
              }
            />
            {formDatas.email.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "phone")}
            </p>
            <input
            value={formDatas.phone.txt}
              style={Style.inputs}
              className="p-3"
              type="text"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  phone: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.phone.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  phone: { txt: "", error: true },
                })
              }
            />
            {formDatas.phone.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "sortDesp")}
            </p>
            <textarea
            value={formDatas.desp.txt}
              style={Style.inputs}
              className="p-3"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  desp: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.desp.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  desp: { txt: "", error: true },
                })
              }
            />
            {formDatas.desp.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "request")}
            </p>
            <textarea
              className="p-3"
              style={Style.request}
              value={formDatas.request.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  request: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.request.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  request: { txt: "", error: true },
                })
              }
            />
            {formDatas.request.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>

          {button(
            t(translationKey, "send"),
            "/support/requests",
            false,
            sendMail
          )}
        </div>
      </div>
    </Container>
  );
}

export function ScreenComponentNL(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "Requests";

  const [formDatas, setFormDatas] = useState({
    name: { txt: "", error: false },
    surname: { txt: "", error: false },
    email: { txt: "", error: false },
    phone: { txt: "", error: false },
    desp: { txt: "", error: false },
    request: { txt: "", error: false },
  });

  const [isMobile, setIsMobile] = useState(false);

  const sendMail = () => {
    const { name, surname, email, phone, desp, request } = formDatas;

    if (name.txt === "") {
      notify(t(translationKey, "nameError"), "error");
      setFormDatas({ ...formDatas, name: { ...name, error: true } });
      return;
    }
    if (surname.txt === "") {
      notify(t(translationKey, "surnameError"), "error");
      setFormDatas({ ...formDatas, surname: { ...surname, error: true } });
      return;
    }
    if (email.txt === "") {
      notify(t(translationKey, "emailError"), "error");
      setFormDatas({ ...formDatas, email: { ...email, error: true } });
      return;
    }
    if (phone.txt === "") {
      notify(t(translationKey, "phoneError"), "error");
      setFormDatas({ ...formDatas, phone: { ...phone, error: true } });
      return;
    }
    if (desp.txt === "") {
      notify(t(translationKey, "despError"), "error");
      setFormDatas({ ...formDatas, desp: { ...desp, error: true } });
      return;
    }
    if (request.txt === "") {
      notify(t(translationKey, "requestError"), "error");
      setFormDatas({ ...formDatas, request: { ...request, error: true } });
      return;
    }

    MailService.sendMail(
      name.txt,
      surname.txt,
      email.txt,
      phone.txt,
      desp.txt,
      request.txt,
      config.countryForAPI
    ).then((res) => {
      if (res.status === 201) {
        notify(t(translationKey, "success"), "success");
        setFormDatas({
          name: { txt: "", error: false },
          surname: { txt: "", error: false },
          email: { txt: "", error: false },
          phone: { txt: "", error: false },
          desp: { txt: "", error: false },
          request: { txt: "", error: false },
        });
      } else {
        notify(t(translationKey, "error"), "error");
      }
    });
  };

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);
  const button = (text: string, url: string, isLong: boolean, onClick: any) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"center"}
          onClick={onClick}
        />
      </div>
    );
  };
  return (
    <Container
      style={{
        marginTop: 60,
        marginBottom: 100,
        maxWidth: "80vw",
      }}
    >
      <div className="d-flex align-items-center">
        <img className="mobile-img" src={theme.IMAGES.support.returnsImg} alt={"sercair"} />
        <h2 className="ms-4 gothamMedium mobile-font" style={Style.mainTitle}>
          {t(translationKey, "mainTitle")}
        </h2>
      </div>
      <p
        className="my-4"
        style={{
          //@ts-ignore
          maxWidth: "80vw",
        }}
        dangerouslySetInnerHTML={{ __html: t(translationKey, "text") }}
      ></p>

      <div className="row m-0 p-0">
        <div
          className="col-sm-12 col-md-12 col-lg-6 col-xl-6 row m-0 p-0"
          id="form"
          style={Style.form}
        >
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "name")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              type="text"
              value={formDatas.name.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  name: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.name.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  name: { txt: "", error: true },
                })
              }
            />
            {formDatas.name.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "lastName")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              type="text"
              value={formDatas.surname.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  surname: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.surname.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  surname: { txt: "", error: true },
                })
              }
            />
            {formDatas.surname.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "email")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              value={formDatas.email.txt}
              type="text"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  email: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.email.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  email: { txt: "", error: true },
                })
              }
            />
            {formDatas.email.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "phone")}
            </p>
            <input
            value={formDatas.phone.txt}
              style={Style.inputs}
              className="p-3"
              type="text"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  phone: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.phone.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  phone: { txt: "", error: true },
                })
              }
            />
            {formDatas.phone.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "sortDesp")}
            </p>
            <textarea
            value={formDatas.desp.txt}
              style={Style.inputs}
              className="p-3"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  desp: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.desp.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  desp: { txt: "", error: true },
                })
              }
            />
            {formDatas.desp.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "request")}
            </p>
            <textarea
              className="p-3"
              style={Style.request}
              value={formDatas.request.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  request: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.request.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  request: { txt: "", error: true },
                })
              }
            />
            {formDatas.request.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>

          {button(
            t(translationKey, "send"),
            "/support/requests",
            false,
            sendMail
          )}
        </div>
      </div>
    </Container>
  );
}

export function ScreenComponentRS(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "Requests";

  const [formDatas, setFormDatas] = useState({
    name: { txt: "", error: false },
    surname: { txt: "", error: false },
    email: { txt: "", error: false },
    phone: { txt: "", error: false },
    desp: { txt: "", error: false },
    request: { txt: "", error: false },
  });

  const [isMobile, setIsMobile] = useState(false);

  const sendMail = () => {
    const { name, surname, email, phone, desp, request } = formDatas;

    if (name.txt === "") {
      notify(t(translationKey, "nameError"), "error");
      setFormDatas({ ...formDatas, name: { ...name, error: true } });
      return;
    }
    if (surname.txt === "") {
      notify(t(translationKey, "surnameError"), "error");
      setFormDatas({ ...formDatas, surname: { ...surname, error: true } });
      return;
    }
    if (email.txt === "") {
      notify(t(translationKey, "emailError"), "error");
      setFormDatas({ ...formDatas, email: { ...email, error: true } });
      return;
    }
    if (phone.txt === "") {
      notify(t(translationKey, "phoneError"), "error");
      setFormDatas({ ...formDatas, phone: { ...phone, error: true } });
      return;
    }
    if (desp.txt === "") {
      notify(t(translationKey, "despError"), "error");
      setFormDatas({ ...formDatas, desp: { ...desp, error: true } });
      return;
    }
    if (request.txt === "") {
      notify(t(translationKey, "requestError"), "error");
      setFormDatas({ ...formDatas, request: { ...request, error: true } });
      return;
    }

    MailService.sendMail(
      name.txt,
      surname.txt,
      email.txt,
      phone.txt,
      desp.txt,
      request.txt,
      config.countryForAPI
    ).then((res) => {
      if (res.status === 201) {
        notify(t(translationKey, "success"), "success");
        setFormDatas({
          name: { txt: "", error: false },
          surname: { txt: "", error: false },
          email: { txt: "", error: false },
          phone: { txt: "", error: false },
          desp: { txt: "", error: false },
          request: { txt: "", error: false },
        });
      } else {
        notify(t(translationKey, "error"), "error");
      }
    });
  };

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);
  const button = (text: string, url: string, isLong: boolean, onClick: any) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"center"}
          onClick={onClick}
        />
      </div>
    );
  };
  return (
    <Container
      style={{
        marginTop: 60,
        marginBottom: 100,
        maxWidth: "80vw",
      }}
    >
      <div className="d-flex align-items-center">
        <img className="mobile-img" src={theme.IMAGES.support.returnsImg} alt={"sercair"} />
        <h2 className="ms-4 gothamMedium mobile-font" style={Style.mainTitle}>
          {t(translationKey, "mainTitle")}
        </h2>
      </div>
      <p
        className="my-4"
        style={{
          //@ts-ignore
          maxWidth: "80vw",
        }}
        dangerouslySetInnerHTML={{ __html: t(translationKey, "text") }}
      ></p>

      <div className="row m-0 p-0">
        <div
          className="col-sm-12 col-md-12 col-lg-6 col-xl-6 row m-0 p-0"
          id="form"
          style={Style.form}
        >
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "name")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              type="text"
              value={formDatas.name.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  name: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.name.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  name: { txt: "", error: true },
                })
              }
            />
            {formDatas.name.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "lastName")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              type="text"
              value={formDatas.surname.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  surname: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.surname.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  surname: { txt: "", error: true },
                })
              }
            />
            {formDatas.surname.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "email")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              value={formDatas.email.txt}
              type="text"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  email: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.email.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  email: { txt: "", error: true },
                })
              }
            />
            {formDatas.email.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "phone")}
            </p>
            <input
            value={formDatas.phone.txt}
              style={Style.inputs}
              className="p-3"
              type="text"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  phone: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.phone.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  phone: { txt: "", error: true },
                })
              }
            />
            {formDatas.phone.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "sortDesp")}
            </p>
            <textarea
            value={formDatas.desp.txt}
              style={Style.inputs}
              className="p-3"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  desp: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.desp.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  desp: { txt: "", error: true },
                })
              }
            />
            {formDatas.desp.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "request")}
            </p>
            <textarea
              className="p-3"
              style={Style.request}
              value={formDatas.request.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  request: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.request.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  request: { txt: "", error: true },
                })
              }
            />
            {formDatas.request.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>

          {button(
            t(translationKey, "send"),
            "/support/requests",
            false,
            sendMail
          )}
        </div>
      </div>
    </Container>
  );
}

export function ScreenComponentFR(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "Requests";

  const [formDatas, setFormDatas] = useState({
    name: { txt: "", error: false },
    surname: { txt: "", error: false },
    email: { txt: "", error: false },
    phone: { txt: "", error: false },
    desp: { txt: "", error: false },
    request: { txt: "", error: false },
  });

  const [isMobile, setIsMobile] = useState(false);

  const sendMail = () => {
    const { name, surname, email, phone, desp, request } = formDatas;

    if (name.txt === "") {
      notify(t(translationKey, "nameError"), "error");
      setFormDatas({ ...formDatas, name: { ...name, error: true } });
      return;
    }
    if (surname.txt === "") {
      notify(t(translationKey, "surnameError"), "error");
      setFormDatas({ ...formDatas, surname: { ...surname, error: true } });
      return;
    }
    if (email.txt === "") {
      notify(t(translationKey, "emailError"), "error");
      setFormDatas({ ...formDatas, email: { ...email, error: true } });
      return;
    }
    if (phone.txt === "") {
      notify(t(translationKey, "phoneError"), "error");
      setFormDatas({ ...formDatas, phone: { ...phone, error: true } });
      return;
    }
    if (desp.txt === "") {
      notify(t(translationKey, "despError"), "error");
      setFormDatas({ ...formDatas, desp: { ...desp, error: true } });
      return;
    }
    if (request.txt === "") {
      notify(t(translationKey, "requestError"), "error");
      setFormDatas({ ...formDatas, request: { ...request, error: true } });
      return;
    }

    MailService.sendMail(
      name.txt,
      surname.txt,
      email.txt,
      phone.txt,
      desp.txt,
      request.txt,
      config.countryForAPI
    ).then((res) => {
      if (res.status === 201) {
        notify(t(translationKey, "success"), "success");
        setFormDatas({
          name: { txt: "", error: false },
          surname: { txt: "", error: false },
          email: { txt: "", error: false },
          phone: { txt: "", error: false },
          desp: { txt: "", error: false },
          request: { txt: "", error: false },
        });
      } else {
        notify(t(translationKey, "error"), "error");
      }
    });
  };

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);
  const button = (text: string, url: string, isLong: boolean, onClick: any) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"center"}
          onClick={onClick}
        />
      </div>
    );
  };
  return (
    <Container
      style={{
        marginTop: 60,
        marginBottom: 100,
        maxWidth: "80vw",
      }}
    >
      <div className="d-flex align-items-center">
        <img className="mobile-img" src={theme.IMAGES.support.returnsImg} alt={"sercair"} />
        <h2 className="ms-4 gothamMedium mobile-font" style={Style.mainTitle}>
          {t(translationKey, "mainTitle")}
        </h2>
      </div>
      <p
        className="my-4"
        style={{
          //@ts-ignore
          maxWidth: "80vw",
        }}
        dangerouslySetInnerHTML={{ __html: t(translationKey, "text") }}
      ></p>

      <div className="row m-0 p-0">
        <div
          className="col-sm-12 col-md-12 col-lg-6 col-xl-6 row m-0 p-0"
          id="form"
          style={Style.form}
        >
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "name")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              type="text"
              value={formDatas.name.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  name: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.name.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  name: { txt: "", error: true },
                })
              }
            />
            {formDatas.name.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "lastName")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              type="text"
              value={formDatas.surname.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  surname: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.surname.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  surname: { txt: "", error: true },
                })
              }
            />
            {formDatas.surname.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "email")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              value={formDatas.email.txt}
              type="text"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  email: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.email.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  email: { txt: "", error: true },
                })
              }
            />
            {formDatas.email.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "phone")}
            </p>
            <input
            value={formDatas.phone.txt}
              style={Style.inputs}
              className="p-3"
              type="text"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  phone: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.phone.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  phone: { txt: "", error: true },
                })
              }
            />
            {formDatas.phone.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "sortDesp")}
            </p>
            <textarea
            value={formDatas.desp.txt}
              style={Style.inputs}
              className="p-3"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  desp: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.desp.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  desp: { txt: "", error: true },
                })
              }
            />
            {formDatas.desp.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "request")}
            </p>
            <textarea
              className="p-3"
              style={Style.request}
              value={formDatas.request.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  request: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.request.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  request: { txt: "", error: true },
                })
              }
            />
            {formDatas.request.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>

          {button(
            t(translationKey, "send"),
            "/support/requests",
            false,
            sendMail
          )}
        </div>
      </div>
    </Container>
  );
}

export function ScreenComponentUK(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "Requests";

  const [formDatas, setFormDatas] = useState({
    name: { txt: "", error: false },
    surname: { txt: "", error: false },
    email: { txt: "", error: false },
    phone: { txt: "", error: false },
    desp: { txt: "", error: false },
    request: { txt: "", error: false },
  });

  const [isMobile, setIsMobile] = useState(false);

  const sendMail = () => {
    const { name, surname, email, phone, desp, request } = formDatas;

    if (name.txt === "") {
      notify(t(translationKey, "nameError"), "error");
      setFormDatas({ ...formDatas, name: { ...name, error: true } });
      return;
    }
    if (surname.txt === "") {
      notify(t(translationKey, "surnameError"), "error");
      setFormDatas({ ...formDatas, surname: { ...surname, error: true } });
      return;
    }
    if (email.txt === "") {
      notify(t(translationKey, "emailError"), "error");
      setFormDatas({ ...formDatas, email: { ...email, error: true } });
      return;
    }
    if (phone.txt === "") {
      notify(t(translationKey, "phoneError"), "error");
      setFormDatas({ ...formDatas, phone: { ...phone, error: true } });
      return;
    }
    if (desp.txt === "") {
      notify(t(translationKey, "despError"), "error");
      setFormDatas({ ...formDatas, desp: { ...desp, error: true } });
      return;
    }
    if (request.txt === "") {
      notify(t(translationKey, "requestError"), "error");
      setFormDatas({ ...formDatas, request: { ...request, error: true } });
      return;
    }

    MailService.sendMail(
      name.txt,
      surname.txt,
      email.txt,
      phone.txt,
      desp.txt,
      request.txt,
      config.countryForAPI
    ).then((res) => {
      if (res.status === 201) {
        notify(t(translationKey, "success"), "success");
        setFormDatas({
          name: { txt: "", error: false },
          surname: { txt: "", error: false },
          email: { txt: "", error: false },
          phone: { txt: "", error: false },
          desp: { txt: "", error: false },
          request: { txt: "", error: false },
        });
      } else {
        notify(t(translationKey, "error"), "error");
      }
    });
  };

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);
  const button = (text: string, url: string, isLong: boolean, onClick: any) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"center"}
          onClick={onClick}
        />
      </div>
    );
  };
  return (
    <Container
      style={{
        marginTop: 60,
        marginBottom: 100,
        maxWidth: "80vw",
      }}
    >
      <div className="d-flex align-items-center">
        <img className="mobile-img" src={theme.IMAGES.support.returnsImg} alt={"sercair"} />
        <h2 className="ms-4 gothamMedium mobile-font" style={Style.mainTitle}>
          {t(translationKey, "mainTitle")}
        </h2>
      </div>
      <p
        className="my-4"
        style={{
          //@ts-ignore
          maxWidth: "80vw",
        }}
        dangerouslySetInnerHTML={{ __html: t(translationKey, "text") }}
      ></p>

      <div className="row m-0 p-0">
        <div
          className="col-sm-12 col-md-12 col-lg-6 col-xl-6 row m-0 p-0"
          id="form"
          style={Style.form}
        >
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "name")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              type="text"
              value={formDatas.name.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  name: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.name.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  name: { txt: "", error: true },
                })
              }
            />
            {formDatas.name.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "lastName")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              type="text"
              value={formDatas.surname.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  surname: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.surname.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  surname: { txt: "", error: true },
                })
              }
            />
            {formDatas.surname.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "email")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              value={formDatas.email.txt}
              type="text"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  email: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.email.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  email: { txt: "", error: true },
                })
              }
            />
            {formDatas.email.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "phone")}
            </p>
            <input
            value={formDatas.phone.txt}
              style={Style.inputs}
              className="p-3"
              type="text"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  phone: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.phone.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  phone: { txt: "", error: true },
                })
              }
            />
            {formDatas.phone.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "sortDesp")}
            </p>
            <textarea
            value={formDatas.desp.txt}
              style={Style.inputs}
              className="p-3"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  desp: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.desp.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  desp: { txt: "", error: true },
                })
              }
            />
            {formDatas.desp.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "request")}
            </p>
            <textarea
              className="p-3"
              style={Style.request}
              value={formDatas.request.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  request: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.request.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  request: { txt: "", error: true },
                })
              }
            />
            {formDatas.request.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>

          {button(
            t(translationKey, "send"),
            "/support/requests",
            false,
            sendMail
          )}
        </div>
      </div>
    </Container>
  );
}

export function ScreenComponentRO(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "Requests";

  const [formDatas, setFormDatas] = useState({
    name: { txt: "", error: false },
    surname: { txt: "", error: false },
    email: { txt: "", error: false },
    phone: { txt: "", error: false },
    desp: { txt: "", error: false },
    request: { txt: "", error: false },
  });

  const [isMobile, setIsMobile] = useState(false);

  const sendMail = () => {
    const { name, surname, email, phone, desp, request } = formDatas;

    if (name.txt === "") {
      notify(t(translationKey, "nameError"), "error");
      setFormDatas({ ...formDatas, name: { ...name, error: true } });
      return;
    }
    if (surname.txt === "") {
      notify(t(translationKey, "surnameError"), "error");
      setFormDatas({ ...formDatas, surname: { ...surname, error: true } });
      return;
    }
    if (email.txt === "") {
      notify(t(translationKey, "emailError"), "error");
      setFormDatas({ ...formDatas, email: { ...email, error: true } });
      return;
    }
    if (phone.txt === "") {
      notify(t(translationKey, "phoneError"), "error");
      setFormDatas({ ...formDatas, phone: { ...phone, error: true } });
      return;
    }
    if (desp.txt === "") {
      notify(t(translationKey, "despError"), "error");
      setFormDatas({ ...formDatas, desp: { ...desp, error: true } });
      return;
    }
    if (request.txt === "") {
      notify(t(translationKey, "requestError"), "error");
      setFormDatas({ ...formDatas, request: { ...request, error: true } });
      return;
    }

    MailService.sendMail(
      name.txt,
      surname.txt,
      email.txt,
      phone.txt,
      desp.txt,
      request.txt,
      config.countryForAPI
    ).then((res) => {
      if (res.status === 201) {
        notify(t(translationKey, "success"), "success");
        setFormDatas({
          name: { txt: "", error: false },
          surname: { txt: "", error: false },
          email: { txt: "", error: false },
          phone: { txt: "", error: false },
          desp: { txt: "", error: false },
          request: { txt: "", error: false },
        });
      } else {
        notify(t(translationKey, "error"), "error");
      }
    });
  };

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);
  const button = (text: string, url: string, isLong: boolean, onClick: any) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"center"}
          onClick={onClick}
        />
      </div>
    );
  };
  return (
    <Container
      style={{
        marginTop: 60,
        marginBottom: 100,
        maxWidth: "80vw",
      }}
    >
      <div className="d-flex align-items-center">
        <img className="mobile-img" src={theme.IMAGES.support.returnsImg} alt={"sercair"} />
        <h2 className="ms-4 gothamMedium mobile-font" style={Style.mainTitle}>
          {t(translationKey, "mainTitle")}
        </h2>
      </div>
      <p
        className="my-4"
        style={{
          //@ts-ignore
          maxWidth: "80vw",
        }}
        dangerouslySetInnerHTML={{ __html: t(translationKey, "text") }}
      ></p>

      <div className="row m-0 p-0">
        <div
          className="col-sm-12 col-md-12 col-lg-6 col-xl-6 row m-0 p-0"
          id="form"
          style={Style.form}
        >
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "name")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              type="text"
              value={formDatas.name.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  name: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.name.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  name: { txt: "", error: true },
                })
              }
            />
            {formDatas.name.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "lastName")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              type="text"
              value={formDatas.surname.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  surname: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.surname.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  surname: { txt: "", error: true },
                })
              }
            />
            {formDatas.surname.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "email")}
            </p>
            <input
              style={Style.inputs}
              className="p-3"
              value={formDatas.email.txt}
              type="text"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  email: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.email.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  email: { txt: "", error: true },
                })
              }
            />
            {formDatas.email.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "phone")}
            </p>
            <input
            value={formDatas.phone.txt}
              style={Style.inputs}
              className="p-3"
              type="text"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  phone: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.phone.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  phone: { txt: "", error: true },
                })
              }
            />
            {formDatas.phone.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "sortDesp")}
            </p>
            <textarea
            value={formDatas.desp.txt}
              style={Style.inputs}
              className="p-3"
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  desp: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.desp.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  desp: { txt: "", error: true },
                })
              }
            />
            {formDatas.desp.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>
          <div className="row m-0 p-0 p-3 w-100">
            <p className="p-0" style={Style.text}>
              {t(translationKey, "request")}
            </p>
            <textarea
              className="p-3"
              style={Style.request}
              value={formDatas.request.txt}
              onChange={(e) => {
                setFormDatas({
                  ...formDatas,
                  request: { txt: e.target.value, error: false },
                });
              }}
              onBlur={() =>
                formDatas.request.txt.length === 0 &&
                setFormDatas({
                  ...formDatas,
                  request: { txt: "", error: true },
                })
              }
            />
            {formDatas.request.error && (
              <p className="p-0" style={Style.required}>
                {t(translationKey, "required")}
              </p>
            )}
          </div>

          {button(
            t(translationKey, "send"),
            "/support/requests",
            false,
            sendMail
          )}
        </div>
      </div>
    </Container>
  );
}