import { Contact } from "../../screens/Contact";

export const ContactScreenNavigator = [
  {
    path: "/contact",
    exact: true,
    screen: <Contact />,
  },
  {
    path: "tr/iletisim",
    exact: true,
    screen: <Contact />,
  },
  {
    path: "nl/contact",
    exact: true,
    screen: <Contact />,
  },
  {
    path: "rs/kontakt",
    exact: true,
    screen: <Contact />,
  },
  {
    path: "fr/contact",
    exact: true,
    screen: <Contact />,
  },
  {
    path: "uk/contact",
    exact: true,
    screen: <Contact />,
  },
  {
    path: "ro/contactati",
    exact: true,
    screen: <Contact />,
  },
];
