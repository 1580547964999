import { CountryModal } from "./Shared/Modals/CountryModal";

import { LanguageModal } from "./Shared/Modals/LanguageModal";
import { OpeningModal } from "./Shared/Modals/OpeningModal";
import { VideoModal } from "./Shared/Modals/VideoModal";

export function ModalStack() {

  return (
    <>
      <LanguageModal />
      <VideoModal  />
      <OpeningModal />
     
      <CountryModal />
      


    </>
  );
}
