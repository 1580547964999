import { MoniMeScreenComponent } from "../../screens/Procucts/AirQuality/MoniMe";
import { MoniWayScreenComponent } from "../../screens/Procucts/AirQuality/MoniWay";

export const AirQualityScreenNavigator = [
  {
    path: "/products/AirQuality/moniway",
    exact: true,
    screen: <MoniWayScreenComponent />,
  },
  {
    path: "/tr/urunler/hava-temizleyici/moniway",
    exact: true,
    screen: <MoniWayScreenComponent />,
  },
  {
    path: "/nl/producten/luchtkwaliteit/moniway",
    exact: true,
    screen: <MoniWayScreenComponent />,
  },
  {
    path: "/rs/proizvodi/kvalitet-vazduha/moniway",
    exact: true,
    screen: <MoniWayScreenComponent />,
  },
  {
    path: "/fr/produits/qualite-de-air/moniway",
    exact: true,
    screen: <MoniWayScreenComponent />,
  },
  {
    path: "/uk/products/AirQuality/moniway",
    exact: true,
    screen: <MoniWayScreenComponent />,
  },
  {
    path: "/ro/produse/calitatea-aerului/moniway",
    exact: true,
    screen: <MoniWayScreenComponent />,
  },
  {
    path: "/products/AirQuality/monime",
    exact: true,
    screen: <MoniMeScreenComponent />,
  },
  {
    path: "/tr/urunler/hava-temizleyici/monime",
    exact: true,
    screen: <MoniMeScreenComponent />,
  },
  {
    path: "/nl/producten/luchtkwaliteit/monime",
    exact: true,
    screen: <MoniMeScreenComponent />,
  },
  {
    path: "/rs/proizvodi/kvalitet-vazduha/monime",
    exact: true,
    screen: <MoniMeScreenComponent />,
  },
  {
    path: "/fr/produits/qualite-de-air/monime",
    exact: true,
    screen: <MoniMeScreenComponent />,
  },
  {
    path: "/uk/products/AirQuality/monime",
    exact: true,
    screen: <MoniMeScreenComponent />,
  },
  {
    path: "/ro/produse/calitatea-aerului/monime",
    exact: true,
    screen: <MoniMeScreenComponent />,
  },
];
