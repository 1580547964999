import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import { NavBars, NavBarsTR } from "../../constants/navbar";
import theme from "../../constants/theme";
import useWindowDimensions from "../../services/getWindowSize.service";
import { TranslationService } from "../../services/translation.service";
import { ModalSwitcher } from "../../utils/modal-switcher";
import CallUs from "../CallUsComponent/CallUs";
import { CollapseComponent } from "../Shared/CollapseComponent/component";
import { Style } from "./style";
import "./style.css";
import { Props } from "./types";

export function Component(props: Props) {
  const langCheck = localStorage.getItem("lang");

  const langClass = document.querySelectorAll(".langChecker");

  if (
    langCheck === "nl" ||
    langCheck === "rs" ||
    langCheck === "fr" ||
    langCheck === "en" ||
    langCheck === "ro" ||
    langCheck === "uk"
  ) {
    langClass.forEach((item) => {
      item.classList.add("langDisplay");
    });
  } else {
    langClass.forEach((item) => {
      item.classList.remove("langDisplay");
    });
  }
  const windowSize = useWindowDimensions().width;
  const t = TranslationService.translate;
  const [logoSize, setLogoSize] = useState({
    height: 75,
    width: 110,
  });
  const [mapIcon, setMapIcon] = useState(
    //@ts-ignore
    theme.IMAGES.navbar["map_" + TranslationService.getLang().code]
  );
  const [productsCollapseOpen, setProductsCollapseOpen] = useState(false);
  const [activePageController, setActivePageController] = useState(
    window.location.pathname
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setActivePageController(window.location.pathname);
  });
  const [navLink, setNavLink] = useState(
    new Array<any>(NavBars.length + 1).fill(Style.navLink)
  );
  const [supportsCollapseOpen, setSupportCollapseOpen] = useState(false);

  useEffect(() => {
    productsCollapseOpen && setSupportCollapseOpen(false);
    supportsCollapseOpen && setProductsCollapseOpen(false);
  }, [productsCollapseOpen, supportsCollapseOpen]);
  useEffect(() => {
    //find class name of navbar-toggler and "navbar-collapse container-fluid justify-content-between bg-white collapse show"
    const navbarToggler = document.getElementsByClassName(
      "navbar-toggler"
    )[0] as HTMLElement;
    const navbarCollapse = document.getElementsByClassName(
      "navbar-collapse container-fluid justify-content-between bg-white collapse show"
    )[0] as HTMLElement;

    //change to navbar-toggler collapsed and "navbar-collapse container-fluid justify-content-between bg-white collapse"
    if (navbarToggler && navbarCollapse) {
      navbarToggler.className = "navbar-toggler collapsed";
      navbarCollapse.className =
        "navbar-collapse container-fluid justify-content-between bg-white collapse";
    }
  }, [activePageController]);
  useEffect(() => {
    setMapIcon(
      //@ts-ignore
      theme.IMAGES.navbar["map_tr"]
    );
    // eslint-disable-next-line
  }, [TranslationService.getLang().code]);

  const handleScroll = (event: any) => {};

  const handleHover = (index: number, hover: boolean) => {
    if (hover) {
      let newNavLink = [...navLink];
      newNavLink[index] = Style.navLinkHover;
      setNavLink(newNavLink);
    } else {
      let newNavLink = [...navLink];
      newNavLink[index] = Style.navLink;
      setNavLink(newNavLink);
    }
  };

  //click controller outside
  const handleClickOutside = (event: any) => {
    if (event.target.id === "support-collapse-link") {
      setTimeout(() => {
        //first go to link after close collapse
        setSupportCollapseOpen(false);
        setProductsCollapseOpen(false);
      }, 100);
    } else {
      if (event.target.tagName !== "A") {
        setProductsCollapseOpen(false);
        setSupportCollapseOpen(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function setStyleLang(arg0: { color: string; cursor: string }): void {
    throw new Error("Function not implemented.");
  }

  return (
    <>
      {windowSize < 991 ? (
        <Container>
          <nav
            className="navbar  fixed-top justify-content-between"
            style={Style.navbarMobile}
            onScroll={handleScroll}
          >
            <div className="container-fluid d-flex">
              <Link
                className="p-0 col-12 main-logo"
                to={
                  langCheck === "nl"
                    ? "/nl"
                    : langCheck === "rs"
                    ? "/rs"
                    : langCheck === "en"
                    ? "/"
                    : langCheck === "tr"
                    ? "/tr"
                    : langCheck === "fr"
                    ? "/fr"
                    : langCheck === "uk"
                    ? "/uk"
                    : langCheck === "ro"
                    ? "/ro"
                    : "/"
                }
                style={{
                  ...Style.logoMarginLeft,
                  marginLeft: 0,
                  marginTop: 17,
                }}
                onClick={() => window.scrollTo(0, 0)}
              >
                <img
                  src={theme.ICONS.logoBlue}
                  alt="sercair"
                  style={{
                    width: 120,
                  }}
                />
              </Link>
              <div
                style={{
                  width: "100%",
                  height: 0.5,
                  backgroundColor: theme.COLORS.grayBorder,
                  marginTop: 20,
                  marginBottom: -15,
                  left: 0,
                  position: "sticky",
                }}
              />
              <div className="d-flex align-items-center mt-4 justify-content-between w-100">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span
                    style={{ width: 20, height: 20 }}
                    className="navbar-toggler-icon"
                  ></span>
                </button>

                <div className="form-inline my-lg-0">
                  <div className="ps-3 d-flex aling-items-center">
                    <div className="langChecker d-flex aling-items-center justify-content-center">
                      {/* <Link
                        className="ps-3"
                        style={Style.menuRightLinks}
                        onClick={() =>
                          window.open(
                            "https://www.sercairstore.com/uye-girisi",
                            "_blank"
                          )
                        }
                        to=""
                      >
                        <img
                          style={accountSize}
                          alt="sercair"
                          className="logoNavbar"
                          src={theme.ICONS.navbar.account}
                          onMouseOver={(e) => {
                            setAccountSize(theme.ICON_SIZE.size24);
                          }}
                          onMouseOut={(e) => {
                            setAccountSize(theme.ICON_SIZE.size18);
                          }}
                        />
                      </Link>
                      <Link
                        className="ps-3"
                        style={Style.menuRightLinks}
                        onClick={() =>
                          window.open(
                            "https://www.sercairstore.com/sepet",
                            "_blank"
                          )
                        }
                        to=""
                      >
                        <img
                          style={basketSize}
                          className="logoNavbar"
                          alt="sercair"
                          src={theme.ICONS.navbar.basket}
                          onMouseOver={(e) => {
                            setBasketSize(theme.ICON_SIZE.size32);
                          }}
                          onMouseOut={(e) => {
                            setBasketSize(theme.ICON_SIZE.size24);
                          }}
                        />
                      </Link> */}
                      {/* <Link
                        className="ps-3 language-position"
                        style={Style.menuRightLinks}
                        to="#"
                      >
                        <img
                          style={langSize}
                          className="logoNavbar"
                          alt="sercair"
                          src={theme.ICONS.navbar.lang}
                          onClick={() => {
                            ModalSwitcher.ShowLanguageModal();
                          }}
                          onMouseOver={(e) => {
                            setLangSize(theme.ICON_SIZE.size24);
                          }}
                          onMouseOut={(e) => {
                            setLangSize(theme.ICON_SIZE.size18);
                          }}
                        />
                      </Link> */}
                    </div>
                    <div>
                      <CallUs />
                    </div>

                    <div
                      style={{ marginLeft: 10 }}
                      className="d-flex justify-content-center"
                    >
                      <div className="d-flex align-items-center justify-content-start ml-2">
                        {/* <img
                          className="hover-effect"
                          src={TranslationService.getLang().flag}
                          style={{
                            width: 33,
                            height: 43,
                            marginLeft: screenSize < 500 ? 10 : 0,
                          }}
                          alt="sercair"
                          onClick={() => ModalSwitcher.ShowCountryModal()}
                          onMouseOver={() =>
                            setStyleLang({
                              color: theme.COLORS.blue,
                              cursor: "pointer",
                            })
                          }
                        /> */}
                        {/* <div className="hover-effect">
                          <span
                            className="d-block"
                            onMouseOver={() =>
                              setStyleLang({
                                color: theme.COLORS.blue,
                                cursor: "pointer",
                              })
                            }
                            onMouseLeave={() =>
                              setStyleLang({
                                color: theme.COLORS.black,
                                cursor: "pointer",
                              })
                            }
                            onClick={() => ModalSwitcher.ShowCountryModal()}
                          >
                            {t("Footer", TranslationService.getLang().country)}
                          </span>
                          <span className="d-block mt-2"></span>
                        </div> */}
                      </div>
                    </div>
                    <Link
                      className="ps-3 d-flex aling-items-center"
                      style={Style.menuRightLinks}
                      to={window.location.pathname}
                      onClick={() => {
                        window.open(
                          "https://map.sercair.com/mapnew?QZoom=5&QLat=36.27120584780272&QLon=240.43533892082847",
                          "_blank"
                        );
                      }}
                    >
                      <img
                        style={{ width: 90 }}
                        alt="sercair"
                        onMouseOver={(e) =>
                          setMapIcon(
                            //@ts-ignore
                            theme.IMAGES.navbar["mapHover_tr"]
                          )
                        }
                        onMouseOut={(e) =>
                          setMapIcon(
                            //@ts-ignore
                            theme.IMAGES.navbar["map_tr"]
                          )
                        }
                        src={mapIcon}
                      />{" "}
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className=""
                style={Style.collapseHeightMobile}
                id="navbarNav"
              >
               
                {/* <ul className="navbar-nav mr-auto ">
                  {NavBars.map(function (data, index) {
                    return (
                      <li key={index + "navbarNav"} className="nav-item ps-4">
                        {data.onCollapse ? (
                          <>
                            <Link
                              aria-controls={data.controls}
                              aria-expanded={
                                data.type === "products"
                                  ? productsCollapseOpen
                                  : supportsCollapseOpen
                              }
                              className="nav-link"
                              to={window.location.pathname}
                              onClick={() => {
                                data.type === "products"
                                  ? setProductsCollapseOpen(
                                      !productsCollapseOpen
                                    )
                                  : setSupportCollapseOpen(
                                      !supportsCollapseOpen
                                    );
                              }}
                            >
                              <p
                                className="dropdown-nav-header"
                                style={navLink[index]}
                                onMouseOver={() => handleHover(index, true)}
                                onMouseOut={() => handleHover(index, false)}
                              >
                                {t("Navbar", data.key)}
                              </p>
                            </Link>
                            <CollapseComponent
                              isOpen={
                                data.type === "products"
                                  ? productsCollapseOpen
                                  : supportsCollapseOpen
                              }
                              setIsOpen={
                                data.type === "products"
                                  ? setProductsCollapseOpen
                                  : setSupportCollapseOpen
                              }
                              datas={
                                data.type === "products"
                                  ? data.collapse
                                  : data.collapse
                              }
                              id={data.controls}
                              type={data.type}
                            />
                          </>
                        ) : (
                          <Link
                            className="nav-link"
                            to={data.url === "/shopping" ? "" : data.url}
                            onClick={() => {
                              data.url === "/shopping" &&
                                window.open(
                                  "https://www.sercairstore.com",
                                  "_blank"
                                );
                            }}
                          >
                            <p
                              style={navLink[index]}
                              onMouseOver={() => handleHover(index, true)}
                              onMouseOut={() => handleHover(index, false)}
                            >
                              {t("Navbar", data.key)}
                            </p>
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul> */}
              </div>
            </div>
          </nav>
        </Container>
      ) : (
        <Container>
          <nav
            className="navbar navbar-expand-lg fixed-top"
            style={Style.navbar}
            onScroll={handleScroll}
          >
            <div className="container-fluid d-flex justify-content-between mx-4 align-items-center">
              <Link
                className="p-0"
                to={
                  langCheck === "nl"
                    ? "/nl"
                    : langCheck === "rs"
                    ? "/rs"
                    : langCheck === "en"
                    ? "/"
                    : langCheck === "tr"
                    ? "/tr"
                    : langCheck === "fr"
                    ? "/fr"
                    : langCheck === "uk"
                    ? "/uk"
                    : langCheck === "ro"
                    ? "/ro"
                    : "/"
                }
                style={Style.logoMarginLeft}
                onClick={() => window.scrollTo(0, 0)}
              >
                <img
                  src={theme.ICONS.logoBlue}
                  alt="sercair"
                  style={logoSize}
                  onMouseOver={(e) => {
                    setLogoSize({
                      height: 75,
                      width: 112,
                    });
                  }}
                  onMouseOut={(e) => {
                    setLogoSize({
                      height: 75,
                      width: 110,
                    });
                  }}
                />
              </Link>

              <div
                className="d-flex align-items-center"
                style={Style.collapseHeight}
              >
                {/* <ul className="navbar-nav mr-auto ">
                  {NavBars.map(function (data, index) {
                    return (
                      <li key={index} className="nav-item ps-4">
                        {data.onCollapse ? (
                          <>
                            <Link
                              aria-controls={data.controls}
                              aria-expanded={
                                data.type === "products"
                                  ? productsCollapseOpen
                                  : supportsCollapseOpen
                              }
                              className="nav-link"
                              to={window.location.pathname}
                              onClick={() => {
                                data.type === "products"
                                  ? setProductsCollapseOpen(
                                      !productsCollapseOpen
                                    )
                                  : setSupportCollapseOpen(true);
                              }}
                            >
                              <p
                                className="dropdown-nav-header"
                                style={navLink[index]}
                                onMouseOver={() => handleHover(index, true)}
                                onMouseOut={() => handleHover(index, false)}
                              >
                                {t("Navbar", data.key)}
                              </p>
                            </Link>
                            <CollapseComponent
                              isOpen={
                                data.type === "products"
                                  ? productsCollapseOpen
                                  : supportsCollapseOpen
                              }
                              setIsOpen={
                                data.type === "products"
                                  ? setProductsCollapseOpen
                                  : setSupportCollapseOpen
                              }
                              datas={
                                data.type === "products"
                                  ? data.collapse
                                  : data.collapse
                              }
                              id={data.controls}
                              type={data.type}
                            />
                          </>
                        ) : (
                          <div>
                            <Link
                              className="nav-link"
                              to={data.url === "/shopping" ? "" : data.url}
                              onClick={() => {
                                data.url === "/shopping" &&
                                  window.open(
                                    "https://www.sercairstore.com",
                                    "_blank"
                                  );
                              }}
                            >
                              <p
                                style={navLink[index]}
                                onMouseOver={() => handleHover(index, true)}
                                onMouseOut={() => handleHover(index, false)}
                              >
                                {t("Navbar", data.key)}
                              </p>
                            </Link>
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul> */}
                <div
                  className="form-inline my-lg-0"
                  style={{ marginRight: "10%" }}
                >
                  <div className="ps-4 d-flex aling-items-center">
                    <div className="d-flex aling-items-center justify-content-center langChecker">
                      {/* <Link
                        className="ps-3"
                        style={Style.menuRightLinks}
                        onClick={() =>
                          window.open(
                            "https://www.sercairstore.com/uye-girisi",
                            "_blank"
                          )
                        }
                        to=""
                      >
                        <img
                          style={accountSize}
                          alt="sercair"
                          className="logoNavbar"
                          src={theme.ICONS.navbar.account}
                          onMouseOver={(e) => {
                            setAccountSize(theme.ICON_SIZE.size24);
                          }}
                          onMouseOut={(e) => {
                            setAccountSize(theme.ICON_SIZE.size18);
                          }}
                        />
                      </Link>
                      <Link
                        className="ps-3"
                        style={Style.menuRightLinks}
                        onClick={() =>
                          window.open(
                            "https://www.sercairstore.com/sepet",
                            "_blank"
                          )
                        }
                        to=""
                      >
                        <img
                          style={basketSize}
                          className="logoNavbar"
                          alt="sercair"
                          src={theme.ICONS.navbar.basket}
                          onMouseOver={(e) => {
                            setBasketSize(theme.ICON_SIZE.size32);
                          }}
                          onMouseOut={(e) => {
                            setBasketSize(theme.ICON_SIZE.size24);
                          }}
                        />
                      </Link> */}
                      {/* <Link
                        className="ps-3 d-flex align-items-center"
                        style={Style.menuRightLinks}
                        to="#"
                      >
                        <img
                          style={langSize}
                          className="logoNavbar"
                          alt="sercair"
                          src={theme.ICONS.navbar.lang}
                          onClick={() => {
                            ModalSwitcher.ShowLanguageModal();
                          }}
                          onMouseOver={(e) => {
                            setLangSize(theme.ICON_SIZE.size24);
                          }}
                          onMouseOut={(e) => {
                            setLangSize(theme.ICON_SIZE.size18);
                          }}
                        />
                      </Link> */}
                    </div>
                    <div>
                      <CallUs />
                    </div>

                    <div
                      style={{ marginLeft: 10 }}
                      className="d-flex justify-content-center"
                    >
                      <div className="d-flex align-items-center justify-content-start ml-2">
                        {/* <img
                          className="hover-effect"
                          src={TranslationService.getLang().flag}
                          style={{
                            width: 33,
                            height: 43,
                            marginLeft: screenSize < 500 ? 40 : 0,
                          }}
                          alt="sercair"
                          onClick={() => ModalSwitcher.ShowCountryModal()}
                          onMouseOver={() =>
                            setStyleLang({
                              color: theme.COLORS.blue,
                              cursor: "pointer",
                            })
                          }
                        /> */}
                        {/* <div className="hover-effect">
                          <span
                            className="d-block"
                            onMouseOver={() =>
                              setStyleLang({
                                color: theme.COLORS.blue,
                                cursor: "pointer",
                              })
                            }
                            onMouseLeave={() =>
                              setStyleLang({
                                color: theme.COLORS.black,
                                cursor: "pointer",
                              })
                            }
                            onClick={() => ModalSwitcher.ShowCountryModal()}
                          >
                            {t("Footer", TranslationService.getLang().country)}
                          </span>
                          <span className="d-block mt-2"></span>
                        </div> */}
                      </div>
                    </div>
                    <Link
                      className="ps-3"
                      style={Style.menuRightLinks}
                      to={window.location.pathname}
                      onClick={() => {
                        if (langCheck === "en") {
                          window.open(
                            "https://map.sercair.com/mapnew?QZoom=5&QLat=36.27120584780272&QLon=240.43533892082847",
                            "_blank"
                          );
                        } else if (langCheck === "tr") {
                          window.open(
                            "https://map.sercair.com/mapnew",
                            "_blank"
                          );
                        } else if (langCheck === "nl") {
                          window.open(
                            "https://map.sercair.com/mapnew?QZoom=8&QLat=52.3194745279673&QLon=4.920335507037411",
                            "_blank"
                          );
                        } else if (langCheck === "rs") {
                          window.open(
                            "https://map.sercair.com/mapnew?QZoom=8&QLat=43.722703813810845&QLon=21.442423220435874",
                            "_blank"
                          );
                        } else if (langCheck === "fr") {
                          window.open(
                            "https://map.sercair.com/mapnew?QZoom=8&QLat=48.79421853174984&QLon=2.326651605012",
                            "_blank"
                          );
                        } else if (langCheck === "uk") {
                          window.open(
                            "https://map.sercair.com/mapnew?QZoom=8&QLat=53.403194439736744&QLon=-2.2374484158289007",
                            "_blank"
                          );
                        } else if (langCheck === "ro") {
                          window.open(
                            "https://map.sercair.com/mapnew?QZoom=8&QLat=45.34206071154833&QLon=25.56095943019575",
                            "_blank"
                          );
                        }
                      }}
                    >
                      <img
                        style={{ width: 140 }}
                        alt="sercair"
                        onMouseOver={(e) =>
                          setMapIcon(
                            //@ts-ignore
                            theme.IMAGES.navbar["mapHover_tr"]
                          )
                        }
                        onMouseOut={(e) =>
                          setMapIcon(
                            //@ts-ignore
                            theme.IMAGES.navbar["map_tr"]
                          )
                        }
                        src={mapIcon}
                      />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </Container>
      )}
    </>
  );
}
