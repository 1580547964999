// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { SliderAuto, Slider2 } from "../../constants/mock";
import { SliderComponent } from "../SliderComponent";

import "./component.css";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper";

export default function AutoSliderComponent(props) {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {/* <SwiperSlide>
          <SliderComponent sliderItems={[]} />
        </SwiperSlide> */}
        <SwiperSlide>
          <SliderComponent sliderItems={SliderAuto} />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
