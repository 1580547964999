import theme from "../../../constants/theme";

export const Style = {
  main: {},
  meetSlienceEchoStyle: {
    position: "relative" as "relative",
    zIndex: 2,
    "--data-color": theme.COLORS.ecoOrange,
    "--data-font-size": "50px",
    "--data-top": "-61px",
    "--data-left": "93px",
  },
  healthySlienceEchoStyle: {
    position: "relative" as "relative",
    zIndex: 2,
    "--data-color": theme.COLORS.ecoOrange,
    "--data-font-size": "50px",
    "--data-top": "-61px",
    "--data-left": "0px",
  },
  airFlowEchoStyle: {
    position: "relative" as "relative",
    zIndex: 2,
    "--data-color": theme.COLORS.ecoBlue,
    "--data-font-size": "50px",
    width: "1000px",
    "--data-top": "-61px",
    "--data-left": "-1px",
    textAlign: "center" as "center",
  },
  highAirQuality: {
    position: "relative" as "relative",
    zIndex: 2,
    "--data-color": theme.COLORS.ecoBlue,
    "--data-font-size": "50px",
    width: "655px",
    "--data-top": "-61px",
    "--data-left": "0px",
    textAlign: "left" as "left",
    marginBottom: "60px",
    marginTop: "56px",
  },
  sterillization: {
    textAlign: "right" as "right",
    position: "relative" as "relative",
    zIndex: 2,
    "--data-color": theme.COLORS.ecoPurple,
    "--data-font-size": "50px",
    width: "517px",
    "--data-top": "-43px",
    "--data-left": "70px",
    marginBottom: "60px",
    marginTop: "56px",
  },
  sterillizationMobileStyle: {
    textAlign: "center" as "center",
    position: "relative" as "relative",
    zIndex: 2,
    "--data-color": theme.COLORS.ecoPurple,
    "--data-font-size": "18px",

    width: "80%",
    "--data-top": "-28px",
    "--data-left": "70px",
    marginBottom: "30px",
  },
  AirCleaningMobileStyle: {
    textAlign: "center" as "center",
    position: "relative" as "relative",
    zIndex: 2,
    "--data-color": theme.COLORS.ecoBlue,
    "--data-font-size": "18px",

    width: "200%",
    "--data-top": "-28px",
    "--data-left": "70px",
    marginBottom: "30px",
  },
  rezervation: {
    textAlign: "left" as "left",
    position: "relative" as "relative",
    zIndex: 2,
    "--data-color": theme.COLORS.ecoPurple,
    "--data-font-size": "50px",
    width: "517px",
    "--data-top": "-41px",
    "--data-left": "0px",
    marginBottom: "60px",
    marginTop: "56px",
  },

  rezervationMobileStyle: {
    textAlign: "center" as "center",
    position: "relative" as "relative",
    zIndex: 2,
    "--data-color": theme.COLORS.ecoPurple,
    "--data-font-size": "18px",
    "--data-top": "-31px",
    "--data-left": "0px",
    marginBottom: "20px",
    marginTop: "56px",
  },
};
