import theme from "../../../constants/theme";

export const Style = {
  tableComponentTitle: {
    fontWeight: "bold",
    color: "#000",
    marginBottom: "1rem",
  },
  bodyHeader: {
    color: theme.COLORS.blue,
    backgroundColor: theme.COLORS.blue40,
    borderRadius: theme.BORDER_RADIUS.large,
    textAlign: "center" as "center",
    padding: "0.5rem",
    marginLeft: -10,
    marginBottom: "0.5rem",
  },
  bodyHeaderNone: {
    color: theme.COLORS.blue,
    backgroundColor: theme.COLORS.white,
    borderRadius: theme.BORDER_RADIUS.large,
    textAlign: "center" as "center",
    padding: "0.5rem",
    marginLeft: -10,
    marginBottom: "0.5rem",
  },
};
