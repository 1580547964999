export const data = [
  {
    questionTitle: "s1",
    questions: [
      {
        question: "s1q1",
        answer: "s1a1",
      },
      {
        question: "s1q2",
        answer: "s1a2",
      },
      {
        question: "s1q3",
        answer: "s1a3",
      },
    ],
  },
  
  {
    questionTitle: "s3",
    questions: [
      {
        question: "s3q1",
        answer: "s3a1",
      },
      {
        question: "s3q2",
        answer: "s3a2",
      },
    ],
  },
  {
    questionTitle: "s4",
    questions: [
      {
        question: "s4q1",
        answer: "s4a1",
      },
    ],
  },
  {
    questionTitle: "s5",
  },
  {
    questionTitle: "s6",
  },
  {
    questionTitle: "s7",
  },
  {
    questionTitle: "s8",
  },
  {
    questionTitle: "s10",
  },
];
