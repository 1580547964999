import {
  showLanguageModal,
  showVideoModal,
  showOpeningModal,
  showCountryModal,
} from "../store/ui/actions";
import { store } from "../store";
export class ModalSwitcher {
  static ShowLanguageModal = (): boolean => {
    store.dispatch(showLanguageModal(true) as any);
    return true;
  };

  static HideLanguageModal = (): boolean => {
    store.dispatch(showLanguageModal(false) as any);
    return true;
  };

  static ShowVideoModal = (videoId: string): boolean => {
    store.dispatch(showVideoModal(true, videoId) as any);
    return true;
  };

  static HideVideoModal = (): boolean => {
    store.dispatch(showVideoModal(false) as any);
    return true;
  };

  static ShowOpeningModal = (imgPath: string, imgAlt: string): boolean => {
    store.dispatch(showOpeningModal(true, imgPath, imgAlt) as any);
    return true;
  };

  static HideOpeningModal = (): boolean => {
    store.dispatch(showOpeningModal(false) as any);
    return true;
  };

  static ShowCountryModal = (): boolean => {
    store.dispatch(showCountryModal(true) as any);
    return true;
  };

  static HideCountryModal = (): boolean => {
    store.dispatch(showCountryModal(false) as any);
    return true;
  };
}
