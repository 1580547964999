import React from 'react'

import './infoText.css'

function InfoText() {
  return (
    <div className='infoText_container'>
         <p className="infoText">Bu veriler bilgilendirme amaçlıdır. Veriler Sercair tarafından sağlanmaktadır.</p>
          <p className="infoText"></p>
    </div>
  )
}

export default InfoText