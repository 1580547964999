import "./weatherCard.css";

function WeatherCard(props) {
  return (
    <div
      className="weatherCard"
      style={{
        backgroundImage: `url(${props.image})`,
        backgroundRepeat: "repeat",
        resize: "contain",
        border: "none",
      }}
    >
      <div className="forecastContainer">
        <div className="weather">
          <p
            className="tex"
            style={{ color: props.isPassive ? "gray" : props.color }}
          >
            {props.weather}
          </p>
        </div>
        <div className="dayContainer">
          <div
            className="day"
            style={{ color: props.isPassive ? "gray" : props.color }}
          >
            {props.day}
          </div>
          <div
            className="date"
            style={{ color: props.isPassive ? "gray" : props.color }}
          >
            {props.date}
          </div>
        </div>
      </div>
      {!props.isPassive ? (
        <h1 className="degree" style={{ color: props.color }}>
          {props.degree}°C
        </h1>
      ) : (
        <h1 className="degree" style={{ color: props.color }}>
          {props.degree}
        </h1>
      )}
    </div>
  );
}

export default WeatherCard;
