import theme from "../../../constants/theme";

export const Style = {
  mainTitle: {
    color: theme.COLORS.blue,
  },
  form: {
    border: "1px solid" + theme.COLORS.blue,
    borderRadius: "20px",
  },
  required: {
    color: theme.COLORS.red,
    fontSize: "9pt",
  },
  text: {
    fontSize: "10pt",
    color: theme.COLORS.blue,
  },
  request: {
    backgroundColor: theme.COLORS.blue40,

    border: "none",
    borderBottom: "1px solid" + theme.COLORS.grayBorder,
  },
  inputs: {
    border: "none",
    borderBottom: "1px solid" + theme.COLORS.grayBorder,
  },
};
