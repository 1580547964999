import axios from "axios";
import React from "react";
import { useState } from "react";
import { Fragment } from "react";

import Artboard1 from "../../../../assets/images/WeatherImages/SVG/Artboard1.svg";
import Artboard3 from "../../../../assets/images/WeatherImages/SVG/Artboard3.svg";
import Artboard4 from "../../../../assets/images/WeatherImages/SVG/Artboard4.svg";
import Artboard5 from "../../../../assets/images/WeatherImages/SVG/Artboard5.svg";
import Artboard2 from "../../../../assets/images/WeatherImages/SVG/Artboard2.svg";
import Artboard6 from "../../../../assets/images/WeatherImages/SVG/Artboard6.svg";
import Artboard7 from "../../../../assets/images/WeatherImages/SVG/Artboard7.svg";
import Artboard8 from "../../../../assets/images/WeatherImages/SVG/Artboard8.svg";
import Artboard9 from "../../../../assets/images/WeatherImages/SVG/Artboard9.svg";
import Artboard10 from "../../../../assets/images/WeatherImages/SVG/Artboard10.svg";
import Artboard11 from "../../../../assets/images/WeatherImages/SVG/Artboard11.svg";
import windspeed from "../../../../assets/images/WeatherImages/SVG/windspeed.gif";
import humidity3 from "../../../../assets/images/WeatherImages/SVG/humidity3.gif";
import Info from "../../Info";
import NH3 from "../../../../assets/images/WeatherImages/SVG/new_nh3.svg";
import O3 from "../../../../assets/images/WeatherImages/SVG/O3.svg";
import NO from "../../../../assets/images/WeatherImages/SVG/NO.svg";
import NO2 from "../../../../assets/images/WeatherImages/SVG/NO2.svg";
import SO2 from "../../../../assets/images/WeatherImages/SVG/SO2.svg";
import Detail from "../../Detail"

import Artboard1Pasif from "../../../../assets/images/WeatherImages/SVG/Artboard1Pasif.svg";
import NemPasif from "../../../../assets/images/WeatherImages/SVG/NemPasif.svg";
import WindDirectionPasif from "../../../../assets/images/WeatherImages/SVG/WindDirectionPasif.svg";
import WindSpeedPasif from "../../../../assets/images/WeatherImages/SVG/WindSpeedPasif.svg";
import BasınçPasif from "../../../../assets/images/WeatherImages/SVG/BasınçPasif.svg";

import "./TomorrowList.css";
import Moment from "../../moments/moment";
import WeatherCard from "../../weatherCard";
import Button from "../../Button/Button";



const TomorrowList12 = (props) => {
  const [moment, setMoment] = React.useState(null);
  const [touch, setTouch] = React.useState(null);
 
  
  return (
    <Fragment>
      
      <div className="scroll_TomorrowList2">
        <div>
          {" "}
          {/*1. data gelmesi için*/}
         
          <div className="Tomorrow_TomorrowList2">
            
            {/* {props.opened &&
             <div className="inner_Detail">
              <div>
             <Detail
              header={props.Detail_Header}
              // situation={situation.Temp}
               value={props.Detail_Value}
               text={props.Detail_Text}
               time={props.Detail_Time}
               max1={props.Detail_MAX}
               min={props.Detail_MIN}
               situation={props.Detail_Situation}
               unit={props.Detail_Unit}
               change={props.Detail_Change}
               initial={props.Detail_Initial}
               in={props.Detail_In}
               t={props.Detail_Toggle}
               color={props.Detail_Color}
               click={props.Detail_Click}
             />
             </div>
             </div>}  */}
             {/* <div className="blur"></div> */}
                    <Info
                        icon={props.isPassive ? Artboard1Pasif : props.isPassive ? Artboard1Pasif : Artboard1}
                        //butt={props.temp}
                        togglePressable={false}
                        variable="Sıcaklık"
                        value={props.Temparature}
                        info={props.temp_pre}
                        type={props.isPassive ? "" : "°C"}
                        isPassive={props.isPassive}
                        style={props.isPassive ? true : false}
                      />
                    
                    </div>
                    <div className="year_AvgYearList">
                      <Info
                      
                        icon={props.isPassive ? NemPasif : humidity3}
                        //butt={props.hum}
                        togglePressable={false}
                        variable="Nem"
                        value={props.isPassive ? ""+props.Hummidty : "%"+props.Hummidty}
                        info={props.hum_pre}
                        isPassive={props.isPassive}
                        style={props.isPassive ? true : false}
                      />
                    </div>
                     <div className="year_AvgYearList">
                      <Info
                        icon={props.isPassive ? WindDirectionPasif : Artboard3}
                        //butt={props.wd}
                        variable="Rüzgar yönü"
                        togglePressable={false}
                        value={props.WindDirection}
                        info={props.wd_pre}
                        type={props.isPassive ? "" : "°"}
                        isPassive={props.isPassive}
                        style={props.isPassive ? true : false}
                      />
                    </div>
                    <div className="year_AvgYearList">
                      <Info
                        icon={props.isPassive ? WindSpeedPasif : windspeed}
                        //butt={props.ws}
                        variable="Rüzgar Hızı"
                        value={props.WindSpeed}
                        info={props.ws_pre}
                        togglePressable={false}
                        type={props.isPassive ? "" : "m/s"}
                        isPassive={props.isPassive}
                        style={props.isPassive ? true : false}
                      />
                    </div>
                    <div className="year_AvgYearList">
                      <Info
                        icon={props.isPassive ? BasınçPasif : Artboard5}
                        //butt={props.pres}
                        variable="Basınç"
                        value={props.Pressure}
                        info={props.pres_pre}
                        togglePressable={false}
                        type={props.isPassive ? "" : "p"} 
                        isPassive={props.isPassive}
                        style={props.isPassive ? true : false}
                      />
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    {/*
                    <div className="year_AvgYearList">
                      <Info
                        icon={props.isPassive ? Artboard1Pasif : Artboard7}
                        butt={props.no2}
                        variable="NO2"
                        value={props.NO2}
                        info={props.no2_pre}
                      />
                    </div>
                    <div className="year_AvgYearList">
                      <Info
                        icon={props.isPassive ? Artboard1Pasif : Artboard8}
                        butt={props.so2}
                        variable="SO2"
                        value={props.SO2}
                        info={props.so2_pre}
                      />
                    </div>
                    <div className="year_AvgYearList">
                      <Info
                        icon={props.isPassive ? Artboard1Pasif : Artboard9}
                        butt={props.pm2_5}
                        variable="Pm 2.5"
                        value={props.PM2_5}
                        info={props.pm2_5_pre}
                      />
                    </div>
                    <div className="year_AvgYearList">
                      <Info
                        icon={props.isPassive ? Artboard1Pasif : Artboard6}
                        butt={props.co2}
                        variable="CO2"
                        value={props.avgCO2}
                        info={props.co2_pre}
                      />
                    </div>
                    <div className="year_AvgYearList">
                      <Info
                        icon={props.isPassive ? Artboard1Pasif : Artboard10}
                        butt={props.co}
                        variable="CO"
                        value={props.avgCO}
                        info={props.co_pre}
                      />
                    </div>
                    <div className="year_AvgYearList">
                      <Info
                        icon={props.isPassive ? Artboard1Pasif : Artboard11}
                        variable="VOCs"
                        value={props.VOCs}
                        butt={props.vocs}
                        info={props.vocs_pre}
                      />
                    </div>
                    <div className="year_AvgYearList">
                      <Info
                        icon={props.isPassive ? Artboard1Pasif : NH3}
                        variable="NH3"
                        value={props.NH3}
                        butt={props.nh3}
                        info={props.nh3_pre}
                      />
                    </div>
                    <div className="year_AvgYearList">
                      <Info
                        icon={props.isPassive ? Artboard1Pasif : NO}
                        variable="NO"
                        value={props.NO}
                        butt={props.no}
                        info={props.no_pre}
                      />
                    </div>
                    <div className="year_AvgYearList">
                      <Info
                        icon={props.isPassive ? Artboard1Pasif : O3}
                        variable="O3"
                        value={props.O3}
                        butt={props.o3}
                        info={props.o3_pre}
                      />
                    </div> */}
                  
         
          {/* {names.map((names) => <Info key={data._id.day} variable={names} value={data.avgCO.toFixed(0)} />)}
           */}
           
        </div>
        
      </div>
     
      
    </Fragment>
  );
};

export default TomorrowList12;
