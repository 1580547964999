import React from "react";

function Step2() {
  return (
    <>
      <div>Cihaz sahibi ve bilgiler de buraya eklenecek</div>
      <div>Tarih ve Açıklama burada olacak</div>
    </>
  );
}

export default Step2;
