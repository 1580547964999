import { CloseButton, Modal } from "react-bootstrap";
import "./style.css";
import { Props } from "./types";
import { ModalSwitcher } from "../../../../utils/modal-switcher";
import { ImageComponent } from "../../ImageComponent/component";
import { useEffect } from "react";
import { store } from "../../../../store";

export function Component(props: Props) {
   //@ts-ignore
   const imgPath = store.getState().ui.imgPath;
  useEffect(() => {
    //find the class content if exist and add the class to it
    const content = document.getElementsByClassName("modal-content");
    if (content.length > 0) {
      content[0].classList.add("modal-content-transparent");
    }

    return () => {
      //remove the class when the component is unmounted
      if (content.length > 0) {
        content[0].classList.remove("modal-content-transparent");
      }
    };
  });
  return (
    <>
      <Modal centered size="lg" show={props.showOpeningModal}>
        <Modal.Body style={{ position: "relative", padding: 0 }}>
          <CloseButton
            onClick={() => ModalSwitcher.HideOpeningModal()}
            style={{ position: "absolute", top: 7, right: 7 }}
          />
          <ImageComponent
            alt={ props.imgAlt}
            src={props.imgPath ? props.imgPath : ""}
            style={{ borderRadius: 5 }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
