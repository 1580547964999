import { PureScreenComponent } from "../../screens/Procucts/Pure/Pure";
import { PureCoreScreenComponent } from "../../screens/Procucts/Pure/PureCore";
import { PureHealtyScreenComponent } from "../../screens/Procucts/Pure/PureHealty";
export const PureScreenNavigator = [
  {
    path: "/products/pure/pure",
    exact: true,
    screen: <PureScreenComponent />,
  },
  {
    path: "/products/pure/healthy",
    exact: true,
    screen: <PureHealtyScreenComponent />,
  },
  {
    path: "/products/pure/core",
    exact: true,
    screen: <PureCoreScreenComponent />,
  },
  {
    path: "/tr/urunler/pure/pure",
    exact: true,
    screen: <PureScreenComponent />,
  },
  {
    path: "/tr/urunler/pure/healthy",
    exact: true,
    screen: <PureHealtyScreenComponent />,
  },
  {
    path: "/tr/urunler/pure/core",
    exact: true,
    screen: <PureCoreScreenComponent />,
  },
  {
    path: "nl/producten/pure/pure",
    exact: true,
    screen: <PureScreenComponent />,
  },
  {
    path: "nl/producten/pure/healthy",
    exact: true,
    screen: <PureHealtyScreenComponent />,
  },
  {
    path: "nl/producten/pure/core",
    exact: true,
    screen: <PureCoreScreenComponent />,
  },
  {
    path: "rs/proizvodi/pure/pure",
    exact: true,
    screen: <PureScreenComponent />,
  },
  {
    path: "rs/proizvodi/pure/healthy",
    exact: true,
    screen: <PureHealtyScreenComponent />,
  },
  {
    path: "rs/proizvodi/pure/core",
    exact: true,
    screen: <PureCoreScreenComponent />,
  },
  //
  {
    path: "fr/produits/pure/pure",
    exact: true,
    screen: <PureScreenComponent />,
  },
  {
    path: "fr/produits/pure/healthy",
    exact: true,
    screen: <PureHealtyScreenComponent />,
  },
  {
    path: "fr/produits/pure/core",
    exact: true,
    screen: <PureCoreScreenComponent />,
  },
  //
  {
    path: "uk/products/pure/pure",
    exact: true,
    screen: <PureScreenComponent />,
  },
  {
    path: "uk/products/pure/healthy",
    exact: true,
    screen: <PureHealtyScreenComponent />,
  },
  {
    path: "uk/products/pure/core",
    exact: true,
    screen: <PureCoreScreenComponent />,
  },
  //
  {
    path: "ro/produse/pure/pure",
    exact: true,
    screen: <PureScreenComponent />,
  },
  {
    path: "ro/produse/pure/healthy",
    exact: true,
    screen: <PureHealtyScreenComponent />,
  },
  {
    path: "ro/produse/pure/core",
    exact: true,
    screen: <PureCoreScreenComponent />,
  },
];
