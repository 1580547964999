import { Col, Row } from "react-bootstrap";
import { ImageComponent } from "../../../../components/Shared/ImageComponent/component";
import theme from "../../../../constants/theme";
import { Style } from "../style";
import { PropsS1 } from "./types";

function Step1(props: PropsS1) {
  const {
    isMobile,
    t,
    information,
    setInformation,
    translationKey,
    btnStyle,
    error,
    setError,
    serialNoControl,
  } = props;
  return (
    <>
      <Row className="m-0 p-0 align-items-center justify-content-between">
        <Col xs={12} md={6} className={isMobile ? "p-0 d-flex" : "p-0 d-flex"}>
          <ImageComponent
            className="d-block col-sm-12 col-md-12 mobile-img"
            src={theme.IMAGES.main.SupportsCollapse.assembly}
            alt={"sercair"}
            width={isMobile ? 25 : 10}
            style={{ height: isMobile ? "25%" : "25%", marginTop: 0 }}
          />
          <h1
            className="d-inline text-left pl-3 col-sm-12 col-md-12 mobile-font"
            style={
              isMobile
                ? {
                    ...Style.title,
                    fontSize: "16pt",
                    marginLeft: 5,
                    width: 200,
                    textAlign: "left",
                    color: theme.COLORS.white,
                  }
                : {
                    ...Style.title,
                    fontSize: "20pt",
                    marginLeft: 15,
                    width: 550,
                    color: theme.COLORS.white,
                  }
            }
          >
            {t(translationKey, "title")}
          </h1>
        </Col>
      </Row>
      <Row className="m-0 p-0 pt-5 align-items-center justify-content-center">
        <Row className="row m-0 p-0 text-center align-items-center justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h3 style={Style.subtitle}> {t(translationKey, "subTitle")}</h3>
            <div style={Style.despText}> {t(translationKey, "despText")}</div>
            <div className="col-12 pt-4">
              <form>
                <div className="form-group">
                  <input
                    style={Style.input}
                    placeholder={t(translationKey, "placeholder")}
                    value={information}
                    onChange={(e) => {
                      setInformation(e.target.value);
                      e.target.value.length > 0
                        ? setError({
                            errorType: "",
                            errorMessage: "",
                            btnState: true,
                          })
                        : setError({
                            errorType: "empty",
                            errorMessage: t(translationKey, "errorEmpty"),
                            btnState: false,
                          });
                    }}
                    onBlur={(e) =>
                      e.target.value.length === 0
                        ? setError({
                            errorType: "empty",
                            errorMessage: t(translationKey, "errorEmpty"),
                            btnState: false,
                          })
                        : setError({
                            errorType: "",
                            errorMessage: "",
                            btnState: true,
                          })
                    }
                  />
                  {error.errorType === "empty" && (
                    <div
                      style={Style.errorStyle}
                      dangerouslySetInnerHTML={{ __html: error.errorMessage }}
                    />
                  )}
                </div>
                {error.errorType === "invalid" && (
                  <div
                    style={Style.errorStyle}
                    dangerouslySetInnerHTML={{ __html: error.errorMessage }}
                  />
                )}

                <div className="w-100 m-0">
                  <Row className="m-0 p-0 p-3 w-100 align-items-center justify-content-center">
                    <button
                      style={error.btnState ? btnStyle[1] : btnStyle[0]}
                      disabled={error.btnState ? false : true}
                      onClick={() => {
                        serialNoControl(information);
                      }}
                    >
                      {t(translationKey, "button")}
                    </button>
                  </Row>
                </div>
              </form>
            </div>
          </div>
        </Row>
      </Row>
    </>
  );
}

export default Step1;
