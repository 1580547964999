import { MainScreen } from "../../screens/Main";
import { Production } from "../../screens/InProduction";
import Calendar from "../../screens/Calendar/Calendar";
import CalendarBurak from "../../screens/CalendarBurak/Calendar";
import CalendarYigit from "../../screens/CalendarYigit/Calendar";

export const MainScreenNavigator = [
  {
    path: "/",
    exact: true,
    screen: <MainScreen />,
  },
  {
    path: "/Home",
    exact: true,
    screen: <MainScreen />,
  },
  // {
  //   path: "/campaigns",
  //   exact: true,
  //   screen: <Campaigns />,
  // },
  // {
  //   path: "/tr/kampanyalar",
  //   exact: true,
  //   screen: <Campaigns />,
  // },
  // {
  //   path: "/nl/campagnes",
  //   exact: true,
  //   screen: <Campaigns />,
  // },
  // {
  //   path: "/rs/kampanje",
  //   exact: true,
  //   screen: <Campaigns />,
  // },
  // {
  //   path: "/fr/campagnes",
  //   exact: true,
  //   screen: <Campaigns />,
  // },
  // {
  //   path: "/uk/campaigns",
  //   exact: true,
  //   screen: <Campaigns />,
  // },
  // {
  //   path: "/ro/campanii",
  //   exact: true,
  //   screen: <Campaigns />,
  // },
  {
    path: "/production",
    exact: true,
    screen: <Production />,
  },
  {
    path: "/nl",
    exact: true,
    screen: <MainScreen />,
  },
  {
    path: "/tr",
    exact: true,
    screen: <MainScreen />,
  },
  {
    path: "/rs",
    exact: true,
    screen: <MainScreen />,
  },
  {
    path: "/fr",
    exact: true,
    screen: <MainScreen />,
  },
  {
    path: "/uk",
    exact: true,
    screen: <MainScreen />,
  },
  {
    path: "/ro",
    exact: true,
    screen: <MainScreen />,
  },
  {
    path: "/calendar/SuatSoyyigit",
    exact: true,
    screen: <Calendar />,
  },
  {
    path: "/calendar/BurakOnkardesler",
    exact: true,
    screen: <CalendarBurak />,
  },
  {
    path: "/calendar/YigitOktan",
    exact: true,
    screen: <CalendarYigit />,
  },
];
