import theme from "../../../constants/theme";

const constantStyle = {
  border: `1px solid ${theme.COLORS.blue}`,
  borderRadius: theme.BORDER_RADIUS.large,
  transition: "all 0.2s ease-in-out",
  textAlign: "center" as "center",
  
};
export const Style = {
  button: {
    ...constantStyle,
    backgroundColor: theme.COLORS.white,
    color: theme.COLORS.blue,
    width: "fit-content",
    paddingTop: 11,
    paddingLeft: 20,
    paddingRight: 20,
    height: 40,
    fontSize: "12px",
  },
  longButton:{
    ...constantStyle,
    backgroundColor: theme.COLORS.white,
    color: theme.COLORS.blue,
    width: "fit-content",
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 12,
    height: 45,
    fontSize: theme.FONT_SIZE.small,
  },
  longButtonHover:{
    ...constantStyle,
    backgroundColor: theme.COLORS.blue,
    color: theme.COLORS.white,
    width: "fit-content",
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 12,
    height: 45,
    fontSize: theme.FONT_SIZE.small,
  },
  longButtonMobile:{
    ...constantStyle,
    backgroundColor: theme.COLORS.white,
    color: theme.COLORS.blue,
    width: "fit-content",
    paddingHorizontal:10,
    paddingLeft: 20,
    paddingRight: 20,
    padding:10,//Tanıtım videosu izle Mobil
     marginRight:-95,
     marginLeft:-25,

    
    paddingTop: 11,
    height: 40,
    fontSize: theme.FONT_SIZE.small,
  },
  longButtonCompMobile:{
    ...constantStyle,
    backgroundColor: theme.COLORS.white,
    color: theme.COLORS.blue,
    width: "fit-content",
    paddingLeft: 20,
    paddingRight: 20,
    paddingHorizontal:10,
    padding:10,//Tanıtım videosu izle Mobil
    // marginRight:-95,
    // marginLeft:-25,

    
    paddingTop: 11,
    height: 40,
    fontSize: theme.FONT_SIZE.small,
  },
  longButtonHoverMobile:{
    ...constantStyle,
    backgroundColor: theme.COLORS.blue,
    color: theme.COLORS.white,
    width: "fit-content",
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    height: 40,
     marginRight:-110,
     marginLeft:-20,
    fontSize: theme.FONT_SIZE.small,
  },
  buttonHover: {
    ...constantStyle,
    backgroundColor: theme.COLORS.blue,
    color: theme.COLORS.white,
    width: "fit-content",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    height: 40,
    fontSize: "12px",
  },
  buttonMobile: {
    ...constantStyle,
    backgroundColor: theme.COLORS.white,
    color: theme.COLORS.blue,
    paddingLeft: 20,
    paddingRight: 20,
    width: "fit-content",
    height: 45,
    paddingTop: 12,
    fontSize: theme.FONT_SIZE.small,
    
  },
  buttonCompMobile: {
    ...constantStyle,
    backgroundColor: theme.COLORS.white,
    color: theme.COLORS.blue,
    paddingLeft: 20,
    paddingRight: 20,
    width: "fit-content",
    height: 45,
    paddingTop: 12,
    fontSize: theme.FONT_SIZE.small,
    
  },
  buttonMobileHover: {
    ...constantStyle,
    backgroundColor: theme.COLORS.blue,
    color: theme.COLORS.white,
    paddingLeft: 20,
    paddingRight: 20,
    width: "fit-content",
    height: 45,
    paddingTop: 5,
    fontSize: theme.FONT_SIZE.small,
  },
};
