import { useEffect, useState } from "react";
import { Container, Pagination } from "react-bootstrap";
import { MapComponent } from "../../components/GoogleMapComponent";
import { ButtonComponent } from "../../components/Shared/ButtonComponent/component";
import { IconContainerComponent } from "../../components/Shared/IconContainerComponent/component";
import { SalesPointsData } from "../../constants/mock";
import theme from "../../constants/theme";
import { TranslationService } from "../../services/translation.service";
import {
  dropdownCityesodelGenerator,
  dropdownCountryModelGenerator,
  dropdownDistrictsModelGenerator,
} from "../../utils/string";
import "./index.css";
import { Style } from "./style";
import { Props } from "./types";

export function Component(props: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addAllValue = (val: any[]) => {
    let xs = val;
    xs.unshift({
      value: "all",
      label: t(translationKey, "all"),
      all: "all",
    });
    return xs;
  };

  const translationKey = "SalesPoints";
  const t = TranslationService.translate;

  const allCityes = addAllValue(
      dropdownCityesodelGenerator(SalesPointsData.city)
    ),
    allDistrict = addAllValue(
      dropdownDistrictsModelGenerator(SalesPointsData.district)
    ),
    countries = addAllValue(
      dropdownCountryModelGenerator(SalesPointsData.country)
    );

  const allPoints = SalesPointsData.points;

  const [cityes, setCityes] = useState(() => allCityes);
  const [districts, setDistricts] = useState(() => allDistrict);

  const [salesPoints, setSalesPoints] = useState(allPoints);
  const [selectedCountry, setSelectedCountry] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedCity, setSelectedCity] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedDistrict, setSelectedDistrict] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageCount, setPageCount] = useState([] as number[]);
  const createPaginationItems = () => {
    let allPointCount = salesPoints ? salesPoints.length : 1;
    let xs = Array.from(Array(Math.ceil(allPointCount / 10)).keys());
    setPageCount(xs);
  };

  const pageAction = (page: number) => {
    if (
      selectedCity[0] !== t(translationKey, "all") ||
      selectedCountry[0] !== t(translationKey, "all") ||
      selectedDistrict[0] !== t(translationKey, "all")
    ) {
      let temp = allPoints.filter((point) => {
        if (selectedCountry[1] !== "all") {
          if (point.countryCode === selectedCountry[1]) {
            if (selectedCity[1] !== "all") {
              if (point.cityCode === selectedCity[1]) {
                if (selectedDistrict[1] !== "all") {
                  if (point.districtCode === selectedDistrict[1]) {
                    return point;
                  }
                } else {
                  return point;
                }
              }
            } else {
              return point;
            }
          }
        } else {
          return point;
        }
      });
      setPageCount(Array.from(Array(Math.ceil(temp.length / 10)).keys()));
      setSelectedPage(page);
      setSalesPoints(temp.slice((page - 1) * 10, page * 10));
    } else {
      setSelectedPage(page);
      setSalesPoints(allPoints.slice((page - 1) * 10, page * 10));
    }
  };

  useEffect(() => {
    createPaginationItems();
    pageAction(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity, selectedCountry, selectedDistrict]);

  const selectedFunc = (e: any, selection: "country" | "city" | "distinc") => {
    if (selection === "country") {
      setSelectedCountry([e.label, e.value]);
      if (e.value === "all") {
        setCityes(allCityes);
        setDistricts(allDistrict);
        setSalesPoints(allPoints);
      } else {
        setSelectedPage(1);
        setCityes(allCityes.filter((city) => city.all.countryCode === e.value));
        setDistricts(
          allDistrict.filter((district) => district.all.countryCode === e.value)
        );
        setSalesPoints(
          allPoints.filter((point) => point.countryCode === e.value)
        );
      }
      setSelectedCity([t(translationKey, "all"), "all"]);
      setSelectedDistrict([t(translationKey, "all"), "all"]);
    } else if (selection === "city") {
      setSelectedCity([e.label, e.value]);
      if (e.value === "all") {
        setDistricts(allDistrict);
        setSalesPoints(allPoints);
      } else {
        setDistricts(
          allDistrict.filter((district) => district.all.cityCode === e.value)
        );
        setSalesPoints(allPoints.filter((point) => point.cityCode === e.value));
      }
      setSelectedDistrict([t(translationKey, "all"), "all"]);
    } else {
      setSelectedDistrict([e.label, e.value]);
      e.value === "all"
        ? setSalesPoints(allPoints)
        : setSalesPoints(
            allPoints.filter((point) => point.districtCode === e.value)
          );
    }
  };
  const button = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
        />
      </div>
    );
  };
  return (
    <div style={{ marginTop: 60, minHeight: "90vh" }}>
      <Container style={{ maxWidth: "80vw" }}>
        <div className="d-flex align-items-center">
          <img
            className="mobile-img"
            src={theme.IMAGES.support.salespoints}
            alt={"sercair"}
          />
          <h2
            style={{ color: theme.COLORS.blue }}
            className="ms-4 gothamMedium mobile-font"
          >
            {t(translationKey, "mainTitle")}
          </h2>
        </div>
      </Container>
      <MapComponent
        pointModel={salesPoints}
        pointModelAll={allPoints}
        selectedCity={selectedCity}
        selectedCountry={selectedCountry}
        selectedDistrict={selectedDistrict}
      />
      <div
        className="d-flex flex-column align-items-center"
        style={{ backgroundColor: theme.COLORS.blue40 }}
      >
        {SalesPointsData.icons && (
          <IconContainerComponent
            icons={SalesPointsData.icons}
            isNotResponsive={true}
            width={"100%"}
            height={"100%"}
          />
        )}
      </div>
      <Container style={{ maxWidth: "80vw" }}>
        {salesPoints &&
          salesPoints.map((point, index) => {
            return (
              <div
                key={index}
                className="d-flex flex-column align-items-start my-4"
              >
                <p style={Style.cardTitle}>{point.name}</p>
                <p style={Style.cardAddress}>{point.address}</p>
                <p style={Style.cardPhone}>
                  {t(translationKey, "phone") + point.phone}
                </p>
                <div className="d-flex justify-content-start">
                  {button(
                    t(translationKey, "goToAdress"),
                    "https://google.com/maps/search/Adres: " + point.address,
                    false
                  )}
                  {point.webSide &&
                    button(t(translationKey, "goToWeb"), point.webSide, false)}
                </div>
              </div>
            );
          })}
      </Container>
    </div>
  );
}

export function ComponentTR(props: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addAllValue = (val: any[]) => {
    let xs = val;
    xs.unshift({
      value: "all",
      label: t(translationKey, "all"),
      all: "all",
    });
    return xs;
  };

  const translationKey = "SalesPoints";
  const t = TranslationService.translate;

  const allCityes = addAllValue(
      dropdownCityesodelGenerator(SalesPointsData.city)
    ),
    allDistrict = addAllValue(
      dropdownDistrictsModelGenerator(SalesPointsData.district)
    ),
    countries = addAllValue(
      dropdownCountryModelGenerator(SalesPointsData.country)
    );

  const allPoints = SalesPointsData.points;

  const [cityes, setCityes] = useState(() => allCityes);
  const [districts, setDistricts] = useState(() => allDistrict);

  const [salesPoints, setSalesPoints] = useState(allPoints);
  const [selectedCountry, setSelectedCountry] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedCity, setSelectedCity] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedDistrict, setSelectedDistrict] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageCount, setPageCount] = useState([] as number[]);
  const createPaginationItems = () => {
    let allPointCount = salesPoints ? salesPoints.length : 1;
    let xs = Array.from(Array(Math.ceil(allPointCount / 10)).keys());
    setPageCount(xs);
  };

  const pageAction = (page: number) => {
    if (
      selectedCity[0] !== t(translationKey, "all") ||
      selectedCountry[0] !== t(translationKey, "all") ||
      selectedDistrict[0] !== t(translationKey, "all")
    ) {
      let temp = allPoints.filter((point) => {
        if (selectedCountry[1] !== "all") {
          if (point.countryCode === selectedCountry[1]) {
            if (selectedCity[1] !== "all") {
              if (point.cityCode === selectedCity[1]) {
                if (selectedDistrict[1] !== "all") {
                  if (point.districtCode === selectedDistrict[1]) {
                    return point;
                  }
                } else {
                  return point;
                }
              }
            } else {
              return point;
            }
          }
        } else {
          return point;
        }
      });
      setPageCount(Array.from(Array(Math.ceil(temp.length / 10)).keys()));
      setSelectedPage(page);
      setSalesPoints(temp.slice((page - 1) * 10, page * 10));
    } else {
      setSelectedPage(page);
      setSalesPoints(allPoints.slice((page - 1) * 10, page * 10));
    }
  };

  useEffect(() => {
    createPaginationItems();
    pageAction(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity, selectedCountry, selectedDistrict]);

  const selectedFunc = (e: any, selection: "country" | "city" | "distinc") => {
    if (selection === "country") {
      setSelectedCountry([e.label, e.value]);
      if (e.value === "all") {
        setCityes(allCityes);
        setDistricts(allDistrict);
        setSalesPoints(allPoints);
      } else {
        setSelectedPage(1);
        setCityes(allCityes.filter((city) => city.all.countryCode === e.value));
        setDistricts(
          allDistrict.filter((district) => district.all.countryCode === e.value)
        );
        setSalesPoints(
          allPoints.filter((point) => point.countryCode === e.value)
        );
      }
      setSelectedCity([t(translationKey, "all"), "all"]);
      setSelectedDistrict([t(translationKey, "all"), "all"]);
    } else if (selection === "city") {
      setSelectedCity([e.label, e.value]);
      if (e.value === "all") {
        setDistricts(allDistrict);
        setSalesPoints(allPoints);
      } else {
        setDistricts(
          allDistrict.filter((district) => district.all.cityCode === e.value)
        );
        setSalesPoints(allPoints.filter((point) => point.cityCode === e.value));
      }
      setSelectedDistrict([t(translationKey, "all"), "all"]);
    } else {
      setSelectedDistrict([e.label, e.value]);
      e.value === "all"
        ? setSalesPoints(allPoints)
        : setSalesPoints(
            allPoints.filter((point) => point.districtCode === e.value)
          );
    }
  };
  const button = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
        />
      </div>
    );
  };
  return (
    <div style={{ marginTop: 60, minHeight: "90vh" }}>
      <Container style={{ maxWidth: "80vw" }}>
        <div className="d-flex align-items-center">
          <img
            className="mobile-img"
            src={theme.IMAGES.support.salespoints}
            alt={"sercair"}
          />
          <h2
            style={{ color: theme.COLORS.blue }}
            className="ms-4 gothamMedium mobile-font"
          >
            {t(translationKey, "mainTitle")}
          </h2>
        </div>
      </Container>
      <MapComponent
        pointModel={salesPoints}
        pointModelAll={allPoints}
        selectedCity={selectedCity}
        selectedCountry={selectedCountry}
        selectedDistrict={selectedDistrict}
      />
      <div
        className="d-flex flex-column align-items-center"
        style={{ backgroundColor: theme.COLORS.blue40 }}
      >
        {SalesPointsData.icons && (
          <IconContainerComponent
            icons={SalesPointsData.icons}
            isNotResponsive={true}
            width={"100%"}
            height={"100%"}
          />
        )}
      </div>
      <Container style={{ maxWidth: "80vw" }}>
        {salesPoints &&
          salesPoints.map((point, index) => {
            return (
              <div
                key={index}
                className="d-flex flex-column align-items-start my-4"
              >
                <p style={Style.cardTitle}>{point.name}</p>
                <p style={Style.cardAddress}>{point.address}</p>
                <p style={Style.cardPhone}>
                  {t(translationKey, "phone") + point.phone}
                </p>
                <div className="d-flex justify-content-start">
                  {button(
                    t(translationKey, "goToAdress"),
                    "https://google.com/maps/search/Adres: " + point.address,
                    false
                  )}
                </div>
              </div>
            );
          })}

        <Pagination>
          <Pagination.Prev
            onClick={() => selectedPage > 1 && pageAction(selectedPage - 1)}
          />
          {pageCount.map((num, i) => {
            return (
              <Pagination.Item
                key={i}
                active={selectedPage === i + 1}
                onClick={() => pageAction(num + 1)}
              >
                {num + 1}
              </Pagination.Item>
            );
          })}
          <Pagination.Next
            onClick={() =>
              pageCount.length < selectedPage && pageAction(selectedPage + 1)
            }
          />
        </Pagination>
      </Container>
    </div>
  );
}

export function ComponentNL(props: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addAllValue = (val: any[]) => {
    let xs = val;
    xs.unshift({
      value: "all",
      label: t(translationKey, "all"),
      all: "all",
    });
    return xs;
  };

  const translationKey = "SalesPoints";
  const t = TranslationService.translate;

  const allCityes = addAllValue(
      dropdownCityesodelGenerator(SalesPointsData.city)
    ),
    allDistrict = addAllValue(
      dropdownDistrictsModelGenerator(SalesPointsData.district)
    ),
    countries = addAllValue(
      dropdownCountryModelGenerator(SalesPointsData.country)
    );

  const allPoints = SalesPointsData.points;

  const [cityes, setCityes] = useState(() => allCityes);
  const [districts, setDistricts] = useState(() => allDistrict);

  const [salesPoints, setSalesPoints] = useState(allPoints);
  const [selectedCountry, setSelectedCountry] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedCity, setSelectedCity] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedDistrict, setSelectedDistrict] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageCount, setPageCount] = useState([] as number[]);
  const createPaginationItems = () => {
    let allPointCount = salesPoints ? salesPoints.length : 1;
    let xs = Array.from(Array(Math.ceil(allPointCount / 10)).keys());
    setPageCount(xs);
  };

  const pageAction = (page: number) => {
    if (
      selectedCity[0] !== t(translationKey, "all") ||
      selectedCountry[0] !== t(translationKey, "all") ||
      selectedDistrict[0] !== t(translationKey, "all")
    ) {
      let temp = allPoints.filter((point) => {
        if (selectedCountry[1] !== "all") {
          if (point.countryCode === selectedCountry[1]) {
            if (selectedCity[1] !== "all") {
              if (point.cityCode === selectedCity[1]) {
                if (selectedDistrict[1] !== "all") {
                  if (point.districtCode === selectedDistrict[1]) {
                    return point;
                  }
                } else {
                  return point;
                }
              }
            } else {
              return point;
            }
          }
        } else {
          return point;
        }
      });
      setPageCount(Array.from(Array(Math.ceil(temp.length / 10)).keys()));
      setSelectedPage(page);
      setSalesPoints(temp.slice((page - 1) * 10, page * 10));
    } else {
      setSelectedPage(page);
      setSalesPoints(allPoints.slice((page - 1) * 10, page * 10));
    }
  };

  useEffect(() => {
    createPaginationItems();
    pageAction(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity, selectedCountry, selectedDistrict]);

  const selectedFunc = (e: any, selection: "country" | "city" | "distinc") => {
    if (selection === "country") {
      setSelectedCountry([e.label, e.value]);
      if (e.value === "all") {
        setCityes(allCityes);
        setDistricts(allDistrict);
        setSalesPoints(allPoints);
      } else {
        setSelectedPage(1);
        setCityes(allCityes.filter((city) => city.all.countryCode === e.value));
        setDistricts(
          allDistrict.filter((district) => district.all.countryCode === e.value)
        );
        setSalesPoints(
          allPoints.filter((point) => point.countryCode === e.value)
        );
      }
      setSelectedCity([t(translationKey, "all"), "all"]);
      setSelectedDistrict([t(translationKey, "all"), "all"]);
    } else if (selection === "city") {
      setSelectedCity([e.label, e.value]);
      if (e.value === "all") {
        setDistricts(allDistrict);
        setSalesPoints(allPoints);
      } else {
        setDistricts(
          allDistrict.filter((district) => district.all.cityCode === e.value)
        );
        setSalesPoints(allPoints.filter((point) => point.cityCode === e.value));
      }
      setSelectedDistrict([t(translationKey, "all"), "all"]);
    } else {
      setSelectedDistrict([e.label, e.value]);
      e.value === "all"
        ? setSalesPoints(allPoints)
        : setSalesPoints(
            allPoints.filter((point) => point.districtCode === e.value)
          );
    }
  };
  const button = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
        />
      </div>
    );
  };
  return (
    <div style={{ marginTop: 60, minHeight: "90vh" }}>
      <Container style={{ maxWidth: "80vw" }}>
        <div className="d-flex align-items-center">
          <img
            className="mobile-img"
            src={theme.IMAGES.support.salespoints}
            alt={"sercair"}
          />
          <h2
            style={{ color: theme.COLORS.blue }}
            className="ms-4 gothamMedium mobile-font"
          >
            {t(translationKey, "mainTitle")}
          </h2>
        </div>
      </Container>
      <MapComponent
        pointModel={salesPoints}
        pointModelAll={allPoints}
        selectedCity={selectedCity}
        selectedCountry={selectedCountry}
        selectedDistrict={selectedDistrict}
      />
      <div
        className="d-flex flex-column align-items-center"
        style={{ backgroundColor: theme.COLORS.blue40 }}
      >
        {SalesPointsData.icons && (
          <IconContainerComponent
            icons={SalesPointsData.icons}
            isNotResponsive={true}
            width={"100%"}
            height={"100%"}
          />
        )}
      </div>
      <Container style={{ maxWidth: "80vw" }}>
        {salesPoints &&
          salesPoints.map((point, index) => {
            return (
              <div
                key={index}
                className="d-flex flex-column align-items-start my-4"
              >
                <p style={Style.cardTitle}>{point.name}</p>
                <p style={Style.cardAddress}>{point.address}</p>
                <p style={Style.cardPhone}>
                  {t(translationKey, "phone") + point.phone}
                </p>
                <div className="d-flex justify-content-start">
                  {button(
                    t(translationKey, "goToAdress"),
                    "https://google.com/maps/search/Adres: " + point.address,
                    false
                  )}
                  {point.webSide &&
                    button(t(translationKey, "goToWeb"), point.webSide, false)}
                </div>
              </div>
            );
          })}
      </Container>
    </div>
  );
}

export function ComponentRS(props: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addAllValue = (val: any[]) => {
    let xs = val;
    xs.unshift({
      value: "all",
      label: t(translationKey, "all"),
      all: "all",
    });
    return xs;
  };

  const translationKey = "SalesPoints";
  const t = TranslationService.translate;

  const allCityes = addAllValue(
      dropdownCityesodelGenerator(SalesPointsData.city)
    ),
    allDistrict = addAllValue(
      dropdownDistrictsModelGenerator(SalesPointsData.district)
    ),
    countries = addAllValue(
      dropdownCountryModelGenerator(SalesPointsData.country)
    );

  const allPoints = SalesPointsData.points;

  const [cityes, setCityes] = useState(() => allCityes);
  const [districts, setDistricts] = useState(() => allDistrict);

  const [salesPoints, setSalesPoints] = useState(allPoints);
  const [selectedCountry, setSelectedCountry] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedCity, setSelectedCity] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedDistrict, setSelectedDistrict] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageCount, setPageCount] = useState([] as number[]);
  const createPaginationItems = () => {
    let allPointCount = salesPoints ? salesPoints.length : 1;
    let xs = Array.from(Array(Math.ceil(allPointCount / 10)).keys());
    setPageCount(xs);
  };

  const pageAction = (page: number) => {
    if (
      selectedCity[0] !== t(translationKey, "all") ||
      selectedCountry[0] !== t(translationKey, "all") ||
      selectedDistrict[0] !== t(translationKey, "all")
    ) {
      let temp = allPoints.filter((point) => {
        if (selectedCountry[1] !== "all") {
          if (point.countryCode === selectedCountry[1]) {
            if (selectedCity[1] !== "all") {
              if (point.cityCode === selectedCity[1]) {
                if (selectedDistrict[1] !== "all") {
                  if (point.districtCode === selectedDistrict[1]) {
                    return point;
                  }
                } else {
                  return point;
                }
              }
            } else {
              return point;
            }
          }
        } else {
          return point;
        }
      });
      setPageCount(Array.from(Array(Math.ceil(temp.length / 10)).keys()));
      setSelectedPage(page);
      setSalesPoints(temp.slice((page - 1) * 10, page * 10));
    } else {
      setSelectedPage(page);
      setSalesPoints(allPoints.slice((page - 1) * 10, page * 10));
    }
  };

  useEffect(() => {
    createPaginationItems();
    pageAction(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity, selectedCountry, selectedDistrict]);

  const selectedFunc = (e: any, selection: "country" | "city" | "distinc") => {
    if (selection === "country") {
      setSelectedCountry([e.label, e.value]);
      if (e.value === "all") {
        setCityes(allCityes);
        setDistricts(allDistrict);
        setSalesPoints(allPoints);
      } else {
        setSelectedPage(1);
        setCityes(allCityes.filter((city) => city.all.countryCode === e.value));
        setDistricts(
          allDistrict.filter((district) => district.all.countryCode === e.value)
        );
        setSalesPoints(
          allPoints.filter((point) => point.countryCode === e.value)
        );
      }
      setSelectedCity([t(translationKey, "all"), "all"]);
      setSelectedDistrict([t(translationKey, "all"), "all"]);
    } else if (selection === "city") {
      setSelectedCity([e.label, e.value]);
      if (e.value === "all") {
        setDistricts(allDistrict);
        setSalesPoints(allPoints);
      } else {
        setDistricts(
          allDistrict.filter((district) => district.all.cityCode === e.value)
        );
        setSalesPoints(allPoints.filter((point) => point.cityCode === e.value));
      }
      setSelectedDistrict([t(translationKey, "all"), "all"]);
    } else {
      setSelectedDistrict([e.label, e.value]);
      e.value === "all"
        ? setSalesPoints(allPoints)
        : setSalesPoints(
            allPoints.filter((point) => point.districtCode === e.value)
          );
    }
  };
  const button = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
        />
      </div>
    );
  };
  return (
    <div style={{ marginTop: 60, minHeight: "90vh" }}>
      <Container style={{ maxWidth: "80vw" }}>
        <div className="d-flex align-items-center">
          <img
            className="mobile-img"
            src={theme.IMAGES.support.salespoints}
            alt={"sercair"}
          />
          <h2
            style={{ color: theme.COLORS.blue }}
            className="ms-4 gothamMedium mobile-font"
          >
            {t(translationKey, "mainTitle")}
          </h2>
        </div>
      </Container>
      <MapComponent
        pointModel={salesPoints}
        pointModelAll={allPoints}
        selectedCity={selectedCity}
        selectedCountry={selectedCountry}
        selectedDistrict={selectedDistrict}
      />
      <div
        className="d-flex flex-column align-items-center"
        style={{ backgroundColor: theme.COLORS.blue40 }}
      >
        {SalesPointsData.icons && (
          <IconContainerComponent
            icons={SalesPointsData.icons}
            isNotResponsive={true}
            width={"100%"}
            height={"100%"}
          />
        )}
      </div>
      <Container style={{ maxWidth: "80vw" }}>
        {salesPoints &&
          salesPoints.map((point, index) => {
            return (
              <div
                key={index}
                className="d-flex flex-column align-items-start my-4"
              >
                <p style={Style.cardTitle}>{point.name}</p>
                <p style={Style.cardAddress}>{point.address}</p>
                <p style={Style.cardPhone}>
                  {t(translationKey, "phone") + point.phone}
                </p>
                <div className="d-flex justify-content-start">
                  {button(
                    t(translationKey, "goToAdress"),
                    "https://google.com/maps/search/Adres: " + point.address,
                    false
                  )}
                  {point.webSide &&
                    button(t(translationKey, "goToWeb"), point.webSide, false)}
                </div>
              </div>
            );
          })}
      </Container>
    </div>
  );
}

export function ComponentFR(props: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addAllValue = (val: any[]) => {
    let xs = val;
    xs.unshift({
      value: "all",
      label: t(translationKey, "all"),
      all: "all",
    });
    return xs;
  };

  const translationKey = "SalesPoints";
  const t = TranslationService.translate;

  const allCityes = addAllValue(
      dropdownCityesodelGenerator(SalesPointsData.city)
    ),
    allDistrict = addAllValue(
      dropdownDistrictsModelGenerator(SalesPointsData.district)
    ),
    countries = addAllValue(
      dropdownCountryModelGenerator(SalesPointsData.country)
    );

  const allPoints = SalesPointsData.points;

  const [cityes, setCityes] = useState(() => allCityes);
  const [districts, setDistricts] = useState(() => allDistrict);

  const [salesPoints, setSalesPoints] = useState(allPoints);
  const [selectedCountry, setSelectedCountry] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedCity, setSelectedCity] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedDistrict, setSelectedDistrict] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageCount, setPageCount] = useState([] as number[]);
  const createPaginationItems = () => {
    let allPointCount = salesPoints ? salesPoints.length : 1;
    let xs = Array.from(Array(Math.ceil(allPointCount / 10)).keys());
    setPageCount(xs);
  };

  const pageAction = (page: number) => {
    if (
      selectedCity[0] !== t(translationKey, "all") ||
      selectedCountry[0] !== t(translationKey, "all") ||
      selectedDistrict[0] !== t(translationKey, "all")
    ) {
      let temp = allPoints.filter((point) => {
        if (selectedCountry[1] !== "all") {
          if (point.countryCode === selectedCountry[1]) {
            if (selectedCity[1] !== "all") {
              if (point.cityCode === selectedCity[1]) {
                if (selectedDistrict[1] !== "all") {
                  if (point.districtCode === selectedDistrict[1]) {
                    return point;
                  }
                } else {
                  return point;
                }
              }
            } else {
              return point;
            }
          }
        } else {
          return point;
        }
      });
      setPageCount(Array.from(Array(Math.ceil(temp.length / 10)).keys()));
      setSelectedPage(page);
      setSalesPoints(temp.slice((page - 1) * 10, page * 10));
    } else {
      setSelectedPage(page);
      setSalesPoints(allPoints.slice((page - 1) * 10, page * 10));
    }
  };

  useEffect(() => {
    createPaginationItems();
    pageAction(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity, selectedCountry, selectedDistrict]);

  const selectedFunc = (e: any, selection: "country" | "city" | "distinc") => {
    if (selection === "country") {
      setSelectedCountry([e.label, e.value]);
      if (e.value === "all") {
        setCityes(allCityes);
        setDistricts(allDistrict);
        setSalesPoints(allPoints);
      } else {
        setSelectedPage(1);
        setCityes(allCityes.filter((city) => city.all.countryCode === e.value));
        setDistricts(
          allDistrict.filter((district) => district.all.countryCode === e.value)
        );
        setSalesPoints(
          allPoints.filter((point) => point.countryCode === e.value)
        );
      }
      setSelectedCity([t(translationKey, "all"), "all"]);
      setSelectedDistrict([t(translationKey, "all"), "all"]);
    } else if (selection === "city") {
      setSelectedCity([e.label, e.value]);
      if (e.value === "all") {
        setDistricts(allDistrict);
        setSalesPoints(allPoints);
      } else {
        setDistricts(
          allDistrict.filter((district) => district.all.cityCode === e.value)
        );
        setSalesPoints(allPoints.filter((point) => point.cityCode === e.value));
      }
      setSelectedDistrict([t(translationKey, "all"), "all"]);
    } else {
      setSelectedDistrict([e.label, e.value]);
      e.value === "all"
        ? setSalesPoints(allPoints)
        : setSalesPoints(
            allPoints.filter((point) => point.districtCode === e.value)
          );
    }
  };
  const button = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
        />
      </div>
    );
  };
  return (
    <div style={{ marginTop: 60, minHeight: "90vh" }}>
      <Container style={{ maxWidth: "80vw" }}>
        <div className="d-flex align-items-center">
          <img
            className="mobile-img"
            src={theme.IMAGES.support.salespoints}
            alt={"sercair"}
          />
          <h2
            style={{ color: theme.COLORS.blue }}
            className="ms-4 gothamMedium mobile-font"
          >
            {t(translationKey, "mainTitle")}
          </h2>
        </div>
      </Container>
      <MapComponent
        pointModel={salesPoints}
        pointModelAll={allPoints}
        selectedCity={selectedCity}
        selectedCountry={selectedCountry}
        selectedDistrict={selectedDistrict}
      />
      <div
        className="d-flex flex-column align-items-center"
        style={{ backgroundColor: theme.COLORS.blue40 }}
      >
        {SalesPointsData.icons && (
          <IconContainerComponent
            icons={SalesPointsData.icons}
            isNotResponsive={true}
            width={"100%"}
            height={"100%"}
          />
        )}
      </div>
      <Container style={{ maxWidth: "80vw" }}>
        {salesPoints &&
          salesPoints.map((point, index) => {
            return (
              <div
                key={index}
                className="d-flex flex-column align-items-start my-4"
              >
                <p style={Style.cardTitle}>{point.name}</p>
                <p style={Style.cardAddress}>{point.address}</p>
                <p style={Style.cardPhone}>
                  {t(translationKey, "phone") + point.phone}
                </p>
                <div className="d-flex justify-content-start">
                  {button(
                    t(translationKey, "goToAdress"),
                    "https://google.com/maps/search/Adres: " + point.address,
                    false
                  )}
                  {point.webSide &&
                    button(t(translationKey, "goToWeb"), point.webSide, false)}
                </div>
              </div>
            );
          })}
      </Container>
    </div>
  );
}

export function ComponentUK(props: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addAllValue = (val: any[]) => {
    let xs = val;
    xs.unshift({
      value: "all",
      label: t(translationKey, "all"),
      all: "all",
    });
    return xs;
  };

  const translationKey = "SalesPoints";
  const t = TranslationService.translate;

  const allCityes = addAllValue(
      dropdownCityesodelGenerator(SalesPointsData.city)
    ),
    allDistrict = addAllValue(
      dropdownDistrictsModelGenerator(SalesPointsData.district)
    ),
    countries = addAllValue(
      dropdownCountryModelGenerator(SalesPointsData.country)
    );

  const allPoints = SalesPointsData.points;

  const [cityes, setCityes] = useState(() => allCityes);
  const [districts, setDistricts] = useState(() => allDistrict);

  const [salesPoints, setSalesPoints] = useState(allPoints);
  const [selectedCountry, setSelectedCountry] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedCity, setSelectedCity] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedDistrict, setSelectedDistrict] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageCount, setPageCount] = useState([] as number[]);
  const createPaginationItems = () => {
    let allPointCount = salesPoints ? salesPoints.length : 1;
    let xs = Array.from(Array(Math.ceil(allPointCount / 10)).keys());
    setPageCount(xs);
  };

  const pageAction = (page: number) => {
    if (
      selectedCity[0] !== t(translationKey, "all") ||
      selectedCountry[0] !== t(translationKey, "all") ||
      selectedDistrict[0] !== t(translationKey, "all")
    ) {
      let temp = allPoints.filter((point) => {
        if (selectedCountry[1] !== "all") {
          if (point.countryCode === selectedCountry[1]) {
            if (selectedCity[1] !== "all") {
              if (point.cityCode === selectedCity[1]) {
                if (selectedDistrict[1] !== "all") {
                  if (point.districtCode === selectedDistrict[1]) {
                    return point;
                  }
                } else {
                  return point;
                }
              }
            } else {
              return point;
            }
          }
        } else {
          return point;
        }
      });
      setPageCount(Array.from(Array(Math.ceil(temp.length / 10)).keys()));
      setSelectedPage(page);
      setSalesPoints(temp.slice((page - 1) * 10, page * 10));
    } else {
      setSelectedPage(page);
      setSalesPoints(allPoints.slice((page - 1) * 10, page * 10));
    }
  };

  useEffect(() => {
    createPaginationItems();
    pageAction(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity, selectedCountry, selectedDistrict]);

  const selectedFunc = (e: any, selection: "country" | "city" | "distinc") => {
    if (selection === "country") {
      setSelectedCountry([e.label, e.value]);
      if (e.value === "all") {
        setCityes(allCityes);
        setDistricts(allDistrict);
        setSalesPoints(allPoints);
      } else {
        setSelectedPage(1);
        setCityes(allCityes.filter((city) => city.all.countryCode === e.value));
        setDistricts(
          allDistrict.filter((district) => district.all.countryCode === e.value)
        );
        setSalesPoints(
          allPoints.filter((point) => point.countryCode === e.value)
        );
      }
      setSelectedCity([t(translationKey, "all"), "all"]);
      setSelectedDistrict([t(translationKey, "all"), "all"]);
    } else if (selection === "city") {
      setSelectedCity([e.label, e.value]);
      if (e.value === "all") {
        setDistricts(allDistrict);
        setSalesPoints(allPoints);
      } else {
        setDistricts(
          allDistrict.filter((district) => district.all.cityCode === e.value)
        );
        setSalesPoints(allPoints.filter((point) => point.cityCode === e.value));
      }
      setSelectedDistrict([t(translationKey, "all"), "all"]);
    } else {
      setSelectedDistrict([e.label, e.value]);
      e.value === "all"
        ? setSalesPoints(allPoints)
        : setSalesPoints(
            allPoints.filter((point) => point.districtCode === e.value)
          );
    }
  };
  const button = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
        />
      </div>
    );
  };
  return (
    <div style={{ marginTop: 60, minHeight: "90vh" }}>
      <Container style={{ maxWidth: "80vw" }}>
        <div className="d-flex align-items-center">
          <img
            className="mobile-img"
            src={theme.IMAGES.support.salespoints}
            alt={"sercair"}
          />
          <h2
            style={{ color: theme.COLORS.blue }}
            className="ms-4 gothamMedium mobile-font"
          >
            {t(translationKey, "mainTitle")}
          </h2>
        </div>
      </Container>
      <MapComponent
        pointModel={salesPoints}
        pointModelAll={allPoints}
        selectedCity={selectedCity}
        selectedCountry={selectedCountry}
        selectedDistrict={selectedDistrict}
      />
      <div
        className="d-flex flex-column align-items-center"
        style={{ backgroundColor: theme.COLORS.blue40 }}
      >
        {SalesPointsData.icons && (
          <IconContainerComponent
            icons={SalesPointsData.icons}
            isNotResponsive={true}
            width={"100%"}
            height={"100%"}
          />
        )}
      </div>
      <Container style={{ maxWidth: "80vw" }}>
        {salesPoints &&
          salesPoints.map((point, index) => {
            return (
              <div
                key={index}
                className="d-flex flex-column align-items-start my-4"
              >
                <p style={Style.cardTitle}>{point.name}</p>
                <p style={Style.cardAddress}>{point.address}</p>
                <p style={Style.cardPhone}>
                  {t(translationKey, "phone") + point.phone}
                </p>
                <div className="d-flex justify-content-start">
                  {button(
                    t(translationKey, "goToAdress"),
                    "https://google.com/maps/search/Adres: " + point.address,
                    false
                  )}
                  {point.webSide &&
                    button(t(translationKey, "goToWeb"), point.webSide, false)}
                </div>
              </div>
            );
          })}
      </Container>
    </div>
  );
}

export function ComponentRO(props: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addAllValue = (val: any[]) => {
    let xs = val;
    xs.unshift({
      value: "all",
      label: t(translationKey, "all"),
      all: "all",
    });
    return xs;
  };

  const translationKey = "SalesPoints";
  const t = TranslationService.translate;

  const allCityes = addAllValue(
      dropdownCityesodelGenerator(SalesPointsData.city)
    ),
    allDistrict = addAllValue(
      dropdownDistrictsModelGenerator(SalesPointsData.district)
    ),
    countries = addAllValue(
      dropdownCountryModelGenerator(SalesPointsData.country)
    );

  const allPoints = SalesPointsData.points;

  const [cityes, setCityes] = useState(() => allCityes);
  const [districts, setDistricts] = useState(() => allDistrict);

  const [salesPoints, setSalesPoints] = useState(allPoints);
  const [selectedCountry, setSelectedCountry] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedCity, setSelectedCity] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedDistrict, setSelectedDistrict] = useState([
    t(translationKey, "all"),
    "all",
  ]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageCount, setPageCount] = useState([] as number[]);
  const createPaginationItems = () => {
    let allPointCount = salesPoints ? salesPoints.length : 1;
    let xs = Array.from(Array(Math.ceil(allPointCount / 10)).keys());
    setPageCount(xs);
  };

  const pageAction = (page: number) => {
    if (
      selectedCity[0] !== t(translationKey, "all") ||
      selectedCountry[0] !== t(translationKey, "all") ||
      selectedDistrict[0] !== t(translationKey, "all")
    ) {
      let temp = allPoints.filter((point) => {
        if (selectedCountry[1] !== "all") {
          if (point.countryCode === selectedCountry[1]) {
            if (selectedCity[1] !== "all") {
              if (point.cityCode === selectedCity[1]) {
                if (selectedDistrict[1] !== "all") {
                  if (point.districtCode === selectedDistrict[1]) {
                    return point;
                  }
                } else {
                  return point;
                }
              }
            } else {
              return point;
            }
          }
        } else {
          return point;
        }
      });
      setPageCount(Array.from(Array(Math.ceil(temp.length / 10)).keys()));
      setSelectedPage(page);
      setSalesPoints(temp.slice((page - 1) * 10, page * 10));
    } else {
      setSelectedPage(page);
      setSalesPoints(allPoints.slice((page - 1) * 10, page * 10));
    }
  };

  useEffect(() => {
    createPaginationItems();
    pageAction(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity, selectedCountry, selectedDistrict]);

  const selectedFunc = (e: any, selection: "country" | "city" | "distinc") => {
    if (selection === "country") {
      setSelectedCountry([e.label, e.value]);
      if (e.value === "all") {
        setCityes(allCityes);
        setDistricts(allDistrict);
        setSalesPoints(allPoints);
      } else {
        setSelectedPage(1);
        setCityes(allCityes.filter((city) => city.all.countryCode === e.value));
        setDistricts(
          allDistrict.filter((district) => district.all.countryCode === e.value)
        );
        setSalesPoints(
          allPoints.filter((point) => point.countryCode === e.value)
        );
      }
      setSelectedCity([t(translationKey, "all"), "all"]);
      setSelectedDistrict([t(translationKey, "all"), "all"]);
    } else if (selection === "city") {
      setSelectedCity([e.label, e.value]);
      if (e.value === "all") {
        setDistricts(allDistrict);
        setSalesPoints(allPoints);
      } else {
        setDistricts(
          allDistrict.filter((district) => district.all.cityCode === e.value)
        );
        setSalesPoints(allPoints.filter((point) => point.cityCode === e.value));
      }
      setSelectedDistrict([t(translationKey, "all"), "all"]);
    } else {
      setSelectedDistrict([e.label, e.value]);
      e.value === "all"
        ? setSalesPoints(allPoints)
        : setSalesPoints(
            allPoints.filter((point) => point.districtCode === e.value)
          );
    }
  };
  const button = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
        />
      </div>
    );
  };
  return (
    <div style={{ marginTop: 60, minHeight: "90vh" }}>
      <Container style={{ maxWidth: "80vw" }}>
        <div className="d-flex align-items-center">
          <img
            className="mobile-img"
            src={theme.IMAGES.support.salespoints}
            alt={"sercair"}
          />
          <h2
            style={{ color: theme.COLORS.blue }}
            className="ms-4 gothamMedium mobile-font"
          >
            {t(translationKey, "mainTitle")}
          </h2>
        </div>
      </Container>
      <MapComponent
        pointModel={salesPoints}
        pointModelAll={allPoints}
        selectedCity={selectedCity}
        selectedCountry={selectedCountry}
        selectedDistrict={selectedDistrict}
      />
      <div
        className="d-flex flex-column align-items-center"
        style={{ backgroundColor: theme.COLORS.blue40 }}
      >
        {SalesPointsData.icons && (
          <IconContainerComponent
            icons={SalesPointsData.icons}
            isNotResponsive={true}
            width={"100%"}
            height={"100%"}
          />
        )}
      </div>
      <Container style={{ maxWidth: "80vw" }}>
        {salesPoints &&
          salesPoints.map((point, index) => {
            return (
              <div
                key={index}
                className="d-flex flex-column align-items-start my-4"
              >
                <p style={Style.cardTitle}>{point.name}</p>
                <p style={Style.cardAddress}>{point.address}</p>
                <p style={Style.cardPhone}>
                  {t(translationKey, "phone") + point.phone}
                </p>
                <div className="d-flex justify-content-start">
                  {button(
                    t(translationKey, "goToAdress"),
                    "https://google.com/maps/search/Adres: " + point.address,
                    false
                  )}
                  {point.webSide &&
                    button(t(translationKey, "goToWeb"), point.webSide, false)}
                </div>
              </div>
            );
          })}
      </Container>
    </div>
  );
}
