import { CloseButton, Container, Modal } from "react-bootstrap";
import "./style.css";
import { Props } from "./types";
import { ModalSwitcher } from "../../../../utils/modal-switcher";
import { useEffect, useState } from "react";
import { TranslationService } from "../../../../services/translation.service";
import { langsAll } from "../../../../constants/langs";
import { store } from "../../../../store";

export function Component(props: Props) {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
  }, [props.showCountryModal]);
  const TranslationKey = "LanguageModal";
  const t = TranslationService.translate;

  //{zone: "Europe", countries: Array(1)}
  const listCountrys = langsAll.map((x) => x.zone);
  //@ts-ignore
  const listCountrysUnique = [...new Set(listCountrys)];

  //{zone: "Europe", countries: Array(1)}
  const listEvents = langsAll.map((x) => x.event);
  //@ts-ignore
  const listCountrysEvents = [...new Set(listEvents)];

  const filterCountryFromZone = (zone: string) => {
    return langsAll.filter((x) => x.zone === zone);
  };

  const [selectedZone, setSelectedZone] = useState(
    TranslationService.getLangFromStorage().zone
  );
  const [selectedEvent, setSelectedEvent] = useState(true);

  const AccordionComp = (props: any) => {
    return (
      <>
        <div
          className="col-12 d-flex flex-column justify-content-between align-items-center my-4"
          style={{ height: "fit-content" }}
        >
          <div className="col-12" style={{ textAlign: "center" }}>
            <p
              className={
                selectedZone === "Europe"
                  ? "sideStyleActive col-4 d-flex justify-content-between align-items-center GothamBold"
                  : "sideStyleActive col-4 d-flex justify-content-center align-items-center GothamBold m-auto text-center"
              }
              onClick={() => setSelectedZone(selectedZone)}
            >
              {t(TranslationKey, "Europe")}
            </p>
          </div>
          <div className="col-12 d-flex flex-column justify-content-between align-items-left my-2">
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-center align-items-center"
              style={{ height: "fit-content", marginTop: 25 }}
            >
              <a href="https://www.sercair.com/tr">
                <p
                  className="modal_country GothamLight modalCountryHover"
                  style={{ cursor: "pointer" }}
                >
                  {t(TranslationKey, "Turkey")}
                </p>
              </a>
            </div>
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-center align-items-center"
              style={{ height: "fit-content", marginTop: 25 }}
            >
              <a href="https://www.sercair.com/nl">
                <p
                  className="modal_country GothamLight modalCountryHover"
                  style={{ cursor: "pointer" }}
                >
                  {t(TranslationKey, "Netherlands")}
                </p>
              </a>
            </div>
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-center align-items-center"
              style={{ height: "fit-content", marginTop: 25 }}
            >
              <a href="https://www.sercair.com/uk">
                <p
                  className="modal_country GothamLight modalCountryHover"
                  style={{ cursor: "pointer" }}
                >
                  {t(TranslationKey, "England")}
                </p>
              </a>
            </div>
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-center align-items-center"
              style={{ height: "fit-content", marginTop: 25 }}
            >
              <a href="https://www.sercair.com/fr">
                <p
                  className="modal_country GothamLight modalCountryHover"
                  style={{ cursor: "pointer" }}
                >
                  {t(TranslationKey, "France")}
                </p>
              </a>
            </div>
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-center align-items-center"
              style={{ height: "fit-content", marginTop: 25 }}
            >
              <a href="https://www.sercair.com/rs">
                <p
                  className="modal_country GothamLight modalCountryHover"
                  style={{ cursor: "pointer" }}
                >
                  {t(TranslationKey, "Serbia")}
                </p>
              </a>
            </div>
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-center align-items-center"
              style={{ height: "fit-content", marginTop: 25 }}
            >
              <a href="https://www.sercair.com/ro">
                <p
                  className="modal_country GothamLight modalCountryHover"
                  style={{ cursor: "pointer" }}
                >
                  {t(TranslationKey, "Romania")}
                </p>
              </a>
            </div>
          </div>
        </div>
        <div
          className="col-12 d-flex flex-column justify-content-center align-items-center my-4"
          style={{ height: "fit-content" }}
        >
          <div className="col-12" style={{ textAlign: "center" }}>
            <p
              className={
                selectedZone === "North America"
                  ? "sideStyleActive col-4 d-flex justify-content-center align-items-center GothamBold m-auto text-center"
                  : "sideStyleActive col-4 d-flex justify-content-center align-items-center GothamBold m-auto text-center"
              }
              onClick={() => setSelectedZone(selectedZone)}
            >
              {t(TranslationKey, "North America")}
            </p>
          </div>
          <div className="col-12 d-flex flex-column justify-content-between align-items-left my-2">
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-center align-items-center"
              style={{ height: "fit-content", marginTop: 25 }}
            >
              <a href="https://www.sercair.com">
                <p
                  className="modal_country GothamLight modalCountryHover"
                  style={{ cursor: "pointer" }}
                >
                  {t(TranslationKey, "USA")}
                </p>
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };

  return isMobile ? (
    <Modal centered fullscreen={true} show={props.showCountryModal}>
      <Modal.Body style={{ position: "relative", padding: 0 }}>
        <Container className="d-flex justify-content-center">
          <div className="col-10 d-flex justify-content-between align-items-center my-4">
            <h1 className="blueTitle fs-24 ">
              {t(TranslationKey, "selectLocation")}
            </h1>
            <CloseButton onClick={() => ModalSwitcher.HideCountryModal()} />
          </div>
        </Container>
        <AccordionComp />
      </Modal.Body>
    </Modal>
  ) : (
    <Modal centered fullscreen={true} show={props.showCountryModal}>
      <Modal.Body style={{ position: "relative", padding: 0 }}>
        <Container className="d-flex justify-content-center">
          <div className="col-10 d-flex justify-content-between align-items-center my-4">
            <h1 className="blueTitle fs-24 ">
              {t(TranslationKey, "selectLocation")}
            </h1>
            <CloseButton onClick={() => ModalSwitcher.HideCountryModal()} />
          </div>
        </Container>
        <hr />
        <Container className="d-flex justify-content-center">
          <div className="col-10 d-flex justify-content-between align-items-center my-4">
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex"
              style={{ height: "fit-content" }}
            >
              {/* test */}
              <div
                className="col-12 d-flex flex-column justify-content-between align-items-center my-4"
                style={{ height: "fit-content" }}
              >
                <div className="col-12" style={{ textAlign: "left" }}>
                  <p
                    className={
                      selectedZone === "Europe"
                        ? "sideStyleActive col-4 d-flex justify-content-between align-items-center GothamBold"
                        : "sideStyleActive col-4 d-flex justify-content-between align-items-center GothamBold"
                    }
                    onClick={() => setSelectedZone(selectedZone)}
                  >
                    {t(TranslationKey, "Europe")}
                  </p>
                </div>
                <div className="col-12 d-flex flex-column justify-content-between align-items-left my-2">
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-between align-items-center"
                    style={{ height: "fit-content", marginTop: 25 }}
                  >
                    <a href="https://www.sercair.com/tr">
                      <p
                        className="modal_country GothamLight modalCountryHover"
                        style={{ cursor: "pointer" }}
                      >
                        {t(TranslationKey, "Turkey")}
                      </p>
                    </a>
                  </div>
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-between align-items-center"
                    style={{ height: "fit-content", marginTop: 25 }}
                  >
                    <a href="https://www.sercair.com/nl">
                      <p
                        className="modal_country GothamLight modalCountryHover"
                        style={{ cursor: "pointer" }}
                      >
                        {t(TranslationKey, "Netherlands")}
                      </p>
                    </a>
                  </div>
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-between align-items-center"
                    style={{ height: "fit-content", marginTop: 25 }}
                  >
                    <a href="https://www.sercair.com/uk">
                      <p
                        className="modal_country GothamLight modalCountryHover"
                        style={{ cursor: "pointer" }}
                      >
                        {t(TranslationKey, "England")}
                      </p>
                    </a>
                  </div>
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-between align-items-center"
                    style={{ height: "fit-content", marginTop: 25 }}
                  >
                    <a href="https://www.sercair.com/fr">
                      <p
                        className="modal_country GothamLight modalCountryHover"
                        style={{ cursor: "pointer" }}
                      >
                        {t(TranslationKey, "France")}
                      </p>
                    </a>
                  </div>
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-between align-items-center"
                    style={{ height: "fit-content", marginTop: 25 }}
                  >
                    <a href="https://www.sercair.com/rs">
                      <p
                        className="modal_country GothamLight modalCountryHover"
                        style={{ cursor: "pointer" }}
                      >
                        {t(TranslationKey, "Serbia")}
                      </p>
                    </a>
                  </div>
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-between align-items-center"
                    style={{ height: "fit-content", marginTop: 25 }}
                  >
                    <a href="https://www.sercair.com/ro">
                      <p
                        className="modal_country GothamLight modalCountryHover"
                        style={{ cursor: "pointer" }}
                      >
                        {t(TranslationKey, "Romania")}
                      </p>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-12 d-flex flex-column justify-content-between align-items-center my-4"
                style={{ height: "fit-content" }}
              >
                <div className="col-12" style={{ textAlign: "left" }}>
                  <p
                    className={
                      selectedZone === "North America"
                        ? "sideStyleActive col-4 d-flex justify-content-center align-items-center GothamBold"
                        : "sideStyleActive col-4 d-flex justify-content-center align-items-center GothamBold"
                    }
                    onClick={() => setSelectedZone(selectedZone)}
                  >
                    {t(TranslationKey, "North America")}
                  </p>
                </div>
                <div className="col-12 d-flex flex-column justify-content-between align-items-left my-2">
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex justify-content-between align-items-center"
                    style={{
                      height: "fit-content",
                      marginTop: 25,
                    }}
                  >
                    <a href="https://www.sercair.com">
                      <p
                        className="modal_country GothamLight modalCountryHover"
                        style={{ cursor: "pointer" }}
                      >
                        {t(TranslationKey, "USA")}
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
