import { Props } from "./types";
import { Style } from "./style";
import { Card, CardGroup } from "react-bootstrap";
import { TranslationService } from "../../../services/translation.service";
import { store } from "../../../store";
import React from "react";
export function CardProductsGroupComponent(props: Props) {
  const cards = props.cards;
  const cardCondition: Boolean = cards.length === 1;
  const t = TranslationService.translate;
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
  }, []);
  return (
    <div
      className={isMobile ? "item-aling-center" : ""}
      style={{ scale: isMobile ? "0.8" : "1", marginLeft: isMobile ? -26 : 0 }}
    >
      {cardCondition ? (
        <Card
          style={{
            width: "18rem",
            borderBottomLeftRadius: "30px",
            borderBottomRightRadius: "30px",
            minWidth: isMobile ? 323 : 420,
          }}
        >
          <div style={{ backgroundColor: "white", minHeight: 240 }}>
            <Card.Img
              // image position is not working
              variant="top"
              src={cards[0].imgUrl}
              alt={t(cards[0].keyComponent, cards[0].imgAlt)}
            />
          </div>
          <Card.Body
            style={
              props.cards[0].bodyColor
                ? {
                    backgroundColor: props.cards[0].bodyColor,
                    color: "white",
                    borderBottomLeftRadius: "30px",
                    borderBottomRightRadius: "30px",
                    minHeight: 170,
                  }
                : { backgroundColor: "white" }
            }
          >
            <>
              <Card.Title
                className="fs-14 GothamMedium"
                dangerouslySetInnerHTML={{
                  __html: t(cards[0].keyComponent, cards[0].title),
                }}
              ></Card.Title>
              {cards[0].text && (
                <Card.Text
                  className="fs-10"
                  dangerouslySetInnerHTML={{
                    __html: t(cards[0].keyComponent, cards[0].text),
                  }}
                ></Card.Text>
              )}
            </>{" "}
            {cards[0].isBtn &&
              props.buttonComponent(
                t(cards[0].keyComponent, cards[0].btnText),
                cards[0].btnUrl,
                cards[0].btnIsLong
              )}
          </Card.Body>
        </Card>
      ) : (
        <CardGroup style={Style.cardGroup}>
          {cards.map((card, index) => {
            let cardStyleCustom = { ...Style.card };
            card.backgroundColor &&
              (cardStyleCustom.backgroundColor = card.backgroundColor);
            card.textColor && (cardStyleCustom.color = card.textColor);
            card.isShadow &&
              (cardStyleCustom.boxShadow =
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)");
            return (
              <Card
                key={"cardProductsGroupComponent" + index}
                style={{
                  width: "15rem",
                  borderBottomLeftRadius: "30px",
                  borderBottomRightRadius: "30px",
                  minWidth: isMobile ? 323 : 420,
                  marginLeft: isMobile ? 0 : index === 0 ? 0 : 40,
                }}
              >
                <div style={{ backgroundColor: "white", minHeight: 240 }}>
                  <Card.Img
                    // image position is not working
                    variant="top"
                    src={cards[index].imgUrl}
                    alt={t(cards[index].keyComponent, cards[index].imgAlt)}
                  />
                </div>
                <Card.Body
                  style={
                    props.cards[index].bodyColor
                      ? {
                          backgroundColor: props.cards[index].bodyColor,
                          color: "white",
                          borderBottomLeftRadius: "30px",
                          borderBottomRightRadius: "30px",
                          minHeight: 170,
                        }
                      : { backgroundColor: "white" }
                  }
                >
                  <>
                    <Card.Title
                      className="fs-14 GothamMedium"
                      dangerouslySetInnerHTML={{
                        __html: t(
                          cards[index].keyComponent,
                          cards[index].title
                        ),
                      }}
                    ></Card.Title>
                    {cards[index].text ? (
                      <Card.Text
                        className="fs-10"
                        dangerouslySetInnerHTML={{
                          __html: t(
                            cards[index].keyComponent,
                            //@ts-ignore
                            cards[index].text
                          ),
                        }}
                      ></Card.Text>
                    ) : null}
                  </>{" "}
                  {cards[index].isBtn &&
                    props.buttonComponent(
                      t(cards[index].keyComponent, cards[index].btnText),
                      cards[index].btnUrl,
                      cards[index].btnIsLong
                    )}
                </Card.Body>
              </Card>
            );
          })}{" "}
        </CardGroup>
      )}
    </div>
  );
}
