import { is } from "date-fns/locale";
import { Campaigns } from "../models/campaigns.model";
import { CatalogModel } from "../models/catalog.model";
import { CollapseModel } from "../models/collapse.model";
import { SalesPoints } from "../models/salesPoints.model";
import { SliderModel } from "../models/slider.model";
import { userGuideTable } from "../models/userguide.model";
import { VideoCardModel } from "../models/videoCard.model";
import theme from "./theme";

const langCheck = localStorage.getItem("lang");

const isMobile = window.innerWidth < 768;

export const Slider: SliderModel[] = [
  {
    imgUrl:
      langCheck === "en"
        ? theme.IMAGES.slider.sliderOther
        : langCheck === "fr"
        ? theme.IMAGES.slider.sliderOther
        : langCheck === "rs"
        ? theme.IMAGES.slider.sliderOther
        : theme.IMAGES.slider.sliderOther,
    imgForMobile:
      langCheck === "en"
        ? theme.IMAGES.slider.slider1MobileOther
        : langCheck === "fr"
        ? theme.IMAGES.slider.slider1MobileOther
        : langCheck === "rs"
        ? theme.IMAGES.slider.slider1MobileOther
        : theme.IMAGES.slider.slider1MobileOther,
    imgAlt: "Sercair",
    imgOnClick: true,
    containerPosition: "left" as "left",
    color: "white",
    imgOnClickUrl: "https://www.sercair.com/",
    title: "sercair",
    text: "newTechSlider",
    webText: "sliderSubTitleWeb",
    isBtn: false,
    textPosition: "left",
    btnText: "moniWayBtnText",
    btnUrl:
      langCheck === "tr"
        ? "/tr/iletisim"
        : langCheck === "nl"
        ? "/nl/contact"
        : langCheck === "rs"
        ? "/rs/kontakt"
        : langCheck === "fr"
        ? "/fr/contact"
        : langCheck === "uk"
        ? "/uk/contact"
        : langCheck === "ro"
        ? "/ro/contactati"
        : "contact",
    btnStyle: "primary",
    isSecondaryContainer: true,
    secondaryContainerPosition: "right" as "right",
    secondaryContainerText: "",
    secondaryContainerTitle: "",
    secondaryIsBtn: true,
    secondaryTextPosition: "right",
    secondaryContainerColor: "white",
    keyComponent: "MainScreen",
  },
];

export const Slider2: SliderModel[] = [
  {
    imgUrl:
      langCheck === "en"
        ? theme.IMAGES.slider.slider2
        : theme.IMAGES.slider.sliderOther,
    imgForMobile:
      langCheck === "en"
        ? theme.IMAGES.slider.slider1MobileOther
        : theme.IMAGES.slider.slider1MobileOther,
    imgAlt: "Sercair",
    imgOnClick: true,
    containerPosition: "left" as "left",
    color: "white",
    imgOnClickUrl: "https://www.sercair.com/",
    title: "sercair",
    text: "newTechSlider",
    webText: "sliderSubTitleWeb",
    isBtn: true,
    textPosition: "left",
    btnText: "moniWayBtnText",
    btnUrl:
      langCheck === "tr"
        ? "/tr/iletisim"
        : langCheck === "nl"
        ? "/nl/contact"
        : langCheck === "rs"
        ? "/rs/kontakt"
        : langCheck === "fr"
        ? "/fr/contact"
        : langCheck === "uk"
        ? "/uk/contact"
        : langCheck === "ro"
        ? "/ro/contactati"
        : "contact",
    btnStyle: "primary",
    isSecondaryContainer: true,
    secondaryContainerPosition: "right" as "right",
    secondaryContainerText: "",
    secondaryContainerTitle: "",
    secondaryIsBtn: isMobile ? false : true,
    secondaryTextPosition: "right",
    secondaryContainerColor: "white",
    keyComponent: "MainScreen",
  },
];

export const SliderAuto: SliderModel[] = [
  {
    imgUrl:
      langCheck === "en"
        ? theme.IMAGES.slider.slider1
        : langCheck === "fr"
        ? theme.IMAGES.slider.slider1Fr
        : langCheck === "rs"
        ? theme.IMAGES.slider.slider1Rs
        : theme.IMAGES.slider.sliderOther,
    imgForMobile:
      langCheck === "en"
        ? theme.IMAGES.slider.slider1MobileOther
        : theme.IMAGES.slider.slider1MobileOther,
    imgAlt: "Sercair",
    imgOnClick: false,
    containerPosition: "left" as "left",
    color: "white",
    imgOnClickUrl: "https://www.sercair.com/",
    title: "sercair",
    text: "newTechSlider",
    webText: "sliderSubTitleWeb",
    isBtn: true,
    textPosition: "left",
    btnText: "moniWayBtnText",
    btnUrl:
      langCheck === "tr"
        ? "/tr/iletisim"
        : langCheck === "nl"
        ? "/nl/contact"
        : langCheck === "rs"
        ? "/rs/kontakt"
        : langCheck === "fr"
        ? "/fr/contact"
        : langCheck === "uk"
        ? "/uk/contact"
        : langCheck === "ro"
        ? "/ro/contactati"
        : "contact",
    btnStyle: "primary",
    isSecondaryContainer: true,
    secondaryContainerPosition: "right" as "right",
    secondaryContainerText: "",
    secondaryContainerTitle: "",
    secondaryIsBtn: isMobile ? false : true,
    secondaryTextPosition: "right",
    secondaryContainerColor: "white",
    keyComponent: "MainScreen",
  },
];

export const Slider2Auto: SliderModel[] = [
  {
    imgUrl:
      langCheck === "en"
        ? theme.IMAGES.slider.sliderOther
        : langCheck === "fr"
        ? theme.IMAGES.slider.sliderOther
        : langCheck === "rs"
        ? theme.IMAGES.slider.sliderOther
        : theme.IMAGES.slider.sliderOther,
    imgForMobile:
      langCheck === "en"
        ? theme.IMAGES.slider.slider1MobileOther
        : langCheck === "fr"
        ? theme.IMAGES.slider.slider1MobileOther
        : langCheck === "rs"
        ? theme.IMAGES.slider.slider1MobileOther
        : theme.IMAGES.slider.slider1MobileOther,
    imgAlt: "Sercair",
    imgOnClick: true,
    containerPosition: "left" as "left",
    color: "white",
    imgOnClickUrl: "https://www.sercair.com/",
    title: "sercair",
    text: "newTechSlider",
    webText: "sliderSubTitleWeb",
    isBtn: true,
    textPosition: "left",
    btnText: "moniWayBtnText",
    btnUrl:
      langCheck === "tr"
        ? "/tr/iletisim"
        : langCheck === "nl"
        ? "/nl/contact"
        : langCheck === "rs"
        ? "/rs/kontakt"
        : langCheck === "fr"
        ? "/fr/contact"
        : langCheck === "uk"
        ? "/uk/contact"
        : langCheck === "ro"
        ? "/ro/contactati"
        : "contact",
    btnStyle: "primary",
    isSecondaryContainer: true,
    secondaryContainerPosition: "right" as "right",
    secondaryContainerText: "",
    secondaryContainerTitle: "",
    secondaryIsBtn: false,
    secondaryTextPosition: "right",
    secondaryContainerColor: "white",
    keyComponent: "MainScreen",
  },
];

export const ProductsCollapseData: CollapseModel[] = [
  {
    title: "airPurifiers",
    img: theme.IMAGES.main.Collapse.airPurifiersAndRegulators,
    imgAlt: "airPurifiersAlt",
    keyComponent: "Collapse",
    items: [
      {
        title: "pure",
        url:
          langCheck === "en"
            ? "/products/pure/pure"
            : langCheck === "tr"
            ? "/tr/urunler/pure/pure"
            : langCheck === "rs"
            ? "/rs/proizvodi/pure/pure"
            : langCheck === "fr"
            ? "/fr/produits/pure/pure"
            : langCheck === "uk"
            ? "/uk/products/pure/pure"
            : langCheck === "ro"
            ? "/ro/produse/pure/pure"
            : "/nl/producten/pure/pure",
      },
      {
        title: "pureHealthy",
        url:
          langCheck === "en"
            ? "/products/pure/healthy"
            : langCheck === "tr"
            ? "/tr/urunler/pure/healthy"
            : langCheck === "rs"
            ? "/rs/proizvodi/pure/healthy"
            : langCheck === "fr"
            ? "/fr/produits/pure/healthy"
            : langCheck === "uk"
            ? "/uk/products/pure/healthy"
            : langCheck === "ro"
            ? "/ro/produse/pure/healthy"
            : "/nl/producten/pure/healthy",
      },
      {
        title: "pureCore",
        url:
          langCheck === "en"
            ? "/products/pure/core"
            : langCheck === "tr"
            ? "/tr/urunler/pure/core"
            : langCheck === "rs"
            ? "/rs/proizvodi/pure/core"
            : langCheck === "fr"
            ? "/fr/produits/pure/core"
            : langCheck === "uk"
            ? "/uk/products/pure/core"
            : langCheck === "ro"
            ? "/ro/produse/pure/core"
            : "/nl/producten/pure/core",
      },
    ],
  },
  {
    title: "airCleaners",
    img: theme.IMAGES.main.Collapse.airPurifiers,
    imgAlt: "airCleanerAlt",
    keyComponent: "Collapse",
    items: [
      {
        title: "piatra",
        url:
          langCheck === "en"
            ? "/products/airpurifiers/piatra"
            : langCheck === "tr"
            ? "/tr/urunler/hava-temizleyicileri/piatra"
            : langCheck === "rs"
            ? "/rs/proizvodi/precistaci-vazduha/piatra"
            : langCheck === "fr"
            ? "/fr/produits/purificateurs-air/piatra"
            : langCheck === "uk"
            ? "/uk/products/airpurifiers/piatra"
            : langCheck === "ro"
            ? "/ro/produse/purificatoare-de-aer/piatra"
            : "/nl/producten/luchtreinigers/piatra",
      },
      {
        title: "spirit",
        url:
          langCheck === "en"
            ? "/products/airpurifiers/spirit"
            : langCheck === "tr"
            ? "/tr/urunler/hava-temizleyicileri/spirit"
            : langCheck === "rs"
            ? "/rs/proizvodi/precistaci-vazduha/spirit"
            : langCheck === "fr"
            ? "/fr/produits/purificateurs-air/spirit"
            : langCheck === "uk"
            ? "/uk/products/airpurifiers/spirit"
            : langCheck === "ro"
            ? "/ro/produse/purificatoare-de-aer/spirit"
            : "/nl/producten/luchtreinigers/spirit",
      },
    ],
  },
  {
    title: "airQuality",
    img: theme.IMAGES.main.Collapse.airQuality,
    imgAlt: "airQualityAlt",
    keyComponent: "Collapse",
    items: [
      {
        title: "moniMe",
        url:
          langCheck === "en"
            ? "/products/AirQuality/monime"
            : langCheck === "tr"
            ? "/tr/urunler/hava-temizleyici/monime"
            : langCheck === "rs"
            ? "/rs/proizvodi/kvalitet-vazduha/monime"
            : langCheck === "fr"
            ? "/fr/produits/qualite-de-air/monime"
            : langCheck === "uk"
            ? "/uk/products/AirQuality/monime"
            : langCheck === "ro"
            ? "/ro/produse/calitatea-aerului/monime"
            : "/nl/producten/luchtkwaliteit/monime",
      },
      {
        title: "moniWay",
        url:
          langCheck === "en"
            ? "/products/AirQuality/moniway"
            : langCheck === "tr"
            ? "/tr/urunler/hava-temizleyici/moniway"
            : langCheck === "rs"
            ? "/rs/proizvodi/kvalitet-vazduha/moniway"
            : langCheck === "fr"
            ? "/fr/produits/qualite-de-air/moniway"
            : langCheck === "uk"
            ? "/uk/products/AirQuality/moniway"
            : langCheck === "ro"
            ? "/ro/produse/calitatea-aerului/moniway"
            : "/nl/producten/luchtkwaliteit/moniway",
      },
    ],
  },
  {
    title: "cubeTitle",
    img: theme.IMAGES.main.Collapse.moniCube,
    imgAlt: "cubeAlt",
    keyComponent: "Collapse",
    items: [
      {
        title: "cubeMoni",
        url:
          langCheck === "en"
            ? "/products/cube"
            : langCheck === "tr"
            ? "/tr/urunler/cube"
            : langCheck === "rs"
            ? "/rs/proizvodi/cube"
            : langCheck === "fr"
            ? "/fr/produits/cube"
            : langCheck === "uk"
            ? "/uk/products/cube"
            : langCheck === "ro"
            ? "/ro/produse/cube"
            : "/nl/producten/cube",
      },
    ],
  },
];

export const CampaignsData: Campaigns[] = [
  {
    bg: theme.IMAGES.campaigns.campaign1,
    alt: "sercair",
    image: theme.IMAGES.campaigns.campaign1Mobile,
    title: "c1T",
    text: "c1Txt",
    url: "c1Url",
    btnText: "btnText",
    inProduction: "production",
    mapScreen: "MapScreen",
    mainScreenW: "MainScreenW",
    WeatherMap: "WeatherMap",
  },
  {
    bg: theme.IMAGES.campaigns.campaign2,
    alt: "sercair",
    image: theme.IMAGES.campaigns.campaign2Mobile,
    title: "c2T",
    text: "c2Txt",
    url: "c2Url",
    btnText: "btnText",
    inProduction: "production",
    mapScreen: "MapScreen",
    mainScreenW: "MainScreenW",
    WeatherMap: "WeatherMap",
  },
];

export const SalesPointsData: SalesPoints = {
  country: [
    {
      name: "United States Of America",
      code: "USA",
      lat: 39,
      lng: 35,
    },
    {
      name: "Nederlands",
      code: "NL",
      lat: 52,
      lng: 5,
    },
    {
      name: "Türkiye",
      code: "TR",
      lat: 39,
      lng: 35,
    },
    {
      name: "Serbia",
      code: "RS",
      lat: 44,
      lng: 21,
    },
    {
      name: "France",
      code: "FR",
      lat: 47,
      lng: 2,
    },
    {
      name: "Cyprus",
      code: "CY",
      lat: 35,
      lng: 33,
    },
    {
      name: "Croatia",
      code: "HR",
      lat: 45,
      lng: 16,
    },
    {
      name: "Romania",
      code: "RO",
      lat: 46,
      lng: 25,
    },
    {
      name: "India",
      code: "IN",
      lat: 20,
      lng: 77,
    },
    {
      name: "Jordan",
      code: "JOR",
      lat: 31,
      lng: 36,
    },
    {
      name: "Morocco",
      code: "MA",
      lat: 32,
      lng: 6,
    },
  ],
  city: [
    {
      name: "California",
      code: "CA",
      countryCode: "USA",
      lat: 34,
      lng: -135,
    },
    {
      name: "Amsterdam",
      code: "AMSTERDAM",
      countryCode: "NL",
      lat: 52.3084,
      lng: 4.7612,
    },
    {
      name: "Overijssel",
      code: "OVERIJSSEL",
      countryCode: "NL",
      lat: 52.4605,
      lng: 6.4108,
    },
    {
      name: "Amsterdam",
      code: "AMSTERDAM",
      countryCode: "NL",
      lat: 52.3084,
      lng: 4.7612,
    },
    {
      name: "Overijssel",
      code: "OVERIJSSEL",
      countryCode: "NL",
      lat: 52.4605,
      lng: 6.4108,
    },
    {
      name: "Amsterdam",
      code: "AMSTERDAM",
      countryCode: "NL",
      lat: 52.3084,
      lng: 4.7612,
    },
    {
      name: "Overijssel",
      code: "OVERIJSSEL",
      countryCode: "NL",
      lat: 52.4605,
      lng: 6.4108,
    },
    {
      name: "Metz",
      code: "METZ",
      countryCode: "FR",
      lat: 49.0911644814087,
      lng: 6.240917037211283,
    },
    {
      name: "GaziMağusa",
      code: "GaziMağusa",
      countryCode: "CY",
      lat: 35.11496978787035,
      lng: 33.91918472936096,
    },
    {
      name: "Osijek",
      code: "Osijek",
      countryCode: "HR",
      lat: 45.56175191038064,
      lng: 18.649628587155984,
    },
    {
      name: "Braşov",
      code: "Braşov",
      countryCode: "RO",
      lat: 45.66949794090345,
      lng: 25.549764377967442,
    },
    {
      name: "New Jersey",
      code: "New Jersey",
      countryCode: "USA",
      lat: 41.07455326603117,
      lng: -74.14029009822049,
    },
    {
      name: "Izmir",
      code: "Izmir",
      countryCode: "IZM",
      lat: 38.47583705135143,
      lng: 27.048859717628286,
    },
    {
      name: "Ankara",
      code: "Ankara",
      countryCode: "ANK",
      lat: 39.98912138330688,
      lng: 32.74580485484004,
    },
    {
      name: "Ahmedabad",
      code: "Ahmedabad",
      countryCode: "AHM",
      lat: 23.022505,
      lng: 72.5713621,
    },
    {
      name: "Amman",
      code: "Amman",
      countryCode: "JOR",
      lat: 31.95384152062257,
      lng: 35.91032975934407,
    },
    {
      name: "Casablanca",
      code: "Casablanca",
      countryCode: "MA",
      lat: 33.595062,
      lng: -7.6187768,
    },
  ],
  district: [
    {
      name: "Naarden",
      code: "NAARDEN",
      cityCode: "AMSTERDAM",
      countryCode: "NL",
    },
    {
      name: "Naarden",
      code: "NAARDEN",
      cityCode: "AMSTERDAM",
      countryCode: "NL",
    },
    {
      name: "Deventer",
      code: "DEVENTER",
      cityCode: "OVERIJSSEL",
      countryCode: "NL",
    },
    {
      name: "Başakşehir",
      code: "BASAK",
      cityCode: "IST",
      countryCode: "TR",
    },
    {
      name: "Metz",
      code: "METZ",
      cityCode: "METZ",
      countryCode: "FR",
    },
    {
      name: "GaziMağusa",
      code: "GaziMağusa",
      cityCode: "GaziMağusa",
      countryCode: "CY",
    },
    {
      name: "Osijek",
      code: "Osijek",
      cityCode: "Osijek",
      countryCode: "HR",
    },
    {
      name: "Braşov",
      code: "Braşov",
      cityCode: "Braşov",
      countryCode: "RO",
    },
    {
      name: "New Jersey",
      code: "New Jersey",
      cityCode: "NJ",
      countryCode: "USA",
    },
    {
      name: "Izmir",
      code: "Izmir",
      cityCode: "IZM",
      countryCode: "TR",
    },
    {
      name: "Ankara",
      code: "Ankara",
      cityCode: "ANK",
      countryCode: "TR",
    },
    {
      name: "Ahmedabad",
      code: "Ahmedabad",
      cityCode: "AHM",
      countryCode: "IND",
    },
    {
      name: "Amman",
      code: "Amman",
      cityCode: "AMM",
      countryCode: "JOR",
    },
    {
      name: "Casablanca",
      code: "Casabca",
      cityCode: "CAS",
      countryCode: "JOR",
    },
  ],
  points: [
    {
      name: "iConn, Inc.",
      address: "8909 Irvine Center Dr, Irvine, CA 92618, USA",
      phone: "+1 949-297-8448",
      countryCode: "USA",
      cityCode: "LA",
      lat: 33.64003887659567,
      lng: -117.74151712883528,
      _id: "5f6b9b0b0b9d0a0017a5b0c3",
    },
    {
      name: "WYTHE WINDOWS USA",
      address: "50 Spring Street Ramsey, NJ 07446 USA",
      phone: "+1 201-962-7444",
      countryCode: "USA",
      cityCode: "NJ",
      lat: 41.07453304605425,
      lng: -74.14028473380291,
      _id: "5f6b9b0b0b9d0a0017a5b0c3",
    },
    {
      name: "Sas Global Trade BV ",
      address: "Keulenstraat 11 A 7418 ET Deventer / NETHERLANDS",
      phone: "+31 6 11012476",
      countryCode: "NL",
      cityCode: "OVERIJSSEL",

      districtCode: "DEVENTER",
      lat: 52.23651,
      lng: 6.18863,
      _id: "5f6b9b0b0b9d0a0017a5b0cb",
    },
    {
      name: "ACM Proje",
      address: "Kızılay Mahallesi Menekşe 2 Cadde 32/10 06420 Çankaya / ANKARA",
      phone: "+90 312 418 00 91",
      countryCode: "TR",
      cityCode: "ANK",
      districtCode: "CANK",
      lat: 39.92124,
      lng: 32.8496,
      _id: "5f6b9b0b0b9d0a0017a5b0a5",
    },
    {
      name: "Aksa Alüminyum",
      address:
        "Selahattin Eyyubi Mahallesi Haliç Caddesi 1631 Sokak No:28 Esenyurt / İSTANBUL",
      phone: "+90 212 886 26 66",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "ESEN",
      lat: 41.05452,
      lng: 28.64956,
    },
    {
      name: "Arda Enerji Sistemleri",
      address:
        "Perpa Ticaret Merkezi B Blok Kat 11 No 1589 34384 Okmeydanı - Şişli / İSTANBUL",
      phone: "+90 212 210 45 15",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "SISLI",
      lat: 41.06144,
      lng: 28.97038,
      _id: "5f6b9b0b0b9d0a0017a5b0a6",
    },
    {
      name: "Arrita Group",
      address:
        "Koza Mahallesi Bahçeşehir Mobilyacılar Çarşısı 1641 Sk. Büyükçekmece / İSTANBUL",
      phone: "+90 212 709 84 88",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "BUC",
      lat: 41.06992,
      lng: 28.66283,
      _id: "5f6b9b0b0b9d0a0017a5b0a7",
    },
    {
      name: "Balkış Yapı",
      address:
        "Altınşehir Mah. Tavukçuyolu Kumru Cad. No.76/B Ümraniye / İSTANBUL",
      phone: "+90 216 526 24 25",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "UMR",
      lat: 41.01055,
      lng: 29.13989,
      _id: "5f6b9b0b0b9d0a0017a5b0a9",
    },
    {
      name: "Beşel Cam",
      address:
        "Pik dökümcüler sanayi sitesi tem 34 kat 3 no, D:265, 34100 İkitelli Osb - Başakşehir / İSTANBUL",
      phone: "+90 212 474 20 03",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "BASAK",
      lat: 41.09207,
      lng: 28.80056,
      _id: "5f6b9b0b0b9d0a0017a5b0aa",
    },
    {
      name: "DMC Yapı Grup",
      address:
        "Firuzköy Mahallesi Mustafa Kemal Paşa Caddesi no : 5/ 1-C Avcılar / İSTANBUL",
      phone: "+90 850 220 08 86",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "AVC",
      lat: 41.00569,
      lng: 28.70893,
      _id: "5f6b9b0b0b9d0a0017a5b0ab",
    },
    {
      name: "Eliz Cam Sistemleri",
      address: "Altınpark, Karpuzlu-1 Cd. No: 5, 06160 Altındağ / ANKARA",
      phone: "+90 312 353 75 71",
      countryCode: "TR",
      cityCode: "ANK",
      districtCode: "ALTINDAG",
      lat: 39.96205,
      lng: 32.89102,
      _id: "5f6b9b0b0b9d0a0017a5b0ac",
    },
    {
      name: "Enwa",
      address: "Turgut Özal Mah. 109. Sokak No:6 B/2 Beylikdüzü / İSTANBUL",
      phone: "+90 212 690 36 36",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "BEYLIKDUZU",
      lat: 40.99101,
      lng: 28.64683,
      _id: "5f6b9b0b0b9d0a0017a5b0ad",
    },
    {
      name: "Four S Trade",
      address: "İzzet Baysal Mah. Kural Sok. No: 4 Merkez/ BOLU",
      phone: "+90 850 434 00 60",
      countryCode: "TR",
      cityCode: "BOL",
      districtCode: "MERKEZ",
      lat: 40.74701,
      lng: 31.59366,
      _id: "5f6b9b0b0b9d0a0017a5b0ae",
    },
    {
      name: "Furkan Group",
      address: "Şekerpınar, Nermin Sk. No:2, 41420 Çayırova / KOCAELİ",
      phone: "+90 444 3 210",
      countryCode: "TR",
      cityCode: "KOC",
      districtCode: "CAYIROVA",
      lat: 40.86406,
      lng: 29.39027,
      _id: "5f6b9b0b0b9d0a0017a5b0af",
    },
    {
      name: "Hatüpen",
      address: "Ostim 1354. Cadde 1421. Sk, D:No:2, 06370 Yenimahalle / ANKARA",
      phone: "+90 312 385 88 50",
      countryCode: "TR",
      cityCode: "ANK",
      districtCode: "YENIMAHALLE",
      lat: 39.98614,
      lng: 32.74434,
      _id: "5f6b9b0b0b9d0a0017a5b0b0",
    },
    {
      name: "İlke Mühendislik",
      address: "Topselvi Mahallesi, İhsan Sokağı No:1, 34873 Kartal / İSTANBUL",
      phone: "+90 216 452 69 06",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "KARTAL",
      lat: 40.9209,
      lng: 29.19872,
      _id: "5f6b9b0b0b9d0a0017a5b0b1",
    },
    {
      name: "Mela Medikal",
      address: "Firüzköy Bulvarı Tempo İş Merkezi No:114-A Avcılar / İSTANBUL",
      phone: "+90 532 256 86 16",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "AVC",
      lat: 41.00312,
      lng: 28.71128,
      _id: "5f6b9b0b0b9d0a0017a5b0b3",
    },
    {
      name: "Modsis",
      address:
        "Orta Mahallesi Keban Sokak No: 10c Kapı No: Z10 Tuzla / İSTANBUL",
      phone: "+90 554 930 21 11",
      countryCode: "TR",
      cityCode: "IST",
      webSide: "https://www.modsis.com.tr/tr/index",
      districtCode: "TUZLA",
      lat: 40.90052,
      lng: 29.38324,
      _id: "5f6b9b0b0b9d0a0017a5b0c8",
    },
    {
      name: "Noma Enerji Sistemleri",
      address:
        "Barbaros Bulvarı Günaydın Apartman No:157/A K:3 D:6 Beşiktaş / İSTANBUL",
      phone: "+90 212 211 11 29",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "BESIKTAS",
      lat: 41.12889,
      lng: 29.03849,
      _id: "5f6b9b0b0b9d0a0017a5b0b4",
    },
    {
      name: "Novamaks Teknoloji",
      address: "Kervan Geçmez Sk. 20/8 34387 Şişli / İSTANBUL",
      phone: "+90 212 275 26 50",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "SISLI",
      lat: 41.06802,
      lng: 28.99473,
      _id: "5f6b9b0b0b9d0a0017a5b0b5",
    },
    {
      name: "Shaadex Rectractable Pergolas",
      address:
        "Başkule Plaza Göztepe Mah. İnönü Caddesi No:122/30 Bağcılar / İSTANBUL",
      phone: "+90 212 445 11 66",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "BAGCILAR",
      lat: 41.06303,
      lng: 28.83346,
      _id: "5f6b9b0b0b9d0a0017a5b0b7",
    },
    {
      name: "Shopiglo",
      address:
        "Başak, Enkoop Cad. & Enkoop 1. Sok İkitelli OSB D:2 / C, 34480 Başakşehir / İSTANBUL",
      phone: "+90 850 532 00 10",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "BASAK",
      webSide: "https://www.shopiglo.com/",
      lat: 41.10512,
      lng: 28.79519,
      _id: "5f6b9b0b0b9d0a0017a5b0b9",
    },
    {
      name: "Si Yapı Mimarlık",
      address: "Selçuklu Caddesi No.7 - 9A Karlıbayır - Arnavutköy / İSTANBUL",
      phone: "+90 532 550 64 61",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "ARNKOY",
      lat: 41.18456,
      lng: 28.711922,
      _id: "5f6b9b0b0b9d0a0017a5b0ba",
    },
    {
      name: "Siemens A Plus AVM",
      address:
        "Ataköy 2-5-6 Kısım Mah. Adnan Kahveci Bulvarı Bakırköy / İSTANBUL",
      phone: "+90 212 852 00 64",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "BKOY",
      lat: 40.978446,
      lng: 28.850707,
      _id: "5f6b9b0b0b9d0a0017a5b0bb",
    },
    {
      name: "Solarwin",
      address: "Çobançeşme, Mithatpaşa Cd. 7/a, 34196 Bağcılar / İSTANBUL",
      phone: "+90 212 494 44 47",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "BAGCILAR",
      lat: 40.99977,
      lng: 28.82781,
      _id: "5f6b9b0b0b9d0a0017a5b0bc",
    },

    {
      name: "Sungur Group",
      address: "Gazi Osmanpaşa Cad. No: 34730 Tuzla / İSTANBUL",
      phone: "+90 216 446 41 24",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "TUZLA",
      lat: 40.831666,
      lng: 29.312162,
      _id: "5f6b9b0b0b9d0a0017a5b0c1",
    },
    {
      name: "Şoteks",
      address:
        "Başak, Enkoop Cad. & Enkoop 1. Sok İkitelli OSB D:2 / C, 34480 Başakşehir / İSTANBUL",
      phone: "+90 850 532 00 10",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "BASAK",
      lat: 41.10508,
      lng: 28.79536,
      _id: "5f6b9b0b0b9d0a0017a5b0c2",
    },
    {
      name: "Umut Yapı",
      address: "Şirintepe Mah. Gümüşhane Cad. N0:13-B Kağıthane / İSTANBUL",
      phone: "+90 212 295 29 03",
      countryCode: "TR",
      cityCode: "IST",
      districtCode: "KAGITHANE",
      lat: 41.08974,
      lng: 28.99287,
      _id: "5f6b9b0b0b9d0a0017a5b0c6",
    },
    {
      name: "Form Alüminyum",
      address: "1419 No:101 ivedik OSB Yenimahalle / Ankara",
      phone: "+90 312 354 40 04",
      countryCode: "TR",
      cityCode: "Ankara",
      districtCode: "Ankara",
      lat: 39.98912138330688,
      lng: 32.74580485484004,
      _id: "5f6b9b0b0b9d0a0017a5b0c4",
    },
    {
      name: "Winland",
      address: "İvedik OSB, 1451 Cadde No: 71 Yenimahalle / Ankara",
      phone: "+90 312 395 27 90",
      countryCode: "TR",
      cityCode: "ANK",
      webSide: "https://winland.com.tr/",
      districtCode: "YENIMAHALLE",
      lat: 39.99105,
      lng: 32.73392,
      _id: "5f6b9b0b0b9d0a0017a5b0c7",
    },
    {
      name: "Alukrom",
      address: "10006/1 Sk. No:2 A.O.S.B. Cigli, Izmir, Turkiye",
      phone: "+90 (232) 461 21 17",
      countryCode: "TR",
      cityCode: "IZM",
      districtCode: "Cigli",
      lat: 38.4758445427229,
      lng: 27.048859717628286,
      _id: "5f6b9b0b0b9d0a0017a5b0c7",
    },
    {
      name: "Aqualab Novi Beograd",
      address:
        "Bulevar Crvene Armije 11g, Novi Beograd 11070, Beograd / SRBIJA",
      phone: " 063/7728152",
      countryCode: "RS",
      cityCode: "Srbija",
      districtCode: "Beograd",
      lat: 44.80652134697752,
      lng: 20.404360656098397,
      _id: "5f6b9b0b0b9d0a0017a5b0c4",
    },
    {
      name: "7 Rue André Marie Ampère",
      address: "NOVEL’R S.A.S. 7 rue André Marie Ampère, 57070 METZ",
      phone: " 07 80 97 76 26",
      countryCode: "FR",
      cityCode: "Metz",
      districtCode: "Metz",
      lat: 49.10455751193819,
      lng: 6.226793175292174,
      _id: "5f6b9b0b0b9d0a0017a5b0c4",
    },
    {
      name: "Kıbrıs",
      address: "Sanayi Bölgesi 4. Sokak No:2 GaziMağusa",
      phone: "0 (548) 851 00 29",
      countryCode: "CY",
      cityCode: "GaziMağusa ",
      districtCode: "GaziMağusa ",
      lat: 35.114981435750956,
      lng: 33.919172672613854,
      _id: "5f6b9b0b0b9d0a0017a5b0c4",
    },
    {
      name: "Croatia",
      address: "Žumberačka 39a, 31000",
      phone: "",
      countryCode: "HR",
      cityCode: "Osijek",
      districtCode: "Osijek",
      lat: 45.56175191038064,
      lng: 18.649628587155984,
      _id: "5f6b9b0b0b9d0a0017a5b0c4",
    },
    {
      name: "Romania",
      address:
        "Institutul de Cercetare Dezvoltare Inovare Produse High tech pentru Dezvoltare Durabilă Str Institutului Nr 10 507075 Cristian, Brașov Laboratorul.12, Etajul 1",
      phone: "",
      countryCode: "RO",
      cityCode: "Braşov",
      districtCode: "Braşov",
      lat: 45.66949794090345,
      lng: 25.549764377967442,
      _id: "5f6b9b0b0b9d0a0017a5b0c4",
    },
    {
      name: "Hindistan",
      address:
        "HIFAB Head Quarters, G-H Block, K.P.Epitome, Near DAV school, Makarba, Ahmedabad, Gujarat. 380051",
      phone: "+91 9099060400",
      countryCode: "IN",
      cityCode: "Ahmedabad",
      districtCode: "Ahmedabad",
      lat: 22.995088715433525,
      lng: 72.50051253695646,
      _id: "5f6b9b0b0b9d0a0017a5b0c4",
    },
    {
      name: "Ürdün",
      address:
        "Tal al-ali - Wasfi tal St. Building 176 M1 Floor Showroom M103 Amman – Jordan",
      phone: ": +962795655115",
      countryCode: "JO",
      cityCode: "Amman",
      districtCode: "Amman",
      lat: 31.95384152062257,
      lng: 35.91032975934407,
      _id: "5f6b9b0b0b9d0a0017a5b0c4",
    },
    {
      name: "Morocco",
      address: "20 Rue Al Ghadfa Residence Wafa, Maârif Casablanca 20100",
      phone: ": +212 6 61 32 25 58",
      countryCode: "MA",
      cityCode: "Casablanca",
      districtCode: "Casablanca",
      lat: 33.595062,
      lng: -7.6187768,
      _id: "5f6b9b0b0b9d0a0017a5b0c4",
    },
  ],
};

export const catalogCardItemsTR: CatalogModel[] = [
  {
    imgUrl: theme.IMAGES.catalogs.tr.catalog1,
    title: "titleMain",
    pdfUrl: theme.IMAGES.catalogs.tr.pdf1,
  },
  {
    imgUrl: theme.IMAGES.catalogs.tr.catalog2,
    title: "titleMain",
    pdfUrl: theme.IMAGES.catalogs.tr.pdf2,
  },
  {
    imgUrl: theme.IMAGES.catalogs.tr.catalog3,
    title: "titleMain",
    pdfUrl: theme.IMAGES.catalogs.tr.pdf3,
  },
  {
    imgUrl: theme.IMAGES.catalogs.tr.catalog4,
    title: "titleMain",
    pdfUrl: theme.IMAGES.catalogs.tr.pdf4,
  },
  {
    imgUrl: theme.IMAGES.catalogs.tr.catalog5,
    title: "titleMain",
    pdfUrl: theme.IMAGES.catalogs.tr.pdf5,
  },
  {
    imgUrl: theme.IMAGES.catalogs.tr.catalog6,
    title: "titleMain",
    pdfUrl: theme.IMAGES.catalogs.tr.pdf6,
  },
];
export const catalogCardItemsEN: CatalogModel[] = [
  {
    imgUrl: theme.IMAGES.catalogs.en.catalog1,
    title: "titleMain",
    pdfUrl: theme.IMAGES.catalogs.en.pdf1,
  },
  {
    imgUrl: theme.IMAGES.catalogs.en.catalog2,
    title: "titleMain",
    pdfUrl: theme.IMAGES.catalogs.en.pdf2,
  },
  {
    imgUrl: theme.IMAGES.catalogs.en.catalog3,
    title: "titleMain",
    pdfUrl: theme.IMAGES.catalogs.en.pdf3,
  },
  {
    imgUrl: theme.IMAGES.catalogs.en.catalog4,
    title: "titleMain",
    pdfUrl: theme.IMAGES.catalogs.en.pdf4,
  },
  {
    imgUrl: theme.IMAGES.catalogs.en.catalog5,
    title: "titleMain",
    pdfUrl: theme.IMAGES.catalogs.en.pdf5,
  },
];

export const userGuidesTableItems: userGuideTable[] = [
  {
    title: "pure",
    url: theme.PDFS.UserGuides.pure,
  },
  {
    title: "pureHealthy",
    url: theme.PDFS.UserGuides.pure,
  },
  {
    title: "corePure",
    url: theme.PDFS.UserGuides.pure,
  },
  {
    title: "piatra",
    url: theme.PDFS.UserGuides.piatra,
  },
  {
    title: "spirit",
    url: theme.PDFS.UserGuides.spirit,
  },
  {
    title: "moniMe",
    url: theme.PDFS.UserGuides.moniMe,
  },
  {
    title: "moniWay",
    url: theme.PDFS.UserGuides.moniWay,
  },
];

export const VideoCardsTRContent: VideoCardModel[] = [
  {
    title: "titleVideos",
    cards: [
      {
        imgUrl: theme.IMAGES.videos.app,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "app",
        titleColor: theme.COLORS.blue,
        btnText: "moreInfo",
        btnUrl: "appLink",
        isBtn: true,
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        titleClass: "text-center",
        videoId: "appVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.pureHealty,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "pure",
        titleColor: theme.COLORS.blue,
        btnText: "moreInfo",
        btnUrl: "pureLink",
        isBtn: true,
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        titleClass: "text-center",
        videoId: "pureVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.piatra,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "piatra",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "piatraLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "piatraVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.spirit,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "spirit",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "spiritLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "spiritVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.tr.moniMe,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "moniMe",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "moniMeLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "moniMeVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.tr.moniWay,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "moniWay",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "moniWayLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "moniWayVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.tr.crystal,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "crystal",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "crystalLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "crystalVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.handy,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "handy",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "handyLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "handyVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.crystalEco,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "crystalEco",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "crystalEcoLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "crystalEcoVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.breathe,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "breathe",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "breatheLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "breatheVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.wave,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "wave",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "waveLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "waveVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.vision,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "visionM",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "visionMLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "visionMVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.handyM,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "handyM",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "handyMLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "handyMVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.aura,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "aura",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "auraLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "auraVideoId",
      },
    ],
  },
];

export const VideoCardsENContent: VideoCardModel[] = [
  {
    title: "titleVideos",
    cards: [
      {
        imgUrl: theme.IMAGES.videos.app,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "app",
        titleColor: theme.COLORS.blue,
        btnText: "moreInfo",
        btnUrl: "appLink",
        isBtn: true,
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        titleClass: "text-center",
        videoId: "appVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.pureHealty,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "pure",
        titleColor: theme.COLORS.blue,
        btnText: "moreInfo",
        btnUrl: "pureLink",
        isBtn: true,
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        titleClass: "text-center",
        videoId: "pureVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.pureHealty,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "pureHealthy",
        titleColor: theme.COLORS.blue,
        btnText: "moreInfo",
        btnUrl: "pureHealtyLink",
        isBtn: true,
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        titleClass: "text-center",
        videoId: "nslkDfHi978",
      },
      {
        imgUrl: theme.IMAGES.videos.piatra,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "piatra",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "piatraLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "piatraVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.spirit,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "spirit",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "spiritLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "spiritVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.tr.moniMe,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "moniMe",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "moniMeLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "nslkDfHi978",
      },

      {
        imgUrl: theme.IMAGES.videos.tr.crystal,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "crystal",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "crystalLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "crystalVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.handy,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "handy",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "handyLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "handyVideoId",
      },
      {
        imgUrl: theme.IMAGES.videos.crystalEco,
        imgAlt: "Sercair",
        imgPosition: "left" as "left",
        title: "crystalEco",
        titleColor: theme.COLORS.blue,
        isBtn: true,
        btnText: "moreInfo",
        titleClass: "text-center",
        btnUrl: "crystalEcoLink",
        isShadow: true,
        isBackgroundColor: true,
        backgroundColor: theme.COLORS.white,
        keyComponent: "Videos",
        videoId: "crystalEcoVideoId",
      },
    ],
  },
];

export const CubePointsData: SalesPoints = {
  country: [
    {
      name: "USA",
      code: "USA",
      lat: 34,
      lng: -135,
    },

    {
      name: "Nederlands",
      code: "NL",
      lat: 52,
      lng: 5,
    },

    {
      name: "Nederlands",
      code: "NL",
      lat: 52,
      lng: 5,
    },

    {
      name: "Nederlands",
      code: "NL",
      lat: 52,
      lng: 5,
    },
  ],
  city: [
    {
      name: "California",
      code: "CA",
      countryCode: "USA",
      lat: 34,
      lng: -135,
    },

    {
      name: "Amsterdam",
      code: "AMSTERDAM",
      countryCode: "NL",
      lat: 52.3084,
      lng: 4.7612,
    },
    {
      name: "Overijssel",
      code: "OVERIJSSEL",
      countryCode: "NL",
      lat: 52.4605,
      lng: 6.4108,
    },

    {
      name: "Amsterdam",
      code: "AMSTERDAM",
      countryCode: "NL",
      lat: 52.3084,
      lng: 4.7612,
    },
    {
      name: "Overijssel",
      code: "OVERIJSSEL",
      countryCode: "NL",
      lat: 52.4605,
      lng: 6.4108,
    },

    {
      name: "Amsterdam",
      code: "AMSTERDAM",
      countryCode: "NL",
      lat: 52.3084,
      lng: 4.7612,
    },
    {
      name: "Overijssel",
      code: "OVERIJSSEL",
      countryCode: "NL",
      lat: 52.4605,
      lng: 6.4108,
    },

    {
      name: "Amsterdam",
      code: "AMSTERDAM",
      countryCode: "NL",
      lat: 52.3084,
      lng: 4.7612,
    },
    {
      name: "Overijssel",
      code: "OVERIJSSEL",
      countryCode: "NL",
      lat: 52.4605,
      lng: 6.4108,
    },
  ],

  district: [
    {
      name: "Costa Mesa",
      code: "Costa Mesa",
      cityCode: "CA",
      countryCode: "USA",
    },

    {
      name: "Naarden",
      code: "NAARDEN",
      cityCode: "AMSTERDAM",
      countryCode: "NL",
    },
    {
      name: "Deventer",
      code: "DEVENTER",
      cityCode: "OVERIJSSEL",
      countryCode: "NL",
    },

    {
      name: "Naarden",
      code: "NAARDEN",
      cityCode: "AMSTERDAM",
      countryCode: "NL",
    },
    {
      name: "Deventer",
      code: "DEVENTER",
      cityCode: "OVERIJSSEL",
      countryCode: "NL",
    },

    {
      name: "Naarden",
      code: "NAARDEN",
      cityCode: "AMSTERDAM",
      countryCode: "NL",
    },
    {
      name: "Deventer",
      code: "DEVENTER",
      cityCode: "OVERIJSSEL",
      countryCode: "NL",
    },
  ],
  points: [
    {
      name: "Sercair",
      address: "450 E 17th St, Costa Mesa, CA 92627, USA",
      phone: "+19495153510",
      countryCode: "USA",
      cityCode: "CA",
      districtCode: "Costa Mesa",
      lat: 33.630022559068344,
      lng: -117.91017517455505,
      _id: "5f6b9b0b0b9d0a0017a5b0a5",
    },
    {
      name: "Sas Global Trade BV ",
      address: "Keulenstraat 11 A 7418 ET Deventer / NETHERLANDS",
      phone: "+31 6 11012476",
      countryCode: "NL",
      cityCode: "OVERIJSSEL",

      districtCode: "DEVENTER",
      lat: 52.23651,
      lng: 6.18863,
      _id: "5f6b9b0b0b9d0a0017a5b0cb",
    },
    {
      name: "Sas Global Trade BV ",
      address: "Keulenstraat 11 A 7418 ET Deventer / NETHERLANDS",
      phone: "+31 6 11012476",
      countryCode: "NL",
      cityCode: "OVERIJSSEL",

      districtCode: "DEVENTER",
      lat: 52.23651,
      lng: 6.18863,
      _id: "5f6b9b0b0b9d0a0017a5b0cb",
    },
    {
      name: "Sas Global Trade BV ",
      address: "Keulenstraat 11 A 7418 ET Deventer / NETHERLANDS",
      phone: "+31 6 11012476",
      countryCode: "NL",
      cityCode: "OVERIJSSEL",

      districtCode: "DEVENTER",
      lat: 52.23651,
      lng: 6.18863,
      _id: "5f6b9b0b0b9d0a0017a5b0cb",
    },
  ],
};

export const OpeningImgModal = {
  imgUrl: theme.IMAGES.openingModal.fuar_tr,
  imgAlt: "Sercair",
  show: false,
};

export const ShowOpeningVideoModal = {
  show: false,
  videoId: "nslkDfHi978",
};
