import React, { useState, useEffect } from "react";
import "./page.scss";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleComplete = () => {
      setIsLoading(false);
    };

    const timer = setTimeout(handleComplete, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div>
      {isLoading ? (
        <>
          <div style={{ visibility: "visible" }} className="loader-div">
            <div id="loader" style={{ visibility: "visible" }}></div>
          </div>
        </>
      ) : (
        <>
          <div style={{ display: "none" }} className="loader-div">
            <div id="loader" style={{ display: "none" }}></div>
          </div>
        </>
      )}
      <div style={{ visibility: isLoading ? "hidden" : "visible" }}></div>
    </div>
  );
}

export default App;
