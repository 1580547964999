import useWindowDimensions from "../../../services/getWindowSize.service";
import { TranslationService } from "../../../services/translation.service";
import { PropsArrow } from "./types";

function ArrowComponent(props: PropsArrow) {
  const isMobile = useWindowDimensions().width < 1200 ? true : false;
  const arrowContainerStyle = {
    dipslay: "inherit",
    margin: "0 auto",
    position: "absolute" as "absolute",
    zIndex: 3,
    width: "100%",
    height: "100%",
    scale: props.scale ? props.scale : isMobile ? 0.8 : 1,
    top: isMobile ? -47 : props.top ? props.top : 0,
    left: isMobile ? -69 : props.left ? props.left : 0,
  };

  const textContainerStyle = {
    display: "none", //inline-flex
    border: "none",
    zIndex: 3,
    textAlign: "center" as "center",
    justifyContent: "center",
    justifyItems: "center",
    alignContent: "center",
    alignItems: "center",
    margin: "0 auto",
    backgroundColor: "rgb(54 50 50)",
    borderRadius: "7px",
    width: 230,
    height: "auto !important",
    padding: 10,
    maxWidth: "230",
    opacity: 0,
    transition: "all 1s linear",
  };
  const despStyle = {
    display: "inline-block",
    wordWrap: "break-word" as "break-word",
    wordBreak: "break-word" as "break-word",
    color: "#fff",
    fontSize: 12,
    lineHeight: 1.33,
  };

  //if click the outside listener will be removed active class tag
  document.addEventListener("click", (e) => {
    //find the active class tag
    const activeClass = document.getElementsByClassName("activeContainer");
    const activeParent = document.getElementsByClassName(
      "activeContainerParent"
    );
    //if the active class tag is not null
    if (activeClass.length > 0) {
      //active element set style
      if (activeClass) {
        setTimeout(() => {
          //@ts-ignore
          activeClass[0].style.cssText =
            "opacity:0 !important;" + activeClass[0].getAttribute("style");

          //@ts-ignore
          activeParent[0].style.cssText =
            "z-index:3 !important; position: absolute;" +
            activeParent[0]?.getAttribute("style");

          //remove the active class tag
          activeClass[0].classList.remove("activeContainer");
          activeParent[0].classList.remove("activeContainerParent");
        }, 5);
      }
    }
  });
  const t = TranslationService.translate;
  const TranslationKey = "Cube";
  return (
    <div style={arrowContainerStyle}>
      {props.arrowData.map((item, index) => {
        return (
          <span
            key={"arrow" + item.bgUrl}
            id={"arrowP" + item.bgUrl}
            style={{
              position: "absolute" as "absolute",
              top: item.top,
              left: item.left,
              background: `url(${item.bgUrl})`,
              height: item.height,
              width: item.width ? item.width : 23,
              backgroundRepeat: "no-repeat",
              cursor: "pointer",
              zIndex: 3,
            }}
            onClick={() => {
              let doc = document.getElementById("arrow" + item.bgUrl);
              let docParent = document.getElementById("arrowP" + item.bgUrl);
              if (doc!.style.display === "inline-flex") {
                doc!.style.opacity = "0";
                setTimeout(() => {
                  doc!.style.display = "none";
                  docParent!.style.zIndex = "3";
                  doc!.style.zIndex = "3";
                  doc?.classList.remove("activeContainer");
                  docParent?.classList.add("activeContainerParent");
                }, 500);
              } else {
                doc!.style.display = "inline-flex";
                setTimeout(() => {
                  doc!.style.opacity =
                    doc!.style.display === "inline-flex" ? "1" : "0";
                  docParent!.style.zIndex =
                    doc!.style.display === "inline-flex" ? "4" : "3";
                  doc!.style.zIndex =
                    doc!.style.display === "inline-flex" ? "4" : "3";
                  doc?.classList.add("activeContainer");
                  docParent?.classList.add("activeContainerParent");
                }, 500);
              }
            }}
          >
            <div id={"arrow" + item.bgUrl} style={textContainerStyle}>
              <div
                style={despStyle}
                dangerouslySetInnerHTML={{
                  __html: t(TranslationKey, item.desp),
                }}
              />
            </div>
          </span>
        );
      })}
    </div>
  );
}

export default ArrowComponent;
