import { Props } from "./types";

export function ImageComponent(props: Props) {
  const imageStyle = {
    ...{ width: props.width ? props.width + "%" : "100%",  },
    ...props.style,
  };
  return (
    <img
      className={props.className}
      src={props.src}
      alt={props.alt}
      style={imageStyle}
      onClick={props.onClick}
    />

  );
}
