import { useEffect, useState } from "react";
import { useParams ,useSearchParams } from 'react-router-dom';

import GoogleMapReact from "google-map-react";
import { useNavigate } from "react-router-dom";

import Guess from "../../../components/WeatherComponents/Guess/guess";

import "./mapScreen.css";

import map_pin from "../../../assets/images/WeatherImages/SVG/map_pin.svg";
import useWindowDimensions from "../../../services/getWindowSize.service";
const AnyReactComponent = (props) => {
 

  const defaultProps = {
    center: {
      lat: 41.008381,
      lng: 28.815352,
    },
    zoom: 11,
  };
  const navigate = useNavigate();
  const pass = {};
  return (
    <div>
      <div style={{ position: "absolute", bottom: -100, left: -25 }}>
        <img className="location_pin" src={map_pin} />

        {props.but ? (
          <div>
            <div className="butto">
              {
                <Guess
                  butt={() =>
                    navigate("/MapScreen/MainScreenW", {
                      state: { latt: props.lat, longg: props.lng },
                    })
                  }
                  value="Tahmin"
                />
              }

              {/* <Button/> */}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

function MapScreen() {
  // const [lng,setLng] = useState(searchParams.get('name') || undefined)
  const [searchParams, setSearchParams] = useSearchParams();
  const [isTurkishContent,setIsTurkishContent] = useState(true)
  const searchParamLng = searchParams.get("lng")
  useEffect(()=>{
    (searchParamLng =='tr' || searchParamLng ==undefined ||searchParamLng ==null ) ?
     setIsTurkishContent(true) : setIsTurkishContent(false)
  },[searchParams])
 
  

  const screenSize = useWindowDimensions().height;
  const [lat, setLat] = useState(41.008381);
  const [long, setLong] = useState(28.815352);
  const [but, setBut] = useState(false);

  const defaultProps = {
    center: {
      lat: 41.008381,
      lng: 28.815352,
    },
    zoom: 11,
  };
  const navigate = useNavigate();
  const pass = {};
  return (
   <>
   {
    isTurkishContent ?  <div className="conta">
    <div
      style={{
        height: screenSize - 75,
        width: "100%",
        backgroundColor: "white",
        flex: 1,
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          paddingTop: "0px",
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyAB_YJmC0j05NMLq2bzsGePrunCeLvVyhM",
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          options={{
            styles: [
              { elementType: "geometry", stylers: [{ color: "#14191f" }] },
              {
                elementType: "labels.text.stroke",
                stylers: [{ color: "red" }],
              },
              {
                elementType: "labels.text.fill",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "administrative.locality",
                elementType: "labels.text.fill",
                stylers: [{ color: "#d59563" }],
              },

              {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [{ color: "#d59563" }],
              },
              {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [{ color: "#263c3f" }],
              },
              {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [{ color: "#6b9a76" }],
              },
              {
                featureType: "road",
                elementType: "geometry",
                stylers: [{ color: "#1b2f32" }],
              },
              {
                featureType: "road",
                elementType: "geometry.stroke",
                stylers: [{ color: "#3c848f" }],
              },
              {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9ca5b3" }],
              },
              {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [{ color: "#3c848f" }],
              },
              {
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [{ color: "#1f2835" }],
              },
              {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [{ color: "#f3d19c" }],
              },
              {
                featureType: "transit",
                elementType: "geometry",
                stylers: [{ color: "#2f3948" }],
              },
              {
                featureType: "transit.station",
                elementType: "labels.text.fill",
                stylers: [{ color: "#d59563" }],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [{ color: "#303140" }],
              },
              {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [{ color: "#515c6d" }],
              },
              {
                featureType: "water",
                elementType: "labels.text.stroke",
                stylers: [{ color: "#303140" }],
              },
            ],
          }}
          onClick={(e) => {
            setLong(e.lng.toFixed(6));
            setLat(e.lat.toFixed(6));
            setBut(true);
          }}
        >
          <AnyReactComponent lat={lat} lng={long} but={but} />
        </GoogleMapReact>
      </div>
    </div>
  </div> : <p className="noLng">No weather information was found for your region.</p>
   }
   </>
  );
}

export default MapScreen;
