import theme from "../../../constants/theme";
import { TranslationService } from "../../../services/translation.service";
import { store } from "../../../store";
import { Props } from "./types";
import React from "react";

export function IconContainerMobileComponent(props: Props) {
  const t = TranslationService.translate;
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
  }, []);

  return (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        padding: 10,
        justifyContent: "space-around",
      }}
    >
      {props.icons.map((icon, index) => {
        return (
          <div style={{ flexDirection: "column" }}>
            <img
              src={icon.icoUrl}
              alt={icon.icoAlt}
              style={{
                width: props.iconWidth ? props.iconWidth : 55,
              }}
            />
            <div style={{ width: props.textWidth, textAlign: "center" }}>
              <div
                style={{ fontSize: 0, color: icon.textColor }}
                dangerouslySetInnerHTML={{
                  __html: icon.text && t(icon.keyComponent, icon.text),
                }}
              />
              {props.isSpirit && (
                <div
                  className="icon-title GothamMedium"
                  style={{
                    fontSize: icon.titleSize
                      ? icon.titleSize
                      : theme.FONT_SIZE.small,
                    color: icon.titleColor
                      ? icon.titleColor
                      : theme.COLORS.blue,
                  }}
                ></div>
              )}
              <div style={{ fontSize: 7.19 }}>
                {icon.text && t(icon.keyComponent, icon.text)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
