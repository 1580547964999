import { Container } from "react-bootstrap";
import theme from "../../../constants/theme";
import { TranslationService } from "../../../services/translation.service";
import { Props } from "./types";
import { useEffect } from "react";

export function ScreenComponent(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "DistanceSelling";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container style={{ marginTop: 50, marginBottom: 100, maxWidth: "80vw" }}>
      <h2
        style={{ color: theme.COLORS.blue }}
        className="gothamMedium"
        dangerouslySetInnerHTML={{ __html: t(translationKey, "titleMain") }}
      />
      <p
        style={{ fontSize: "11pt" }}
        dangerouslySetInnerHTML={{ __html: t(translationKey, "text") }}
      />
    </Container>
  );
}
