import React from "react";
import { Props } from "./types";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { darkTheme } from "./style";
import theme from "../../constants/theme";

const containerStyle = {
  width: "100%",
  height: "550px",
  marginTop: "25px",
  marginBottom: "75px",
};

const googleMapAPI = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

function MyComponent(props: Props) {
  const langCheck = localStorage.getItem("lang");
  const enpos = { lat: 44, lng: 25 };
  const trpos = { lat: 44, lng: 25 };
  const nlpos = { lat: 44, lng: 25 };
  const rspos = { lat: 44, lng: 25 };
  const frpos = { lat: 44, lng: 25 };
  const ukpos = { lat: 44, lng: 25 };
  const ropos = { lat: 44, lng: 25 };

  const [zoom, setZoom] = React.useState(5);
  const [center, setCenter] = React.useState(
    langCheck === "tr"
      ? trpos
      : langCheck === "en"
      ? enpos
      : langCheck === "nl"
      ? nlpos
      : langCheck === "fr"
      ? frpos
      : langCheck === "uk"
      ? ukpos
      : langCheck === "ro"
      ? ropos
      : rspos
  );
  React.useEffect(() => {
    setTimeout(() => {
      setZoom(2.5);
    }, 500);
  }, []);

  const [marks, setMarks] = React.useState(props.pointModelAll);
  const [popUp, setPopUp] = React.useState(true);
  const [infoWindows, setInfoWindows] = React.useState(
    props.pointModelAll.map((point) => {
      return {
        isOpen: false,
        point: point,
      };
    })
  );

  React.useEffect(() => {
    var temp: any[] = [];
    var center = { lat: 41, lng: 28 };
    var zoom = 10;
    if (props.selectedDistrict[1] !== "all") {
      props.pointModel &&
        props.pointModel.forEach((point) => {
          if (point.districtCode === props.selectedDistrict[1]) {
            center = { lat: point.lat, lng: point.lng };
            zoom = 12;
            temp = [point];
          }
        });
    } else if (props.selectedCity[1] !== "all") {
      props.pointModel &&
        props.pointModel.forEach((point) => {
          if (point.cityCode === props.selectedCity[1]) {
            center = { lat: point.lat, lng: point.lng };
            zoom = 10;
            temp = [...temp, point];
          }
        });
    } else if (props.selectedCountry[1] !== "all") {
      center = {
        lat: props.pointModel && props.pointModel[0].lat,
        lng: props.pointModel && props.pointModel[0].lng,
      };
      zoom = 6;
      temp = props.pointModel && props.pointModel;
    } else {
      temp = props.pointModelAll;
      center = { lat: 41, lng: 28 };
      zoom = 4;
    }
    setMarks(temp);
    setCenter(center);
    setZoom(zoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedCity, props.selectedCountry, props.selectedDistrict]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapAPI ? googleMapAPI : "",
  });
  //eslint-disable-next-line
  const [map, setMap] = React.useState(null);
  const onLoad = React.useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      options={{
        fullscreenControl: true,
        mapTypeControl: false,
        styles: props.selectedDarkTheme ? darkTheme : darkTheme,
      }}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {marks.map((point, index) => {
        return (
          <>
            {props.isIconSpecial ? (
              <Marker
                key={"marker" + index}
                position={{ lat: point.lat, lng: point.lng }}
                clickable={true}
                draggable={false}
                icon={{
                  url: theme.IMAGES.products.cube.mapPin,
                  scaledSize: new window.google.maps.Size(30, 60),
                }}
                animation={window.google.maps.Animation.DROP}
                onClick={() => {
                  infoWindows[index].isOpen = true;
                  setPopUp(!popUp);
                  setInfoWindows((infoWindows) => infoWindows);
                  props.setSelectedPoint && props.setSelectedPoint(point);
                  props.setInfoContainer && props.setInfoContainer(true);
                }}
              />
            ) : (
              <Marker
                key={"marker" + index}
                position={{ lat: point.lat, lng: point.lng }}
                clickable={true}
                draggable={false}
                animation={window.google.maps.Animation.DROP}
                onClick={() => {
                  infoWindows[index].isOpen = true;
                  setPopUp(!popUp);
                  setInfoWindows((infoWindows) => infoWindows);
                }}
              />
            )}

            {infoWindows[index].isOpen && (
              <InfoWindow
                position={{ lat: point.lat, lng: point.lng }}
                onCloseClick={() => {
                  var temp = infoWindows;
                  temp[index].isOpen = false;
                  setInfoWindows(temp);
                }}
              >
                <>
                  <h6>{point.name}</h6>
                  <p>{point.address}</p>
                </>
              </InfoWindow>
            )}
          </>
        );
      })}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
