import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { ButtonComponent } from "../../../../components/Shared/ButtonComponent/component";
import { ButtonComponentPurple } from "../../../../components/Shared/ButtonComponentPurple/component";
import { CardGroupComponent } from "../../../../components/Shared/CardComponent/component";
import { FullImageContentComponent } from "../../../../components/Shared/FullImageContent/component";
import { ImageComponent } from "../../../../components/Shared/ImageComponent/component";
import { SliderProductsComponent } from "../../../../components/Shared/SliderProductsComponent/component";
import { TabComponent } from "../../../../components/Shared/TabComponent/component";
import { TableComponent } from "../../../../components/Shared/TableComponent/component";
import { TextContainerComponent } from "../../../../components/Shared/TextContainerComponent/component";
import { SliderComponent } from "../../../../components/SliderComponent";
import {
  MobileSliderPureSeriesOverview,
  MobileTextContainerPureSeries,
  PureBoxContent,
  PureSeriesTechnicalData,
  SliderPureSeries,
  SliderPureSeriesMobile,
  SliderPureSeriesOverview,
  TextContainerPureSeries,
  UnderSecondHeaderMobileTextContainerPureSeries,
} from "../../../../constants/datas";
import theme from "../../../../constants/theme";
import useWindowDimensions from "../../../../services/getWindowSize.service";
import { TranslationService } from "../../../../services/translation.service";
import { store } from "../../../../store";
import { AppTabPage } from "../../app/component";
import "./style.css";
import { Props } from "./types";

export function ScreenComponent(props: Props) {
  const langCheck = localStorage.getItem("lang");
  const t = TranslationService.translate;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);

  const [lang, setLang] = useState(TranslationService.getLang().code);

  useEffect(() => {
    setLang(TranslationService.getLang().code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TranslationService.getLang().code]);

  const [sensorsStyles, setSensorsStyles] = useState([
    {
      color: theme.COLORS.blue,
      position: "absolute" as "absolute",
      top: 100,
      width: 450,
      left: 100,
      textAlign: "center" as "center",
      fontSize: "24px !important",
    },
    {
      color: theme.COLORS.white,
      position: "absolute" as "absolute",
      top: 100,
      width: 450,
      right: 100,
      textAlign: "center" as "center",
      fontSize: "24px !important",
    },
  ]);
  const scWidth = useWindowDimensions().width;
  useEffect(
    () => {
      if (isMobile) {
        let xss = [...sensorsStyles];
        xss[0] = {
          color: theme.COLORS.blue,
          position: "absolute" as "absolute",
          top: 20,
          width: 70,
          left: 5,
          textAlign: "center" as "center",
          fontSize: "10px !important",
        };
        xss[1] = {
          color: theme.COLORS.white,
          position: "absolute" as "absolute",
          top: 20,
          width: 70,
          right: 5,
          textAlign: "center" as "center",
          fontSize: "10px  !important",
        };
        setSensorsStyles(xss);
      } else if (scWidth < 1237) {
        let xss = [...sensorsStyles];
        xss[0] = {
          color: theme.COLORS.blue,
          position: "absolute" as "absolute",
          top: 20,
          width: 70,
          left: 5,
          textAlign: "center" as "center",
          fontSize: "10px !important",
        };
        xss[1] = {
          color: theme.COLORS.white,
          position: "absolute" as "absolute",
          top: 20,
          width: 70,
          right: 0,
          textAlign: "center" as "center",
          fontSize: "10px  !important",
        };
        setSensorsStyles(xss);
      } else if (scWidth > 1237 && scWidth < 1537) {
        let xss = [...sensorsStyles];
        xss[0] = {
          color: theme.COLORS.blue,
          position: "absolute" as "absolute",
          top: 10,
          width: 400,
          left: 50,
          textAlign: "center" as "center",
          fontSize: "24px",
        };
        xss[1] = {
          color: theme.COLORS.white,
          position: "absolute" as "absolute",
          top: 10,
          width: 400,
          right: 10,
          textAlign: "center" as "center",
          fontSize: "24px",
        };
        setSensorsStyles(xss);
      } else if (scWidth < 1237) {
        let xss = [...sensorsStyles];
        xss[0] = {
          color: theme.COLORS.blue,
          position: "absolute" as "absolute",
          top: 20,
          width: 150,
          left: 5,
          textAlign: "center" as "center",
          fontSize: "20px !important",
        };
        xss[1] = {
          color: theme.COLORS.white,
          position: "absolute" as "absolute",
          top: 20,
          width: 150,
          right: 5,
          textAlign: "center" as "center",
          fontSize: "20px  !important",
        };
        setSensorsStyles(xss);
      }
    },
    // eslint-disable-next-line
    [scWidth]
  );

  const button = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: isMobile ? "start" : "start",
          marginBottom: theme.SPACES.large,
          marginTop: 80,
          marginLeft: 10,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
        />
      </div>
    );
  };
  const buttonPurple = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: isMobile ? "center" : "start",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponentPurple
          url={url}
          text={text}
          isLong={false}
          position={"center"}
          isTabSwitcher={true}
        />
      </div>
    );
  };
  const children = [
    {
      title: t("PureSeries", "tabTitle1"),
      body: (
        <div
          className="mobile-size-purePure"
          style={{ backgroundColor: "white" }}
        >
          <SliderComponent
            sliderItems={isMobile ? SliderPureSeriesMobile : SliderPureSeries}
          />
          <TextContainerComponent
            texts={
              isMobile ? MobileTextContainerPureSeries : TextContainerPureSeries
            }
            columnNumber={3}
          />
          {isMobile ? (
            <div>
              <FullImageContentComponent
                imgUrl={
                  isMobile
                    ? theme.IMAGES.main.cleanairMobile
                    : theme.IMAGES.main.cleanair
                }
                imgAlt="Rahat bir nefes için"
                title={
                  window.screen.width < 1500
                    ? t("PureSeries", "textContainer3Title").replace(
                        /<br\s*\/?>/gi,
                        ""
                      )
                    : t("PureSeries", "textContainer3Title")
                }
                pStyle={{ width: 250 }}
                text={t("PureSeries", "textContainer3Text")}
                titleColor={theme.COLORS.blue}
                isBtn={false}
                textPosition={isMobile ? "right" : "left"}
              />
              <TextContainerComponent
                texts={
                  isMobile
                    ? UnderSecondHeaderMobileTextContainerPureSeries
                    : TextContainerPureSeries
                }
                columnNumber={3}
              />
            </div>
          ) : (
            <FullImageContentComponent
              imgUrl={
                isMobile
                  ? theme.IMAGES.main.cleanairMobile
                  : theme.IMAGES.main.cleanair
              }
              imgAlt="Rahat bir nefes için"
            />
          )}

          {isMobile ? (
            <FullImageContentComponent
              imgUrl={theme.IMAGES.products.pure.pureSeries.cleanAir}
              title={
                window.screen.width < 1500
                  ? t("PureSeries", "effectiveCleanAirTitle").replace(
                      /<br\s*\/?>/gi,
                      ""
                    )
                  : t("PureSeries", "effectiveCleanAirTitle")
              }
              pStyle={{ width: 250 }}
              hStyle={{ width: 250 }}
              text={t("PureSeries", "effectiveCleanAirText")}
              titleColor={theme.COLORS.blue}
              imgAlt={t("PureSeries", "altProductsAlt")}
              isBtn={false}
              textPosition={isMobile ? "right" : "left"}
            />
          ) : (
            <FullImageContentComponent
              imgUrl={theme.IMAGES.products.pure.pureSeries.cleanAir}
              title={
                window.screen.width < 1500
                  ? t("PureSeries", "effectiveCleanAirTitle").replace(
                      /<br\s*\/?>/gi,
                      ""
                    )
                  : t("PureSeries", "effectiveCleanAirTitle")
              }
              text={t("PureSeries", "effectiveCleanAirText")}
              titleColor={theme.COLORS.blue}
              imgAlt={t("PureSeries", "altProductsAlt")}
              isBtn={false}
              textPosition={isMobile ? "right" : "left"}
            />
          )}

          <div
            className={isMobile ? "containerRel" : ""}
            style={{ position: "relative", marginTop: 5 }}
          >
            <h4
              style={sensorsStyles[0]}
              dangerouslySetInnerHTML={{
                __html: isMobile
                  ? t("PureSeries", "sensorsTitle1").replace(
                      /<br\s*[\/]?>/gi,
                      ""
                    )
                  : t("PureSeries", "sensorsTitle1"),
              }}
            ></h4>
            <h4
              style={sensorsStyles[1]}
              dangerouslySetInnerHTML={{
                __html: isMobile
                  ? t("PureSeries", "sensorsTitle2").replace(
                      /<br\s*[\/]?>/gi,
                      ""
                    )
                  : t("PureSeries", "sensorsTitle2"),
              }}
            ></h4>
            <FullImageContentComponent
              imgUrl={
                scWidth < 1237
                  ? theme.IMAGES.products.pure.pureSeries.sensorPhone
                  : theme.IMAGES.products.pure.pureSeries.sensors
              }
              imgAlt="Sercair Pure Series"
            />
          </div>
          {isMobile ? (
            <div style={{}}>
              <FullImageContentComponent
                imgUrl={
                  lang === "tr"
                    ? //ts-ignore
                      theme.IMAGES.products.pure.pureSeries.appGifTR
                    : theme.IMAGES.products.pure.pureSeries.appGifEN
                }
                imgAlt={t("PureSeries", "altProductsAlt")}
                style={{ marginBottom: isMobile ? 0 : 0 }}
                pStyle={{}}
                isBtn={true}
                textPosition="left"
                textBackgroundImg={isMobile ? "none" : theme.IMAGES.gifLine}
                title={t("PureSeries", "appTechnologyTitle")}
                text={t("PureSeries", "appTechnologyText")}
                btnText={t("PureSeries", "btnMoreInfo")}
                btnUrl={
                  langCheck === "tr"
                    ? "/tr/iletisim"
                    : langCheck === "en"
                    ? "/contact"
                    : langCheck === "nl"
                    ? "/nl/contact"
                    : langCheck === "fr"
                    ? "/fr/contact"
                    : langCheck === "uk"
                    ? "/uk/contact"
                    : langCheck === "ro"
                    ? "/ro/contactati"
                    : "/rs/kontakt"
                }
                btnIsLong={true}
                buttonComponent={buttonPurple}
                isPureComponent={true}
              />
            </div>
          ) : (
            <div style={{}}>
              <FullImageContentComponent
                imgUrl={
                  lang === "tr"
                    ? //ts-ignore
                      theme.IMAGES.products.pure.pureSeries.appGifTR
                    : theme.IMAGES.products.pure.pureSeries.appGifEN
                }
                imgAlt={t("PureSeries", "altProductsAlt")}
                style={{}}
                isBtn={true}
                textPosition="left"
                textBackgroundImg={isMobile ? "none" : theme.IMAGES.gifLine}
                title={t("PureSeries", "appTechnologyTitle")}
                text={t("PureSeries", "appTechnologyText")}
                btnText={t("PureSeries", "btnMoreInfo")}
                btnUrl={
                  langCheck === "tr"
                    ? "/tr/iletisim"
                    : langCheck === "en"
                    ? "/contact"
                    : langCheck === "nl"
                    ? "/nl/contact"
                    : langCheck === "fr"
                    ? "/fr/contact"
                    : langCheck === "uk"
                    ? "/uk/contact"
                    : langCheck === "ro"
                    ? "/ro/contactati"
                    : "/rs/kontakt"
                }
                btnIsLong={true}
                buttonComponent={buttonPurple}
              />
            </div>
          )}

          <div
            style={{ padding: 60, backgroundColor: theme.COLORS.blue40 }}
            className="text-center"
          >
            <h1 style={{ margin: 20, fontSize: "25pt", marginTop: -20 }}>
              {t("OverViewPureSlider", "sliderMainTitle")}
            </h1>
            <p
              style={{ fontSize: "14pt" }}
              dangerouslySetInnerHTML={{
                __html: t("OverViewPureSlider", "sliderMainText"),
              }}
            ></p>
            <div
              style={
                isMobile
                  ? { paddingInline: 0, marginTop: 25, marginBottom: -50 }
                  : { paddingInline: scWidth < 1500 ? 75 : 200, marginTop: 25 }
              }
            >
              {isMobile ? (
                <SliderProductsComponent
                  sliderRightItems={MobileSliderPureSeriesOverview}
                  //sliderBottomItems={MobileSliderPureSeriesOverview}
                  textPosition="right"
                />
              ) : (
                <SliderProductsComponent
                  sliderRightItems={SliderPureSeriesOverview}
                  textPosition="right"
                />
              )}
            </div>
          </div>
          {isMobile ? (
            <div style={{}}>
              <FullImageContentComponent
                imgUrl={
                  theme.IMAGES.products.pure.pureSeries.easyFilterChangeMobile
                }
                imgAlt={t("PureSeries", "altProductsAlt")}
                isBtn={false}
                isPureComponent={true}
                // style={{marginTop: -100}}
                //hStyle={{ marginTop: isMobile ? 0 : 140 }}
                pStyle={{ width: 250 }}
                hStyle={{ width: 250, marginTop: isMobile ? 0 : 140 }}
                textPosition={isMobile ? "left" : "right"}
                //textBackgroundImg={theme.IMAGES.waweLine2}
                title={t("PureSeries", "easyFilterChangeTitle")}
                text={
                  window.screen.width < 1500
                    ? t("PureSeries", "easyFilterChangeText").replace(
                        /<br\s*\/?>/gi,
                        ""
                      )
                    : t("PureSeries", "easyFilterChangeText")
                }
              />
            </div>
          ) : (
            <FullImageContentComponent
              imgUrl={theme.IMAGES.products.pure.pureSeries.easyFilterChange}
              imgAlt={t("PureSeries", "altProductsAlt")}
              isBtn={false}
              hStyle={{ marginTop: isMobile ? 0 : 140 }}
              pStyle={{}}
              textPosition={isMobile ? "left" : "right"}
              textBackgroundImg={theme.IMAGES.waweLine2}
              title={t("PureSeries", "easyFilterChangeTitle")}
              text={
                window.screen.width < 1500
                  ? t("PureSeries", "easyFilterChangeText").replace(
                      /<br\s*\/?>/gi,
                      ""
                    )
                  : t("PureSeries", "easyFilterChangeText")
              }
            />
          )}

          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureSeries.adminPanel}
            imgAlt={t("PureSeries", "altProductsAlt")}
            isBtn={false}
            textPosition="left"
            // textBackgroundImg={theme.IMAGES.bgLinweHalf}
            title={t("PureSeries", "adminPanelTitle")}
            text={
              window.screen.width < 1500
                ? t("PureSeries", "adminPanelText").replace(
                    /<br\s*[\/]?>/gi,
                    ""
                  )
                : t("PureSeries", "adminPanelText")
            }
          />
          {isMobile ? (
            <div>
              <FullImageContentComponent
                imgUrl={theme.IMAGES.products.pure.pureSeries.clearFutureMobile}
                imgAlt={t("PureSeries", "altProductsAlt")}
                isBtn={false}
                textPosition={isMobile ? "left" : "right"}
                title={t("PureSeries", "clearFutureTitle")}
                titleColor={theme.COLORS.blue}
                pStyle={{ width: isMobile ? 250 : 500 }}
                hStyle={{ width: isMobile ? 250 : 500 }}
                //text={t("PureSeries", "clearFutureText")}
              />
              <div style={{ marginTop: 50 }} />
              <FullImageContentComponent
                imgUrl={theme.IMAGES.products.pure.pureSeries.workSpaceMobile}
                imgAlt={t("PureSeries", "altProductsAlt")}
                isBtn={false}
                pStyle={{ width: isMobile ? 250 : 500 }}
                hStyle={{ width: isMobile ? 250 : 500 }}
                textPosition="left"
                title={t("PureSeries", "workSpaceTitle")}
                titleColor={theme.COLORS.blue}
                // text={t("PureSeries", "workSpaceText")}
              />
            </div>
          ) : (
            <div>
              <FullImageContentComponent
                imgUrl={theme.IMAGES.products.pure.pureSeries.clearFuture}
                imgAlt={t("PureSeries", "altProductsAlt")}
                isBtn={false}
                textPosition={isMobile ? "left" : "right"}
                title={t("PureSeries", "clearFutureTitle")}
                titleColor={theme.COLORS.blue}
                pStyle={{ width: isMobile ? 250 : 500 }}
                hStyle={{ width: isMobile ? 250 : 500 }}
                //text={t("PureSeries", "clearFutureText")}
              />
              <div style={{ marginTop: 50 }} />
              <FullImageContentComponent
                imgUrl={theme.IMAGES.products.pure.pureSeries.workSpace}
                imgAlt={t("PureSeries", "altProductsAlt")}
                isBtn={false}
                pStyle={{ width: isMobile ? 250 : 500 }}
                hStyle={{ width: isMobile ? 250 : 500 }}
                textPosition="left"
                title={t("PureSeries", "workSpaceTitle")}
                titleColor={theme.COLORS.blue}
                // text={t("PureSeries", "workSpaceText")}
              />
            </div>
          )}

          <Container
            style={{
              marginBottom: 10,
              marginTop: 50,
              //marginLeft: isMobile ? 2 : 167,
              alignItems: "center",
            }}
          ></Container>
        </div>
      ),
    },
    {
      title: t("PureSeries", "tabTitle2"),
      body: (
        <div style={{ backgroundColor: "white" }}>
          <ImageComponent
            src={theme.IMAGES.products.pure.pureSeries.overView}
            alt="Sercair"
            style={{ marginTop: 110 }}
          />
          <TableComponent
            data={PureSeriesTechnicalData}
            keyComponent={"PureSeries"}
          />
          <hr />
          {!isMobile ? (
            <CardGroupComponent cards={PureBoxContent} />
          ) : (
            <div className="d-flex justify-content-center my-4">
              <div style={{ width: isMobile ? "70%" : "80%" }}>
                <SliderProductsComponent
                  textPosition="bottom"
                  sliderBottomItems={PureBoxContent}
                  minHeight={402}
                />
              </div>
            </div>
          )}

          <hr />
        </div>
      ),
    },
    {
      title: t("PureSeries", "tabTitle3"),
      body: (
        <div>
          <AppTabPage isMobile={isMobile} />
        </div>
      ),
    },
  ];

  return (
    <div>
      <TabComponent children={children} componentKey={"PureSeries"} />
    </div>
  );
}
