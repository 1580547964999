import React from "react";
import axios from "axios";
import { Fragment } from "react";
import WeatherCard from "../weatherCard";
import "./moment.css";
import MomentList1 from "../Lists/MomentList/MomentList1";
import MomentList2 from "../Lists/MomentList/MomentList2";
import TomorrowPassive from "../tomorrow/TomorrowPassive";
import AirQualityIndex from "../AirQualityIndex/AirQualityIndex";
import one from "../../../assets/images/WeatherImages/Weather/one.png";
import two from "../../../assets/images/WeatherImages/Weather/two.png";
import three from "../../../assets/images/WeatherImages/Weather/three.png";
import four from "../../../assets/images/WeatherImages/Weather/four.png";
import five from "../../../assets/images/WeatherImages/Weather/five.png";
import six from "../../../assets/images/WeatherImages/Weather/six.png";

const current = new Date();
const date = `${current.getDate()}/${
  current.getMonth() + 1
}/${current.getFullYear()}`;

const objectDate = new Date();

let day1 = objectDate.getDate();
let day2 = objectDate.getDate() - 1;
let month = objectDate.getMonth() + 1;
let year = objectDate.getFullYear();

const day_format_first = year + "-" + month + "-" + day2;
const day_format_last = year + "-" + month + "-" + day1;

const Moment = (props) => {
  // const baseURL = `http://78.186.56.6:9005/api/v1/current/getCurrent?KE=${props.lang}&KB=${props.long}`;
  const baseURL = `http://78.186.56.6:9000/getcurrent?ke=${props.lang}&kb=${props.long}&km=16`;

    const config = {
    headers: {
      Authorization:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6InRlc3RAZ21haWwuY29tIn0.Y-iOGZPutGUODJshuimE-Fi78O77YRdBbcrWOkqKwII",
    },
  };

  const [datee, setDatee] = React.useState();
  const [situation, setSituation] = React.useState(null);
  const [sit, setSit] = React.useState();
  const [secondsit, setSecondSit] = React.useState();
  const [loading, setLoading] = React.useState();
  const [valuee, setValuee] = React.useState();
  const [secondvaluee, setSecondValuee] = React.useState("Sıcaklık");
  const [int, setInt] = React.useState(null);
  const [secondint, setSecondInt] = React.useState(null);
  const [moment, setMoment] = React.useState(null);
  const [minval, setMinval] = React.useState();
  const [secondminval, setSecondMinval] = React.useState();
  const [maxval, setMaxval] = React.useState();
  const [secondmaxval, setSecondMaxval] = React.useState();
  const [unit, setUnit] = React.useState();
  const [secondunit, setSecondUnit] = React.useState();
  const [change, setChange] = React.useState(true);
  const [secondchange, setSecondChange] = React.useState(true);
  const [ins, setIns] = React.useState(true);
  const [secondins, setSecondIns] = React.useState(true);
  const [tog, setTog] = React.useState(false);

  const [secondtog, setSecondTog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [secondopen, setSecondOpen] = React.useState(false);
  const [wstoggle, setWstoggle] = React.useState(false);
  const [temptoggle, setTemptoggle] = React.useState(false);
  const [humtoggle, setHumtoggle] = React.useState(false);
  const [wdtoggle, setWdtoggle] = React.useState(false);
  const [no2toggle, setNo2toggle] = React.useState(false);
  const [so2toggle, setSo2toggle] = React.useState(false);
  const [pm2_5toggle, setPm2_5toggle] = React.useState(false);
  const [pm10toggle, setPm10toggle] = React.useState(false);
  const [co2toggle, setCo2toggle] = React.useState(false);
  const [cotoggle, setCotoggle] = React.useState(false);
  const [vocstoggle, setVocstoggle] = React.useState(false);
  const [nh3toggle, setNh3toggle] = React.useState(false);
  const [notoggle, setNotoggle] = React.useState(false);
  const [o3toggle, setO3toggle] = React.useState(false);
  const [prestoggle, setPresstoggle] = React.useState(false);
  const [color, setColor] = React.useState();
  const [secondcolor, setSecondColor] = React.useState();
  const [col, setCol] = React.useState();
  const [aqi, setAqi] = React.useState();
  const [devam, setDevam] = React.useState();
  const [bekle, setBekle] = React.useState(true);
  const [status, setStatus] = React.useState();
  React.useEffect(() => {
    const timer = setInterval(() => {
      setDatee(new Date());
    }, 15 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    axios
    .get(baseURL, config)
      .then((response) => {
        console.log(response.data);
        console.log(response.data[0]);
        setStatus(response.data[0].status)(
          status == 400
            ? (setMoment(null), setSituation(null), setCol(null), setAqi(null))
            : (setMoment(response.data[0]),
              setSituation(response.data[1]),
              setCol(response.data[2]),
              setAqi(response.data[3]))
        );
        // setSituation(response.data[1]);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  }, []);

  if (status !== 400) {
    if (!moment) return null;
    if (!situation) return null;
    if (!col) return null;
    if (!aqi) return null;
  }

  //  if (!situation) return null;

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const days = [
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ];
  const day = days[current.getDay()];
  const timer = current.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  const updateTemp = () => {
    setSecondMaxval(
      -20 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= -4
        ? -4
        : -4 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 6
        ? 6
        : 6 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 14
        ? 14
        : 14 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 17
        ? 17
        : 17 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 19
        ? 19
        : 19 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 23
        ? 23
        : 23 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 25
        ? 25
        : 25 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 28
        ? 28
        : 28 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 37
        ? 37
        : 37 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 46
        ? 46
        : 46 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 50
        ? 50
        : 50
    );
    setSecondMinval(
      -20 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= -4
        ? -20
        : -4 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 6
        ? -4
        : 6 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 14
        ? 6
        : 14 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 17
        ? 14
        : 17 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 19
        ? 17
        : 19 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 23
        ? 19
        : 23 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 25
        ? 23
        : 25 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 28
        ? 25
        : 28 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 37
        ? 28
        : 37 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 46
        ? 37
        : 46 < moment.Temp.toFixed(0) && moment.Temp.toFixed(0) <= 50
        ? 46
        : 0
    );
    setSecondValuee("Sıcaklık");
    setSecondInt(moment.Temp.toFixed(0));
    setSecondUnit("°C");
    setSecondChange(true);
    setSecondIns(false);
    setSecondTog(false);
    setSecondSit(situation.Temp);
    setTemptoggle(true);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);

    setSecondColor(col.Temp);
    setPresstoggle(false);
    setSecondOpen(true);
  };
  const updateHum = () => {
    setSecondOpen(true);
    setSecondValuee("Nem");
    setSecondInt(moment.Hum.toFixed(0));
    setSecondSit(situation.Hum);
    setSecondMaxval(100);
    setSecondMinval(0);
    setSecondIns(false);
    setSecondUnit("%");
    setSecondTog(true);
    setSecondChange(true);
    setSecondColor(col.Hum);
    setTemptoggle(false);
    setPresstoggle(false);
    setHumtoggle(true);
    setWdtoggle(false);
    setWstoggle(false);
  };
  const updateWD = () => {
    setSecondValuee("Rüzgar Yönü");
    setSecondInt(moment.WD.toFixed(0));
    setSecondOpen(true);
    setSecondSit();
    setSecondMaxval(360);
    setSecondMinval(0);
    setSecondIns(false);
    setSecondUnit("°");
    setSecondTog(true);
    setSecondChange(true);
    setSecondColor(col.WS);
    setTemptoggle(false);
    setPresstoggle(false);
    setHumtoggle(false);
    setWdtoggle(true);
    setWstoggle(false);
  };
  const updateWS = () => {
    setSecondValuee("Rüzgar Hızı");
    setSecondInt(moment.WS.toFixed(0));
    setSecondSit(situation.WS);
    setSecondMaxval(
      0 < moment.WS.toFixed(0) && moment.WS.toFixed(0) <= 1
        ? 1
        : 1 < moment.WS.toFixed(0) && moment.WS.toFixed(0) <= 5
        ? 5
        : 5 < moment.WS.toFixed(0) && moment.WS.toFixed(0) <= 10
        ? 10
        : 10 < moment.WS.toFixed(0) && moment.WS.toFixed(0) <= 20
        ? 17
        : 20 < moment.WS.toFixed(0) && moment.WS.toFixed(0) <= 35
        ? 35
        : 50
    );
    setSecondMinval(
      0 < moment.WS.toFixed(0) && moment.WS.toFixed(0) <= 1
        ? 0
        : 1 < moment.WS.toFixed(0) && moment.WS.toFixed(0) <= 5
        ? 1
        : 5 < moment.WS.toFixed(0) && moment.WS.toFixed(0) <= 10
        ? 5
        : 10 < moment.WS.toFixed(0) && moment.WS.toFixed(0) <= 20
        ? 10
        : 20 < moment.WS.toFixed(0) && moment.WS.toFixed(0) <= 35
        ? 20
        : 35
    );
    setPresstoggle(false);
    setSecondIns(false);
    setSecondOpen(true);
    setSecondUnit("m/s");
    setSecondTog(true);
    setSecondChange(true);
    setSecondColor(col.WS);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(true);

    setPresstoggle(false);
  };
  const updatePres = () => {
    setSecondValuee("Basınç");
    setSecondInt(moment.Pres.toFixed(0));
    setSecondSit(situation.Pres);
    setSecondMaxval(1500);
    setSecondIns(false);
    setSecondUnit("p");
    setSecondTog(true);
    setSecondChange(true);
    setSecondColor(col.Pres);
    setTemptoggle(false);
    setHumtoggle(false);
    setSecondOpen(true);
    setWdtoggle(false);
    setWstoggle(false);
    setPresstoggle(true);
  };
  const updateNO2 = () => {
    setValuee("NO2");
    setInt(moment.NO2.toFixed(0));
    setSit(situation.NO2);
    setMaxval(
      0 < moment.NO2.toFixed(0) && moment.NO2.toFixed(0) <= 10
        ? 10
        : 10 < moment.NO2.toFixed(0) && moment.NO2.toFixed(0) <= 25
        ? 25
        : 25 < moment.NO2.toFixed(0) && moment.NO2.toFixed(0) <= 40
        ? 40
        : 40 < moment.NO2.toFixed(0) && moment.NO2.toFixed(0) <= 60
        ? 60
        : 60 < moment.NO2.toFixed(0) && moment.NO2.toFixed(0) <= 120
        ? 120
        : 300
    );
    setMinval(
      0 < moment.NO2.toFixed(0) && moment.NO2.toFixed(0) <= 10
        ? 0
        : 10 < moment.NO2.toFixed(0) && moment.NO2.toFixed(0) <= 25
        ? 10
        : 25 < moment.NO2.toFixed(0) && moment.NO2.toFixed(0) <= 40
        ? 25
        : 40 < moment.NO2.toFixed(0) && moment.NO2.toFixed(0) <= 60
        ? 40
        : 60 < moment.NO2.toFixed(0) && moment.NO2.toFixed(0) <= 120
        ? 60
        : 120
    );
    setIns(false);
    setUnit("μg/m3");
    setTog(true);
    setChange(true);
    setColor(col.NO2);

    setOpen(true);

    setNo2toggle(true);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateSO2 = () => {
    setValuee("SO2");
    setInt(moment.SO2.toFixed(0));
    setSit(situation.SO2);
    setMaxval(
      0 < moment.SO2.toFixed(0) && moment.SO2.toFixed(0) <= 10
        ? 10
        : 10 < moment.SO2.toFixed(0) && moment.SO2.toFixed(0) <= 15
        ? 15
        : 15 < moment.SO2.toFixed(0) && moment.SO2.toFixed(0) <= 30
        ? 30
        : 30 < moment.SO2.toFixed(0) && moment.SO2.toFixed(0) <= 100
        ? 100
        : 100 < moment.SO2.toFixed(0) && moment.SO2.toFixed(0) <= 250
        ? 250
        : 450
    );
    setMinval(
      0 < moment.SO2.toFixed(0) && moment.SO2.toFixed(0) <= 10
        ? 0
        : 10 < moment.SO2.toFixed(0) && moment.SO2.toFixed(0) <= 15
        ? 10
        : 15 < moment.SO2.toFixed(0) && moment.SO2.toFixed(0) <= 30
        ? 15
        : 30 < moment.SO2.toFixed(0) && moment.SO2.toFixed(0) <= 100
        ? 30
        : 100 < moment.SO2.toFixed(0) && moment.SO2.toFixed(0) <= 250
        ? 100
        : 250
    );
    setIns(false);
    setUnit("μg/m3");
    setTog(true);
    setChange(true);
    setOpen(true);
    setColor(col.SO2);

    setNo2toggle(false);
    setSo2toggle(true);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updatePM2_5 = () => {
    setValuee("PM2_5");
    setInt(moment.PM2_5.toFixed(0));
    setSit(situation.PM2_5);
    setMaxval(
      0 < moment.PM2_5.toFixed(0) && moment.PM2_5.toFixed(0) <= 50
        ? 50
        : 50 < moment.PM2_5.toFixed(0) && moment.PM2_5.toFixed(0) <= 100
        ? 100
        : 100 < moment.PM2_5.toFixed(0) && moment.PM2_5.toFixed(0) <= 180
        ? 180
        : 180 < moment.PM2_5.toFixed(0) && moment.PM2_5.toFixed(0) <= 500
        ? 100
        : 500 < moment.PM2_5.toFixed(0) && moment.PM2_5.toFixed(0) <= 900
        ? 900
        : 900
    );
    setMinval(
      0 < moment.PM2_5.toFixed(0) && moment.PM2_5.toFixed(0) <= 50
        ? 0
        : 50 < moment.PM2_5.toFixed(0) && moment.PM2_5.toFixed(0) <= 100
        ? 50
        : 100 < moment.PM2_5.toFixed(0) && moment.PM2_5.toFixed(0) <= 180
        ? 100
        : 180 < moment.PM2_5.toFixed(0) && moment.PM2_5.toFixed(0) <= 500
        ? 180
        : 500 < moment.PM2_5.toFixed(0) && moment.PM2_5.toFixed(0) <= 900
        ? 500
        : 500
    );
    setOpen(true);
    setIns(false);
    setUnit("μg/m3");
    setTog(true);
    setChange(true);
    setColor(col.PM2_5);

    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(true);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updatePM10 = () => {
    setValuee("PM10");
    setInt(moment.PM10.toFixed(0));
    setSit(situation.PM10);
    setMaxval(
      0 < moment.PM10.toFixed(0) && moment.PM10.toFixed(0) <= 50
        ? 50
        : 50 < moment.PM10.toFixed(0) && moment.PM10.toFixed(0) <= 100
        ? 100
        : 100 < moment.PM10.toFixed(0) && moment.PM10.toFixed(0) <= 180
        ? 180
        : 180 < moment.PM10.toFixed(0) && moment.PM10.toFixed(0) <= 500
        ? 100
        : 500 < moment.PM10.toFixed(0) && moment.PM10.toFixed(0) <= 900
        ? 900
        : 900
    );
    setMinval(
      0 < moment.PM10.toFixed(0) && moment.PM10.toFixed(0) <= 50
        ? 0
        : 50 < moment.PM10.toFixed(0) && moment.PM10.toFixed(0) <= 100
        ? 50
        : 100 < moment.PM10.toFixed(0) && moment.PM10.toFixed(0) <= 180
        ? 100
        : 180 < moment.PM10.toFixed(0) && moment.PM10.toFixed(0) <= 500
        ? 180
        : 500 < moment.PM10.toFixed(0) && moment.PM10.toFixed(0) <= 900
        ? 500
        : 500
    );
    setOpen(true);
    setIns(false);
    setUnit("μg/m3");
    setTog(true);
    setChange(true);
    setColor(col.PM10);

    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(true);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateCO = () => {
    setValuee("CO");
    setInt(moment.CO.toFixed(0));
    setSit(situation.CO);
    setMaxval(
      0 < moment.CO.toFixed(0) && moment.CO.toFixed(0) <= 200
        ? 200
        : 200 < moment.CO.toFixed(0) && moment.CO.toFixed(0) <= 400
        ? 400
        : 400 < moment.CO.toFixed(0) && moment.CO.toFixed(0) <= 1000
        ? 1000
        : 1000 < moment.CO.toFixed(0) && moment.CO.toFixed(0) <= 3000
        ? 3000
        : 3000 < moment.CO.toFixed(0) && moment.CO.toFixed(0) <= 5000
        ? 5000
        : 10000
    );
    setMinval(
      0 < moment.CO.toFixed(0) && moment.CO.toFixed(0) <= 200
        ? 0
        : 200 < moment.CO.toFixed(0) && moment.CO.toFixed(0) <= 400
        ? 200
        : 400 < moment.CO.toFixed(0) && moment.CO.toFixed(0) <= 1000
        ? 400
        : 1000 < moment.CO.toFixed(0) && moment.CO.toFixed(0) <= 3000
        ? 1000
        : 3000 < moment.CO.toFixed(0) && moment.CO.toFixed(0) <= 5000
        ? 3000
        : 5000
    );
    setOpen(true);
    setIns(false);
    setUnit("μg/m3");
    setTog(true);
    setChange(true);
    setColor(col.CO);

    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(true);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateCO2 = () => {
    setValuee("CO2");
    setInt(moment.CO2.toFixed(0));
    setSit(situation.CO2);
    setOpen(true);
    setIns(false);
    setUnit("PPM");
    setTog(true);
    setChange(true);
    setMaxval(
      0 < moment.CO2.toFixed(0) && moment.CO2.toFixed(0) <= 700
        ? 700
        : 700 < moment.CO2.toFixed(0) && moment.CO2.toFixed(0) <= 1100
        ? 1100
        : 1100 < moment.CO2.toFixed(0) && moment.CO2.toFixed(0) <= 1500
        ? 1500
        : 1500 < moment.CO2.toFixed(0) && moment.CO2.toFixed(0) <= 5000
        ? 5000
        : 5000 < moment.CO2.toFixed(0) && moment.CO2.toFixed(0) <= 50000
        ? 50000
        : 50000
    );
    setMinval(
      0 < moment.CO2.toFixed(0) && moment.CO2.toFixed(0) <= 700
        ? 350
        : 700 < moment.CO2.toFixed(0) && moment.CO2.toFixed(0) <= 1100
        ? 700
        : 1100 < moment.CO2.toFixed(0) && moment.CO2.toFixed(0) <= 1500
        ? 1100
        : 1500 < moment.CO2.toFixed(0) && moment.CO2.toFixed(0) <= 5000
        ? 1500
        : 5000 < moment.CO2.toFixed(0) && moment.CO2.toFixed(0) <= 5000
        ? 5000
        : 5000
    );

    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(true);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateVOCS = () => {
    setValuee("VOCS");
    setInt(moment.VOCS.toFixed(0));
    setSit(situation.VOCS);
    setMaxval(
      0 < moment.VOCS.toFixed(0) && moment.VOCS.toFixed(0) <= 100
        ? 100
        : 100 < moment.VOCS.toFixed(0) && moment.VOCS.toFixed(0) <= 1000
        ? 1000
        : 1000 < moment.VOCS.toFixed(0) && moment.VOCS.toFixed(0) <= 3000
        ? 3000
        : 3000 < moment.VOCS.toFixed(0) && moment.VOCS.toFixed(0) <= 35000
        ? 35000
        : 35000 < moment.VOCS.toFixed(0) && moment.VOCS.toFixed(0) <= 70000
        ? 70000
        : 70000
    );
    setMinval(
      0 < moment.VOCS.toFixed(0) && moment.VOCS.toFixed(0) <= 100
        ? 0
        : 100 < moment.VOCS.toFixed(0) && moment.VOCS.toFixed(0) <= 1000
        ? 100
        : 1000 < moment.VOCS.toFixed(0) && moment.VOCS.toFixed(0) <= 3000
        ? 1000
        : 3000 < moment.VOCS.toFixed(0) && moment.VOCS.toFixed(0) <= 35000
        ? 3000
        : 35000 < moment.VOCS.toFixed(0) && moment.VOCS.toFixed(0) <= 70000
        ? 35000
        : 35000
    );
    setOpen(true);
    setIns(false);
    setUnit("NM");
    setTog(true);
    setChange(true);

    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(true);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
    setColor(col.VOCS);
  };
  const updateNH3 = () => {
    setValuee("NH3");
    setInt(moment.NH3);
    setChange(true);
    setMaxval(
      0 <= moment.NH3.toFixed(0) && moment.NH3.toFixed(0) <= 5
        ? 5
        : 5 < moment.NH3.toFixed(0) && moment.NH3.toFixed(0) <= 8
        ? 8
        : 8 < moment.NH3.toFixed(0) && moment.NH3.toFixed(0) <= 16
        ? 16
        : 16 < moment.NH3.toFixed(0) && moment.NH3.toFixed(0) <= 25
        ? 25
        : 25 < moment.NH3.toFixed(0) && moment.NH3.toFixed(0) <= 40
        ? 40
        : 150
    );
    setMinval(
      0 <= moment.NH3.toFixed(0) && moment.NH3.toFixed(0) <= 5
        ? 0
        : 5 < moment.NH3.toFixed(0) && moment.NH3.toFixed(0) <= 8
        ? 5
        : 8 < moment.NH3.toFixed(0) && moment.NH3.toFixed(0) <= 16
        ? 8
        : 16 < moment.NH3.toFixed(0) && moment.NH3.toFixed(0) <= 25
        ? 16
        : 25 < moment.NH3.toFixed(0) && moment.NH3.toFixed(0) <= 40
        ? 25
        : 40
    );
    setIns(false);
    setUnit("μg/m3");
    setTog(true);
    setSit(situation.NH3);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(true);
    setNotoggle(false);
    setO3toggle(false);
    setColor(col.NH3);
    setOpen(true);
  };
  const updateNO = () => {
    setValuee("NO");
    setInt(moment.NO.toFixed(0));
    setChange(true);
    setMaxval(
      0 < moment.NO.toFixed(0) && moment.NO.toFixed(0) <= 20
        ? 20
        : 20 < moment.NO.toFixed(0) && moment.NO.toFixed(0) <= 40
        ? 40
        : 40 < moment.NO.toFixed(0) && moment.NO.toFixed(0) <= 65
        ? 65
        : 65 < moment.NO.toFixed(0) && moment.NO.toFixed(0) <= 250
        ? 250
        : 250 < moment.NO.toFixed(0) && moment.NO.toFixed(0) <= 600
        ? 600
        : 5000
    );
    setMinval(
      0 < moment.NO.toFixed(0) && moment.NO.toFixed(0) <= 20
        ? 0
        : 20 < moment.NO.toFixed(0) && moment.NO.toFixed(0) <= 40
        ? 20
        : 40 < moment.NO.toFixed(0) && moment.NO.toFixed(0) <= 65
        ? 40
        : 65 < moment.NO.toFixed(0) && moment.NO.toFixed(0) <= 250
        ? 250
        : 250 < moment.NO.toFixed(0) && moment.NO.toFixed(0) <= 600
        ? 250
        : 600
    );
    setIns(false);
    setUnit("μg/m3");
    setTog(true);

    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setOpen(true);
    setNh3toggle(false);
    setNotoggle(true);
    setO3toggle(false);
    setColor(col.NO);
    setSit(situation.NO);
  };
  const updateO3 = () => {
    setValuee("O3");
    setIns(false);
    setInt(moment.O3.toFixed(0));
    setChange(true);
    setMaxval(
      0 < moment.O3.toFixed(0) && moment.O3.toFixed(0) <= 20
        ? 20
        : 20 < moment.O3.toFixed(0) && moment.O3.toFixed(0) <= 50
        ? 50
        : 50 < moment.O3.toFixed(0) && moment.O3.toFixed(0) <= 75
        ? 75
        : 75 < moment.O3.toFixed(0) && moment.O3.toFixed(0) <= 100
        ? 100
        : 100 < moment.O3.toFixed(0) && moment.O3.toFixed(0) <= 150
        ? 150
        : 500
    );
    setMinval(
      0 < moment.O3.toFixed(0) && moment.O3.toFixed(0) <= 20
        ? 0
        : 20 < moment.O3.toFixed(0) && moment.O3.toFixed(0) <= 50
        ? 20
        : 50 < moment.O3.toFixed(0) && moment.O3.toFixed(0) <= 75
        ? 50
        : 75 < moment.O3.toFixed(0) && moment.O3.toFixed(0) <= 100
        ? 75
        : 100 < moment.O3.toFixed(0) && moment.O3.toFixed(0) <= 150
        ? 100
        : 150
    );
    setOpen(true);
    setUnit("μg/m3");
    setTog(true);
    setSit(situation.O3);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(true);
    setColor(col.O3);
  };

  const unSelected = () => {
    setOpen(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };

  const unSelected2 = () => {
    setSecondOpen(false);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setPresstoggle(false);
  };

  return (
    <Fragment>
      <div className="paralel_Moment">
        {status == 400 ? (
          <TomorrowPassive
            weather={""}
            color={"white"}
            day={day}
            date={date}
            Header={"Anlık Hava Kalite Değeri Bulunmamaktadır"}
          />
        ) : (
          <div>
            <p className="content_header">Anlık Hava Kalite Değerleri</p>

            <WeatherCard
              weather={moment.WeatherDesc}
              color={
                aqi.AqiDesc == "Hassas"
                  ? "white"
                  : aqi.AqiDesc == "Orta"
                  ? "white"
                  : aqi.AqiDesc == "İyi"
                  ? "white"
                  : aqi.AqiDesc == "Sağlıksız"
                  ? "white"
                  : aqi.AqiDesc == "Kötü"
                  ? "white"
                  : aqi.AqiDesc == "Tehlikeli"
                  ? six
                  : six
              }
              day={day}
              date={date}
              degree={moment.Temp.toFixed(0)}
              image={
                aqi.AqiDesc == "Hassas"
                  ? three
                  : aqi.AqiDesc == "Orta"
                  ? two
                  : aqi.AqiDesc == "İyi"
                  ? one
                  : aqi.AqiDesc == "Sağlıksız"
                  ? four
                  : aqi.AqiDesc == "Kötü"
                  ? five
                  : aqi.AqiDesc == "Tehlikeli"
                  ? six
                  : six
              }
            />

            <AirQualityIndex
              value={aqi.AqiValue}
              max1={500}
              min1={0}
              bgcolor={aqi.AqiColor}
              airSituation={aqi.AqiDesc}
              text={
                aqi.AqiDesc == "Hassas"
                  ? "Hava Kalitesi yaşlılar, çocuklar ve solunum rahatsızlığı olanları etkileyebilecek seviyededir"
                  : aqi.AqiDesc == "Orta"
                  ? "Hava kalitesi sağlık açısından uygun seviyededir"
                  : aqi.AqiDesc == "İyi"
                  ? "Hava kalitesi iyi seviyededir"
                  : aqi.AqiDesc == "Sağlıksız"
                  ? "Herkes sağlık etkileri yaşamaya başlayabilir, hassas gruplar için ciddi sağlık etkileri söz konusu olabilir."
                  : aqi.AqiDesc == "Kötü"
                  ? "Sağlık açısından acil durum oluşturabilir. Nüfusun tamamının etkilenme olasılığı yüksektir."
                  : aqi.AqiDesc == "Tehlikeli"
                  ? "Sağlık alarmı: Herkes daha ciddi sağlık etkileri ile karşılaşabilir."
                  : " Hava Kalitesi sağlık açısından uygun seviyededir"
              }
            />

            <div className="scroll_Moment">
              <div className="column_Moment">
                <MomentList1
                  Detail_Header={valuee}
                  Detail_Value={ins ? moment.Temp.toFixed(0) + "°C" : int}
                  Detail_Text="Bölgedeki değer uygun koşullarda gözükmektedir"
                  Detail_Time={timer}
                  Detail_MAX={maxval}
                  Detail_MIN={minval}
                  Detail_Situation={ins ? situation.Temp : sit}
                  Detail_Unit={unit}
                  Detail_Change={change}
                  Detail_Initial={ins}
                  Detail_In={moment.Temp.toFixed(0)}
                  Detail_Toggle={tog}
                  Detail_Color={color}
                  Detail_Click={unSelected}
                  opened={open}
                  open={open}
                  no2={updateNO2}
                  so2={updateSO2}
                  pm2_5={updatePM2_5}
                  pm10={updatePM10}
                  co={updateCO}
                  co2={updateCO2}
                  vocs={updateVOCS}
                  nh3={updateNH3}
                  no={updateNO}
                  o3={updateO3}
                  co2_pre={co2toggle}
                  no2_pre={no2toggle}
                  so2_pre={so2toggle}
                  pm2_5_pre={pm2_5toggle}
                  pm10_pre={pm10toggle}
                  co_pre={cotoggle}
                  vocs_pre={vocstoggle}
                  nh3_pre={nh3toggle}
                  no_pre={notoggle}
                  o3_pre={o3toggle}
                  WindSpeed={moment.WS}
                  NO2={moment.NO2}
                  SO2={moment.SO2}
                  PM2_5={moment.PM2_5}
                  PM10={moment.PM10}
                  avgCO={moment.CO}
                  avgCO2={moment.CO2}
                  VOCs={moment.VOCS}
                  NH3={moment.NH3}
                  NO={moment.NO}
                  O3={moment.O3}
                />
              </div>
            </div>

            <div className="scroll_Moment1">
              <div className="column_Moment1">
                <MomentList2
                  Detail_Header={secondvaluee}
                  Detail_Value={
                    secondins ? moment.Temp.toFixed(0) + "°C" : secondint
                  }
                  Detail_Text="Bölgedeki değer uygun koşullarda gözükmektedir"
                  Detail_Time={timer}
                  Detail_MAX={secondmaxval}
                  Detail_MIN={secondminval}
                  Detail_Situation={secondins ? situation.Temp : secondsit}
                  Detail_Unit={secondunit}
                  Detail_Change={secondchange}
                  Detail_Initial={secondins}
                  Detail_In={moment.Temp.toFixed(0)}
                  Detail_Toggle={secondtog}
                  Detail_Color={secondcolor}
                  Detail_Click={unSelected2}
                  opened={secondopen}
                  open={secondopen}
                  temp={updateTemp}
                  hum={updateHum}
                  wd={updateWD}
                  ws={updateWS}
                  pres={updatePres}
                  pres_pre={prestoggle}
                  temp_pre={temptoggle}
                  hum_pre={humtoggle}
                  wd_pre={wdtoggle}
                  ws_pre={wstoggle}
                  Temparature={moment.Temp.toFixed(0)}
                  Hummidty={moment.Hum.toFixed(0)}
                  WindSpeed={moment.WS.toFixed(0)}
                  WindDirection={moment.WD.toFixed(0)}
                  Pressure={moment.Pres.toFixed(0)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Moment;
