//@ts-ignore
import ModalVideo from "react-modal-video";
import { store } from "../../../../store";
import { ModalSwitcher } from "../../../../utils/modal-switcher";
import { Props } from "./types";
export const Component = (props: Props) => {
  //@ts-ignore
  const videoId = store.getState().ui.videoId;
  return (
    <div>
      <ModalVideo
        autoplay = {true}
        channel="youtube"
        isOpen={props.showVideoModal}
        videoId={videoId}
        onClose={() => ModalSwitcher.HideVideoModal()}
      />
    </div>
  );
};
