import { useEffect, useState } from "react";
import { MapComponent } from "../../../components/GoogleMapComponent";
import { ButtonComponent } from "../../../components/Shared/ButtonComponent/component";
import { ImageComponent } from "../../../components/Shared/ImageComponent/component";
import { TabComponent } from "../../../components/Shared/TabComponent/component";
import { SliderComponent } from "../../../components/SliderComponent";
import {
  SliderCubeSeries,
  cubeLeftTechicalData,
  cubeRightTechicalData,
} from "../../../constants/datas";
import { CubePointsData } from "../../../constants/mock";
import theme from "../../../constants/theme";
import useWindowDimensions from "../../../services/getWindowSize.service";
import { TranslationService } from "../../../services/translation.service";
import { Contact } from "../../Contact";
import ArrowComponent from "./arrowComponent";
import { Style } from "./style";

function WebPage() {
  const langCheck = localStorage.getItem("lang");
  const t = TranslationService.translate;
  const screenSize = useWindowDimensions().width;
  const marginContainer = screenSize < 1537 ? 142 : 170;
  const TranslationKey = "Cube";
  const [infoContainer, setInfoContainer] = useState(false);
  const allPoints = CubePointsData.points; // adresler ve şehir bağlantısı burada
  const [cubeSelectedPoints, setCubeSelectedPoints] = useState(allPoints);
  const [selectedPoint, setSelectedPoint] = useState(allPoints[0]);
  const [leftOpen, setLeftOpen] = useState(true);
  const cityes = CubePointsData.city;

  const [lang, setLang] = useState(TranslationService.getLang().code);

  const [sliderItem, setSliderItem] = useState(SliderCubeSeries);
  const button = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
        />
      </div>
    );
  };
  //lineHeight:30

  useEffect(() => {
    let xss = sliderItem;
    lang === "tr"
      ? xss.map((item) => {
          item.secondaryIsBtn = true;
        })
      : xss.map((item) => {
          item.secondaryIsBtn = false;
        });

    setSliderItem(xss);
  }, [lang]);

  const firstTabItems = () => {
    return (
      <>
        <SliderComponent sliderItems={sliderItem} />
        {/* meet Slience Start */}
        <div style={{ display: "flex" }}>
          <div style={{ width: "40%", textAlign: "center" }}>
            <ImageComponent
              alt="sercair"
              src={theme.IMAGES.products.cube.whiteCube}
              width={50}
              style={{ marginLeft: 140 }}
            />
          </div>
          <div
            style={{
              width: "60%",
              textAlign: "right",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div style={{ width: screenSize < 1537 ? "80%" : "82%" }}>
              <h3
                style={
                  screenSize < 1537
                    ? {
                        ...Style.meetSlienceEchoStyle,
                        "--data-font-size": "50px",
                        "--data-top": "-45px",
                      }
                    : Style.meetSlienceEchoStyle
                }
                data-text={t(TranslationKey, "meetSlienceEcho")}
                className="textEffectRight"
              >
                {t(TranslationKey, "meetSlienceTitle")}
              </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    window.screen.width < 1500
                      ? t(TranslationKey, "meetSlienceText").replace(
                          /<br\s*\/?>/gi,
                          ""
                        )
                      : t(TranslationKey, "meetSlienceText"),
                }}
              />

              <div
                style={{
                  backgroundImage: `url(${theme.IMAGES.products.cube.orangeIconsBg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "75%",
                  marginLeft: screenSize < 1537 ? 183 : 237,
                  marginTop: 20,
                  padding: 20,
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 69,
                    left: screenSize < 1537 ? 47 : 62,
                  }}
                >
                  {t(TranslationKey, "meetDbMax")}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 69,
                    right: screenSize < 1537 ? 64 : 80,
                  }}
                >
                  {t(TranslationKey, "meetDbMin")}
                </div>
                <ImageComponent
                  alt="sercair"
                  src={theme.IMAGES.products.cube.orangeIcons}
                />
              </div>
            </div>
          </div>
        </div>
        {/* meet Slience End */}
        {/* healthy Slience Start */}
        <div
          className="d-flex"
          style={{ marginLeft: marginContainer, marginTop: 100 }}
        >
          <div style={{ width: "30%" }}>
            <h3
              style={
                screenSize < 1537
                  ? {
                      ...Style.healthySlienceEchoStyle,
                      "--data-font-size": "50px",
                      "--data-top": "-45px",
                    }
                  : Style.healthySlienceEchoStyle
              }
              data-text={t(TranslationKey, "healthySlienceEcho")}
              className="textEffectLeft"
            >
              {t(TranslationKey, "healthySlienceTitle")}
            </h3>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  window.screen.width < 1500
                    ? t(TranslationKey, "healthySlienceText").replace(
                        /<br\s*\/?>/gi,
                        ""
                      )
                    : t(TranslationKey, "healthySlienceText"),
              }}
            />
            {button(t(TranslationKey, "getContact"), "/contact", true)}
          </div>
          <ImageComponent
            alt="sercair"
            width={15}
            src={theme.IMAGES.products.cube.healthy1}
          />
          <ImageComponent
            alt="sercair"
            width={15}
            src={theme.IMAGES.products.cube.healthy2}
          />
          <ImageComponent
            alt="sercair"
            width={30}
            src={theme.IMAGES.products.cube.healthy3}
          />
        </div>
        {/* healthy Slience End */}

        {/* Start Pratic Usage */}
        <div
          style={{
            display: "flex",
            marginLeft: marginContainer,
            marginTop: 100,
          }}
        >
          <div
            style={{
              width: "54%",
              textAlign: "center",
              display: "flex",
              zIndex: 2,
            }}
          >
            <ImageComponent
              alt="sercair"
              width={35}
              style={{ objectFit: "contain" }}
              src={theme.IMAGES.products.cube.praticUsage1}
            />
            <ImageComponent
              alt="sercair"
              width={39.8}
              style={{ objectFit: "contain" }}
              src={theme.IMAGES.products.cube.praticUsage2}
            />
            <ImageComponent
              alt="sercair"
              width={32}
              style={{ objectFit: "contain" }}
              src={theme.IMAGES.products.cube.praticUsage3}
            />
          </div>
          <div
            style={{
              width: "40%",
              textAlign: "right",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "40%",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                backgroundColor: theme.COLORS.cubeContainer,
                padding: 30,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                marginRight: 60,
              }}
            >
              <h3>{t(TranslationKey, "praticUsageTitle")}</h3>
              <p
                style={{ paddingLeft: window.screen.width < 1500 ? 20 : 0 }}
                dangerouslySetInnerHTML={{
                  __html:
                    window.screen.width < 1500
                      ? t(TranslationKey, "praticUsageText").replace(
                          /<br\s*\/?>/gi,
                          ""
                        )
                      : t(TranslationKey, "praticUsageText"),
                }}
              />
            </div>
          </div>
        </div>

        {/* End Protic Usage */}
        {/* icon side ergonomic */}
        <div
          style={{
            display: "flex",
            marginLeft: marginContainer,
            marginTop: 100,
            marginRight: 104,
          }}
        >
          <div
            style={{
              width: "40%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "60%",
                backgroundColor: theme.COLORS.cubeContainer,
                padding: 30,
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,

                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="d-flex">
                <ImageComponent
                  alt="sercair"
                  width={10}
                  style={{ marginRight: 20 }}
                  src={theme.IMAGES.products.cube.con1}
                />
                <p
                  style={{ fontSize: 15 }}
                  className="d-flex align-items-center"
                  dangerouslySetInnerHTML={{
                    __html:
                      window.screen.width < 1500
                        ? t(TranslationKey, "icoText1").replace(
                            /<br\s*\/?>/gi,
                            ""
                          )
                        : t(TranslationKey, "icoText1"),
                  }}
                />
              </div>
              <div className="d-flex my-4">
                <ImageComponent
                  alt="sercair"
                  width={10}
                  style={{ marginRight: 20 }}
                  src={theme.IMAGES.products.cube.con2}
                />
                <p
                  style={{ fontSize: 15 }}
                  className="d-flex align-items-center"
                  dangerouslySetInnerHTML={{
                    __html:
                      window.screen.width < 1500
                        ? t(TranslationKey, "icoText2").replace(
                            /<br\s*\/?>/gi,
                            ""
                          )
                        : t(TranslationKey, "icoText2"),
                  }}
                />
              </div>
              <div className="d-flex">
                <ImageComponent
                  alt="sercair"
                  width={10}
                  style={{ marginRight: 20 }}
                  src={theme.IMAGES.products.cube.con3}
                />
                <p
                  style={{ fontSize: 15 }}
                  className="d-flex align-items-center"
                  dangerouslySetInnerHTML={{
                    __html:
                      window.screen.width < 1500
                        ? t(TranslationKey, "icoText3").replace(
                            /<br\s*\/?>/gi,
                            ""
                          )
                        : t(TranslationKey, "icoText3"),
                  }}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              width: "54%",
              textAlign: "center",
              display: "flex",
              zIndex: 2,
            }}
          >
            <ImageComponent
              alt="sercair"
              src={theme.IMAGES.products.cube.laptop}
            />
          </div>
        </div>

        {/* icon side ergonomic */}

        {/* Start Air Flow */}
        <div
          className="d-flex flex-column align-items-center"
          style={{ marginTop: 115 }}
        >
          <h3
            style={Style.airFlowEchoStyle}
            data-text={t(TranslationKey, "airFlow")}
            className="textEffectCenter"
          >
            {t(TranslationKey, "airFlowTitle")}
          </h3>
          <div
            style={{
              backgroundImage: `url(${theme.IMAGES.products.cube.airFlow})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: 1000,
              height: 500,
              marginTop: 50,
              position: "relative",
              zIndex: 2,
            }}
          >
            {/* <div style={{ position: "absolute", bottom: 0, right: -70 }}>
              {button(t(TranslationKey, "airFlowWatch"), "/contact", true)}
            </div> */}
          </div>
        </div>
        {/* End Air Flow */}

        {/* Start high air quality */}
        <div
          className="d-flex"
          style={{
            marginLeft: marginContainer,
            marginTop: 150,
            marginRight: 184,
          }}
        >
          <div>
            <h3
              style={Style.highAirQuality}
              data-text={t(TranslationKey, "highAirQualityEco")}
              className="textEffectLeft"
              dangerouslySetInnerHTML={{
                __html: t(TranslationKey, "highAirQualityTitle"),
              }}
            />
            <p style={{ width: 487, marginTop: 100, lineHeight: "30px" }}>
              {t(TranslationKey, "highAirQualityText")}
            </p>
            {button(
              t(TranslationKey, "highAirQualityButton"),
              langCheck === "tr"
                ? "/tr/urunler/hava-temizleyici/moniway"
                : langCheck === "nl"
                ? "/nl/producten/luchtkwaliteit/moniway"
                : langCheck === "rs"
                ? "/rs/proizvodi/kvalitet-vazduha/moniway"
                : langCheck === "fr"
                ? "/fr/produits/qualite-de-air/moniway"
                : langCheck === "uk"
                ? "/uk/products/AirQuality/moniway"
                : langCheck === "ro"
                ? "/ro/produse/calitatea-aerului/moniway"
                : "/products/AirQuality/moniway",
              // "/products/AirQuality/moniway",
              true
            )}
          </div>
          <ImageComponent
            alt="sercair"
            style={{ marginTop: 10, marginLeft: 100 }}
            src={theme.IMAGES.products.cube.highAirQuality}
            width={35}
          />
        </div>
        {/* End high air quality */}

        {/* Start sterilization */}
        <div className="d-flex" style={{ marginTop: 100 }}>
          <ImageComponent
            alt="sercair"
            src={theme.IMAGES.products.cube.sterilization}
            width={50}
          />
          <div
            style={{
              textAlign: "right",
              marginLeft: screenSize < 1537 ? 64 : 100,
              marginTop: 100,
            }}
          >
            <h3
              style={Style.sterillization}
              data-text={t(TranslationKey, "sterilizationEco")}
              className="textEffectRight"
              dangerouslySetInnerHTML={{
                __html: t(TranslationKey, "sterilizationTitle"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t(TranslationKey, "sterilizationText"),
              }}
              style={{ width: 500, marginTop: 25, lineHeight: "30px" }}
            ></p>
          </div>
        </div>
        {/* End sterilization */}

        {/* Start Rezervation */}
        <div className="d-flex" style={{ marginLeft: marginContainer }}>
          <div style={{ textAlign: "left", marginLeft: 100, marginTop: 100 }}>
            <h3
              style={Style.rezervation}
              data-text={t(TranslationKey, "rezervationEco")}
              className="textEffectLeft"
              dangerouslySetInnerHTML={{
                __html: t(TranslationKey, "rezervationTitle"),
              }}
            />
            <p
              style={{ width: 487, marginTop: 25 }}
              dangerouslySetInnerHTML={{
                __html: t(TranslationKey, "rezervationText"),
              }}
            />
          </div>
          <ImageComponent
            alt="sercair"
            src={theme.IMAGES.products.cube.rezervation}
            width={50}
          />
        </div>
        {/* End Rezervation */}

        <div className="d-flex container">
          <ImageComponent
            alt="sercair"
            src={theme.IMAGES.products.cube.app1}
            width={33}
          />
          <ImageComponent
            alt="sercair"
            src={theme.IMAGES.products.cube.app2}
            width={33}
          />
          <ImageComponent
            alt="sercair"
            src={theme.IMAGES.products.cube.app3}
            width={33}
          />
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ marginBottom: 50 }}
        >
          <ImageComponent
            alt="sercair"
            src={theme.IMAGES.products.cube.lastImageLeft}
            width={40}
            style={{ marginRight: 25, marginLeft: 25 }}
          />
          <ImageComponent
            alt="sercair"
            src={theme.IMAGES.products.cube.lastImageRight}
            width={40}
          />
        </div>
      </>
    );
  };

  const techTabItems = () => {
    return (
      <div style={{ marginLeft: marginContainer }}>
        <div className="d-flex" style={{ marginTop: 120, marginBottom: 150 }}>
          <div
            style={{
              position: "relative",
              marginLeft: window.screen.width < 1500 ? -37 : 150,
            }}
          >
            <ImageComponent
              alt="sercair"
              src={theme.IMAGES.products.cube.tech1}
              style={{
                display: "inline-block",
                position: "ralative" as "relative",
                zIndex: 2,
                maxHeight: "100%",
                width: 430,
                height: 670,
              }}
            />
            {/* Component Arrows Here */}

            <ArrowComponent arrowData={cubeLeftTechicalData} />
          </div>
          <div style={{ position: "relative", marginLeft: 60 }}>
            <ImageComponent
              alt="sercair"
              src={theme.IMAGES.products.cube.tech2}
              width={40}
              style={{
                marginTop: 161,
                marginLeft: 220,
                display: "inline-block",
                position: "ralative" as "relative",
                zIndex: 2,
                maxHeight: "100%",
                width: 481,
                height: 306,
              }}
            />
            <ArrowComponent
              arrowData={cubeRightTechicalData}
              left={-45}
              top={-157}
            />
          </div>
        </div>
        <div className="d-flex" style={{ marginBottom: 150, marginLeft: 60 }}>
          <div style={{ width: "50%" }}>
            <ImageComponent
              alt="sercair"
              width={120}
              style={{ marginLeft: -100 }}
              src={theme.IMAGES.products.cube.tech3}
            />
          </div>
          <div
            style={{
              width: "50%",
              textAlign: "right",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ width: "60%" }}>
              <h2 style={{ marginBottom: 50, fontSize: "1.5rem" }}>
                {t(TranslationKey, "techProps")}
              </h2>
              <h3 style={{ fontSize: "1.3rem" }}>
                {t(TranslationKey, "techProps1Title")}
              </h3>
              <div style={{ fontSize: "1rem", marginBottom: 35 }}>
                {t(TranslationKey, "techProps1Text")}
              </div>
              <h3 style={{ fontSize: "1.3rem" }}>
                {t(TranslationKey, "techProps2Title")}
              </h3>
              <div style={{ fontSize: "1rem", marginBottom: 35 }}>
                {t(TranslationKey, "techProps2Text")}
              </div>
              <h3 style={{ fontSize: "1.3rem" }}>
                {t(TranslationKey, "techProps3Title")}
              </h3>
              <div style={{ fontSize: "1rem", marginBottom: 35 }}>
                {t(TranslationKey, "techProps3Text")}
              </div>
              <h3 style={{ fontSize: "1.3rem" }}>
                {t(TranslationKey, "techProps4Title")}
              </h3>
              <div style={{ fontSize: "1rem" }}>
                {t(TranslationKey, "techProps4Text")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const secondTabItems = () => {
    return (
      <div
        tabIndex={-1}
        className="container"
        style={{ position: "relative", width: "100%" }}
      >
        {/* map top side */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "calc(100% - 24px)",
            margin: "0 12px",
            height: "10%",
            backgroundColor: theme.COLORS.blueContainer,
            zIndex: 50,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ImageComponent
            alt="sercair"
            src={theme.IMAGES.products.cube.mapIcon}
            width={3}
            onClick={() => {
              setLeftOpen(!leftOpen);
            }}
            style={{
              marginTop: 11,
              marginLeft: 25,
              cursor: "pointer",
              rotate: !leftOpen ? "0deg" : "180deg",
              transition: "all 0.5s",
            }}
          />
          <ImageComponent
            onClick={() => {
              //gm-fullscreen-control triggered
              let fullscreenControl = document.getElementsByClassName(
                "gm-fullscreen-control"
              )[0] as HTMLElement;
              //remove background color
              fullscreenControl.style.backgroundColor = "transparent";
              //fullscreenControl change background image as theme.IMAGES.products.cube.mapIcon
              fullscreenControl.style.backgroundImage = `url(${theme.IMAGES.products.cube.fullScreen})`;
              //no repeat
              fullscreenControl.style.backgroundRepeat = "no-repeat";

              //delete all img tag in fullscreenControl
              let imgTag = fullscreenControl.getElementsByTagName("img");
              while (imgTag.length > 0) {
                imgTag[0].parentNode!.removeChild(imgTag[0]);
              }

              //trigger click event
              fullscreenControl.click();
            }}
            alt="sercair"
            src={theme.IMAGES.products.cube.fullScreen}
            width={3}
            style={{ marginTop: 11, marginRight: 25, cursor: "pointer" }}
          />
        </div>
        {/* map left side */}
        {leftOpen && (
          <div
            style={
              infoContainer
                ? {
                    position: "absolute",
                    bottom: 0,
                    left: 12,
                    width: "25%",
                    height: "calc(60%)",
                    backgroundColor: theme.COLORS.white,
                    zIndex: 50,
                    display: "flex",
                    flexDirection: "column",
                    transition: "all 0.5s",
                  }
                : {
                    position: "absolute",
                    top: "10%",
                    left: 12,
                    width: "25%",
                    height: "calc(90%)",
                    backgroundColor: theme.COLORS.white,
                    zIndex: 50,
                    display: "flex",
                    flexDirection: "column",
                  }
            }
          >
            <div
              style={{
                height: infoContainer ? "15%" : "10%",
                backgroundColor: theme.COLORS.blue,
                display: "flex",
                alignItems: "center",
                paddingLeft: infoContainer ? 10 : 30,
                color: "white",
              }}
            >
              <b>
                {infoContainer ? (
                  <div className="d-flex">
                    <ImageComponent
                      alt="sercair"
                      src={theme.IMAGES.products.cube.backArrow}
                      width={5}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setInfoContainer(!infoContainer);
                      }}
                    />
                    <div style={{ marginLeft: 10 }}>{selectedPoint.name}</div>
                  </div>
                ) : (
                  t(TranslationKey, "cubeMapTitle")
                )}
              </b>
            </div>

            {infoContainer ? (
              <div style={{ fontSize: 15 }}>
                <div style={{ marginTop: 25 }}>
                  <h6 style={{ color: "rgb(187 187 187)" }}>
                    {t(TranslationKey, "name")}
                  </h6>
                  <div>{selectedPoint.name}</div>
                </div>
                <div style={{ marginTop: 25 }}>
                  <h6 style={{ color: "rgb(187 187 187)" }}>
                    {t(TranslationKey, "location")}
                  </h6>
                  <div>{selectedPoint.address}</div>
                </div>
                <div style={{ marginTop: 25 }}>
                  <h6 style={{ color: "rgb(187 187 187)" }}>
                    {t(TranslationKey, "locationGoogle")}
                  </h6>
                  <a
                    target="_blank"
                    href={
                      "https://google.com/maps/search/Adres:" +
                      selectedPoint.address
                    }
                    rel="noreferrer"
                  >
                    <div style={{ color: theme.COLORS.blue }}>
                      {t(TranslationKey, "locationGoogleText")}
                    </div>
                  </a>
                </div>
              </div>
            ) : (
              cityes.map((city, index) => {
                return (
                  <div key={"mainContainerCity" + index}>
                    <>
                      <div
                        style={{
                          backgroundColor: theme.COLORS.cubeMapBg,
                          height: 45,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            color: theme.COLORS.cubeMapCityColor,
                            paddingLeft: 25,
                          }}
                        >
                          {city.name}
                        </div>
                      </div>
                      {/* get allPoints (array) by cityes */}
                      {allPoints.map((point, index) => {
                        return (
                          point.cityCode === city.code && (
                            <div
                              className={"mainContainerPoints" + index}
                              key={"mainContainerPoints" + index}
                              style={{
                                paddingLeft: 25,
                                fontSize: 12,
                                margin: "5px 0",
                                cursor: "pointer",
                                fontWeight: "normal",
                              }}
                              onMouseOut={() => {
                                let mainContainerPoints =
                                  document.getElementsByClassName(
                                    "mainContainerPoints" + index
                                  )[0] as HTMLElement;
                                mainContainerPoints.style.fontWeight = "normal";
                              }}
                              onMouseOver={() => {
                                let mainContainerPoints =
                                  document.getElementsByClassName(
                                    "mainContainerPoints" + index
                                  )[0] as HTMLElement;
                                mainContainerPoints.style.fontWeight = "bold";
                              }}
                              onClick={() => {
                                setSelectedPoint(point);
                                setInfoContainer(!infoContainer);
                              }}
                            >
                              {point.name}
                            </div>
                          )
                        );
                      })}
                    </>
                  </div>
                );
              })
            )}
          </div>
        )}
        <MapComponent
          isIconSpecial={true}
          pointModel={cubeSelectedPoints}
          pointModelAll={allPoints}
          selectedCity={["", "all"]}
          selectedCountry={["", "all"]}
          selectedDistrict={["", "all"]}
          selectedDarkTheme={true}
          setSelectedPoint={setSelectedPoint}
          setInfoContainer={setInfoContainer}
        />
      </div>
    );
  };

  const thirdTabItems = () => {
    return (
      <div style={{ marginTop: -40 }}>
        <Contact />
      </div>
    );
  };

  const children = [
    {
      title: t("Cube", "tabTitle1"),
      body: <div style={{ backgroundColor: "white" }}>{firstTabItems()}</div>,
    },
    // {
    //   title: t("Cube", "tabTitle2"),
    //   body: <div style={{ backgroundColor: "white" }}>{secondTabItems()}</div>,
    // },
    {
      title: t("Cube", "tabTitle3"),
      body: <div style={{ backgroundColor: "white" }}>{thirdTabItems()}</div>,
    },
    {
      title: t("Cube", "tabTitle4"),
      body: <div style={{ backgroundColor: "white" }}>{techTabItems()}</div>,
    },
  ];
  useEffect(() => {
    setLang(TranslationService.getLang().code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TranslationService.getLang().code]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {" "}
      <TabComponent children={children} componentKey={"PureSeries"} />
    </div>
  );
}

export default WebPage;
