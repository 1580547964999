import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { APIURLs } from "../../API/consts/APIURLs";
import { VideosService } from "../../API/services/Videos.service";
import { CardGroupComponent } from "../../components/Shared/CardComponent/component";
import theme, { positions } from "../../constants/theme";
import { VideoCardModel } from "../../models/videoCard.model";
import { TranslationService } from "../../services/translation.service";
import { Props } from "./types";

export function ScreenComponent(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "Videos";

  const [videoCards, setVideoCards] = useState<VideoCardModel[]>([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    VideosService.getVideos(TranslationService.getLang().code).then((res) => {
      let data = res.data;
      data.forEach((item: any) => {
        item.keyComponent = "Videos";
        item.btnText = "moreInfo";
        item.text = "";
        item.imgUrl = APIURLs.MEDIA_URL + "videoCard/" + item.imgUrl;
      });
      setVideoCards(data);
      setVideoCards([{ title: "titleVideos", cards: res.data }]);
    });
  }, [TranslationService.getLang()]);

  const button = (
    text: string,
    url: string,
    isLong?: boolean,
    position?: positions
  ) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: theme.SPACES.large,
        }}
      >
        {/* <ButtonComponent
          url={t(translationKey, url)}
          text={text}
          isLong={isLong}
          position={position}
        /> */}
      </div>
    );
  };

  return (
    <Container style={{ marginTop: 60, marginBottom: 100, maxWidth: "80vw" }}>
      <div className="d-flex align-items-center">
        <img
          className="mobile-img"
          src={theme.IMAGES.videos.title}
          alt={"sercair"}
        />
        <h2
          style={{ color: theme.COLORS.blue }}
          className="ms-4 gothamMedium mobile-font"
        >
          {t(translationKey, "titleMain")}
        </h2>
      </div>
      {videoCards &&
        videoCards.map((videoCard, index) => {
          return (
            <div key={"videoContainer" + index} style={{ marginTop: 50 }}>
              <div className="d-flex align-items-center">
                <h3
                  style={{ color: theme.COLORS.blue }}
                  className="ms-4 gothamMedium"
                >
                  {t(translationKey, videoCard.title)}
                </h3>
              </div>
              <div className="row">
                {videoCard.cards.map((card, index) => {
                  return (
                    <div
                      key={"videoCard" + index}
                      className={
                        window.screen.width < 1400
                          ? "col-sm-12 col-md-12 col-lg-4 col-xl-4"
                          : "col-sm-12 col-md-12 col-lg-4 col-xl-4"
                      }
                      style={{ marginTop: 50 }}
                    >
                      <CardGroupComponent
                        key={"videoCard" + index}
                        isAPI={true}
                        cards={[card]}
                        buttonComponent={button}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </Container>
  );
}
