import { DropdownModel } from "../models/dropdown.model";
import { cityModel, countryModel, districtModel, } from "../models/salesPoints.model";

export const dropdownArrayModelGenerator = (
  list: Array<any>,
  labelKey: string,
  valueKey: string
): DropdownModel[] => {
  const dropdownModelList = [] as DropdownModel[];
  list.forEach((item) => {
    dropdownModelList.push({
      label: item[labelKey],
      value: item[valueKey],
    });
  });
  return dropdownModelList;
};

export const dropdownCountryModelGenerator = (
  list: Array<countryModel>,
): DropdownModel[] => {
  const dropdownModelList = [] as DropdownModel[];
  list.forEach((item) => {
    dropdownModelList.push({
      label: item.name,
      value: item.code,
      all: item,
    });
  });
  return dropdownModelList;
};

export const dropdownCityesodelGenerator = (
  list: Array<cityModel>,
): DropdownModel[] => {
  const dropdownModelList = [] as DropdownModel[];
  list.forEach((item) => {
    dropdownModelList.push({
      label: item.name,
      value: item.code,
      all: item,
    });
  });
  return dropdownModelList;
};


export const dropdownDistrictsModelGenerator = (
  list: Array<districtModel>,
): DropdownModel[] => {
  const dropdownModelList = [] as DropdownModel[];
  list.forEach((item) => {
    dropdownModelList.push({
      label: item.name,
      value: item.code,
      all: item,
    });
  });
  return dropdownModelList;
};


 