import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APIURLs } from "./API/consts/APIURLs";
import { OpeningsPopUpService } from "./API/services/OpeningsPopUp.service";
import { ModalStack } from "./components/ModalStack";
import "./index.css";
import "./index.scss";
import "./lang.css";
import { OpeningImg } from "./models/openingImg.model";
import { AppRouter } from "./navigations";
import useWindowDimensions from "./services/getWindowSize.service";
import {
  getItemsSessionStorage,
  setItemsSessionStorage,
} from "./services/session.service";
import { TranslationService } from "./services/translation.service";
import { ModalSwitcher } from "./utils/modal-switcher";
import { ScreenStateSwither } from "./utils/screen-switcher";

function App() {
  const windowSize = useWindowDimensions().width;
  const [openingImgModal, setOpeningImgModal] = useState<OpeningImg>();
  useEffect(() => {
    windowSize < 1200
      ? ScreenStateSwither.isMobile()
      : ScreenStateSwither.isDesktop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    OpeningsPopUpService.getOpeningIMGs(TranslationService.getLang().code).then(
      (res) => {
        let modals = res.data.filter((item: any) => item.show === true);
        //get modals show true
        // eslint-disable-next-line array-callback-return
        setOpeningImgModal({
          imgUrl: modals && modals[0].imgUrl,
          imgAlt: modals && modals[0].alt,
          show: modals && modals.length > 0 ? true : false,
        });
      }
    );
  }, [TranslationService.getLang()]);

  useEffect(() => {
    openingImgModal && openingModalController();
  }, [openingImgModal]);

  async function openingModalController() {
    const modal = await getItemsSessionStorage(
      "modalOpened" + TranslationService.getLang().code
    );
    if (modal === null) {
      ModalSwitcher.ShowOpeningModal(
        openingImgModal
          ? APIURLs.MEDIA_URL + "openingImage/" + openingImgModal.imgUrl
          : "",
        openingImgModal ? openingImgModal.imgAlt : ""
      );
      setItemsSessionStorage(
        "modalOpened" + TranslationService.getLang().code,
        {
          modalOpened: true,
          lang: TranslationService.getLang().code,
        }
      );
    }
  }
  return (
    <div className="pad-mar">
      {/* <Cookie/> */}

      <ModalStack />

      <ToastContainer />

      <AppRouter />
    </div>
  );
}

export default App;
