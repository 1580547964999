import { connect } from "react-redux";
import { Component } from "./component";
import { GlobalState } from "../../../../store/state";

const mapStateToProps = (state: GlobalState) => {
  return {
    lang: state.ui.lang,
    showVideoModal: state.ui.showVideoModal,
    videoId: state.ui.videoId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const VideoModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
