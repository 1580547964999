import React, { useState, useEffect } from "react";

import Button from "../Button/Button";

import Sonbahar_Gri from "../../../assets/images/WeatherImages/SVG/Sonbahar_Gri.svg";
import Kis_Gri from "../../../assets/images/WeatherImages/SVG/Kis_Gri.svg";
import Bahar_Gri from "../../../assets/images/WeatherImages/SVG/Bahar_Gri.svg";
import Yaz_Gri from "../../../assets/images/WeatherImages/SVG/Yaz_Gri.svg";

import Sonbahar_Beyaz from "../../../assets/images/WeatherImages/SVG/Sonbahar_Beyaz.svg";
import Kis_Beyaz from "../../../assets/images/WeatherImages/SVG/Kis_Beyaz.svg";
import Bahar_Beyaz from "../../../assets/images/WeatherImages/SVG/Bahar_Beyaz.svg";
import Yaz_Beyaz from "../../../assets/images/WeatherImages/SVG/Yaz_Beyaz.svg";

import AutumnDisabled from "../../../assets/images/WeatherImages/SVG/AutumnDisabled.svg";
import SpringDisabled from "../../../assets/images/WeatherImages/SVG/SpringDisabled.svg";
import SummerDisabled from "../../../assets/images/WeatherImages/SVG/SummerDisabled.svg";
import WinterDisabled from "../../../assets/images/WeatherImages/SVG/WinterDisabled.svg";
import "./YearlySummary.css";

function YearlySummary({
  chooseMessage,
  isAutumn,
  isSpring,
  isWinter,
  isSummer,
  aqi,
}) {
  let msg = "Autumn";
  let msg1 = "Winter";
  let msg2 = "Spring";
  let msg3 = "Summer";

  const current = new Date();
  // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()}`;

  var season = require("date-season")();

  const Sezon = season(date);

  const [changePhotoS, setChangePhotoS] = useState(
    Sezon == "Autumn" ? true : isAutumn ? true : false
  );
  const [changePhotoI, setChangePhotoI] = useState(
    Sezon == "Spring" ? false : isSpring ? false : true
  );
  const [changePhotoK, setChangePhotoK] = useState(
    Sezon == "Winter" ? true : isWinter ? true : false
  );
  const [changePhotoY, setChangePhotoY] = useState(
    Sezon == "Summer" ? true : isSummer ? true : false
  );

  const [seasn, setSeasn] = useState(
    Sezon == "Autumn"
      ? "Sonbahar"
      : Sezon == "Winter"
      ? "Kış"
      : Sezon == "Spring"
      ? "İlkbahar"
      : "Yaz"
  );

  useEffect(() => {
    let msg5 =
      Sezon == "Autumn"
        ? "Autumn"
        : Sezon == "Winter"
        ? "Winter"
        : Sezon == "Spring"
        ? "Spring"
        : "Summer";

    chooseMessage(msg5);
  }, []);

  const toggleAu = () => {
    setChangePhotoS(false);
    setChangePhotoK(true);
    setChangePhotoI(true);
    setChangePhotoY(true);
    //setSe("Autumn")
    chooseMessage(msg);
    setSeasn("Sonbahar");
  };
  const toggleWi = () => {
    setChangePhotoS(true);
    setChangePhotoK(false);
    setChangePhotoI(true);
    setChangePhotoY(true);
    // setSe("Winter")
    chooseMessage(msg1);
    setSeasn("Kış");
  };

  const toggleSi = () => {
    setChangePhotoS(true);
    setChangePhotoK(true);
    setChangePhotoI(false);
    setChangePhotoY(true);
    //setSe("Spring")
    chooseMessage(msg2);
    setSeasn("İlkbahar");
  };
  const toggleSu = () => {
    setChangePhotoS(true);
    setChangePhotoK(true);
    setChangePhotoI(true);
    setChangePhotoY(false);
    // setSe("Summer")
    chooseMessage(msg3);
    setSeasn("Yaz");
  };

  const sonbaharImg = changePhotoS ? Sonbahar_Gri : Sonbahar_Beyaz;
  const KisImg = changePhotoK ? Kis_Gri : Kis_Beyaz;
  const BaharImg = changePhotoI ? Bahar_Gri : Bahar_Beyaz;
  const YazImg = changePhotoY ? Yaz_Gri : Yaz_Beyaz;
  return (
    <div className="yearlyContainer">
      <div
        style={{
          justifyContent: "center",
          flexDirection: "row",
          display: "flex",
          marginBottom: -25,
          justifyContent: "space-evenly",
        }}
      >
        {isAutumn ? (
          <Button type="button" onClick={toggleAu} pressable={true}>
            <div>
              {" "}
              {changePhotoS && isAutumn ? (
                <img
                  style={{
                    width: 65,
                    height: 65,
                    resize: "contain",
                    display: "flex",
                    padding: 10,
                  }}
                  src={sonbaharImg}
                />
              ) : (
                <img
                  style={{
                    width: 65,
                    height: 65,
                    resize: "contain",
                    display: "flex",
                    padding: 7,
                  }}
                  src={Sonbahar_Beyaz}
                />
              )}
            </div>
          </Button>
        ) : (
          <div>
            <img
              style={{
                width: 65,
                height: 65,
                resize: "contain",
                display: "flex",
                padding: 10,
              }}
              src={AutumnDisabled}
            />
          </div>
        )}
        {isWinter ? (
          <Button type="button" onClick={toggleWi} pressable={true}>
            <div>
              {changePhotoK && isWinter ? (
                <img
                  style={{
                    width: 65,
                    height: 65,
                    resize: "contain",
                    display: "flex",
                    padding: 10,
                  }}
                  src={KisImg}
                />
              ) : (
                <img
                  style={{
                    width: 65,
                    height: 65,
                    resize: "contain",
                    display: "flex",
                    padding: 7,
                  }}
                  src={KisImg}
                />
              )}
            </div>
          </Button>
        ) : (
          <div>
            <img
              style={{
                width: 65,
                height: 65,
                resize: "contain",
                display: "flex",
                padding: 10,
              }}
              src={WinterDisabled}
            />
          </div>
        )}
        {isSpring ? (
          <Button type="button" onClick={toggleSi} pressable={true}>
            <div>
              {" "}
              {changePhotoI && isSpring ? (
                <img
                  style={{
                    width: 65,
                    height: 65,
                    resize: "contain",
                    display: "flex",
                    padding: 10,
                  }}
                  src={BaharImg}
                />
              ) : (
                <img
                  style={{
                    width: 65,
                    height: 65,
                    resize: "contain",
                    display: "flex",
                    padding: 7,
                  }}
                  src={BaharImg}
                />
              )}
            </div>
          </Button>
        ) : (
          <div>
            <img
              style={{
                width: 65,
                height: 65,
                resize: "contain",
                display: "flex",
                padding: 10,
              }}
              src={SpringDisabled}
            />
          </div>
        )}
        {isSummer ? (
          <Button type="button" onClick={toggleSu} pressable={true}>
            <div>
              {changePhotoY && isSummer ? (
                <img
                  style={{
                    width: 65,
                    height: 65,
                    resize: "contain",
                    display: "flex",
                    padding: 10,
                  }}
                  src={YazImg}
                />
              ) : (
                <img
                  style={{
                    width: 65,
                    height: 65,
                    resize: "contain",
                    display: "flex",
                    padding: 7,
                  }}
                  src={YazImg}
                />
              )}
            </div>
          </Button>
        ) : (
          <div>
            <img
              style={{
                width: 65,
                height: 65,
                resize: "contain",
                display: "flex",
                padding: 10,
              }}
              src={SummerDisabled}
            />
          </div>
        )}
      </div>

      <div className="yearlyHeader">Mevsimsel Hava Kalitesi Özeti</div>
      <div className="yearlyText">
        {" "}
        {aqi == "Hassas"
          ? "Hava Kalitesi yaşlılar, çocuklar ve solunum rahatsızlığı olanları etkileyebilecek seviyededir"
          : aqi == "Orta"
          ? "Hava kalitesi sağlık açısından uygun seviyededir"
          : aqi == "İyi"
          ? "Hava kalitesi iyi seviyededir"
          : aqi == "Sağlıksız"
          ? "Sağlıksız"
          : aqi == "Kötü"
          ? "Kötü"
          : aqi == "Tehlikeli"
          ? "Tehlikeli"
          : " Hava Kalitesi sağlık açısından uygun seviyededir"}
      </div>
    </div>
  );
}

export default YearlySummary;
