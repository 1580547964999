import { useState, useEffect } from "react";
import { Button, CloseButton, Modal } from "react-bootstrap";
import { langs, Lang } from "../../../../constants/langs";
import { TranslationService } from "../../../../services/translation.service";
import {
  dropdownArrayModelGenerator,
} from "../../../../utils/string";
import { DropdownComponent } from "../../DropdownComponent/component"; 
import { store } from "../../../../store";
import { Style } from "./style";
import "./style.css";
import { Props } from "./types";
import { ModalSwitcher } from "../../../../utils/modal-switcher";



export function Component(props: Props) {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
  }, []);
  const t = TranslationService.translate;
  const languagesModeled = dropdownArrayModelGenerator(
    langs,
    "display",
    "code"
  );
  
  return (
    <>
      <Modal centered show={props.showLanguageModal}>
        <div className="modal-bg" style={Style.backGround}>
          <Modal.Header style={Style.noBorder}>
            <CloseButton onClick={() => ModalSwitcher.HideLanguageModal()} />
          </Modal.Header>
          <Modal.Body>
            <h4 className="GothamMedium fs-14" style={Style.title}>
              {t("LanguageModal", "selectLanguage")}
            </h4>
            <div className="w-100" style={Style.body}>
              {isMobile ? (
                
                <span style={Style.textMobile}>
                {t("LanguageModal", "language")}
              </span>
              
              ) : (
                <span className="GothamBook" style={Style.text}>
                {t("LanguageModal", "language")}
              </span>
              )}
              {/* <p className="GothamBook" style={Style.text}>
                {t("LanguageModal", "language")}
              </p> */}
              <DropdownComponent
              
              className="fs-9 m-1 p-1"
                selectedValue={TranslationService.getLang().display}
                values={languagesModeled}
                onChange={(model) => {
                  const selected = langs.find((x) => x.code === model.value);
                  TranslationService.changeLang(selected as Lang);
                }}
              />
            </div>
          </Modal.Body>
          <div className="col-12 p0 pb-2 fs-10"></div>
          <Modal.Footer
            className="justify-content-center"
            style={Style.noBorder}
          >
            <div className="d-grid gap-2 col-6 mx-auto">
              <Button
                style={Style.btnSend}
                variant="btn btn-primary"
                className="mb-4"
                onClick={() => {
                  ModalSwitcher.HideLanguageModal();
                }}
              >
                {t("LanguageModal", "btnText")}
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
