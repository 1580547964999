import { Props } from "./types";
import { LeftTextComponent } from "./LeftTextComponent/component";
import { RightTextComponent } from "./RightTextComponent/component";
import { CenterTextComponent } from "./CenterTextComponent/component";
import { ImageComponent } from "../ImageComponent/component";

export function FullImageContentComponent(props: Props) {
  return (
    <>
      {!props.textPosition && (
        <ImageComponent src={props.imgUrl} alt={props.imgAlt} />
      )}
      <div className="row d-flex justify-content-center me-0">
        {props.textPosition && props.textPosition === "left" && (
          <LeftTextComponent
            imgAlt={props.imgAlt}
            style={props.style}
            imgUrl={props.imgUrl}
            textBackgroundImg={props.textBackgroundImg}
            textBackgroundRepeat={props.textBackgroundRepeat}
            text={props.text}
            title={props.title}
            textImg={props.textImg}
            hStyle={props.hStyle}
            pStyle={props.pStyle}
            isBtn={props.isBtn}
            btnText={props.btnText}
            btnUrl={props.btnUrl}
            btnIsLong={props.btnIsLong}
            titleColor={props.titleColor}
            buttonComponent={props.buttonComponent}
            backgroundColor={props.backgroundColor}
            isIcon={props.isIcon}
            isMobileBus={props.isMobileBus}
            isPureComponent={props.isPureComponent}
            isApp={props.isApp}
          />
        )}
        {props.textPosition && props.textPosition === "right" && (
          <RightTextComponent
            text={props.text}
            style={props.style}
            imgAlt={props.imgAlt}
            imgUrl={props.imgUrl}
            title={props.title}
            isBtn={props.isBtn}
            textBackgroundImg={props.textBackgroundImg}
            textBackgroundRepeat={props.textBackgroundRepeat}
            btnText={props.btnText}
            btnIsLong={props.btnIsLong}
            textImg={props.textImg}
            titleColor={props.titleColor}
            btnUrl={props.btnUrl}
            hStyle={props.hStyle}
            pStyle={props.pStyle}
            buttonComponent={props.buttonComponent}
            backgroundColor={props.backgroundColor}
            isIcon={props.isIcon}
          />
        )}
        {props.textPosition && props.textPosition === "center" && (
          <CenterTextComponent
            text={props.text}
            style={props.style}
            title={props.title}
            isBtn={props.isBtn}
            hStyle={props.hStyle}
            pStyle={props.pStyle}
            btnText={props.btnText}
            btnUrl={props.btnUrl}
            buttonComponent={props.buttonComponent}
          />
        )}
      </div>
    </>
  );
}
