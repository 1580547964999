import { config } from "../config/config";
import { Lang, getLangFile, Langs } from "../constants/langs";
import { LangSwither } from "../utils/lang-switcher";
export class TranslationService {
  public static translate(screen: string, key: string) {
    const lang = LangSwither.getLang().code;
    const path = getLangFile(screen, lang);
    return path[key] ? path[key] : key;
  }

  public static changeLang(lang: Lang): boolean {
    localStorage.setItem(config.webPath, JSON.stringify(lang));
    LangSwither.changeLanguage(lang);
    return true;
  }

  public static getLangFromStorage(): Lang {
    const selectedLang = localStorage.getItem(config.webPath);
    if (selectedLang) return JSON.parse(selectedLang);
    else {
      if (window.location.href.includes("nl") === true) {
        localStorage.setItem(config.webPath, JSON.stringify(Langs.NL));
        localStorage.setItem("lang","nl");
        localStorage.removeItem("www.sercair.com");
        return Langs.NL;
      } else if (window.location.href.includes("tr") === true) {
        localStorage.setItem(config.webPath, JSON.stringify(Langs.TR));
        localStorage.setItem("lang","tr");
        localStorage.removeItem("www.sercair.com");
        return Langs.TR;
      } else if (window.location.href.includes("rs") === true) {
        localStorage.setItem(config.webPath, JSON.stringify(Langs.RS));
        localStorage.setItem("lang","rs");
        localStorage.removeItem("www.sercair.com");
        return Langs.RS;
      }  else if (window.location.href.includes("fr") === true) {
        localStorage.setItem(config.webPath, JSON.stringify(Langs.FR));
        localStorage.setItem("lang","fr");
        localStorage.removeItem("www.sercair.com");
        return Langs.FR;
      }  else if (window.location.href.includes("uk") === true) {
        localStorage.setItem(config.webPath, JSON.stringify(Langs.UK));
        localStorage.setItem("lang","uk");
        localStorage.removeItem("www.sercair.com");
        return Langs.UK;
      }  else if (window.location.href.includes("ro") === true) {
        localStorage.setItem(config.webPath, JSON.stringify(Langs.RO));
        localStorage.setItem("lang","ro");
        localStorage.removeItem("www.sercair.com");
        return Langs.RO;
      } else {
        localStorage.setItem(config.webPath, JSON.stringify(Langs.EN));
        localStorage.setItem("lang","en");
        localStorage.removeItem("www.sercair.com");
        return Langs.EN;
      }
    }
  }

  public static getLang(): Lang {
    return LangSwither.getLang();
  }
}
