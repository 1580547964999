import { Col, Container, Row } from "react-bootstrap";
import { SliderComponent } from "../../../components/SliderComponent";
import { SliderContactApp } from "../../../constants/datas";
import theme from "../../../constants/theme";
import { TranslationService } from "../../../services/translation.service";
import { store } from "../../../store";
import { Props } from "./types";
import React, { useEffect } from "react";
import { ImageComponent } from "../../../components/Shared/ImageComponent/component";
import { Style } from "./style";

export function ScreenComponent(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "DealershipPageLogin";
  const imgStyle = { width: 20, marginRight: 20 };
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
  }, []);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className=" d-flex justify-content-center align-items-center"
      style={{ marginBottom: 70, marginTop: 25 }}
    >
      <div className="row col-8 m-0 p-0 align-items-center">
        <Container style={Style.main}>
          <div style={{ marginTop: 1 }}>
            <>
              <Row className="m-0 p-0 align-items-center justify-content-between">
                <Col
                  xs={12}
                  md={6}
                  className={isMobile ? "p-0 xx" : "p-0 d-flex"}
                >
                  <h1
                    className="d-inline text-center pl-3 col-sm-12 col-md-12"
                    style={
                      isMobile
                        ? { ...Style.title, fontSize: "18pt", marginLeft: 0 }
                        : { ...Style.title, fontSize: "20pt", marginLeft: 0 }
                    }
                  >
                    {t(translationKey, "title")}
                  </h1>
                </Col>
              </Row>
              <Row className="m-0 p-0 pt-3 align-items-center justify-content-center">
                <Row className="row m-0 p-0 text-center align-items-center justify-content-center">
                  <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div className="col-12 pt-4">
                      <div className="form-group">
                        <input
                          style={Style.input}
                          placeholder={t(translationKey, "email")}
                        />
                        <div style={{ marginBottom: 20 }}></div>
                        <input
                          style={Style.input}
                          placeholder={t(translationKey, "password")}
                          type="password"
                        />
                      </div>
                      <div className="w-100 m-0">
                        <Row className="m-0 p-0 p-3 w-100 align-items-center justify-content-center">
                          <button
                            style={Style.btnActive}
                            onClick={() => {
                              setError(true);
                            }}
                          >
                            {t(translationKey, "login")}
                          </button>
                        </Row>
                      </div>
                      <p
                        style={{
                          color: "red",
                          display: !error ? "none" : "block",
                        }}
                      >
                        {t(translationKey, "error")}
                      </p>
                    </div>
                  </div>
                </Row>
              </Row>
            </>
          </div>
        </Container>
      </div>
    </div>
  );
}
