import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { TranslationService } from "../../../services/translation.service";
import { store } from "../../../store";
import Step1 from "./Steps/step1";
import Step2 from "./Steps/step2";
import { Style } from "./style";
import { Props } from "./types";

export function ScreenComponent(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "Assembly";
  const [information, setInformation] = React.useState("");
  const [error, setError] = React.useState({
    errorType: "", //empty - invalid
    errorMessage: "",
    btnState: false,
  });
  const [step, setStep] = React.useState(1);

  const btnPassive = Style.btnPassive;
  const btnActive = Style.btnActive;

  const [isMobile, setIsMobile] = useState(false);

  const serialNoControl = (e: string) => {
    if (e === "123456789") {
      setStep(2);
    } else {
      setError({
        errorType: "invalid",
        errorMessage: t(translationKey, "errorSerial"),
        btnState: false,
      });
    }
  };

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className=" d-flex justify-content-center align-items-center"
      style={{ marginBottom: 70, marginTop: 50 }}
    >
      <div
        className="row col-8 m-0 p-0 align-items-center"
        style={{ width: isMobile ? "85%" : "65%" }}
      >
        <Container style={Style.main}>
          {step === 1 && (
            <Step1
              isMobile={isMobile}
              btnStyle={[btnPassive, btnActive]}
              information={information}
              setInformation={setInformation}
              translationKey={translationKey}
              error={error}
              setError={setError}
              t={t}
              setStep={setStep}
              serialNoControl={serialNoControl}
            />
          )}

          {step === 2 && <Step2 />}
        </Container>
      </div>
    </div>
  );
}
