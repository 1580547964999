import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { ButtonComponent } from "../../../../components/Shared/ButtonComponent/component";
import { CardGroupComponent } from "../../../../components/Shared/CardComponent/component";
import { FullImageContentComponent } from "../../../../components/Shared/FullImageContent/component";
import { IconContainerComponent } from "../../../../components/Shared/IconContainerComponent/component";
import { IconContainerMobileComponent } from "../../../../components/Shared/IconContainerMobileComponent/component";
import { ImageComponent } from "../../../../components/Shared/ImageComponent/component";
import { CardProductsGroupComponent } from "../../../../components/Shared/ProductsCard/component";
import { SliderProductsComponent } from "../../../../components/Shared/SliderProductsComponent/component";
import { TabComponent } from "../../../../components/Shared/TabComponent/component";
import { TableComponent } from "../../../../components/Shared/TableComponent/component";
import { SliderComponent } from "../../../../components/SliderComponent";
import {
  IconContentMoniMe,
  MoniMeBoxContent,
  MoniMeSeriesTechnicalData,
  SliderMoniMe,
  SliderMoniMeEmergency,
} from "../../../../constants/datas";
import theme from "../../../../constants/theme";
import useWindowDimensions from "../../../../services/getWindowSize.service";
import { TranslationService } from "../../../../services/translation.service";
import { store } from "../../../../store";
import { AppTabPage } from "../../app/component";
import "./style.css";
import { Props } from "./types";

export function ScreenComponent(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "moniMe";
  const windowSize = useWindowDimensions().width;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);

  const button = (text: string, url: string, isLong: boolean) => {
    return <></>;
  };
  const Cardbutton = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
          marginLeft: isMobile ? 10 : 0,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
        />
      </div>
    );
  };

  const children = [
    {
      title: t(translationKey, "overView"),
      body: (
        <div style={{ backgroundColor: "white", marginTop: isMobile ? 75 : 0 }}>
          <SliderComponent sliderItems={SliderMoniMe} />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.airQuality.moniMe.led}
            imgAlt="Sercair"
            buttonComponent={button}
            btnText={t(translationKey, "btnBuy")}
            btnUrl={"https://sercair.com/contact"}
            text={t(translationKey, "ledText")}
            title={t(translationKey, "ledTitle")}
            textPosition={"left"}
            isBtn={true}
          />
          <h2 style={{ textAlign: "center", marginTop: 50 }}>
            {t(translationKey, "icoTitle")}
          </h2>
          <IconContainerComponent
            icons={IconContentMoniMe}
            isNotResponsive={true}
            style={{ marginBottom: 50 }}
          />
          <div className="d-flex justify-content-center">
            {!isMobile && (
              <ImageComponent
                src={theme.IMAGES.products.airQuality.moniMe.leftSideColorEN}
                alt="sercair"
                width={75}
              />
            )}
          </div>

          <div style={{ textAlign: "center" }}>
            <h3
              style={{ paddingTop: 50 }}
              dangerouslySetInnerHTML={{
                __html: t(
                  translationKey,
                  isMobile ? "appClockTitleMobile" : "appClockTitle"
                ),
              }}
            ></h3>
            <p
              dangerouslySetInnerHTML={{
                __html: t(translationKey, "appClockText"),
              }}
            ></p>
            <ImageComponent
              src={
                isMobile
                  ? theme.IMAGES.products.airQuality.moniMe.alarmClockMobile
                  : theme.IMAGES.products.airQuality.moniMe.alarmClock
              }
              alt="Sercair"
            />
          </div>

          {isMobile ? (
            <div style={{ marginTop: 50 }}>
              <FullImageContentComponent
                pStyle={{ width: "90%" }}
                imgAlt="Emergency"
                imgUrl=""
                text={t(translationKey, "emergencyText")}
                title={t(translationKey, "emergencyTitle")}
                textPosition="center"
              />
            </div>
          ) : (
            <SliderComponent sliderItems={SliderMoniMeEmergency} />
          )}

          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div
              className="row d-flex container "
              style={{ marginTop: 100, marginBottom: 100 }}
            >
              <div
                style={{ marginTop: 25 }}
                className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex flex-column align-items-center"
              >
                <div
                  style={{
                    paddingLeft: isMobile
                      ? 10
                      : window.screen.width < 1500
                      ? 85
                      : 0,
                  }}
                >
                  <h3
                    style={{ marginBottom: 10, fontSize: "20px" }}
                    dangerouslySetInnerHTML={{
                      __html: t(translationKey, "batteryTitle"),
                    }}
                  ></h3>
                  <br />
                  <h2 style={{ color: theme.COLORS.purple, fontSize: "30px" }}>
                    {t(translationKey, "batteryPurpleTitle")}
                  </h2>
                  <p style={{ fontSize: "16px" }}>
                    {t(translationKey, "batteryText")}
                  </p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(translationKey, "batteryNote"),
                    }}
                    style={{ fontWeight: "bold", fontSize: 13 }}
                  ></p>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex justify-content-center"
                style={{ marginTop: isMobile ? 50 : 0 }}
              >
                <ImageComponent
                  src={theme.IMAGES.products.airQuality.moniMe.battery}
                  alt="sercair"
                  width={40}
                />
              </div>
            </div>
          </div>
          <FullImageContentComponent
            imgAlt="sercair"
            imgUrl={theme.IMAGES.products.airQuality.moniMe.babyLeft}
            textBackgroundImg={
              theme.IMAGES.products.airQuality.moniMe.babyRight
            }
            text={t(translationKey, "")}
            textPosition={"right"}
            hStyle={{ marginTop: !isMobile ? -357 : 0 }}
            pStyle={{ fontSize: 20, alignItems: "left" }}
          />

          {!isMobile ? (
            <div style={{ marginTop: 66 }}>
              <div
                className="d-flex justify-content-center"
                style={{
                  position: "absolute",
                  zIndex: "2",
                  marginLeft: "40%",
                  textAlign: "center",
                }}
              >
                <div
                  style={
                    TranslationService.getLang().code === "tr"
                      ? windowSize > 1700
                        ? { marginRight: 301, marginLeft: -165 }
                        : windowSize > 1500
                        ? { marginRight: 275, marginLeft: -162 }
                        : { marginRight: 204, marginLeft: -153 }
                      : windowSize > 1700
                      ? { marginRight: 250, marginLeft: -184 }
                      : windowSize > 1500
                      ? { marginRight: 275, marginLeft: -145 }
                      : { marginRight: 111, marginLeft: -153 }
                  }
                  dangerouslySetInnerHTML={{
                    __html: t(translationKey, "fingerLeftText"),
                  }}
                ></div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(translationKey, "fingerRightText"),
                  }}
                ></div>
              </div>
              <ImageComponent
                src={theme.IMAGES.products.airQuality.moniMe.fingerFull}
                alt="sercair"
                style={{ marginTop: isMobile ? 0 : -22 }}
              />
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(translationKey, "fingerLeftText"),
                }}
                style={{ marginTop: 50 }}
              ></div>
              <ImageComponent
                src={theme.IMAGES.products.airQuality.moniMe.fingerLeft}
                alt="sercair"
              />
              <div
                style={{ marginTop: 50 }}
                dangerouslySetInnerHTML={{
                  __html: t(translationKey, "fingerRightText"),
                }}
              ></div>
              <ImageComponent
                src={theme.IMAGES.products.airQuality.moniMe.fingerRight}
                alt="sercair"
                style={{ marginTop: -22 }}
              />
            </div>
          )}
          <hr />
          <Container
            style={{
              marginTop: 120,
              marginBottom: 50,
              marginLeft: isMobile ? 32 : 171,
            }}
          ></Container>
        </div>
      ),
    },
    {
      title: t("PureSeries", "tabTitle2"),
      body: (
        <div style={{ backgroundColor: "white" }}>
          <div className="d-flex justify-content-center">
            <ImageComponent
              src={theme.IMAGES.products.technicalSpecifications.moniMe}
              width={100}
              style={{ marginTop: 50 }}
              alt="sercair"
            />
          </div>
          <TableComponent
            data={MoniMeSeriesTechnicalData}
            keyComponent={translationKey}
          />
          <hr />
          {!isMobile ? (
            <CardGroupComponent cards={MoniMeBoxContent} />
          ) : (
            <div className="d-flex justify-content-center my-4">
              <div style={{ width: isMobile ? "70%" : "80%" }}>
                <SliderProductsComponent
                  textPosition="bottom"
                  sliderBottomItems={MoniMeBoxContent}
                  minHeight={402}
                />
              </div>
            </div>
          )}
          <hr />
          <Container
            style={{
              marginTop: 120,
              marginBottom: 50,
              marginLeft: isMobile ? 32 : 171,
            }}
          ></Container>
        </div>
      ),
    },
    {
      title: t("PureSeries", "tabTitle3"),
      body: (
        <div>
          <AppTabPage isMobile={props.isMobile} />
        </div>
      ),
    },
  ];

  const childrenMobile = [
    {
      title: t(translationKey, "overView"),
      body: (
        <div
          className="mobile-slider-me"
          style={{ backgroundColor: "white", marginTop: isMobile ? 75 : 0 }}
        >
          <SliderComponent sliderItems={SliderMoniMe} />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.airQuality.moniMe.led}
            imgAlt="Sercair"
            buttonComponent={button}
            btnText={t(translationKey, "btnBuy")}
            pStyle={{ width: 300, textAlign: "center", margin: "auto" }}
            hStyle={{
              marginTop: 20,
              paddingBottom: 10,
              textAlign: "center",
              margin: "auto",
            }}
            btnUrl={"https://sercair.com/contact"}
            text={t(translationKey, "ledText")}
            title={t(translationKey, "ledTitle")}
            textPosition={"left"}
            isBtn={false}
          />
          <h2 style={{ textAlign: "center", marginTop: 50 }}>
            {t(translationKey, "icoTitle")}
          </h2>
          <IconContainerMobileComponent
            icons={IconContentMoniMe}
            isSpirit={true}
            iconWidth={55}
            textWidth={50}
          />
          <div style={{ textAlign: "center" }}>
            <h4
              style={{
                color: theme.COLORS.blue,
                fontSize: 12,
                width: 250,
                margin: "auto",
                marginTop: 20,
              }}
            >
              {t(translationKey, "colorTitleBlue")}{" "}
            </h4>
            <h6
              style={{
                fontSize: 12,
                width: 250,
                margin: "auto",
                marginTop: 10,
                marginBottom: 20,
              }}
            >
              {t(translationKey, "colorTitleMobile")}
            </h6>
          </div>
          <FullImageContentComponent
            imgUrl={
              t(translationKey, "moniMeIcons") == "moniMeIconsTR"
                ? theme.IMAGES.products.airQuality.moniMe.moniMeIconsTR
                : theme.IMAGES.products.airQuality.moniMe.moniMeIconsEN
            }
            imgAlt="Sercair"
          />

          <div style={{ marginTop: 30, marginBottom: 20 }}></div>
          <div style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
            <h6
              style={{
                color: theme.COLORS.black,
                fontSize: 12,
                width: 270,
                margin: "auto",
              }}
            >
              {t(translationKey, "blueContainerTitleMobile")}{" "}
            </h6>
            <div
              style={{
                fontSize: 12,
                width: 250,
                margin: "auto",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              {t(translationKey, "blueContainerTitle2Mobile")}
            </div>
          </div>
          <FullImageContentComponent
            imgUrl={
              t(translationKey, "moniMeIcons") == "moniMeIconsTR"
                ? theme.IMAGES.products.airQuality.moniMe.MoniMemeasurementTR
                : theme.IMAGES.products.airQuality.moniMe.MoniMemeasurementEN
            }
            imgAlt="Sercair"
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.app.handPhoneWithMount}
            imgAlt={t("ProductsApp", "dailyAirQualityAlt")}
            isBtn={false}
            textPosition={isMobile ? "left" : "right"}
            pStyle={{ width: isMobile ? 300 : 500, color: theme.COLORS.black }}
            hStyle={{
              width: isMobile ? 300 : 500,
              fontSize: 25,
              fontFamily: theme.FONTS.GothamMedium,
            }}
            title={t("ProductsApp", "dailyAirQualityTitle")}
            text={t("ProductsApp", "dailyAirQualityTextMobile")}
            titleColor={theme.COLORS.black}

            //textBackgroundImg={theme.IMAGES.products.app.mount}
          />

          <div style={{ textAlign: "center" }}>
            <h6
              style={{
                paddingTop: 30,
                fontSize: 15,
                width: 290,
                margin: "auto",
              }}
              dangerouslySetInnerHTML={{
                __html: t(
                  translationKey,
                  isMobile ? "appClockTitleMobile" : "appClockTitle"
                ),
              }}
            ></h6>
            <div
              style={{
                fontSize: 12,
                width: 250,
                margin: "auto",
                marginTop: 10,
                marginBottom: 10,
              }}
              dangerouslySetInnerHTML={{
                __html: t(translationKey, "appClockText"),
              }}
            ></div>
            <ImageComponent
              src={
                isMobile
                  ? theme.IMAGES.products.airQuality.moniMe.alarmClockMobile
                  : theme.IMAGES.products.airQuality.moniMe.alarmClock
              }
              alt="Sercair"
            />
          </div>

          {isMobile ? (
            <div style={{ marginTop: 50 }}>
              <div
                style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}
              >
                <h6
                  style={{
                    color: theme.COLORS.black,
                    fontSize: 15,
                    width: 270,
                    margin: "auto",
                  }}
                >
                  {t(translationKey, "emergencyTitle")}{" "}
                </h6>
                <div
                  style={{
                    fontSize: 12,
                    width: 270,
                    margin: "auto",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  {t(translationKey, "emergencyTextMobile")}
                </div>
              </div>
              <ImageComponent
                src={theme.IMAGES.products.airQuality.moniMe.emergencyMobile}
                alt="sercair"
                style={{ marginTop: 0 }}
              />
            </div>
          ) : (
            <SliderComponent sliderItems={SliderMoniMeEmergency} />
          )}

          <div className="d-flex" style={{ justifyContent: "center" }}>
            <div
              className="row d-flex container "
              style={{ marginTop: 10, marginBottom: 30 }}
            >
              <div
                style={{ marginTop: 25 }}
                className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex flex-column align-items-center"
              >
                <h3
                  style={{ marginBottom: 0, width: 330, textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html: t(translationKey, "batteryTitle"),
                  }}
                ></h3>
                <br />
                <div
                  style={{
                    paddingLeft: 10,
                    justifyContent: "row",
                    display: "flex",
                    alignItems: "end",
                  }}
                >
                  <div>
                    <div style={{ width: 220 }}>
                      <h2
                        style={{ color: theme.COLORS.purple, fontSize: "30px" }}
                      >
                        {t(translationKey, "batteryPurpleTitle")}
                      </h2>
                      <p style={{ fontSize: "16px" }}>
                        {t(translationKey, "batteryText")}
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t(translationKey, "batteryNote"),
                        }}
                        style={{ fontWeight: "bold", fontSize: 13 }}
                      ></p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "40%",
                      justifyContent: "center",
                      alignItems: "start",
                      display: "flex",
                    }}
                  >
                    <ImageComponent
                      src={theme.IMAGES.products.airQuality.moniMe.battery}
                      alt="sercair"
                      width={80}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FullImageContentComponent
            imgAlt="sercair"
            imgUrl={theme.IMAGES.products.airQuality.moniMe.babyLeft}
          />

          <div style={{ textAlign: "center" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: t(translationKey, "fingerLeftText"),
              }}
              style={{ marginTop: 50, fontFamily: theme.FONTS.GothamMedium }}
            ></div>
            <ImageComponent
              src={theme.IMAGES.products.airQuality.moniMe.fingerLeftMobile}
              alt="sercair"
              style={{ marginBottom: 30, marginLeft: -40 }}
            />
            <div
              style={{
                marginTop: 50,
                marginBottom: 25,
                fontFamily: theme.FONTS.GothamMedium,
                width: 250,
                margin: "auto",
              }}
              dangerouslySetInnerHTML={{
                __html: t(translationKey, "fingerRightText"),
              }}
            ></div>
            <ImageComponent
              src={theme.IMAGES.products.airQuality.moniMe.fingerRightMobile}
              alt="sercair"
              style={{ marginTop: 0 }}
            />
          </div>

          <hr />
          <Container
            style={{
              marginTop: -30,
              marginBottom: -20,

              marginLeft: isMobile ? 32 : 171,
            }}
          >
            <CardProductsGroupComponent
              cards={[
                {
                  title: t(translationKey, "technicalProducts1Title"),
                  text: t(translationKey, "technicalProductsText"),
                  imgUrl:
                    theme.IMAGES.products.technicalSpecifications.moniMeP1,
                  imgAlt: t(translationKey, "altProductsAlt"),
                  isBtn: false,
                  btnText: t(translationKey, "addToBasket"),
                  btnUrl: "",
                  btnIsLong: true,
                  titleColor: theme.COLORS.blue,
                  textColor: theme.COLORS.blue,
                  isBackgroundColor: true,
                  imgPosition: "left",
                  keyComponent: translationKey,
                  isShadow: true,
                  bodyColor: theme.COLORS.productBlue,
                },
              ]}
              buttonComponent={Cardbutton}
            />
          </Container>
        </div>
      ),
    },
    {
      title: t("PureSeries", "tabTitle2"),
      body: (
        <div style={{ backgroundColor: "white" }}>
          <div className="d-flex justify-content-center">
            <ImageComponent
              src={theme.IMAGES.products.technicalSpecifications.moniMe}
              width={250}
              style={{ marginTop: 50 }}
              alt="sercair"
            />
          </div>
          <TableComponent
            data={MoniMeSeriesTechnicalData}
            keyComponent={translationKey}
          />
          <hr />
          {!isMobile ? (
            <CardGroupComponent cards={MoniMeBoxContent} />
          ) : (
            <div className="d-flex justify-content-center my-4">
              <div style={{ width: isMobile ? "70%" : "80%" }}>
                <SliderProductsComponent
                  textPosition="bottom"
                  sliderBottomItems={MoniMeBoxContent}
                  minHeight={402}
                />
              </div>
            </div>
          )}
          <hr />
          <Container
            style={{
              marginTop: 120,
              marginBottom: 50,
              marginLeft: isMobile ? 32 : 171,
            }}
          ></Container>
        </div>
      ),
    },
    {
      title: t("PureSeries", "tabTitle3"),
      body: (
        <div>
          <AppTabPage isMobile={props.isMobile} />
        </div>
      ),
    },
  ];

  return (
    <div>
      <TabComponent
        children={isMobile ? childrenMobile : children}
        componentKey={"PureSeries"}
      />
    </div>
  );
}
