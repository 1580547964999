import { Confidentiality } from "../../screens/Agreements/Confidentiality";
import { Cookie } from "../../screens/Agreements/Cookie";
import { DistanceSelling } from "../../screens/Agreements/DistanceSelling";
import { ReturnsScreenComponent } from "../../screens/Support/Returns";

export const AgreementsScreenNavigator = [
  {
    path: "agreements/privacy",
    exact: true,
    screen: <Confidentiality />,
  },
  {
    path: "/tr/anlasmalar/gizlilik",
    exact: true,
    screen: <Confidentiality />,
  },
  {
    path: "/nl/overeenkomsten/privacy",
    exact: true,
    screen: <Confidentiality />,
  },
  {
    path: "/rs/sporazumi/privatnost",
    exact: true,
    screen: <Confidentiality />,
  },
  {
    path: "/fr/accords/vie-privee",
    exact: true,
    screen: <Confidentiality />,
  },
  {
    path: "/uk/agreements/privacy",
    exact: true,
    screen: <Confidentiality />,
  },
  {
    path: "/ro/acorduri/intimitate",
    exact: true,
    screen: <Confidentiality />,
  },
  {
    path: "agreements/cookie",
    exact: true,
    screen: <Cookie />,
  },
  {
    path: "/tr/anlasmalar/cerezler",
    exact: true,
    screen: <Cookie />,
  },
  {
    path: "/nl/overeenkomsten/cookie",
    exact: true,
    screen: <Cookie />,
  },
  {
    path: "/rs/sporazumi/kolacic",
    exact: true,
    screen: <Cookie />,
  },
  {
    path: "/fr/accords/cookies",
    exact: true,
    screen: <Cookie />,
  },
  {
    path: "/uk/agreements/cookie",
    exact: true,
    screen: <Cookie />,
  },
  {
    path: "/ro/acorduri/cookie",
    exact: true,
    screen: <Cookie />,
  },
  {
    path: "/agreements/distance",
    exact: true,
    screen: <DistanceSelling />,
  },
  {
    path: "/tr/anlasmalar/mesafe",
    exact: true,
    screen: <DistanceSelling />,
  },
  {
    path: "/nl/overeenkomsten/afstand",
    exact: true,
    screen: <DistanceSelling />,
  },
  {
    path: "/rs/sporazumi/udaljenost",
    exact: true,
    screen: <DistanceSelling />,
  },
  {
    path: "/fr/accords/distance",
    exact: true,
    screen: <DistanceSelling />,
  },
  {
    path: "/uk/agreements/distance",
    exact: true,
    screen: <DistanceSelling />,
  },
  {
    path: "/ro/acorduri/distanta",
    exact: true,
    screen: <DistanceSelling />,
  },
  {
    path: "agreements/return",
    exact: true,
    screen: <ReturnsScreenComponent />,
  },
  {
    path: "/tr/anlasmalar/return",
    exact: true,
    screen: <ReturnsScreenComponent />,
  },
  {
    path: "/nl/overeenkomsten/return",
    exact: true,
    screen: <ReturnsScreenComponent />,
  },
  {
    path: "/rs/sporazumi/return",
    exact: true,
    screen: <ReturnsScreenComponent />,
  },
  {
    path: "/fr/accords/return",
    exact: true,
    screen: <ReturnsScreenComponent />,
  },
  {
    path: "/uk/agreements/return",
    exact: true,
    screen: <ReturnsScreenComponent />,
  },
  {
    path: "/ro/acorduri/return",
    exact: true,
    screen: <ReturnsScreenComponent />,
  },
];
