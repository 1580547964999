import "./style.css";
import { FooterContactUsComponent } from "./Views/ContactUs";
import { FooterHelpAndSupportComponent } from "./Views/HelpAndSupport";
import { FooterCopyRightComponent } from "./Views/CopyRight";

export function Component() {
  
  return (
    <div className="">
      <FooterHelpAndSupportComponent />
      <footer className="shadow-top GothamBook fs-9">
        <FooterContactUsComponent />
        <FooterCopyRightComponent />
      </footer>
    </div>
  );
}
