import { TranslationService } from "../../../../services/translation.service";
import { Link } from "react-router-dom";
import { Footers } from "../../../../constants/footer";
import theme from "../../../../constants/theme";
import { store } from "../../../../store";
import { useEffect, useState } from "react";
export function Component() {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
  }, []);
  const t = TranslationService.translate;
  return (
    <div
      className="fs-9 py-4"
      style={{
        background: theme.COLORS.blueSupport,
        fontSize: isMobile ? 10 : 12,
      }}
    >
      <div className="align-items-center">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center text-sm-center ">
            {" "}
            {t("Footer", "copyRight")}
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8">
            <div className="row align-items-center justify-content-center text-center text-sm-center">
              {Footers.map(function (data, index) {
                return (
                  <Link
                    to={data.url}
                    className="col-sm-12 col-md-3 col-lg-2"
                    key={index + "footers"}
                  >
                    <div className="col-md-auto col-sm-12 pt-2 pt-sm-0">
                      {t("Footer", data.key)}
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
