import theme from "../../../../constants/theme";

export const Style = {
  multiContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    width: "100%",
    padding: "0 7.25rem",
    top: "25%",
    left: "0",
    marginTop: 0,
  },
  containerLeft: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    width: "100%",
    padding: "0 1.25rem",
  },
  containerRight: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "right",
    width: "100%",
    padding: "0 1.25rem",
  },
  buttonContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 15,
  },
  cardGroup: {
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    width: "80%",
    padding: "10px 0px",
  },
  card: {
    color: theme.COLORS.black,
    borderStyle: "solid",
    borderColor: theme.COLORS.white,
    borderWidth: 1,
    margin: "10px 20px",
    backgroundColor: theme.COLORS.gray,
    borderBottomLeftRadius: theme.BORDER_RADIUS.large,
    borderBottomRightRadius: theme.BORDER_RADIUS.large,
    boxShadow: "",
  },
};
