import { connect } from "react-redux";
import {
  Component,
  ComponentTR,
  ComponentNL,
  ComponentRS,
  ComponentFR,
  ComponentUK,
  ComponentRO,
} from "./component";
import { GlobalState } from "../../store/state";

const mapStateToProps = (state: GlobalState) => {
  return {
    loading: state.ui.loading,
    lang: state.ui.lang,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const SalesPoints = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export const SalesPointsTR = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentTR);

export const SalesPointsNL = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentNL);

export const SalesPointsRS = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentRS);

export const SalesPointsFR = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentFR);

export const SalesPointsUK = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentUK);

export const SalesPointsRO = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentRO);
