import { useEffect, useState } from "react";
import { OpeningsPopUpService } from "../../API/services/OpeningsPopUp.service";
import { UserGuideService } from "../../API/services/UserGuide.service";
import MessageImgTR from "../../assets/images/message.jpg";
import MessageImgTRMobile from "../../assets/images/messageMobile.jpg";
import AutoSliderComponent from "../../components/AutoSliderComponent/component";
import { ButtonComponent } from "../../components/Shared/ButtonComponent/component";
import { CardGroupComponent } from "../../components/Shared/CardComponent/component";
import { FullImageContentComponent } from "../../components/Shared/FullImageContent/component";
import { IconContainerComponent } from "../../components/Shared/IconContainerComponent/component";
import { SliderProductsComponent } from "../../components/Shared/SliderProductsComponent/component";
import { SliderComponent } from "../../components/SliderComponent";
import {
  BlueTitleCardComponent,
  CardsContent,
  IconContent,
} from "../../constants/datas";
import { Slider } from "../../constants/mock";
import theme, { positions } from "../../constants/theme";
import { OpeningVideo } from "../../models/openingVideo.model";
import { StorageService } from "../../services/storage.service";
import { TranslationService } from "../../services/translation.service";
import { store } from "../../store";
import { ModalSwitcher } from "../../utils/modal-switcher";
import { Style } from "./style";
import { Props } from "./types";
import Loader from "../../components/SpinnerComponent/component";

export function MainScreenComponent(props: Props) {
  const langCheck = localStorage.getItem("lang");
  //get api UserGuideService

  async function getApiUserGuide() {
    let langCode = await StorageService.getLanguageCode();
    await UserGuideService.getUserGuide(langCode).then((res) => {});
  }

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    getApiUserGuide();
    window.scrollTo(0, 0);
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
  }, []);

  const [videoModalData, setVideoModalData] = useState<OpeningVideo>();

  useEffect(() => {
    OpeningsPopUpService.getOpeningVideos(
      TranslationService.getLang().code
    ).then((res) => {
      let activeVideo = res.data.find((item: any) => item.show);
      setVideoModalData(activeVideo);
    });
  }, [TranslationService.getLang()]);

  useEffect(() => {
    videoModalData && videoModalData.show && getVideoController();
  }, [videoModalData]);

  const t = TranslationService.translate;

  const button = (
    text: string,
    url: string,
    isLong?: boolean,
    position?: positions
  ) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: isMobile ? "center" : "start",
          marginBottom: theme.SPACES.large,
          marginTop: 50,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={position}
        />
      </div>
    );
  };

  const button2 = (
    text: string,
    url: string,
    isLong?: boolean,
    position?: positions
  ) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: isMobile ? "center" : "start",
          marginBottom: theme.SPACES.xlarge,
          marginTop: 0,
          marginLeft: isMobile ? 0 : 16,
          fontSize: 16,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={false}
          position={position}
        />
      </div>
    );
  };

  async function getVideoController() {
    //get date YYYY-MM-DD
    const date = new Date();
    //if getMainVideoDate is null or not equal to date, get new video
    if (
      (await StorageService.getMainVideoDate(
        "mainVideoDate" + TranslationService.getLang().code
      )) === null ||
      (await StorageService.getMainVideoDate(
        "mainVideoDate" + TranslationService.getLang().code
      )) === undefined ||
      (await StorageService.getMainVideoDate(
        "mainVideoDate" + TranslationService.getLang().code
      )) !== date.toISOString().slice(0, 10)
    ) {
      ModalSwitcher.ShowVideoModal(
        videoModalData && videoModalData.videoId ? videoModalData.videoId : ""
      );
      StorageService.setMainVideoDate(
        "mainVideoDate" + TranslationService.getLang().code,
        date.toISOString().slice(0, 10)
      );
    }
  }

  return (
    <>
      <Loader />
      <div style={Style.main}>
        {/*  */}
        {langCheck === "en" ? (
          <AutoSliderComponent
            MessageMobile={MessageImgTRMobile}
            MessageImg={MessageImgTR}
          />
        ) : (
          <SliderComponent sliderItems={Slider} />
        )}

        {/* <Popup /> */}
        <div style={Style.cardGroupTitle} className="text-justify-responsive">
          {" "}
          <h2
            className="GothamMedium"
            style={{ marginTop: theme.SPACES.xxlarge }}
          >
            {t("MainScreen", "airQualityTitle")}
          </h2>{" "}
        </div>
        {!isMobile ? (
          <CardGroupComponent
            cards={CardsContent}
            backgroundImage={theme.IMAGES.bgLineFull}
            width={window.screen.width < 1500 ? "80%" : "60%"}
            buttonComponent={button}
          />
        ) : (
          <div className="d-flex justify-content-center my-4">
            <div style={{ width: "80%" }}>
              <SliderProductsComponent
                textPosition="bottom"
                sliderBottomItems={CardsContent}
                minHeight={402}
              />
            </div>
          </div>
        )}
        {!isMobile && (
          <FullImageContentComponent
            style={{ marginTop: 5, marginBottom: 10 }}
            pStyle={{ width: "70%" }}
            imgUrl=""
            imgAlt="Image"
            text={t("MainScreen", "centerTextComponent")}
            isBtn={false}
            textPosition="center"
          />
        )}
        <FullImageContentComponent
          imgUrl={
            isMobile
              ? theme.IMAGES.main.cleanairMobile
              : theme.IMAGES.main.cleanair
          }
          imgAlt="Rahat bir nefes için"
        />
        <div style={{}}>
          <IconContainerComponent
            icons={IconContent}
            style={isMobile ? Style.iconContainerMobile : Style.iconContainer}
            width={"50%"}
          />
        </div>
        {isMobile ? (
          <div style={{ paddingTop: 0 }}>
            {/*paddingTop: 40*/}
            <FullImageContentComponent
              imgUrl={theme.IMAGES.main.moniWay}
              title={t("MainScreen", "moniWayTitle")}
              text={t("MainScreen", "moniWayText")}
              imgAlt={t("MainScreen", "moniWayAlt")}
              isBtn={true}
              textBackgroundRepeat={true}
              isIcon={true}
              buttonComponent={button}
              btnText={t("MainScreen", "moniWayBtnText")}
              btnUrl={
                langCheck === "tr"
                  ? "/tr/iletisim"
                  : langCheck === "en"
                  ? "/contact"
                  : langCheck === "nl"
                  ? "/nl/contact"
                  : langCheck === "fr"
                  ? "/fr/contact"
                  : langCheck === "uk"
                  ? "/uk/contact"
                  : langCheck === "ro"
                  ? "/ro/contactati"
                  : "/rs/kontakt"
              }
              textPosition="left"
              textBackgroundImg={theme.IMAGES.main.moniBus}
              isMobileBus={true}
              style={{ marginTop: isMobile ? -80 : 0 }}
            />
          </div>
        ) : (
          <div style={{ paddingTop: 0 }}>
            <FullImageContentComponent
              imgUrl={theme.IMAGES.main.moniWay}
              title={t("MainScreen", "moniWayTitle")}
              text={t("MainScreen", "moniWayText")}
              imgAlt={t("MainScreen", "moniWayAlt")}
              isBtn={true}
              textBackgroundRepeat={true}
              isIcon={true}
              buttonComponent={button}
              btnText={t("MainScreen", "moniWayBtnText")}
              btnUrl={
                langCheck === "tr"
                  ? "/tr/iletisim"
                  : langCheck === "en"
                  ? "/contact"
                  : langCheck === "nl"
                  ? "/nl/contact"
                  : langCheck === "fr"
                  ? "/fr/contact"
                  : langCheck === "uk"
                  ? "/uk/contact"
                  : langCheck === "ro"
                  ? "/ro/contactati"
                  : "/rs/kontakt"
              }
              textPosition="left"
              textBackgroundImg={theme.IMAGES.main.moniBus}
              isMobileBus={true}
              style={{ marginTop: isMobile ? -80 : 0 }}
            />
          </div>
        )}

        <FullImageContentComponent
          text={t("MainScreen", "sercairAppText")}
          title={t("MainScreen", "sercairAppTitle")}
          imgAlt={t("MainScreen", "sercairAppAlt")}
          imgUrl={theme.IMAGES.main.appPhone}
          textPosition="left"
        />
        <div className="w-100 text-center ">
          <h2 style={{ color: theme.COLORS.blue }}>
            {t("MainScreen", "blueCardMainTitle")}
          </h2>
        </div>
        {isMobile ? (
          <SliderProductsComponent
            textPosition="bottom"
            sliderBottomItems={BlueTitleCardComponent}
            buttonComponent={button2}
            textEnable={true}
            minHeight={551}
          />
        ) : (
          <CardGroupComponent
            cards={BlueTitleCardComponent}
            buttonComponent={button2}
          />
        )}
      </div>
    </>
  );
}
