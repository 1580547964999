import { isMobile } from '../store/ui/actions'
import { store } from '../store';
export class ScreenStateSwither {

    static isMobile = (): boolean => {
        store.dispatch(isMobile(true) as any)
        return true
    };

    static isDesktop = (): boolean => {
        store.dispatch(isMobile(false) as any)
        return true
    };

}