import { useEffect, useState } from "react";
import { store } from "../../../store";
import MobilePage from "./mobilePage";
import WebPage from "./webPage";

export default function ScreenComponent() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);

  return isMobile ? <MobilePage /> : <WebPage />;
}
