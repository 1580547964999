import { PiatraScreenComponent } from "../../screens/Procucts/AirPurifers/Piatra";
import { SpiritScreenComponent } from "../../screens/Procucts/AirPurifers/Spirit";
export const AirPurifersScreenNavigator = [
  {
    path: "/products/airpurifiers/piatra",
    exact: true,
    screen: <PiatraScreenComponent />,
  },
  {
    path: "/tr/urunler/hava-temizleyicileri/piatra",
    exact: true,
    screen: <PiatraScreenComponent />,
  },
  {
    path: "nl/producten/luchtreinigers/piatra",
    exact: true,
    screen: <PiatraScreenComponent />,
  },
  {
    path: "rs/proizvodi/precistaci-vazduha/piatra",
    exact: true,
    screen: <PiatraScreenComponent />,
  },
  {
    path: "fr/produits/purificateurs-air/piatra",
    exact: true,
    screen: <PiatraScreenComponent />,
  },
  {
    path: "uk/products/airpurifiers/piatra",
    exact: true,
    screen: <PiatraScreenComponent />,
  },
  {
    path: "ro/produse/purificatoare-de-aer/piatra",
    exact: true,
    screen: <PiatraScreenComponent />,
  },
  {
    path: "/products/airpurifiers/spirit",
    exact: true,
    screen: <SpiritScreenComponent />,
  },
  {
    path: "/tr/urunler/hava-temizleyicileri/spirit",
    exact: true,
    screen: <SpiritScreenComponent />,
  },
  {
    path: "nl/producten/luchtreinigers/spirit",
    exact: true,
    screen: <SpiritScreenComponent />,
  },
  {
    path: "rs/proizvodi/precistaci-vazduha/spirit",
    exact: true,
    screen: <SpiritScreenComponent />,
  },
  {
    path: "fr/produits/purificateurs-air/spirit",
    exact: true,
    screen: <SpiritScreenComponent />,
  },
  {
    path: "uk/products/airpurifiers/spirit",
    exact: true,
    screen: <SpiritScreenComponent />,
  },
  {
    path: "ro/produse/purificatoare-de-aer/spirit",
    exact: true,
    screen: <SpiritScreenComponent />,
  },
];
