import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { IconType } from "react-icons";

export interface SocialMedia {
    key: string;
    url: string;
    icon: IconType
}
export const SocialMediasTR: SocialMedia[] = [
    {
        key: "instagram",
        url: "https://www.instagram.com/sercair.tr",
        icon: FaInstagram
    },
    {
        key: "twitter",
        url: "https://twitter.com/sercair",
        icon: FaTwitter
    },
    {
        key: "linkedin",
        url: "https://www.linkedin.com/company/sercair/mycompany/",
        icon: FaLinkedin
    },
    {
        key: "youtube",
        url: "https://www.youtube.com/channel/UCKPmNMNvfwmgAkoL_2HiKxg",
        icon: FaYoutube
    },
    {
        key: "facebook",
        url: "https://www.facebook.com/havatemizlemeteknolojisi/",
        icon: FaFacebook
    }
];

export const SocialMediasEN: SocialMedia[] = [
    {
        key: "instagram",
        url: "https://www.instagram.com/sercair.en/",
        icon: FaInstagram
    },
    {
        key: "twitter",
        url: "https://twitter.com/sercair_en",
        icon: FaTwitter
    },
    {
        key: "linkedin",
        url: "https://www.linkedin.com/company/sercair/mycompany/",
        icon: FaLinkedin
    },
    {
        key: "youtube",
        url: "https://www.youtube.com/channel/UCKPmNMNvfwmgAkoL_2HiKxg",
        icon: FaYoutube
    },
    {
        key: "facebook",
        url: "https://www.facebook.com/sercairr.en/",
        icon: FaFacebook
    }
];

export const SocialMediasNL: SocialMedia[] = [
    {
        key: "instagram",
        url: "https://www.instagram.com/sercair.en/",
        icon: FaInstagram
    },
    {
        key: "twitter",
        url: "https://twitter.com/sercair_en",
        icon: FaTwitter
    },
    {
        key: "linkedin",
        url: "https://www.linkedin.com/company/sercair/mycompany/",
        icon: FaLinkedin
    },
    {
        key: "youtube",
        url: "https://www.youtube.com/channel/UCKPmNMNvfwmgAkoL_2HiKxg",
        icon: FaYoutube
    },
    {
        key: "facebook",
        url: "https://www.facebook.com/sercairr.en/",
        icon: FaFacebook
    }
];

export const SocialMediasRS: SocialMedia[] = [
    {
        key: "instagram",
        url: "https://www.instagram.com/sercair.en/",
        icon: FaInstagram
    },
    {
        key: "twitter",
        url: "https://twitter.com/sercair_en",
        icon: FaTwitter
    },
    {
        key: "linkedin",
        url: "https://www.linkedin.com/company/sercair/mycompany/",
        icon: FaLinkedin
    },
    {
        key: "youtube",
        url: "https://www.youtube.com/channel/UCKPmNMNvfwmgAkoL_2HiKxg",
        icon: FaYoutube
    },
    {
        key: "facebook",
        url: "https://www.facebook.com/sercairr.en/",
        icon: FaFacebook
    }
];

export const SocialMediasFR: SocialMedia[] = [
    {
        key: "instagram",
        url: "https://www.instagram.com/sercair.en/",
        icon: FaInstagram
    },
    {
        key: "twitter",
        url: "https://twitter.com/sercair_en",
        icon: FaTwitter
    },
    {
        key: "linkedin",
        url: "https://www.linkedin.com/company/sercair/mycompany/",
        icon: FaLinkedin
    },
    {
        key: "youtube",
        url: "https://www.youtube.com/channel/UCKPmNMNvfwmgAkoL_2HiKxg",
        icon: FaYoutube
    },
    {
        key: "facebook",
        url: "https://www.facebook.com/sercairr.en/",
        icon: FaFacebook
    }
];