import React from "react";
import AirQualityIndex from "../AirQualityIndex/AirQualityIndex";
import TomorrowList11 from "../Lists/TomorrowList/TomorrowList11";
import TomorrowList12 from "../Lists/TomorrowList/TomorrowList12";
import WeatherCard from "../weatherCard";
import Weather from "../../../assets/images/WeatherImages/Weather/Weather.png";
function TomorrowPassive(props) {
  return (
    <div>
      <p className="content_header">{props.Header}</p>
      <WeatherCard
        weather={props.weather}
        color={props.color}
        day={props.day}
        date={props.date}
        image={Weather}
        isPassive={true}
      />
      <AirQualityIndex
        value={0}
        max1={500}
        min1={0}
        bgcolor={"rgba(255,255,255,0.15)"}
        airSituation={""}
        text={""}
        isPassive={true}
      />

      <div className="scroll_TomorrowPassive">
        <div className="column_TomorrowPassive">
          <TomorrowList11
            isPassive={true}
            avgCO2={"-"}
            NO2={"-"}
            SO2={"-"}
            PM2_5={"-"}
            PM10={"-"}
            avgCO={"-"}
            VOCs={"-"}
            NH3={"-"}
            NO={"-"}
            O3={"-"}
          />
        </div>
      </div>
      <div className="scroll_Tomorrow1_Passive">
        <div className="column_Tomorrow1">
          <TomorrowList12
            isPassive={true}
            Temparature={"-"}
            Hummidty={"-"}
            WindSpeed={"-"}
            WindDirection={"-"}
            Pressure={"-"}
          />
        </div>
      </div>
    </div>
  );
}

export default TomorrowPassive;
