import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { ButtonComponent } from "../../../../components/Shared/ButtonComponent/component";
import { CardGroupComponent } from "../../../../components/Shared/CardComponent/component";
import { FullImageContentComponent } from "../../../../components/Shared/FullImageContent/component";
import { IconContainerComponent } from "../../../../components/Shared/IconContainerComponent/component";
import { ImageComponent } from "../../../../components/Shared/ImageComponent/component";
import { SliderProductsComponent } from "../../../../components/Shared/SliderProductsComponent/component";
import { TabComponent } from "../../../../components/Shared/TabComponent/component";
import { TableComponent } from "../../../../components/Shared/TableComponent/component";
import { TextContainerComponent } from "../../../../components/Shared/TextContainerComponent/component";
import { SliderComponent } from "../../../../components/SliderComponent";
import {
  IconPureCoreContent,
  MobileSliderPureSeriesOverview,
  PureCoreBoxContent,
  PureCoreSeriesTechnicalDatav2,
  SliderPureCoreSeries,
  SliderPureCoreSeriesTop,
  SliderPureSeriesOverview,
  TextContainerPureCoreSeries,
} from "../../../../constants/datas";
import theme from "../../../../constants/theme";
import { TranslationService } from "../../../../services/translation.service";
import { store } from "../../../../store";
import { AppTabPage } from "../../app/component";
import "./style.css";
import { Props } from "./types";

export function ScreenComponent(props: Props) {
  const t = TranslationService.translate;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);
  const cardbutton = (
    text: string,
    url: string,
    isLong: boolean,
    isCardComp: boolean
  ) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: isCardComp
            ? isMobile
              ? "start"
              : "start"
            : isMobile
            ? "start"
            : "start",
          marginBottom: theme.SPACES.large,
          marginTop: 50,
          marginLeft: 10,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
        />
      </div>
    );
  };
  const button = (
    text: string,
    url: string,
    isLong: boolean,
    isCardComp: boolean
  ) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: isCardComp
            ? isMobile
              ? "start"
              : "start"
            : isMobile
            ? "center"
            : "start",
          marginBottom: theme.SPACES.large,
          marginTop: 50,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
        />
      </div>
    );
  };

  const buttonApp = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: isMobile ? "center" : "start",
          marginRight: 10,
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
          isTabSwitcher={true}
        />
      </div>
    );
  };

  const children = [
    {
      title: t("PureSeries", "tabTitle1"),
      body: (
        <div
          style={{ backgroundColor: "white", marginTop: isMobile ? "19%" : 0 }}
        >
          <SliderComponent sliderItems={SliderPureCoreSeries} />
          <div style={{ marginBottom: -10, marginTop: isMobile ? 0 : 60 }}>
            <FullImageContentComponent
              textPosition="center"
              imgUrl=""
              text={t("PureCoreSeries", "altSliderTitle")}
              imgAlt="Pure Core Series"
            />
          </div>
          <SliderProductsComponent
            textPosition={"top"}
            isNotContainer={true}
            sliderTopItems={SliderPureCoreSeriesTop}
          />
          <FullImageContentComponent
            textPosition="center"
            imgUrl=""
            text={t("PureCoreSeries", "altSliderText")}
            style={{ backgroundColor: "white", paddingBottom: 50 }}
            imgAlt="Pure Core Series"
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureCore.restaurant}
            textBackgroundImg={theme.IMAGES.products.pure.pureCore.forkSpoon}
            title={t("PureCoreSeries", "restaurantTitle")}
            text={t("PureCoreSeries", "restaurantText")}
            isIcon={true}
            titleColor={theme.COLORS.black}
            textBackgroundRepeat={true}
            imgAlt={t("PureCoreSeries", "altProductsAlt")}
            isBtn={true}
            btnUrl={"/contact"}
            buttonComponent={button}
            btnText={t("PureCoreSeries", "btnCall")}
            textPosition="left"
          />
          <div
            style={{
              backgroundColor: theme.COLORS.blue40,
              marginBottom: 50,
            }}
          >
            <TextContainerComponent
              texts={TextContainerPureCoreSeries}
              columnNumber={3}
              width={"90%"}
            />
          </div>
          <FullImageContentComponent
            imgUrl=""
            imgAlt="Pure Core Series"
            text={t("PureCoreSeries", isMobile ? "gifText" : "gifWeb")}
            title={t("PureCoreSeries", "gifTitle")}
            isBtn={false}
            textPosition="center"
            style={{ marginBottom: 50 }}
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureCore.restaurant2}
            textBackgroundImg={theme.IMAGES.products.pure.pureCore.forkSpoon}
            textBackgroundRepeat={true}
            title={t("PureCoreSeries", "naturalEatTitle")}
            text={t("PureCoreSeries", "naturalEatText")}
            imgAlt={t("PureCoreSeries", "altProductsAlt")}
            isBtn={true}
            buttonComponent={button}
            isIcon={true}
            btnIsLong={true}
            btnText={t("PureCoreSeries", "btnCall")}
            btnUrl={"/contact"}
            textPosition="left"
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureSeries.easyFilterChange}
            imgAlt={t("PureCoreSeries", "altProductsAlt")}
            isBtn={false}
            textBackgroundImg={theme.IMAGES.waweLine}
            hStyle={{ marginTop: isMobile ? 0 : 140 }}
            textPosition="right"
            title={t("PureCoreSeries", "easyFilterChangeTitle")}
            text={t("PureCoreSeries", "easyFilterChangeText")}
          />
          <div
            style={{
              padding: 60,
              backgroundColor: theme.COLORS.blue40,
            }}
            className="text-center"
          >
            <h1 style={{ margin: 20 }}>
              {t("OverViewPureSlider", "sliderMainTitle")}
            </h1>
            <p
              dangerouslySetInnerHTML={{
                __html: t("OverViewPureSlider", "sliderMainText"),
              }}
            ></p>
            <div
              style={
                isMobile
                  ? { paddingInline: 0, marginTop: 25, marginBottom: -50 }
                  : {
                      paddingInline: window.screen.width < 1500 ? 75 : 200,
                      marginTop: 25,
                    }
              }
            >
              {isMobile ? (
                <SliderProductsComponent
                  sliderRightItems={MobileSliderPureSeriesOverview}
                  textPosition="right"
                />
              ) : (
                <SliderProductsComponent
                  sliderRightItems={SliderPureSeriesOverview}
                  textPosition="right"
                />
              )}
            </div>
          </div>

          <FullImageContentComponent
            imgUrl={
              TranslationService.getLang().code === "tr"
                ? //ts-ignore
                  theme.IMAGES.products.pure.pureCore["gifCoreTR"]
                : theme.IMAGES.products.pure.pureCore.gifCoreEN
            }
            backgroundColor={"#f7fbfe"}
            imgAlt={t("PureCoreSeries", "altProductsAlt")}
            isBtn={true}
            textPosition="left"
            hStyle={{ fontSize: isMobile ? 20 : 22 }}
            title={t("PureCoreSeries", "appTechnologyTitle")}
            text={t("PureCoreSeries", "appTechnologyText")}
            btnText={t("PureCoreSeries", "btnMoreInfo")}
            btnUrl="/contact"
            buttonComponent={buttonApp}
            btnIsLong={true}
          />

          <IconContainerComponent
            icons={IconPureCoreContent}
            isNotResponsive={true}
            style={{ backgroundColor: "white", paddingBottom: 50 }}
          />
          <FullImageContentComponent
            imgUrl=""
            imgAlt="Pure Core Series"
            isBtn={false}
            textPosition="center"
            title={t("PureCoreSeries", "adminPanelTitle")}
            text={t("PureCoreSeries", "adminPanelText")}
            style={{ backgroundColor: "white", marginBottom: 50 }}
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureSeries.adminPanel}
            imgAlt={t("PureCoreSeries", "fullImageContentAlt")}
          />
          <div style={{ backgroundColor: "white" }}>
            <Container
              style={{
                marginBottom: 50,
                marginTop: 50,
                marginLeft: isMobile ? 32 : 167,
              }}
            ></Container>
          </div>
        </div>
      ),
    },
    {
      title: t("PureSeries", "tabTitle2"),
      body: (
        <div style={{ backgroundColor: "white" }}>
          <ImageComponent
            style={{ marginTop: 110 }}
            src={theme.IMAGES.products.pure.pureCore.techImg}
            alt="Teknik Özellikler"
          />
          <TableComponent
            data={PureCoreSeriesTechnicalDatav2}
            keyComponent="PureCoreSeries"
          />
          <hr />
          {!isMobile ? (
            <CardGroupComponent cards={PureCoreBoxContent} />
          ) : (
            <div className="d-flex justify-content-center my-4">
              <div style={{ width: isMobile ? "70%" : "80%" }}>
                <SliderProductsComponent
                  textPosition="bottom"
                  sliderBottomItems={PureCoreBoxContent}
                  minHeight={402}
                />
              </div>
            </div>
          )}
          <hr />
        </div>
      ),
    },
    {
      title: t("PureSeries", "tabTitle3"),
      body: (
        <div>
          <AppTabPage isMobile={isMobile} />
        </div>
      ),
    },
  ];
  const mobilechildren = [
    {
      title: t("PureSeries", "tabTitle1"),
      body: (
        <div
          className="mobile-slider-pureCore"
          style={{ backgroundColor: "white" }}
        >
          <SliderComponent sliderItems={SliderPureCoreSeries} />
          <div style={{ marginBottom: -10, marginTop: isMobile ? 0 : 60 }}>
            <FullImageContentComponent
              textPosition="center"
              imgUrl=""
              text={t("PureCoreSeries", "altSliderTitle")}
              imgAlt="Pure Core Series"
            />
          </div>
          <SliderProductsComponent
            textPosition={"top"}
            isNotContainer={true}
            sliderTopItems={SliderPureCoreSeriesTop}
            isPhone={false}
          />

          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureCore.restaurantMobile}
            textBackgroundImg={theme.IMAGES.products.pure.pureCore.forkSpoon}
            title={t("PureCoreSeries", "restaurantTitle")}
            hStyle={{
              width: 250,
              textAlign: "center",
              marginLeft: 20,
              marginBottom: -30,
            }}
            isIcon={false}
            titleColor={theme.COLORS.black}
            textBackgroundRepeat={true}
            imgAlt={t("PureCoreSeries", "altProductsAlt")}
            isBtn={true}
            btnUrl={"/contact"}
            buttonComponent={button}
            btnText={t("PureCoreSeries", "btnCall")}
            textPosition="left"
            isPureComponent={false}
          />
          <div
            style={{
              backgroundColor: theme.COLORS.blue40,
              marginBottom: 50,
            }}
          >
            <TextContainerComponent
              texts={TextContainerPureCoreSeries}
              columnNumber={3}
            />
          </div>

          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureCore.restaurant2}
            textBackgroundImg={theme.IMAGES.products.pure.pureCore.forkSpoon}
            textBackgroundRepeat={true}
            title={t("PureCoreSeries", "naturalEatTitle")}
            text={t("PureCoreSeries", "naturalEatText")}
            hStyle={{ width: 250 }}
            pStyle={{ width: 270 }}
            imgAlt={t("PureCoreSeries", "altProductsAlt")}
            isBtn={true}
            buttonComponent={button}
            isIcon={false}
            isPureComponent={false}
            style={{ marginTop: -50 }}
            btnText={t("PureCoreSeries", "btnCall")}
            btnUrl={"/contact"}
            textPosition="left"
          />
          <FullImageContentComponent
            imgUrl={
              theme.IMAGES.products.pure.pureSeries.easyFilterChangeMobile
            }
            imgAlt={t("PureCoreSeries", "altProductsAlt")}
            isBtn={false}
            //textBackgroundImg={theme.IMAGES.waweLine}

            hStyle={{ width: 250, marginTop: isMobile ? 0 : 140 }}
            pStyle={{ width: 270 }}
            textPosition="left"
            title={t("PureCoreSeries", "easyFilterChangeTitle")}
            text={t("PureCoreSeries", "easyFilterChangeText")}
          />
          <div
            style={{
              padding: 60,
              backgroundColor: theme.COLORS.blue40,
            }}
            className="text-center"
          >
            <h1 style={{ margin: 20, marginTop: -20 }}>
              {t("OverViewPureSlider", "sliderMainTitle")}
            </h1>
            <p
              dangerouslySetInnerHTML={{
                __html: t("OverViewPureSlider", "sliderMainText"),
              }}
            ></p>
            <div
              style={
                isMobile
                  ? { paddingInline: 0, marginTop: 25, marginBottom: -50 }
                  : {
                      paddingInline: window.screen.width < 1500 ? 75 : 200,
                      marginTop: 25,
                    }
              }
            >
              {isMobile ? (
                <SliderProductsComponent
                  sliderRightItems={MobileSliderPureSeriesOverview}
                  textPosition="right"
                />
              ) : (
                <SliderProductsComponent
                  sliderRightItems={SliderPureSeriesOverview}
                  textPosition="right"
                />
              )}
            </div>
          </div>

          <FullImageContentComponent
            imgUrl={
              TranslationService.getLang().code === "tr"
                ? //ts-ignore
                  theme.IMAGES.products.pure.pureCore["gifCoreTR"]
                : theme.IMAGES.products.pure.pureCore.gifCoreEN
            }
            backgroundColor={"#f7fbfe"}
            imgAlt={t("PureCoreSeries", "altProductsAlt")}
            isBtn={true}
            hStyle={{ width: 270, fontSize: isMobile ? 20 : 30 }}
            pStyle={{ width: 270 }}
            textPosition="left"
            isApp={true}
            title={t("PureCoreSeries", "appTechnologyTitle")}
            text={t("PureCoreSeries", "appTechnologyText")}
            btnText={t("PureCoreSeries", "btnMoreInfo")}
            btnUrl="/contact"
            buttonComponent={buttonApp}
            btnIsLong={true}
          />

          <IconContainerComponent // yatay olmalı
            icons={IconPureCoreContent}
            isNotResponsive={true}
            style={{ backgroundColor: "white", paddingBottom: 0 }}
          />
          <FullImageContentComponent
            imgUrl=""
            imgAlt="PureCoreSeries"
            isBtn={false}
            textPosition="left"
            title={t("PureCoreSeries", "adminPanelTitle")}
            text={t("PureCoreSeries", "adminPanelText")}
            style={{ backgroundColor: "white", marginBottom: 25, width: 300 }}
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureSeries.adminPanel}
            imgAlt={t("PureCoreSeries", "fullImageContentAlt")}
          />
          <div style={{ backgroundColor: "white" }}>
            <Container
              style={{
                marginBottom: 50,
                marginTop: 50,
                marginLeft: isMobile ? 32 : 167,
              }}
            ></Container>
          </div>
        </div>
      ),
    },
    {
      title: t("PureSeries", "tabTitle2"),
      body: (
        <div style={{ backgroundColor: "white" }}>
          <ImageComponent
            style={{ marginTop: 110 }}
            src={theme.IMAGES.products.pure.pureCore.techImg}
            alt="Teknik Özellikler"
          />
          <TableComponent
            data={PureCoreSeriesTechnicalDatav2}
            keyComponent="PureCoreSeries"
          />
          <hr />
          {!isMobile ? (
            <CardGroupComponent cards={PureCoreBoxContent} />
          ) : (
            <div className="d-flex justify-content-center my-4">
              <div style={{ width: isMobile ? "70%" : "80%" }}>
                <SliderProductsComponent
                  textPosition="bottom"
                  sliderBottomItems={PureCoreBoxContent}
                  minHeight={402}
                />
              </div>
            </div>
          )}
          <hr />
        </div>
      ),
    },
    {
      title: t("PureSeries", "tabTitle3"),
      body: (
        <div>
          <AppTabPage isMobile={isMobile} />
        </div>
      ),
    },
  ];

  return (
    <div>
      <TabComponent
        children={isMobile ? mobilechildren : children}
        componentKey={"PureSeries"}
      />
    </div>
  );
}
