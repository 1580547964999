import theme, { positions } from "../../constants/theme";

export const Style = {
  main: {
    marginBottom: 20,
  },
  header: {
    heigh: 720,
    backgroundColor: "#f5f5f5",
    alignItems: "center",
    color: "#333",
  },
  cardGroupTitle: {
    textAlign: "center" as positions,
  },
  textCentered: {
    textAlign: "center" as positions,
    display: "flex",
    justifyContent: "center",
    alignItems: "center" as positions,
    padding: 50,
  },
  iconContainer: {
    marginTop: 10,
    marginBottom: 80,
    width: "100%"
  },
  iconContainerMobile: {
    marginTop: 10,
    marginBottom: 80,
    width: "100%",
  },
  centerText: {
    marginTop: theme.SPACES.xlarge,
    marginBottom: theme.SPACES.xlarge,
  },
};
