import React from "react";
import axios from "axios";
import { Fragment } from "react";

import WeatherCard from "../weatherCard";
import "./Tomorrow.css";
import TomorrowList11 from "../Lists/TomorrowList/TomorrowList11";
import TomorrowList12 from "../Lists/TomorrowList/TomorrowList12";
import AirQualityIndex from "../AirQualityIndex/AirQualityIndex";
import one from "../../../assets/images/WeatherImages/Weather/one.png";
import two from "../../../assets/images/WeatherImages/Weather/two.png";
import three from "../../../assets/images/WeatherImages/Weather/three.png";
import four from "../../../assets/images/WeatherImages/Weather/four.png";
import five from "../../../assets/images/WeatherImages/Weather/five.png";
import six from "../../../assets/images/WeatherImages/Weather/six.png";
import TomorrowPassive from "./TomorrowPassive";

const objectDate = new Date();

let day1 = objectDate.getDate() - 1;
let day2 = objectDate.getDate() - 2;
let month = objectDate.getMonth() + 1;
let year = objectDate.getFullYear();

const day_format_first = year + "-" + month + "-" + day2;
const day_format_last = year + "-" + month + "-" + day1;

const Tomorrow1 = (props) => {
  // const baseURL = `http://78.186.56.6:9005/api/v1/predict/getPredict?KE=${props.lang}&KB=${props.long}`;
  const baseURL = `http://78.186.56.6:9000/getPredict?ke=${props.lang}&kb=${props.long}`;
  const weatherURL = `http://78.186.56.6:9000/getWeather?ke=${props.lang}&kb=${props.long}`;
  const config = {
    headers: {
      Authorization:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6InRlc3RAZ21haWwuY29tIn0.Y-iOGZPutGUODJshuimE-Fi78O77YRdBbcrWOkqKwII",
    },
  };
  const [datee, setDatee] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [tomorrow, setTomorrow] = React.useState(null);
  const [valuee, setValuee] = React.useState("Sıcaklık");
  const [maxval, setMaxval] = React.useState();
  const [int, setInt] = React.useState(null);
  const [unit, setUnit] = React.useState();
  const [change, setChange] = React.useState(true);
  const [ins, setIns] = React.useState(true);
  const [tog, setTog] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);
  const [sit, setSit] = React.useState();
  const [temptoggle, setTemptoggle] = React.useState(false);
  const [humtoggle, setHumtoggle] = React.useState(false);
  const [wdtoggle, setWdtoggle] = React.useState(false);

  const [secondsit, setSecondSit] = React.useState();
  const [secondvaluee, setSecondValuee] = React.useState("Sıcaklık");
  const [secondint, setSecondInt] = React.useState(null);
  const [secondminval, setSecondMinval] = React.useState();
  const [secondmaxval, setSecondMaxval] = React.useState();
  const [secondunit, setSecondUnit] = React.useState();
  const [secondchange, setSecondChange] = React.useState(true);
  const [secondins, setSecondIns] = React.useState(true);
  const [secondtog, setSecondTog] = React.useState(false);
  const [secondopen, setSecondOpen] = React.useState(false);
  const [secondcolor, setSecondColor] = React.useState();
  const [wstoggle, setWstoggle] = React.useState(false);
  const [no2toggle, setNo2toggle] = React.useState(false);
  const [so2toggle, setSo2toggle] = React.useState(false);
  const [pm2_5toggle, setPm2_5toggle] = React.useState(false);
  const [pm10toggle, setPm10toggle] = React.useState(false);
  const [co2toggle, setCo2toggle] = React.useState(false);
  const [cotoggle, setCotoggle] = React.useState(false);
  const [vocstoggle, setVocstoggle] = React.useState(false);
  const [nh3toggle, setNh3toggle] = React.useState(false);
  const [notoggle, setNotoggle] = React.useState(false);
  const [o3toggle, setO3toggle] = React.useState(false);
  const [prestoggle, setPresstoggle] = React.useState(false);
  const [weather, setWeather] = React.useState();
  const [minval, setMinval] = React.useState();
  const [color, setColor] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [aqi, setAqi] = React.useState();
  const [predictStatus, setPredictStatus] = React.useState();
  const [status, setStatus] = React.useState();
  const [situation, setSituation] = React.useState();
  React.useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDatee(new Date());
    }, 15 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);
  React.useEffect(() => {
    setLoading(false);
    axios.get(weatherURL, config).then((response) => {
      setWeather(response.data);
    });
    setLoading(true);
  }, []);

  React.useEffect(() => {
    setLoading(false);
    axios.get(baseURL, config).then((response) => {
      // setPredictStatus(response.data.status)(
      //   predictStatus == 400
      //     ? (setTomorrow(null), setSituation(null), setAqi(null))
      //     : (setTomorrow(response.data.data),
      //       setSituation(response.data.data.slice(-2)[0]),
      //       setAqi(response.data.data.slice(-1)[0]))
      // );
      setPredictStatus(response.data[0].status)(
        predictStatus == 400
          ? (setTomorrow(null), setSituation(null), setAqi(null))
          : (setTomorrow(response.data),
            setSituation(response.data.slice(-2)[0]),
            setAqi(response.data.slice(-1)[0]))
      );
    });
    setLoading(true);
  }, []);
  if (predictStatus !== 400) {
    if (!tomorrow) return null;
    if (!weather) return null;
    if (!aqi) return null;
    if (!situation) return null;
  }
  const current = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  const date = `${current.getDate() + 0}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const days = [
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ];
  const day = days[current.getDay()];
  const timer = current.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  const updateTemp = () => {
    setMaxval(
      -20 < tomorrow[11].preds[2].toFixed(0) &&
        tomorrow[11].preds[2].toFixed(0) <= -4
        ? -4
        : -4 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 6
        ? 6
        : 6 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 14
        ? 14
        : 14 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 17
        ? 17
        : 17 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 19
        ? 19
        : 19 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 23
        ? 23
        : 23 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 25
        ? 25
        : 25 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 28
        ? 28
        : 28 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 37
        ? 37
        : 37 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 46
        ? 46
        : 46 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 50
        ? 50
        : 50
    );
    setMinval(
      -20 < tomorrow[11].preds[2].toFixed(0) &&
        tomorrow[11].preds[2].toFixed(0) <= -4
        ? -20
        : -4 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 6
        ? -4
        : 6 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 14
        ? 6
        : 14 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 17
        ? 14
        : 17 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 19
        ? 17
        : 19 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 23
        ? 19
        : 23 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 25
        ? 23
        : 25 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 28
        ? 25
        : 28 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 37
        ? 28
        : 37 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 46
        ? 37
        : 46 < tomorrow[11].preds[2].toFixed(0) &&
          tomorrow[11].preds[2].toFixed(0) <= 50
        ? 46
        : 0
    );

    setValuee("Sıcaklık");
    setInt(tomorrow[11].preds[2].toFixed(0));
    setSit(situation.Temp);
    setColor(tomorrow[15].Temp);
    setUnit("°C");
    setChange(true);
    setIns(false);
    setTog(false);
    setTemptoggle(true);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateHum = () => {
    setValuee("Nem");
    setInt(tomorrow[2].preds[2].toFixed(0));
    setSit(situation.Hum);
    setColor(tomorrow[15].Hum);
    setUnit("%");
    setMaxval(100);
    setMinval(0);
    setChange(false);
    setIns(false);
    setTog(true);
    setToggle(true);
    setTemptoggle(false);
    setHumtoggle(true);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateWD = () => {
    setValuee("Rüzgar Yönü");
    setInt(tomorrow[13].preds[2].toFixed(0));
    setColor(tomorrow[15].WS);
    setSit(null);
    setMaxval(360);
    setMinval(0);
    setIns(false);
    setUnit("°");
    setTog(true);
    setChange(true);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(true);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateWS = () => {
    setValuee("Rüzgar Hızı");
    setInt(tomorrow[14].preds[2].toFixed(0));
    setSit(situation.WS);
    setColor(tomorrow[15].WS);
    setIns(false);
    setUnit("m/s");
    setTog(true);
    setChange(true);
    setMaxval(
      0 < tomorrow[13].preds[2].toFixed(0) &&
        tomorrow[13].preds[2].toFixed(0) <= 1
        ? 1
        : 1 < tomorrow[13].preds[2].toFixed(0) &&
          tomorrow[13].preds[2].toFixed(0) <= 5
        ? 5
        : 5 < tomorrow[13].preds[2].toFixed(0) &&
          tomorrow[13].preds[2].toFixed(0) <= 10
        ? 10
        : 10 < tomorrow[13].preds[2].toFixed(0) &&
          tomorrow[13].preds[2].toFixed(0) <= 20
        ? 17
        : 20 < tomorrow[13].preds[2].toFixed(0) &&
          tomorrow[13].preds[2].toFixed(0) <= 35
        ? 35
        : 50
    );
    setMinval(
      0 < tomorrow[13].preds[2].toFixed(0) &&
        tomorrow[13].preds[2].toFixed(0) <= 1
        ? 0
        : 1 < tomorrow[13].preds[2].toFixed(0) &&
          tomorrow[13].preds[2].toFixed(0) <= 5
        ? 1
        : 5 < tomorrow[13].preds[2].toFixed(0) &&
          tomorrow[13].preds[2].toFixed(0) <= 10
        ? 5
        : 10 < tomorrow[13].preds[2].toFixed(0) &&
          tomorrow[13].preds[2].toFixed(0) <= 20
        ? 10
        : 20 < tomorrow[13].preds[2].toFixed(0) &&
          tomorrow[13].preds[2].toFixed(0) <= 35
        ? 20
        : 35
    );

    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(true);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updatePres = () => {
    setValuee("Basınç");
    setInt(tomorrow[9].preds[2].toFixed(0));
    setColor(tomorrow[15].Temp);
    setMaxval(1500);
    setIns(false);
    setUnit("p");
    setTog(true);
    setChange(true);
    setMaxval(1500);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setPresstoggle(true);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateNO2 = () => {
    setValuee("NO2");
    setMaxval(
      0 < tomorrow[5].preds[2].toFixed(0) &&
        tomorrow[5].preds[2].toFixed(0) <= 10
        ? 10
        : 10 < tomorrow[5].preds[2].toFixed(0) &&
          tomorrow[5].preds[2].toFixed(0) <= 25
        ? 25
        : 25 < tomorrow[5].preds[2].toFixed(0) &&
          tomorrow[5].preds[2].toFixed(0) <= 40
        ? 40
        : 40 < tomorrow[5].preds[2].toFixed(0) &&
          tomorrow[5].preds[2].toFixed(0) <= 60
        ? 60
        : 60 < tomorrow[5].preds[2].toFixed(0) &&
          tomorrow[5].preds[2].toFixed(0) <= 120
        ? 120
        : 300
    );
    setMinval(
      0 < tomorrow[5].preds[2].toFixed(0) &&
        tomorrow[5].preds[2].toFixed(0) <= 10
        ? 0
        : 10 < tomorrow[5].preds[2].toFixed(0) &&
          tomorrow[5].preds[2].toFixed(0) <= 25
        ? 10
        : 25 < tomorrow[5].preds[2].toFixed(0) &&
          tomorrow[5].preds[2].toFixed(0) <= 40
        ? 25
        : 40 < tomorrow[5].preds[2].toFixed(0) &&
          tomorrow[5].preds[2].toFixed(0) <= 60
        ? 40
        : 60 < tomorrow[5].preds[2].toFixed(0) &&
          tomorrow[5].preds[2].toFixed(0) <= 120
        ? 60
        : 120
    );
    setOpen(true);
    setInt(tomorrow[5].preds[2].toFixed(0));
    setSit(situation.NO2);
    setColor(tomorrow[15].NO2);
    setIns(false);
    setUnit("μg/m3");
    setTog(true);
    setChange(true);

    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(true);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateSO2 = () => {
    setValuee("SO2");
    setMaxval(
      0 < tomorrow[10].preds[2].toFixed(0) &&
        tomorrow[10].preds[2].toFixed(0) <= 10
        ? 10
        : 10 < tomorrow[10].preds[2].toFixed(0) &&
          tomorrow[10].preds[2].toFixed(0) <= 15
        ? 15
        : 15 < tomorrow[10].preds[2].toFixed(0) &&
          tomorrow[10].preds[2].toFixed(0) <= 30
        ? 30
        : 30 < tomorrow[10].preds[2].toFixed(0) &&
          tomorrow[10].preds[2].toFixed(0) <= 100
        ? 100
        : 100 < tomorrow[10].preds[2].toFixed(0) &&
          tomorrow[10].preds[2].toFixed(0) <= 250
        ? 250
        : 450
    );
    setMinval(
      0 < tomorrow[10].preds[2].toFixed(0) &&
        tomorrow[10].preds[2].toFixed(0) <= 10
        ? 0
        : 10 < tomorrow[10].preds[2].toFixed(0) &&
          tomorrow[10].preds[2].toFixed(0) <= 15
        ? 10
        : 15 < tomorrow[10].preds[2].toFixed(0) &&
          tomorrow[10].preds[2].toFixed(0) <= 30
        ? 15
        : 30 < tomorrow[10].preds[2].toFixed(0) &&
          tomorrow[10].preds[2].toFixed(0) <= 100
        ? 30
        : 100 < tomorrow[10].preds[2].toFixed(0) &&
          tomorrow[10].preds[2].toFixed(0) <= 250
        ? 100
        : 250
    );
    setOpen(true);
    setInt(tomorrow[10].preds[2].toFixed(0));
    setColor(tomorrow[15].SO2);
    setSit(situation.SO2);
    setIns(false);
    setUnit("μg/m3");
    setTog(true);
    setChange(true);

    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(true);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updatePM2_5 = () => {
    setValuee("PM2_5");
    setInt(tomorrow[8].preds[2].toFixed(0));
    setMaxval(
      0 < tomorrow[8].preds[2].toFixed(0) &&
        tomorrow[8].preds[2].toFixed(0) <= 50
        ? 50
        : 50 < tomorrow[8].preds[2].toFixed(0) &&
          tomorrow[8].preds[2].toFixed(0) <= 100
        ? 100
        : 100 < tomorrow[8].preds[2].toFixed(0) &&
          tomorrow[8].preds[2].toFixed(0) <= 180
        ? 180
        : 180 < tomorrow[8].preds[2].toFixed(0) &&
          tomorrow[8].preds[2].toFixed(0) <= 500
        ? 100
        : 500 < tomorrow[8].preds[2].toFixed(0) &&
          tomorrow[8].preds[2].toFixed(0) <= 900
        ? 900
        : 900
    );
    setMinval(
      0 < tomorrow[8].preds[2].toFixed(0) &&
        tomorrow[8].preds[2].toFixed(0) <= 50
        ? 0
        : 50 < tomorrow[8].preds[2].toFixed(0) &&
          tomorrow[8].preds[2].toFixed(0) <= 100
        ? 50
        : 100 < tomorrow[8].preds[2].toFixed(0) &&
          tomorrow[8].preds[2].toFixed(0) <= 180
        ? 100
        : 180 < tomorrow[8].preds[2].toFixed(0) &&
          tomorrow[8].preds[2].toFixed(0) <= 500
        ? 180
        : 500 < tomorrow[8].preds[2].toFixed(0) &&
          tomorrow[8].preds[2].toFixed(0) <= 900
        ? 500
        : 500
    );
    setColor(tomorrow[15].PM2_5);
    setSit(situation.PM2_5);
    setIns(false);
    setUnit("μg/m3");
    setTog(true);
    setChange(true);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(true);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
    setOpen(true);
  };
  const updatePM10 = () => {
    setValuee("PM10");
    setInt(tomorrow[7].preds[2].toFixed(0));
    setMaxval(
      0 < tomorrow[7].preds[2].toFixed(0) &&
        tomorrow[7].preds[2].toFixed(0) <= 90
        ? 90
        : 90 < tomorrow[7].preds[2].toFixed(0) &&
          tomorrow[7].preds[2].toFixed(0) <= 180
        ? 180
        : 180 < tomorrow[7].preds[2].toFixed(0) &&
          tomorrow[7].preds[2].toFixed(0) <= 324
        ? 324
        : 324 < tomorrow[7].preds[2].toFixed(0) &&
          tomorrow[7].preds[2].toFixed(0) <= 900
        ? 900
        : 900 < tomorrow[7].preds[2].toFixed(0) &&
          tomorrow[7].preds[2].toFixed(0) <= 1620
        ? 1620
        : 1620
    );
    setMinval(
      0 < tomorrow[7].preds[2].toFixed(0) &&
        tomorrow[7].preds[2].toFixed(0) <= 90
        ? 0
        : 90 < tomorrow[7].preds[2].toFixed(0) &&
          tomorrow[7].preds[2].toFixed(0) <= 180
        ? 90
        : 180 < tomorrow[7].preds[2].toFixed(0) &&
          tomorrow[7].preds[2].toFixed(0) <= 324
        ? 180
        : 324 < tomorrow[7].preds[2].toFixed(0) &&
          tomorrow[7].preds[2].toFixed(0) <= 900
        ? 324
        : 900 < tomorrow[7].preds[2].toFixed(0) &&
          tomorrow[7].preds[2].toFixed(0) <= 1620
        ? 900
        : 900
    );
    setColor(tomorrow[15].PM10);
    setSit(situation.PM10);
    setIns(false);
    setUnit("μg/m3");
    setTog(true);
    setChange(true);

    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(true);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
    setOpen(true);
  };
  const updateCO = () => {
    setValuee("CO");
    setInt(tomorrow[0].preds[2].toFixed(0));
    setMaxval(
      0 < tomorrow[0].preds[2].toFixed(0) &&
        tomorrow[0].preds[2].toFixed(0) <= 200
        ? 200
        : 200 < tomorrow[0].preds[2].toFixed(0) &&
          tomorrow[0].preds[2].toFixed(0) <= 400
        ? 400
        : 400 < tomorrow[0].preds[2].toFixed(0) &&
          tomorrow[0].preds[2].toFixed(0) <= 1000
        ? 1000
        : 1000 < tomorrow[0].preds[2].toFixed(0) &&
          tomorrow[0].preds[2].toFixed(0) <= 3000
        ? 3000
        : 3000 < tomorrow[0].preds[2].toFixed(0) &&
          tomorrow[0].preds[2].toFixed(0) <= 5000
        ? 5000
        : 10000
    );
    setMinval(
      0 < tomorrow[0].preds[2].toFixed(0) &&
        tomorrow[0].preds[2].toFixed(0) <= 200
        ? 0
        : 200 < tomorrow[0].preds[2].toFixed(0) &&
          tomorrow[0].preds[2].toFixed(0) <= 400
        ? 200
        : 400 < tomorrow[0].preds[2].toFixed(0) &&
          tomorrow[0].preds[2].toFixed(0) <= 1000
        ? 400
        : 1000 < tomorrow[0].preds[2].toFixed(0) &&
          tomorrow[0].preds[2].toFixed(0) <= 3000
        ? 1000
        : 3000 < tomorrow[0].preds[2].toFixed(0) &&
          tomorrow[0].preds[2].toFixed(0) <= 5000
        ? 3000
        : 5000
    );
    setColor(tomorrow[15].CO);
    setSit(situation.CO);
    setIns(false);
    setUnit("μg/m3");
    setTog(true);
    setChange(true);

    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(true);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
    setOpen(true);
  };
  const updateCO2 = () => {
    setValuee("CO2");
    setInt(tomorrow[1].preds[2].toFixed(0));
    setMaxval(
      0 < tomorrow[1].preds[2].toFixed(0) &&
        tomorrow[1].preds[2].toFixed(0) <= 700
        ? 700
        : 700 < tomorrow[15].preds[2].toFixed(0) &&
          tomorrow[15].preds[2].toFixed(0) <= 1100
        ? 1100
        : 1100 < tomorrow[15].preds[2].toFixed(0) &&
          tomorrow[15].preds[2].toFixed(0) <= 1500
        ? 1500
        : 1500 < tomorrow[15].preds[2].toFixed(0) &&
          tomorrow[15].preds[2].toFixed(0) <= 5000
        ? 5000
        : 5000 < tomorrow[15].preds[2].toFixed(0) &&
          tomorrow[15].preds[2].toFixed(0) <= 50000
        ? 50000
        : 50000
    );
    setMinval(
      0 < tomorrow[1].preds[2].toFixed(0) &&
        tomorrow[1].preds[2].toFixed(0) <= 700
        ? 350
        : 700 < tomorrow[15].preds[2].toFixed(0) &&
          tomorrow[15].preds[2].toFixed(0) <= 1100
        ? 700
        : 1100 < tomorrow[15].preds[2].toFixed(0) &&
          tomorrow[15].preds[2].toFixed(0) <= 1500
        ? 1100
        : 1500 < tomorrow[15].preds[2].toFixed(0) &&
          tomorrow[15].preds[2].toFixed(0) <= 5000
        ? 1500
        : 5000 < tomorrow[15].preds[2].toFixed(0) &&
          tomorrow[15].preds[2].toFixed(0) <= 5000
        ? 5000
        : 5000
    );
    setColor(tomorrow[15].CO2);
    setSit(situation.CO2);
    setOpen(true);
    setIns(false);
    setUnit("PPM");
    setTog(true);
    setChange(true);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(true);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateVOCS = () => {
    setValuee("VOCS");
    setInt(tomorrow[12].preds[2].toFixed(0));
    setColor(tomorrow[15].VOCS);
    setSit(situation.VOCS);
    setIns(false);
    setUnit("NM");
    setTog(true);
    setChange(true);
    setMaxval(
      0 < tomorrow[12].preds[2].toFixed(0) &&
        tomorrow[12].preds[2].toFixed(0) <= 100
        ? 100
        : 100 < tomorrow[12].preds[2].toFixed(0) &&
          tomorrow[12].preds[2].toFixed(0) <= 1000
        ? 1000
        : 1000 < tomorrow[12].preds[2].toFixed(0) &&
          tomorrow[12].preds[2].toFixed(0) <= 3000
        ? 3000
        : 3000 < tomorrow[12].preds[2].toFixed(0) &&
          tomorrow[12].preds[2].toFixed(0) <= 35000
        ? 35000
        : 35000 < tomorrow[12].preds[2].toFixed(0) &&
          tomorrow[12].preds[2].toFixed(0) <= 70000
        ? 70000
        : 70000
    );
    setMinval(
      0 < tomorrow[12].preds[2].toFixed(0) &&
        tomorrow[12].preds[2].toFixed(0) <= 100
        ? 0
        : 100 < tomorrow[12].preds[2].toFixed(0) &&
          tomorrow[12].preds[2].toFixed(0) <= 1000
        ? 100
        : 1000 < tomorrow[12].preds[2].toFixed(0) &&
          tomorrow[12].preds[2].toFixed(0) <= 3000
        ? 1000
        : 3000 < tomorrow[12].preds[2].toFixed(0) &&
          tomorrow[12].preds[2].toFixed(0) <= 35000
        ? 3000
        : 35000 < tomorrow[12].preds[2].toFixed(0) &&
          tomorrow[12].preds[2].toFixed(0) <= 70000
        ? 35000
        : 35000
    );
    setOpen(true);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(true);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateNH3 = () => {
    setValuee("NH3");
    setInt(tomorrow[3].preds[2].toFixed(0));
    setSit(situation.NH3);
    setColor(tomorrow[15].NH3);
    setIns(false);
    setChange(true);
    setMaxval(
      0 < tomorrow[3].preds[2].toFixed(0) &&
        tomorrow[3].preds[2].toFixed(0) <= 5
        ? 5
        : 5 < tomorrow[3].preds[2].toFixed(0) &&
          tomorrow[3].preds[2].toFixed(0) <= 8
        ? 8
        : 8 < tomorrow[3].preds[2].toFixed(0) &&
          tomorrow[3].preds[2].toFixed(0) <= 16
        ? 16
        : 16 < tomorrow[3].preds[2].toFixed(0) &&
          tomorrow[3].preds[2].toFixed(0) <= 25
        ? 25
        : 25 < tomorrow[3].preds[2].toFixed(0) &&
          tomorrow[3].preds[2].toFixed(0) <= 40
        ? 40
        : 150
    );
    setMinval(
      0 < tomorrow[3].preds[2].toFixed(0) &&
        tomorrow[3].preds[2].toFixed(0) <= 5
        ? 0
        : 5 < tomorrow[3].preds[2].toFixed(0) &&
          tomorrow[3].preds[2].toFixed(0) <= 8
        ? 5
        : 8 < tomorrow[3].preds[2].toFixed(0) &&
          tomorrow[3].preds[2].toFixed(0) <= 16
        ? 8
        : 16 < tomorrow[3].preds[2].toFixed(0) &&
          tomorrow[3].preds[2].toFixed(0) <= 25
        ? 16
        : 25 < tomorrow[3].preds[2].toFixed(0) &&
          tomorrow[3].preds[2].toFixed(0) <= 40
        ? 25
        : 40
    );
    setOpen(true);
    setUnit("μg/m3");
    setTog(true);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(true);
    setNotoggle(false);
    setO3toggle(false);
  };
  const updateNO = () => {
    setValuee("NO");
    setInt(tomorrow[4].preds[2].toFixed(0));
    setSit(situation.NO);
    setColor(tomorrow[15].NO);
    setIns(false);
    setChange(true);
    setMaxval(
      0 < tomorrow[4].preds[2].toFixed(0) &&
        tomorrow[4].preds[2].toFixed(0) <= 20
        ? 20
        : 20 < tomorrow[4].preds[2].toFixed(0) &&
          tomorrow[4].preds[2].toFixed(0) <= 40
        ? 40
        : 40 < tomorrow[4].preds[2].toFixed(0) &&
          tomorrow[4].preds[2].toFixed(0) <= 65
        ? 65
        : 65 < tomorrow[4].preds[2].toFixed(0) &&
          tomorrow[4].preds[2].toFixed(0) <= 250
        ? 250
        : 250 < tomorrow[4].preds[2].toFixed(0) &&
          tomorrow[4].preds[2].toFixed(0) <= 600
        ? 600
        : 5000
    );
    setMinval(
      0 < tomorrow[4].preds[2].toFixed(0) &&
        tomorrow[4].preds[2].toFixed(0) <= 20
        ? 0
        : 20 < tomorrow[4].preds[2].toFixed(0) &&
          tomorrow[4].preds[2].toFixed(0) <= 40
        ? 20
        : 40 < tomorrow[4].preds[2].toFixed(0) &&
          tomorrow[4].preds[2].toFixed(0) <= 65
        ? 40
        : 65 < tomorrow[4].preds[2].toFixed(0) &&
          tomorrow[4].preds[2].toFixed(0) <= 250
        ? 250
        : 250 < tomorrow[4].preds[2].toFixed(0) &&
          tomorrow[4].preds[2].toFixed(0) <= 600
        ? 250
        : 600
    );
    setOpen(true);
    setUnit("μg/m3");
    setTog(true);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(true);
    setO3toggle(false);
  };
  const updateO3 = () => {
    setValuee("O3");
    setInt(tomorrow[6].preds[2].toFixed(0));
    setColor(tomorrow[15].O3);
    setSit(situation.O3);
    setIns(false);
    setChange(true);
    setMaxval(
      0 < tomorrow[6].preds[2].toFixed(0) &&
        tomorrow[6].preds[2].toFixed(0) <= 20
        ? 20
        : 20 < tomorrow[6].preds[2].toFixed(0) &&
          tomorrow[6].preds[2].toFixed(0) <= 50
        ? 50
        : 50 < tomorrow[6].preds[2].toFixed(0) &&
          tomorrow[6].preds[2].toFixed(0) <= 75
        ? 75
        : 75 < tomorrow[6].preds[2].toFixed(0) &&
          tomorrow[6].preds[2].toFixed(0) <= 100
        ? 100
        : 100 < tomorrow[6].preds[2].toFixed(0) &&
          tomorrow[6].preds[2].toFixed(0) <= 150
        ? 150
        : 500
    );
    setMinval(
      0 < tomorrow[6].preds[2].toFixed(0) &&
        tomorrow[6].preds[2].toFixed(0) <= 20
        ? 0
        : 20 < tomorrow[6].preds[2].toFixed(0) &&
          tomorrow[6].preds[2].toFixed(0) <= 50
        ? 20
        : 50 < tomorrow[6].preds[2].toFixed(0) &&
          tomorrow[6].preds[2].toFixed(0) <= 75
        ? 50
        : 75 < tomorrow[6].preds[2].toFixed(0) &&
          tomorrow[6].preds[2].toFixed(0) <= 100
        ? 75
        : 100 < tomorrow[6].preds[2].toFixed(0) &&
          tomorrow[6].preds[2].toFixed(0) <= 150
        ? 100
        : 150
    );
    setOpen(true);
    setUnit("μg/m3");
    setTog(true);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(true);
  };
  const unSelected = () => {
    setOpen(false);
    setNo2toggle(false);
    setSo2toggle(false);
    setPm2_5toggle(false);
    setPm10toggle(false);
    setCo2toggle(false);
    setCotoggle(false);
    setVocstoggle(false);
    setNh3toggle(false);
    setNotoggle(false);
    setO3toggle(false);
  };
  const unSelected2 = () => {
    setSecondOpen(false);
    setTemptoggle(false);
    setHumtoggle(false);
    setWdtoggle(false);
    setWstoggle(false);
    setPresstoggle(false);
  };
  const toggleTemp = (toggle) => {
    setToggle(true);
  };
  const toggleHum = (toggle) => {
    setToggle(false);
  };

  return (
    <Fragment>
      {loading && props.load ? (
        <div className="paralel_Tomorrow">
          {predictStatus == 400 ? (
            <TomorrowPassive
              weather={""}
              color={"white"}
              day={day}
              date={date}
              Header={"Yarın Beklenen Hava Kalite Değerleri Bulunmamaktadır"}
            />
          ) : (
            <div>
              <p className="content_header">
                Yarın Beklenen Hava Kalite Değerleri
              </p>
              <WeatherCard
                weather={weather.WeatherDesc}
                color={
                  aqi.AqiDesc[2] == "Hassas"
                    ? "white"
                    : aqi.AqiDesc[2] == "Orta"
                    ? "white"
                    : aqi.AqiDesc[2] == "İyi"
                    ? "white"
                    : aqi.AqiDesc[2] == "Sağlıksız"
                    ? "white"
                    : aqi.AqiDesc[2] == "Kötü"
                    ? "white"
                    : aqi.AqiDesc[2] == "Tehlikeli"
                    ? six
                    : six
                }
                day={day}
                date={date}
                degree={tomorrow[11].preds[2].toFixed(0)}
                image={
                  aqi.AqiDesc[2] == "Hassas"
                    ? three
                    : aqi.AqiDesc[2] == "Orta"
                    ? two
                    : aqi.AqiDesc[2] == "İyi"
                    ? one
                    : aqi.AqiDesc[2] == "Sağlıksız"
                    ? four
                    : aqi.AqiDesc[2] == "Kötü"
                    ? five
                    : aqi.AqiDesc[2] == "Tehlikeli"
                    ? six
                    : six
                }
              />
              <AirQualityIndex
                value={aqi.AqiValue[2]}
                max1={500}
                min1={0}
                bgcolor={aqi.AqiColor[2]}
                airSituation={aqi.AqiDesc[2]}
                text={
                  aqi.AqiDesc[2] == "Hassas"
                    ? "Hava Kalitesi yaşlılar, çocuklar ve solunum rahatsızlığı olanları etkileyebilecek seviyededir"
                    : aqi.AqiDesc[2] == "Orta"
                    ? "Hava kalitesi sağlık açısından uygun seviyededir"
                    : aqi.AqiDesc[2] == "İyi"
                    ? "Hava kalitesi iyi seviyededir"
                    : aqi.AqiDesc[2] == "Sağlıksız"
                    ? "Sağlıksız"
                    : aqi.AqiDesc[2] == "Kötü"
                    ? "Kötü"
                    : aqi.AqiDesc[2] == "Tehlikeli"
                    ? "Tehlikeli"
                    : " Hava Kalitesi sağlık açısından uygun seviyededir"
                }
              />

              <div className="scroll_Tomorrow">
                <div className="column_Tomorrow">
                  <TomorrowList11
                    Detail_Header={valuee}
                    Detail_Value={
                      ins ? tomorrow[10].preds[2].toFixed(0) + "°C" : int
                    }
                    Detail_Text="Bölgedeki değer uygun koşullarda gözükmektedir"
                    Detail_Time={timer}
                    Detail_MAX={maxval}
                    Detail_MIN={minval}
                    Detail_Situation={ins ? situation.Temp : sit}
                    Detail_Unit={unit}
                    Detail_Change={change}
                    Detail_Initial={ins}
                    Detail_In={tomorrow[10].preds[2].toFixed(0)}
                    Detail_Toggle={tog}
                    Detail_Color={color}
                    Detail_Click={unSelected}
                    opened={open}
                    open={open}
                    // temp={updateTemp}
                    // hum={updateHum}

                    no2={updateNO2}
                    so2={updateSO2}
                    pm2_5={updatePM2_5}
                    pm10={updatePM10}
                    co={updateCO}
                    co2={updateCO2}
                    vocs={updateVOCS}
                    nh3={updateNH3}
                    no={updateNO}
                    o3={updateO3}
                    co2_pre={co2toggle}
                    no2_pre={no2toggle}
                    so2_pre={so2toggle}
                    pm2_5_pre={pm2_5toggle}
                    pm10_pre={pm10toggle}
                    co_pre={cotoggle}
                    vocs_pre={vocstoggle}
                    nh3_pre={nh3toggle}
                    no_pre={notoggle}
                    o3_pre={o3toggle}
                    avgCO2={tomorrow[1].preds[2]}
                    NO2={tomorrow[5].preds[2]}
                    SO2={tomorrow[10].preds[2]}
                    PM2_5={tomorrow[8].preds[2]}
                    PM10={tomorrow[7].preds[2]}
                    avgCO={tomorrow[0].preds[2]}
                    VOCs={tomorrow[12].preds[2]}
                    NH3={tomorrow[3].preds[2]}
                    NO={tomorrow[4].preds[2]}
                    O3={tomorrow[6].preds[2]}
                  />
                </div>
              </div>
              <div className="scroll_Tomorrow1">
                <div className="column_Tomorrow1">
                  <TomorrowList12
                    Detail_Header={secondvaluee}
                    Detail_Value={
                      secondins
                        ? tomorrow[10].preds[2].toFixed(0) + "°C"
                        : secondint
                    }
                    Detail_Text="Bölgedeki değer uygun koşullarda gözükmektedir"
                    Detail_Time={timer}
                    Detail_MAX={secondmaxval}
                    Detail_MIN={secondminval}
                    Detail_Situation={secondins ? situation.Temp : secondsit}
                    Detail_Unit={secondunit}
                    Detail_Change={secondchange}
                    Detail_Initial={secondins}
                    Detail_In={tomorrow[10].preds[2].toFixed(0)}
                    Detail_Toggle={secondtog}
                    Detail_Color={secondcolor}
                    Detail_Click={unSelected2}
                    opened={secondopen}
                    open={secondopen}
                    temp={updateTemp}
                    hum={updateHum}
                    wd={updateWD}
                    ws={updateWS}
                    pres={updatePres}
                    pres_pre={prestoggle}
                    temp_pre={temptoggle}
                    hum_pre={humtoggle}
                    wd_pre={wdtoggle}
                    ws_pre={wstoggle}
                    Temparature={tomorrow[11].preds[2]}
                    Hummidty={tomorrow[2].preds[2]}
                    WindSpeed={tomorrow[14].preds[2]}
                    WindDirection={tomorrow[13].preds[2]}
                    Pressure={tomorrow[9].preds[2]}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </Fragment>
  );
};

export default Tomorrow1;
