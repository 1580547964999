import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { ButtonComponent } from "../../../../components/Shared/ButtonComponent/component";
import { CardGroupComponent } from "../../../../components/Shared/CardComponent/component";
import { IconContainerComponent } from "../../../../components/Shared/IconContainerComponent/component";
import { IconContainerMobileComponent } from "../../../../components/Shared/IconContainerMobileComponent/component";
import { ImageComponent } from "../../../../components/Shared/ImageComponent/component";
import { SliderProductsComponent } from "../../../../components/Shared/SliderProductsComponent/component";
import { TabComponent } from "../../../../components/Shared/TabComponent/component";
import { TableComponent } from "../../../../components/Shared/TableComponent/component";
import { TextContainerComponent } from "../../../../components/Shared/TextContainerComponent/component";
import { SliderComponent } from "../../../../components/SliderComponent";
import {
  IconSpiritSeriesContent,
  SliderSpiritCarSeries,
  SliderSpiritSeries,
  spiritContent,
  spiritContentTechnicalData,
  TextContainerSpiritSeries,
  TextContainerSpiritSeriesMobile,
} from "../../../../constants/datas";
import theme from "../../../../constants/theme";
import { TranslationService } from "../../../../services/translation.service";
import { store } from "../../../../store";
import "./index.css";
import { Props } from "./types";

const sliderIcons = [
  {
    icon: theme.IMAGES.products.airPurifiers.spirit.art1,
    text: "sliderIco1",
    key: "SpiritSeries",
  },
  {
    icon: theme.IMAGES.products.airPurifiers.spirit.art2,
    text: "sliderIco2",
    key: "SpiritSeries",
  },
  {
    icon: theme.IMAGES.products.airPurifiers.spirit.art3,
    text: "sliderIco3",
    key: "SpiritSeries",
  },
  {
    icon: theme.IMAGES.products.airPurifiers.spirit.art4,
    text: "sliderIco4",
    key: "SpiritSeries",
  },
  {
    icon: theme.IMAGES.products.airPurifiers.spirit.art5,
    text: "sliderIco5",
    key: "SpiritSeries",
  },
];

export function ScreenComponent(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "SpiritSeries";
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);

  const button = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: isMobile ? "start" : "start",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
          marginLeft: isMobile ? "3%" : "0%",
        }}
      >
        <ButtonComponent
          styleHover={{
            backgroundColor: theme.COLORS.blue,
            color: theme.COLORS.white,
          }}
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
        />
      </div>
    );
  };

  const children = [
    {
      title: t("PiatraSeries", "overView"),
      body: (
        <div
          style={{
            backgroundColor: "white",
            marginTop: props.isMobile ? 70 : 0,
          }}
        >
          <SliderComponent sliderItems={SliderSpiritSeries} />
          <TextContainerComponent
            texts={TextContainerSpiritSeries}
            columnNumber={3}
            style={{
              marginTop: 50,
              marginBottom: 50,
              width: "80%",
              fontSize: "",
            }}
          />
          <ImageComponent
            src={
              isMobile
                ? theme.IMAGES.products.airPurifiers.spirit.spiritMobile //for mobile
                : theme.IMAGES.products.airPurifiers.spirit.spirit
            }
            alt={"sercair"}
          />
          <div
            className="col-lg-12 col-xl-12 col-md-12 col-sm-12 d-flex"
            style={{ marginTop: 50 }}
          >
            <div
              className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center"
              style={{ marginLeft: 20 }}
            >
              {sliderIcons.map((item, index) => {
                return (
                  <div className="d-flex aling-items-center justify-content-center">
                    <div
                      key={"piatraSlider" + index}
                      className="d-flex flex-column my-2 d-flex align-items-center justify-content-center text-center"
                    >
                      <ImageComponent
                        src={item.icon}
                        alt={"sercair"}
                        width={40}
                      />
                      <div className="mt-3" style={{ width: 170, height: 50 }}>
                        {t(item.key, item.text)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{ gap: 20 }}
            className="d-flex justify-content-center flex-column mt-4 text-center"
          >
            <h2 className="gothamLight">
              {t(translationKey, "sliderIcoHeader")}
            </h2>
            <div style={{ width: "50%", margin: "auto" }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(translationKey, "sliderIcoText"),
                }}
              ></p>
            </div>
          </div>
          <ImageComponent
            src={
              isMobile
                ? theme.IMAGES.products.airPurifiers.spirit.insideSpiritMobile //for mobile
                : theme.IMAGES.products.airPurifiers.spirit.insideSpirit
            }
            alt={"sercair"}
          />

          {isMobile ? (
            <div
              className="container"
              style={{ textAlign: "center", marginTop: 50 }}
            >
              <h3
                dangerouslySetInnerHTML={{
                  __html: t(translationKey, "carTitle"),
                }}
              ></h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(translationKey, "carTextMobile"),
                }}
                style={{ marginBottom: 50 }}
              ></div>
              <ImageComponent
                src={theme.IMAGES.products.airPurifiers.spirit.spiritCarMobile}
                alt="sercair"
              />
            </div>
          ) : (
            <SliderComponent sliderItems={SliderSpiritCarSeries} />
          )}

          <Container
            style={{ textAlign: "center", marginTop: 75, marginBottom: 75 }}
          >
            <h2>{t(translationKey, "icoContainerTitle")}</h2>
            <IconContainerComponent icons={IconSpiritSeriesContent} />
          </Container>

          <ImageComponent
            src={
              isMobile
                ? theme.IMAGES.products.airPurifiers.spirit.fingerMobile
                : theme.IMAGES.products.airPurifiers.spirit.finger
            }
            alt="sercair"
          />
          <hr />
          <Container
            style={{
              marginTop: 120,
              marginBottom: 50,
              marginLeft: isMobile ? 32 : 170,
            }}
          >
            {/* <CardProductsGroupComponent
              cards={[
                {
                  imgWidth: 50,
                  title: t(translationKey, "technicalProducts1Title"),
                  text: t(translationKey, "technicalProductsText"),
                  imgUrl: theme.IMAGES.products.technicalSpecifications.spirit,
                  imgAlt: t(translationKey, "altProductsAlt"),
                  isBtn: false,
                  btnText: t(translationKey, "addToBasket"),
                  btnUrl:
                    "https://www.sercairstore.com/urun/sercair-spirit-mini-hava-temizleyici",
                  btnIsLong: true,
                  titleColor: theme.COLORS.blue,
                  textColor: theme.COLORS.blue,
                  isBackgroundColor: true,
                  imgPosition: "left",
                  keyComponent: translationKey,
                  isShadow: true,
                  bodyColor: theme.COLORS.productBlue,
                },
              ]}
              buttonComponent={button}
            /> */}
          </Container>
        </div>
      ),
    },
    {
      title: t("PureSeries", "tabTitle2"),
      body: (
        <div style={{ backgroundColor: "white" }}>
          <div className="d-flex justify-content-center">
            <ImageComponent
              style={{ marginTop: 30 }}
              src={theme.IMAGES.products.technicalSpecifications.spiritSlider}
              alt="sercair"
              width={100}
            />
          </div>
          <TableComponent
            data={spiritContentTechnicalData}
            keyComponent={translationKey}
          />
          <hr />
          {!isMobile ? (
            <CardGroupComponent cards={spiritContent} />
          ) : (
            <div className="d-flex justify-content-center my-4">
              <div style={{ width: isMobile ? "70%" : "80%" }}>
                <SliderProductsComponent
                  textPosition="bottom"
                  sliderBottomItems={spiritContent}
                  minHeight={402}
                />
              </div>
            </div>
          )}
          <hr />
          <Container
            style={{
              marginTop: 120,
              marginBottom: 50,
              marginLeft: isMobile ? 32 : 170,
            }}
          >
            {/* <CardProductsGroupComponent
              cards={[
                {
                  title: t(translationKey, "technicalProducts1Title"),
                  text: t(translationKey, "technicalProductsText"),
                  imgUrl: theme.IMAGES.products.technicalSpecifications.spirit,
                  imgAlt: t(translationKey, "altProductsAlt"),
                  isBtn: false,
                  btnText: t(translationKey, "addToBasket"),
                  btnUrl:
                    "https://www.sercairstore.com/urun/sercair-spirit-mini-hava-temizleyici",
                  btnIsLong: true,
                  titleColor: theme.COLORS.blue,
                  textColor: theme.COLORS.blue,
                  isBackgroundColor: true,
                  imgPosition: "left",
                  keyComponent: translationKey,
                  isShadow: true,
                  bodyColor: theme.COLORS.productBlue,
                },
              ]}
              buttonComponent={button}
            /> */}
          </Container>
        </div>
      ),
    },
  ];

  const childrenMobile = [
    {
      title: t("PiatraSeries", "overView"),
      body: (
        <div
          className="mobile-slider-spirit"
          style={{
            backgroundColor: "white",
          }}
        >
          <SliderComponent sliderItems={SliderSpiritSeries} />
          <TextContainerComponent
            texts={TextContainerSpiritSeriesMobile}
            columnNumber={3}
            style={{
              marginTop: 0,
              marginBottom: 0,
              justifyContent: "center",
              textAlign: "center",
            }}
          />
          <ImageComponent
            src={theme.IMAGES.products.airPurifiers.spirit.spiritMobile} //for mobile
            alt={"sercair"}
          />
          <div
            className="col-lg-8 col-xl-8 col-md-8 col-sm-12 d-flex"
            style={{ marginTop: 50 }}
          >
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              {sliderIcons.map((item, index) => {
                return (
                  <div
                    key={"piatraSlider" + index}
                    className="d-flex align-items-center justify-content-center my-2"
                  >
                    <ImageComponent
                      src={item.icon}
                      alt={"sercair"}
                      width={70}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{ gap: 20 }}
            className="d-flex justify-content-center flex-column mt-4 text-center"
          >
            <h2 className="gothamLight">
              {t(translationKey, "sliderIcoHeader")}
            </h2>
            <p
              className="p-2"
              dangerouslySetInnerHTML={{
                __html: t(translationKey, "sliderIcoText"),
              }}
            ></p>
          </div>
          <ImageComponent
            src={
              isMobile
                ? theme.IMAGES.products.airPurifiers.spirit.insideSpiritMobile //for mobile
                : theme.IMAGES.products.airPurifiers.spirit.insideSpirit
            }
            alt={"sercair"}
          />

          {isMobile ? (
            <div
              className="container"
              style={{ textAlign: "center", marginTop: 25 }}
            >
              <h3
                dangerouslySetInnerHTML={{
                  __html: t(translationKey, "carTitle"),
                }}
              ></h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(translationKey, "carTextMobile"),
                }}
                style={{
                  fontSize: 11,
                  marginBottom: 25,
                  width: 250,
                  textAlign: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              ></div>

              <ImageComponent
                src={theme.IMAGES.products.airPurifiers.spirit.spiritCarMobile}
                alt="sercair"
              />
            </div>
          ) : (
            <SliderComponent sliderItems={SliderSpiritCarSeries} />
          )}

          <Container
            style={{ textAlign: "center", marginTop: 75, marginBottom: 75 }}
          >
            <h2>{t(translationKey, "icoContainerTitle")}</h2>
            {/* <IconContainerComponent icons={IconSpiritSeriesContent} /> */}
            <IconContainerMobileComponent
              textWidth={100}
              isSpirit={true}
              iconWidth={95}
              icons={IconSpiritSeriesContent}
            />{" "}
            {/*burdan devam edersin*/}
          </Container>

          <ImageComponent
            src={
              isMobile
                ? theme.IMAGES.products.airPurifiers.spirit.fingerMobile
                : theme.IMAGES.products.airPurifiers.spirit.finger
            }
            alt="sercair"
          />
          <hr />
          <Container
            style={{
              marginTop: 120,
              marginBottom: 50,
              marginLeft: isMobile ? 32 : 170,
            }}
          >
            {/* <CardProductsGroupComponent
              cards={[
                {
                  imgWidth: 50,
                  title: t(translationKey, "technicalProducts1Title"),
                  text: t(translationKey, "technicalProductsText"),
                  imgUrl: theme.IMAGES.products.technicalSpecifications.spirit,
                  imgAlt: t(translationKey, "altProductsAlt"),
                  isBtn: false,
                  btnText: t(translationKey, "addToBasket"),
                  btnUrl:
                    "https://www.sercairstore.com/urun/sercair-spirit-mini-hava-temizleyici",
                  btnIsLong: true,
                  titleColor: theme.COLORS.blue,
                  textColor: theme.COLORS.blue,
                  isBackgroundColor: true,
                  imgPosition: "left",
                  keyComponent: translationKey,
                  isShadow: true,
                  bodyColor: theme.COLORS.productBlue,
                },
              ]}
              buttonComponent={button}
            /> */}
          </Container>
        </div>
      ),
    },
    {
      title: t("PureSeries", "tabTitle2"),
      body: (
        <div style={{ backgroundColor: "white" }}>
          <div className="d-flex justify-content-center">
            <ImageComponent
              style={{ marginTop: 110 }}
              src={theme.IMAGES.products.technicalSpecifications.spiritSlider}
              alt="sercair"
              width={50}
            />
          </div>
          <TableComponent
            data={spiritContentTechnicalData}
            keyComponent={translationKey}
          />
          <hr />
          {!isMobile ? (
            <CardGroupComponent cards={spiritContent} />
          ) : (
            <div className="d-flex justify-content-center my-4">
              <div style={{ width: isMobile ? "70%" : "80%" }}>
                <SliderProductsComponent
                  textPosition="bottom"
                  sliderBottomItems={spiritContent}
                  minHeight={402}
                />
              </div>
            </div>
          )}
          <hr />
          <Container
            style={{
              marginTop: 120,
              marginBottom: 50,
              marginLeft: isMobile ? 32 : 170,
            }}
          >
            {/* <CardProductsGroupComponent
              cards={[
                {
                  title: t(translationKey, "technicalProducts1Title"),
                  text: t(translationKey, "technicalProductsText"),
                  imgUrl: theme.IMAGES.products.technicalSpecifications.spirit,
                  imgAlt: t(translationKey, "altProductsAlt"),
                  isBtn: false,
                  btnText: t(translationKey, "addToBasket"),
                  btnUrl:
                    "https://www.sercairstore.com/urun/sercair-spirit-mini-hava-temizleyici",
                  btnIsLong: true,
                  titleColor: theme.COLORS.blue,
                  textColor: theme.COLORS.blue,
                  isBackgroundColor: true,
                  imgPosition: "left",
                  keyComponent: translationKey,
                  isShadow: true,
                  bodyColor: theme.COLORS.productBlue,
                },
              ]}
              buttonComponent={button}
            /> */}
          </Container>
        </div>
      ),
    },
  ];

  return (
    <div>
      <TabComponent
        children={isMobile ? childrenMobile : children}
        componentKey={"PureSeries"}
      />
    </div>
  );
}
