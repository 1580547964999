import { positions } from "../../../../constants/theme";

export const Style = {
    textCentered: {
        textAlign: "center" as positions,
        display: "flex",
        justifyContent: "center",
        alignItems: "center" as positions,
    },
    maxWidth:{
        width:'60%'
    }
}