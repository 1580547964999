import { Container } from "react-bootstrap";
import theme from "../../../constants/theme";
import { TranslationService } from "../../../services/translation.service";
import { Props } from "./types";
import { useEffect } from "react";

export function ScreenComponent(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "Cookie";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container style={{ marginTop: 50, marginBottom: 100, maxWidth: "80vw" }}>
      <h2
        style={{ color: theme.COLORS.blue }}
        className="gothamMedium"
        dangerouslySetInnerHTML={{ __html: t(translationKey, "titleMain") }}
      />
      <p
        style={{ fontSize: "11pt" }}
        dangerouslySetInnerHTML={{ __html: t(translationKey, "text") }}
      />
      {
        /* items get here */
        Array.from(Array(12).keys()).map((i, index) => {
          return (
            <>
              <p
                style={{ color: theme.COLORS.blue, fontSize: "18pt" }}
                dangerouslySetInnerHTML={{
                  __html: t(translationKey, "subtitle" + (index + 1)),
                }}
              />
              <p
                style={{ fontSize: "11pt" }}
                dangerouslySetInnerHTML={{
                  __html: t(translationKey, "subText" + (index + 1)),
                }}
              />
            </>
          );
        })
      }

      <p
        style={{ color: theme.COLORS.blue, fontSize: "18pt" }}
        dangerouslySetInnerHTML={{ __html: t(translationKey, "subtitle1") }}
      />
      <p
        style={{ fontSize: "11pt" }}
        dangerouslySetInnerHTML={{ __html: t(translationKey, "subText1") }}
      />
    </Container>
  );
}
