import theme from "../../../../constants/theme";

export const Style = {
  helpAndSupport: {
    backgroundColor: theme.COLORS.blueSupport,
  },
  title: {
    color: theme.COLORS.blue,
    fontSize: 16
  }
};
