import theme from "../../../../constants/theme";

export const Style = {
  backGround: {
    backgroundImage: `url(${theme.IMAGES.modals.languageModal.background})`,
    backgroundSize: "cover",
    backgroundPosition: "top-center",
    backgroundRepeat: "no-repeat",
    marginBottom: 10
  },
  body:{
    padding:40,
    paddingTop: 20,
    marginBottom: -10,
    
  },
  text:{
    color: theme.COLORS.blue,
    fontSize: 15,
    paddingLeft: 12,
  },
  textMobile:{
    color: theme.COLORS.blue,
    fontSize: 15,
    paddingLeft: 12,
    paddingBottom: 2,
    
  },
  title:{
    fontSize: 16,
    color:theme.COLORS.blue,
    textAlign: 'center' as 'center',
    marginBottom:20
  },
  noBorder:{
    border: "none",
    marginBottom: 5,
    paddingBottom: 20 //To hide blur on changelanguage
  },
  btnSend: {
    backgroundColor: theme.COLORS.blue,
    color: theme.COLORS.white,
    borderRadius: theme.BORDER_RADIUS.large,
    padding: "6px"
  },
};
