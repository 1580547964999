import React from "react";


import Button from "../Button/Button"

import "./guess.css";


const Guess = (props) => {
  return (
    <Button type="button" onClick={props.butt} pressable={true}>
      <div key={props.keyy} className={props.info ? "pressedg" : "infog"}>
       

        <p className={"valueg" }>
          {props.value}
        </p>
      </div>
    </Button>
  );
};

export default Guess;
