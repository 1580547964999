import { Props } from "./types";
import "./style.css";
import { Style } from "./style";
import { Container, Row, Table } from "react-bootstrap";
import { TranslationService } from "../../../services/translation.service";
import { store } from "../../../store";
import { TabComponent } from "../TabComponent/component";
import { useEffect, useState } from "react";
import React from "react";
import theme from "../../../constants/theme";
export function TableComponent(props: Props) {
  const data = props.data;
  const t = TranslationService.translate;

  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
  }, []);
  const tableMobileGenerator = () => {
    return (
      data.bodyHeader &&
      data.bodyHeader.map((title, titleIndex) => {
        return [
          {
            title: title,
            body: (
              <>
                {data &&
                  data.body.map((properties, pIndex) => {
                    return (
                      <Container key={"tableContainer" + pIndex}>
                        <div
                          className="d-flex justify-content-between"
                          key={"properties" + pIndex}
                        >
                          <div
                          style={{fontSize: isMobile ? 12 : 12}} //Technical Specifications titles
                            dangerouslySetInnerHTML={{
                              __html: t(
                                props.keyComponent,
                                properties.bodyTitle
                              ),
                            }}
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: t(
                                props.keyComponent,
                                properties.bodyItems[titleIndex]
                              ),
                            }}
                          />
                        </div>
                        {properties.bodyItems[titleIndex] !== "technicalSpecs19" && <hr />} {/*To hide bottom line after Mobile Support*/} 
                      </Container>
                    );
                  })}
              </>
            ),
          },
        ];
      })
    );
  };
  const [tabArrayItems, setTabArrayItems] = useState();

  /* eslint-disable */
  useEffect(() => {
    //@ts-ignore
    let xss = tableMobileGenerator();
    //@ts-ignore
    setTabArrayItems(xss.flat());
  }, []);
  /* eslint-enable */
  return (
    <Container>
      {isMobile ? (
        tabArrayItems && (
          //@ts-ignore
          <TabComponent
            children={tabArrayItems}
            componentKey={props.keyComponent}
            className="technicalTable"
          />
        )
      ) : (
        <Row>
          <div className="column col-12 fs-4 " style={Style.tableComponentTitle}>
            {data.title && t(props.keyComponent, data.title)}
          </div>
          {data.titleLeft && (
            <div
              className="column col-2 d-flex align-items-center fw-bold "
              style={{ fontSize: 18, fontFamily: theme.FONTS.GothamBook, }}
            >
              {t(props.keyComponent, data.titleLeft)}
            </div>
          )}
          <div
            className={
              data.titleLeft
                ? "column col-10 d-flex justify-content-center"
                : "column col-12 d-flex justify-content-center"
            }
            style={{ }}
          >
            <Table
              style={{
                width:
                  data.bodyHeader && data.bodyHeader.length === 1
                    ? "40%"
                    : "100%",
                marginLeft: isMobile ? 0 : 90,
              }}
            >
              <thead>
                <tr style={{ fontSize: 12 }}>
                  <th> </th>
                  {data.bodyHeader &&
                    data.bodyHeader.map((item, index) => {
                      return (
                        <th key={index + "th"}>
                          <div
                            style={
                              data.bodyHeader && data.bodyHeader.length === 1
                                ? Style.bodyHeaderNone
                                : Style.bodyHeader
                            }
                            dangerouslySetInnerHTML={{
                              __html: t(props.keyComponent, item),
                            }}
                          ></div>
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {data.body.map((item, index) => {
                  return (
                    <tr
                      key={index + "trBody"}
                      style={{
                        fontSize:
                          data.bodyHeader && data.bodyHeader.length === 1
                            ? 12
                            : 12,
                            
                      }}
                    >
                      <td
                      style={{ fontWeight: "light"}}
                        className="d-flex align-items-center"
                        dangerouslySetInnerHTML={{
                          __html: t(props.keyComponent, item.bodyTitle),
                        }}
                      ></td>
                      {item.bodyItems.map((item, index) => {
                        return (
                          <td
                          style={{ fontWeight: "light"}}
                            key={index + "tdBody"}
                            dangerouslySetInnerHTML={{
                              __html: t(props.keyComponent, item),
                            }}
                          ></td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Row>
      )}
    </Container>
  );
}
