import "./style.css";
import { Container } from "react-bootstrap";
import theme from "../../constants/theme";
import { TranslationService } from "../../services/translation.service";
import { Props } from "./types";
import { useEffect, useState } from "react";
import { CatalogsService } from "../../API/services/Catalogs.service";
import { CatalogModel } from "../../models/catalog.model";
import { APIURLs } from "../../API/consts/APIURLs";

export function ScreenComponent(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "Catalogs";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [catalogCardItems, setCatalogCardItems] = useState<CatalogModel[]>([]);

  useEffect(() => {
    CatalogsService.getCatalogs(TranslationService.getLang().code).then(
      (res) => {
        setCatalogCardItems(res.data);
      }
    );
  }, [TranslationService.getLang()]);
  return (
    <Container style={{ marginTop: 60, marginBottom: 100 }}>
      <div className="d-flex align-items-center justify-content-center">
        <img
          className="mobile-img"
          src={theme.IMAGES.catalogs.title}
          alt={"sercair"}
        />
        <h2
          className="ms-4 gothamMedium mobile-font"
          style={{ color: theme.COLORS.blue }}
        >
          {t(translationKey, "titleMain")}
        </h2>
      </div>
      <div className="row m-0 pt-5 justify-content-center">
        {catalogCardItems &&
          catalogCardItems.map((item, index) => {
            return (
              <div
                key={index + "catalogCard"}
                className="col-auto m-0 p-0 card m-3 m-xs-4 catalogs-panel text-left"
              >
                <div className="card-img-top p-0 m-0">
                  <img
                    className="card-img-top p-0 m-0"
                    src={APIURLs.MEDIA_URL + "catalog/" + item.imgUrl}
                    style={{ width: "100%" }}
                    alt={"sercair"}
                  ></img>
                </div>
                <div className="card-body catalogs-body">
                  <h4 className="card-title catalogs-text1">
                    {t(translationKey, item.title)}
                  </h4>
                  <div className="d-flex  m-0 justify-content-center">
                    <a
                      rel="noreferrer"
                      target={"_blank"}
                      href={APIURLs.MEDIA_URL + "catalog/" + item.pdfUrl}
                      style={{ marginRight: 10 }}
                    >
                      <div className="col-auto text-center catalogs-btn-show mr-1">
                        {t(translationKey, "btnShow")}
                      </div>
                    </a>
                    <a
                      href={APIURLs.MEDIA_URL + "catalog/" + item.pdfUrl}
                      download={t(translationKey, item.title)}
                    >
                      <div className="col-auto text-center catalogs-btn-download ml-1">
                        {t(translationKey, "btnDownload")}
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Container>
  );
}
