import {
  SalesPoints,
  SalesPointsTR,
  SalesPointsNL,
  SalesPointsRS,
  SalesPointsFR,
  SalesPointsUK,
  SalesPointsRO,
} from "../../screens/SalesPoints";

export const SalesPointsScreenNavigator = [
  {
    path: "/salespoints",
    exact: true,
    screen: <SalesPoints />,
  },
  {
    path: "/tr/satis-noktalari",
    exact: true,
    screen: <SalesPointsTR />,
  },
  {
    path: "/nl/verkooppunten",
    exact: true,
    screen: <SalesPointsNL />,
  },
  {
    path: "/rs/prodajna-mesta",
    exact: true,
    screen: <SalesPointsRS />,
  },
  {
    path: "/fr/points-de-vente",
    exact: true,
    screen: <SalesPointsFR />,
  },
  {
    path: "/uk/salespoints",
    exact: true,
    screen: <SalesPointsUK />,
  },
  {
    path: "/ro/puncte-de-vanzare",
    exact: true,
    screen: <SalesPointsRO />,
  },
];
