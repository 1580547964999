import theme from "../../../constants/theme";

export const Style = {
  main: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    marginTop: theme.SPACES.large,
    backgroundImage: `url(${theme.IMAGES.support.assemblybg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    border: "1px solid #3c9feb",
    borderRadius: "10px",
    padding: "3rem",
  },
  title: {
    fontSize: "24pt",
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
    color: theme.COLORS.blue,
    marginLeft: 14,
  },
  subtitle: {
    fontSize: "12pt",
    color: theme.COLORS.black,
  },
  despText: {
    color: "#2d2d2d",
    fontSize: "8pt",
  },
  errorStyle: {
    color: "#a94442",
    fontSize: "9pt",
  },
  input: {
    width:"100%",
    backgroundColor: "white",
    border: "1px solid #67b7ec",
    borderRadius: "5px",
    padding: "10px",
    fontFamily: "GothamBook",
    fontSize: "10pt",
    height: "45px",
    color: "#2d2d2d",
  },

  btnActive: {
    color: "#ffffff",
    backgroundColor: "#3c9feb",
    fontSize: "9pt",
    width: 140,
    height: 35,
    cursor: "pointer",
    border: "1px solid #3c9feb",
    borderRadius: 50,
  },
  btnPassive: {
    backgroundColor: "#eee",
    color: "#aaa",
    cursor: "auto",
    fontSize: "9pt",
    width: 140,
    height: 35,
    border: " 1px solid #3c9feb",
    borderRadius: 50,
  },
};
