import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import { TranslationService } from "../../services/translation.service";

import { useEffect, useState } from "react";

import MainScreen from "../WeatherScreens/mainScreen/mainScreenW";
import MapScreen from "../WeatherScreens/mapScreen/mapScreen";







export function Component(){

  const t = TranslationService.translate;

  const [lang, setLang] = useState(TranslationService.getLang().code);

 
  return(
    <div>
      <div style={{display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',}}>
       
        <p style={{fontWeight: 700, fontSize: 50}}>wadwadwad</p>
        </div>
        </div>
      
  )
}