import { ButtonComponent } from "../../../../components/Shared/ButtonComponent/component";
import { CardGroupComponent } from "../../../../components/Shared/CardComponent/component";
import { FullImageContentComponent } from "../../../../components/Shared/FullImageContent/component";
import { IconContainerComponent } from "../../../../components/Shared/IconContainerComponent/component";
import { ImageComponent } from "../../../../components/Shared/ImageComponent/component";
import { SliderProductsComponent } from "../../../../components/Shared/SliderProductsComponent/component";
import { TabComponent } from "../../../../components/Shared/TabComponent/component";
import { TableComponent } from "../../../../components/Shared/TableComponent/component";
import {
  CarouselPiatraProducts,
  IconPiatraSeriesContent,
  piatraBoxContent,
  piatraSeriesTechnicalData,
} from "../../../../constants/datas";
import theme from "../../../../constants/theme";
import { TranslationService } from "../../../../services/translation.service";
import { Props } from "./types";

import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import useWindowDimensions from "../../../../services/getWindowSize.service";
import { store } from "../../../../store";
import { ModalSwitcher } from "../../../../utils/modal-switcher";
import "./index.css";

export function ScreenComponent(props: Props) {
  const screenSize = useWindowDimensions().width;
  const [style, setStyle] = React.useState({
    marginLeft: 0,
    marginRight: 0,
  });
  const [isMobile, setIsMobile] = React.useState(false);

  const [lang, setLang] = React.useState(TranslationService.getLang().code);

  useEffect(() => {
    setLang(TranslationService.getLang().code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TranslationService.getLang().code]);

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
    if (screenSize < 1440) {
      setStyle({
        marginLeft: 0,
        marginRight: 0,
      });
    } else if (screenSize < 1441) {
      setStyle({
        marginLeft: 70,
        marginRight: 0,
      });
    } else if (screenSize < 1590) {
      setStyle({
        marginLeft: -12,
        marginRight: 12,
      });
    } else if (screenSize < 1890) {
      setStyle({
        marginLeft: -120,
        marginRight: 120,
      });
    } else if (screenSize <= 1920) {
      setStyle({
        marginLeft: -135,
        marginRight: 135,
      });
    } else if (screenSize < 1980) {
      setStyle({
        marginLeft: -160,
        marginRight: 160,
      });
    } else {
      setStyle({
        marginLeft: -228,
        marginRight: 228,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sliderIcons = [
    {
      icon: theme.IMAGES.products.airPurifiers.piatra.home,
      text: "sliderIco1",
      key: "PiatraSeries",
    },
    {
      icon: theme.IMAGES.products.airPurifiers.piatra.hospital,
      text: "sliderIco2",
      key: "PiatraSeries",
    },
    {
      icon: theme.IMAGES.products.airPurifiers.piatra.office,
      text: "sliderIco3",
      key: "PiatraSeries",
    },
    {
      icon: theme.IMAGES.products.airPurifiers.piatra.school,
      text: "sliderIco4",
      key: "PiatraSeries",
    },
    {
      icon: theme.IMAGES.products.airPurifiers.piatra.hostel,
      text: "sliderIco5",
      key: "PiatraSeries",
    },
  ];

  const t = TranslationService.translate;

  const button = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: isMobile ? "start" : "start",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
          marginLeft: 10,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
        />
      </div>
    );
  };

  const children = [
    {
      title: t("PiatraSeries", "overView"),
      body: (
        <div
          style={{ backgroundColor: "white", marginTop: isMobile ? 94 : 50 }}
        >
          <div className="row container" style={{ margin: "0px auto" }}>
            <div
              className="col-lg-4 col-xl-4 col-md-4 col-sm-12 d-flex flex-column justify-content-center align-items-left"
              style={style}
            >
              <h3
                className="aristotelicadisplaytriallight"
                style={{ color: theme.COLORS.blue, marginBottom: 20 }}
              >
                {t("PiatraSeries", "sliderTitle")}
              </h3>
              <h3
                style={{
                  marginBottom: 10,
                  fontSize: "20px",
                  fontFamily: "GothamMedium",
                }}
                dangerouslySetInnerHTML={{
                  __html: t("PiatraSeries", "sliderSubtitle"),
                }}
              />
              <div
                style={{ marginBottom: 30 }}
                dangerouslySetInnerHTML={{
                  __html: t("PiatraSeries", "sliderSubtext"),
                }}
              />

              <ButtonComponent
                url={"/products/airpurifiers/piatra"}
                text={t("PiatraSeries", "sliderBtn")}
                isLong={true}
                onClick={() => {
                  ModalSwitcher.ShowVideoModal(t("PiatraSeries", "videoId"));
                }}
              />
            </div>
            {isMobile ? (
              <div
                className="col-lg-8 col-xl-8 col-md-8 col-sm-12 d-flex"
                style={{ marginTop: 50 }}
              >
                <div
                  className="col-lg-2 col-xl-2 col-md-2 col-sm-3 col-4 d-flex justify-content-center align-items-center flex-column "
                  style={{ marginLeft: 20 }}
                >
                  {/* {sliderIcons.map((item, index) => {
                    return (
                      <div
                        key={"piatraSlider" + index}
                        className="d-flex align-items-center justify-content-center my-2"
                      >
                        <ImageComponent
                          src={item.icon}
                          alt={"sercair"}
                          width={25}
                        />
                        <div
                          style={
                            isMobile
                              ? { fontSize: 20, marginLeft: 10, minWidth: 100 }
                              : window.screen.width < 1450
                              ? { fontSize: 25, marginLeft: 12, minWidth: 116 }
                              : { fontSize: 30, marginLeft: 15, minWidth: 116 }
                          }
                        >
                          {t(item.key, item.text)}
                        </div>
                      </div>
                    );
                  })} */}
                </div>
                <div className="col-lg-6 col-xl-6 col-md-6 col-sm-8 col-8">
                  <ImageComponent
                    src={theme.IMAGES.products.airPurifiers.piatra.mainImg}
                    width={100}
                    alt={t("PiatraSeries", "sliderImgAlt")}
                  />
                </div>
              </div>
            ) : (
              <>
                <div
                  className="col-lg-2 col-xl-2 col-md-2 col-sm-3 col-4 d-flex justify-content-center align-items-center flex-column "
                  style={{ marginLeft: isMobile ? 20 : 0 }}
                >
                  {/* {sliderIcons.map((item, index) => {
                    return (
                      <div
                        key={"piatraSlider" + index}
                        className="d-flex align-items-center justify-content-center my-2"
                      >
                        <ImageComponent
                          src={item.icon}
                          alt={"sercair"}
                          width={25}
                        />
                        <div
                          style={
                            isMobile
                              ? { fontSize: 20, marginLeft: 0, minWidth: 100 }
                              : { fontSize: 20, marginLeft: 15, minWidth: 116 }
                          }
                        >
                          {t(item.key, item.text)}
                        </div>
                      </div>
                    );
                  })} */}
                </div>
                <div
                  className={
                    screenSize < 1500
                      ? "col-lg-6 col-xl-5 col-md-6 col-sm-8 col-8"
                      : "col-lg-6 col-xl-6 col-md-6 col-sm-8 col-8"
                  }
                >
                  <ImageComponent
                    src={theme.IMAGES.products.airPurifiers.piatra.mainImg}
                    width={100}
                    alt={t("PiatraSeries", "sliderImgAlt")}
                  />
                </div>
              </>
            )}
          </div>
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <FullImageContentComponent
              imgUrl={theme.IMAGES.products.airPurifiers.piatra.babyRoom}
              pStyle={{ width: screenSize < 1500 ? "100%" : 480 }}
              imgAlt={t("PureHealthySeries", "altProductsAlt")}
              isBtn={false}
              textPosition="left"
              title={t("PiatraSeries", "touchpad")}
              text={t("PiatraSeries", "touchpadText")}
            />
          </div>
          <div style={{ marginBottom: 91 }}>
            <IconContainerComponent
              icons={IconPiatraSeriesContent}
              isNotResponsive={true}
              width={"65%"}
            />
          </div>
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.airPurifiers.piatra.touch}
            imgAlt={t("PiatraSeries", "fullImageContentAlt")}
          />
          <div style={{ backgroundColor: theme.COLORS.blueContainer }}>
            <div className="text-center pt-5">
              <h3 style={{ fontSize: 33 }}>
                {t("PiatraSeries", "slider2Title")}
              </h3>
              <div style={{ fontSize: 18 }}>
                {t("PiatraSeries", "slider2Text")}
              </div>
            </div>
            <SliderProductsComponent
              backgroundColor={theme.COLORS.blueContainer}
              sliderTopItems={CarouselPiatraProducts}
              textPosition="top"
            />
          </div>
          <FullImageContentComponent
            style={{ marginTop: 75 }}
            imgUrl=""
            imgAlt="Hepa"
            text={
              isMobile
                ? t("PiatraSeries", "hepaTechTextMobile")
                : screenSize < 1500
                ? t("PiatraSeries", "hepaTechText").replace(/<br\s*\/?>/gi, "")
                : t("PiatraSeries", "hepaTechText")
            }
            title={t("PiatraSeries", "hepaTechTitle")}
            isBtn={false}
            textPosition="center"
          />
          <FullImageContentComponent
            imgUrl={
              lang === "tr"
                ? //@ts-ignore
                  theme.IMAGES.products.airPurifiers.piatra.cleanAirtr
                : theme.IMAGES.products.airPurifiers.piatra.cleanAiren
            }
            imgAlt={t("PiatraSeries", "fullImgAlt")}
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.airPurifiers.piatra.insideRoom}
            imgAlt={t("PiatraSeries", "fullImgAlt")}
          />

          <Container
            style={{
              marginBottom: 50,
              marginTop: 50,
              marginLeft: isMobile ? 25 : 187,
              scale: window.screen.width < 1450 ? 0.8 : 1,
            }}
          >
            {/* <CardProductsGroupComponent
              cards={[
                {
                  title: t("PiatraSeries", "productTitle"),
                  text: t("PiatraSeries", "productText"),
                  imgUrl: theme.IMAGES.products.airPurifiers.piatra.product1,
                  imgAlt: t("PiatraSeries", "altProductsAlt"),
                  isBtn: false,
                  btnText: t("PiatraSeries", "addBasket"),
                  btnUrl:
                    "https://www.sercairstore.com/urun/sercair-piatra-hava-temizleme-cihazi",
                  btnIsLong: true,
                  titleColor: theme.COLORS.blue,
                  textColor: theme.COLORS.blue,
                  isBackgroundColor: true,
                  imgPosition: "left",
                  keyComponent: "PiatraSeries",
                  isShadow: true,
                  bodyColor: theme.COLORS.productBlue,
                },
              ]}
              buttonComponent={button}
            /> */}
          </Container>
        </div>
      ),
    },

    {
      title: t("PureSeries", "tabTitle2"),
      body: (
        <div style={{ backgroundColor: "white" }}>
          <div className="d-flex justify-content-center">
            <ImageComponent
              style={{ marginTop: 110 }}
              src={theme.IMAGES.products.technicalSpecifications.piatra}
              width={50}
              alt="Sercair"
            />
          </div>
          <TableComponent
            data={piatraSeriesTechnicalData}
            keyComponent={"PiatraSeries"}
          />
          <hr />
          {!isMobile ? (
            <div style={{ scale: window.screen.width < 1450 ? 0.8 : 1 }}>
              <CardGroupComponent cards={piatraBoxContent} />
            </div>
          ) : (
            <div className="d-flex justify-content-center my-4">
              <div
                style={
                  isMobile
                    ? { paddingInline: 0, marginTop: 25 }
                    : {
                        paddingInline: window.screen.width < 1500 ? 75 : 200,
                        marginTop: 25,
                      }
                }
              >
                <SliderProductsComponent
                  textPosition="bottom"
                  sliderBottomItems={piatraBoxContent}
                  minHeight={402}
                />
              </div>
            </div>
          )}
          <hr />
          <Container
            style={{
              marginTop: 120,
              marginBottom: 50,
              marginLeft: isMobile ? 32 : 172,
            }}
          >
            <div style={{ scale: window.screen.width < 1450 ? 0.8 : 1 }}>
              {/* <CardProductsGroupComponent
                cards={[
                  {
                    title: t("PiatraSeries", "technicalProducts1Title"),
                    text: t("PiatraSeries", "technicalProductsText"),
                    imgUrl: theme.IMAGES.products.airPurifiers.piatra.product1,
                    imgAlt: t("PiatraSeries", "altProductsAlt"),
                    isBtn: false,
                    btnText: t("PiatraSeries", "addToBasket"),
                    btnUrl:
                      "https://www.sercairstore.com/urun/sercair-piatra-hava-temizleme-cihazi",
                    btnIsLong: true,
                    titleColor: theme.COLORS.blue,
                    textColor: theme.COLORS.blue,
                    isBackgroundColor: true,
                    imgPosition: "left",
                    keyComponent: "PiatraSeries",
                    isShadow: true,
                    bodyColor: theme.COLORS.productBlue,
                  },
                ]}
                buttonComponent={button}
              /> */}
            </div>
          </Container>
        </div>
      ),
    },
  ];

  const childrenMobile = [
    {
      title: t("PiatraSeries", "overView"),
      body: (
        <div style={{ backgroundColor: "white", marginTop: isMobile ? 94 : 0 }}>
          <div className="row container" style={{ margin: "0px auto" }}>
            <div
              className="col-lg-4 col-xl-4 col-md-4 col-sm-12 d-flex flex-column justify-content-center align-items-left"
              style={style}
            >
              <h3
                className="aristotelicadisplaytriallight"
                style={{ color: theme.COLORS.blue, marginBottom: 20 }}
                dangerouslySetInnerHTML={{
                  __html: t("PiatraSeries", "sliderTitle"),
                }}
              />

              <h3
                style={{
                  marginBottom: 10,
                  fontSize: "20px",
                  fontFamily: "GothamMedium",
                }}
                dangerouslySetInnerHTML={{
                  __html: t("PiatraSeries", "sliderSubtitle"),
                }}
              />
              <div
                style={{ marginBottom: 30, fontSize: 9 }}
                dangerouslySetInnerHTML={{
                  __html: t("PiatraSeries", "sliderSubtext"),
                }}
              />
              <div style={{ marginLeft: isMobile ? -20 : 0 }}>
                <ButtonComponent
                  url={"/products/airpurifiers/piatra"}
                  text={t("PiatraSeries", "sliderBtn")}
                  isLong={true}
                  onClick={() => {
                    ModalSwitcher.ShowVideoModal(t("PiatraSeries", "videoId"));
                  }}
                />
              </div>
            </div>

            <div
              className="col-lg-8 col-xl-8 col-md-8 col-sm-12 d-flex"
              style={{ marginTop: 50 }}
            >
              <div
                className="col-lg-2 col-xl-2 col-md-2 col-sm-3 col-4 d-flex justify-content-center align-items-center flex-column "
                style={{ marginLeft: 20 }}
              >
                {/* {sliderIcons.map((item, index) => {
                    return (
                      <div
                        key={"piatraSlider" + index}
                        className="d-flex align-items-center justify-content-center my-2"
                      >
                        <ImageComponent
                          src={item.icon}
                          alt={"sercair"}
                          width={25}
                        />
                        <div
                          style={
                            isMobile
                              ? { fontSize: 9, marginLeft: 10, minWidth: 100 }
                              : window.screen.width < 1450
                              ? { fontSize: 25, marginLeft: 12, minWidth: 116 }
                              : { fontSize: 30, marginLeft: 15, minWidth: 116 }
                          }
                        >
                          {t(item.key, item.text)}
                        </div>
                      </div>
                    );
                  })} */}
              </div>
              <div className="col-lg-6 col-xl-6 col-md-6 col-sm-8 col-8">
                <ImageComponent
                  src={theme.IMAGES.products.airPurifiers.piatra.mainImg}
                  width={100}
                  alt={t("PiatraSeries", "sliderImgAlt")}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: 50, marginBottom: 10 }}>
            <FullImageContentComponent
              imgUrl={theme.IMAGES.products.airPurifiers.piatra.babyRoomMobile}
              pStyle={{ width: 280 }}
              imgAlt="Touch"
              isBtn={false}
              textPosition="right"
              title={t("PiatraSeries", "touchpad")}
              text={t("PiatraSeries", "touchpadText")}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <IconContainerComponent icons={IconPiatraSeriesContent} />
          </div>

          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.airPurifiers.piatra.touchMobile}
            imgAlt={t("PiatraSeries", "fullImageContentAlt")}
          />
          <div style={{ backgroundColor: theme.COLORS.blueContainer }}>
            <div style={{}} className="text-center pt-5">
              <h3 style={{ fontSize: 33 }}>
                {t("PiatraSeries", "slider2Title")}
              </h3>
              <div
                style={{
                  fontSize: 12,
                  width: 250,
                  textAlign: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                {t("PiatraSeries", "slider2Text")}
              </div>
            </div>
            <SliderProductsComponent
              backgroundColor={theme.COLORS.blueContainer}
              sliderTopItems={CarouselPiatraProducts}
              textPosition="top"
            />
          </div>
          <FullImageContentComponent
            style={{ marginTop: 75, width: 360 }}
            imgUrl=""
            imgAlt="Hepa Tech"
            title={t("PiatraSeries", "hepaTechTitle")}
            isBtn={false}
            textPosition="center"
          />
          <FullImageContentComponent
            imgUrl={
              lang === "tr"
                ? //@ts-ignore
                  theme.IMAGES.products.airPurifiers.piatra.cleanAirtrMobile
                : theme.IMAGES.products.airPurifiers.piatra.cleanAirenMobile
            }
            imgAlt={t("PiatraSeries", "fullImgAlt")}
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.airPurifiers.piatra.insideRoom2}
            imgAlt={t("PiatraSeries", "fullImgAlt")}
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.airPurifiers.piatra.insideRoom1}
            imgAlt={t("PiatraSeries", "fullImgAlt")}
          />

          <Container
            style={{
              marginBottom: 50,
              marginTop: 10,
              marginLeft: isMobile ? 25 : 187,
              scale: window.screen.width < 1450 ? 0.8 : 1,
            }}
          >
            {/* <CardProductsGroupComponent
              cards={[
                {
                  title: t("PiatraSeries", "productTitle"),
                  text: t("PiatraSeries", "productText"),
                  imgUrl: theme.IMAGES.products.airPurifiers.piatra.product1,
                  imgAlt: t("PiatraSeries", "altProductsAlt"),
                  isBtn: false,
                  btnText: t("PiatraSeries", "addBasket"),
                  btnUrl:
                    "https://www.sercairstore.com/urun/sercair-piatra-hava-temizleme-cihazi",
                  btnIsLong: true,
                  titleColor: theme.COLORS.blue,
                  textColor: theme.COLORS.blue,
                  isBackgroundColor: true,
                  imgPosition: "left",
                  keyComponent: "PiatraSeries",
                  isShadow: true,
                  bodyColor: theme.COLORS.productBlue,
                },
              ]}
              buttonComponent={button}
            /> */}
          </Container>
        </div>
      ),
    },

    {
      title: t("PureSeries", "tabTitle2"),
      body: (
        <div style={{ backgroundColor: "white" }}>
          <div className="d-flex justify-content-center">
            <ImageComponent
              style={{ marginTop: 110 }}
              src={theme.IMAGES.products.technicalSpecifications.piatra}
              width={50}
              alt="Sercair"
            />
          </div>
          <TableComponent
            data={piatraSeriesTechnicalData}
            keyComponent={"PiatraSeries"}
          />
          <hr />
          {!isMobile ? (
            <div style={{ scale: window.screen.width < 1450 ? 0.8 : 1 }}>
              <CardGroupComponent cards={piatraBoxContent} />
            </div>
          ) : (
            <div className="d-flex justify-content-center my-4">
              <div
                style={
                  isMobile
                    ? { paddingInline: 0, marginTop: 25 }
                    : {
                        paddingInline: window.screen.width < 1500 ? 75 : 200,
                        marginTop: 25,
                      }
                }
              >
                <div style={{ width: 300 }}>
                  <SliderProductsComponent
                    textPosition="bottom"
                    sliderBottomItems={piatraBoxContent}
                    minHeight={402}
                  />
                </div>
              </div>
            </div>
          )}
          <hr />
          <Container
            style={{
              marginTop: 120,
              marginBottom: 50,
              marginLeft: isMobile ? 32 : 172,
            }}
          >
            <div style={{ scale: window.screen.width < 1450 ? 0.8 : 1 }}>
              {/* <CardProductsGroupComponent
                cards={[
                  {
                    title: t("PiatraSeries", "technicalProducts1Title"),
                    text: t("PiatraSeries", "technicalProductsText"),
                    imgUrl: theme.IMAGES.products.airPurifiers.piatra.product1,
                    imgAlt: t("PiatraSeries", "altProductsAlt"),
                    isBtn: false,
                    btnText: t("PiatraSeries", "addToBasket"),
                    btnUrl:
                      "https://www.sercairstore.com/urun/sercair-piatra-hava-temizleme-cihazi",
                    btnIsLong: true,
                    titleColor: theme.COLORS.blue,
                    textColor: theme.COLORS.blue,
                    isBackgroundColor: true,
                    imgPosition: "left",
                    keyComponent: "PiatraSeries",
                    isShadow: true,
                    bodyColor: theme.COLORS.productBlue,
                  },
                ]}
                buttonComponent={button}
              /> */}
            </div>
          </Container>
        </div>
      ),
    },
  ];

  return (
    <div>
      <TabComponent
        children={isMobile ? childrenMobile : children}
        componentKey={"PureSeries"}
      />
    </div>
  );
}
