import theme from "../../../constants/theme";

export const Style = {
  
    buttonContainer:{
        justifyContent:"center",
        alignItems:"center",
        paddingBottom: 15,
    },
    cardGroup:{
        flexDirection:"row" as "row",
        justifyContent:"space-between",
        width:"70%",
        padding: "10px 0px",
        scale:  1,
        
    },
    card:{
        scale:  1,
        color: theme.COLORS.black,
        borderStyle: "solid",
        borderColor: theme.COLORS.white,
        borderWidth: 1,
        margin: "10px 20px",
        backgroundColor: theme.COLORS.gray,
        borderBottomLeftRadius:theme.BORDER_RADIUS.large,
        borderBottomRightRadius:theme.BORDER_RADIUS.large,   
        boxShadow:"",
    }

};
