export const Style = ({
  multiContainer:{
    display:"flex",
    flexDirection:"column" as "column",
    width: "100%",
    padding:"0 7.25rem",
    top: "25%",
    left: 0,
    marginTop:0,
    
  },
 containerLeft:{
    display:"flex",
    flexDirection:"column" as "column",
    justifyContent:"center",
    width:"100%",
    padding:"0 1.25rem",

  },
  containerRight:{
    display:"flex",
    flexDirection:"column" as "column",
    alignItems:"right",
    width:"100%",
    padding:"0 1.25rem",
  },
});