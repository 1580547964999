import { Container } from "react-bootstrap";
import theme from "../../../../constants/theme";
import { ImageComponent } from "../../ImageComponent/component";
import { Props } from "./types";
import { store } from "../../../../store";
import React from "react";
export function RightTextComponent(props: Props) {
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
  }, []);
  const hDefStyle = {
    marginBottom: theme.SPACES.xlarge,
    color: props.titleColor ? props.titleColor : "black",
    fontFamily: theme.FONTS.GothamMedium,
    fontSize: "22px"
  };
  const pDefStyle = { marginBottom: theme.SPACES.xlarge, fontSize: "14px" };
  const hStyle = {
    ...hDefStyle,
    ...props.hStyle,
  };
  const pStyle = {
    ...pDefStyle,
    ...props.pStyle,
  };
  return (
    <>
      <Container className="row" style={props.style}>
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <ImageComponent src={props.imgUrl} alt={props.imgAlt} />
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex text-justify-responsive justify-content-center align-items-center"
          style={
            props.textBackgroundImg
              ? {
                  backgroundImage: "url(" + props.textBackgroundImg + ")",
                  backgroundRepeat: props.textBackgroundRepeat
                    ? "no-repeat"
                    : "initial",
                  backgroundSize: props.isIcon ? "contain" : "cover",
                  backgroundPosition: "100% 100%",
                  height: isMobile ? "100vw" : "100%",
                }
              : {
                  backgroundColor: props.backgroundColor
                    ? props.backgroundColor
                    : "white",
                }
          }
        >
          <div
            className="card"
            style={{
              width: isMobile ? "fit-content" : "60%",
              borderColor: props.backgroundColor
                ? props.backgroundColor
                : "transparent",
              backgroundColor: props.backgroundColor
                ? props.backgroundColor
                : "transparent",
            }}
          >
            <div
              className="card-body"
              style={{ textAlign: isMobile ? "center" : "right" }}
            >
              <h1
                className="card-title"
                style={hStyle}
                dangerouslySetInnerHTML={{
                  __html: isMobile
                    ? props.title && props.title.replace(/<br\s*[\/]?>/gi, "")
                    : (props.title as any),
                }}
              ></h1>
              <p
                style={pStyle}
                className="card-text"
                dangerouslySetInnerHTML={{
                  __html: isMobile
                    ? props.text && props.text.replace(/<br\s*[\/]?>/gi, "")
                    : (props.text as any),
                }}
              ></p>
              {props.textImg && (
                <ImageComponent src={props.textImg} alt={props.imgAlt} />
              )}
              {props.isBtn && (
                <>
                  {" "}
                  <div style={{ marginTop: theme.SPACES.large }}></div>
                  {props.buttonComponent(
                    props.btnText,
                    props.btnUrl,
                    props.btnIsLong
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
