export const Style = {
  line: {
    height: 5,
    background:
      "linear-gradient(to bottom, transparent -22px, black 2px, black 3px, transparent 3px)",
    width: 244,
  },
  lineMobile :{
    height: 55,
    background:
      "linear-gradient(to right, transparent -22px, black 2px, black 3px, transparent 3px)",
  }
};
