import React from "react";
import { Fragment } from "react";

import Artboard6 from "../../../../assets/images/WeatherImages/SVG/Artboard6.svg";
import Artboard9 from "../../../../assets/images/WeatherImages/SVG/Artboard9.svg";
import Artboard10 from "../../../../assets/images/WeatherImages/SVG/Artboard10.svg";
import Artboard11 from "../../../../assets/images/WeatherImages/SVG/Artboard11.svg";

import Info from "../../Info";
import NH3 from "../../../../assets/images/WeatherImages/SVG/new_nh3.svg";
import O3 from "../../../../assets/images/WeatherImages/SVG/O3.svg";
import NO from "../../../../assets/images/WeatherImages/SVG/NO.svg";
import NO2 from "../../../../assets/images/WeatherImages/SVG/NO2.svg";
import SO2 from "../../../../assets/images/WeatherImages/SVG/SO2.svg";
import PM10 from "../../../../assets/images/WeatherImages/SVG/PM10.svg";
import Detail from "../../Detail";

import "./MomentList.css";
// import Scrollbars from "react-custom-scrollbars";

const MomentList1 = (props) => {
  const [moment, setMoment] = React.useState(null);
  const [touch, setTouch] = React.useState(props.opened);

  const className = true ? "scroll_MomentList_Extend" : "scroll_MomentList";
  return (
    <Fragment>
      <div
        style={{
          position: "absolute",
          marginTop: 10,
          paddingTop: 18,
          zIndex: 1,
        }}
      >
        {props.opened && props.Detail_Click && (
          <div className="inner_Detail">
            <div>
              <Detail
                header={props.Detail_Header}
                headerstyle={props.Detail_Color}
                // situation={situation.Temp}
                value={props.Detail_Value}
                text={props.Detail_Text}
                time={props.Detail_Time}
                max1={props.Detail_MAX}
                min={props.Detail_MIN}
                situation={props.Detail_Situation}
                unit={props.Detail_Unit}
                change={props.Detail_Change}
                initial={props.Detail_Initial}
                in={props.Detail_In}
                t={props.Detail_Toggle}
                color={props.Detail_Color}
                click={props.Detail_Click}
              />
            </div>
          </div>
        )}{" "}
      </div>
      <div className="blur1"></div>
      <div
        className={
          props.opened ? "scroll_MomentList_Extend" : "scroll_MomentList"
        }
      >
        <div>
          {" "}
          {/*1. data gelmesi için*/}
          <div
            className={
              props.opened ? "moment_Momentlist" : "moment_MomentList_extend"
            }
          >
            {/* {props.opened &&
             <div className="inner_Detail">
              
              <div>
             <Detail
              header={props.Detail_Header}
              // situation={situation.Temp}
               value={props.Detail_Value}
               text={props.Detail_Text}
               time={props.Detail_Time}
               max1={props.Detail_MAX}
               min={props.Detail_MIN}
               situation={props.Detail_Situation}
               unit={props.Detail_Unit}
               change={props.Detail_Change}
               initial={props.Detail_Initial}
               in={props.Detail_In}
               t={props.Detail_Toggle}
               color={props.Detail_Color}
               click={props.Detail_Click}
             />
             </div>
             </div>}  */}

            {/* <Info
                        
                        icon={Artboard1}
                        butt={props.temp}
                        variable="Sıcaklık"
                        value={props.Temparature +"°C"}
                        info={props.temp_pre}
                      /> */}
          </div>
          {/* <div className="year_AvgYearList">
                      <Info
                      
                        icon={humidity3}
                        butt={props.hum}
                        variable="Nem"
                        value={"%"+props.Hummidty}
                        info={props.hum_pre}
                      />
                    </div> 
                    <div className="year_AvgYearList">
                      <Info
                        icon={Artboard3}
                        butt={props.wd}
                        variable="Rüzgar yönü"
                        value={props.WindDirection+"°"}
                        info={props.wd_pre}
                      />
                    </div>
                    <div className="year_AvgYearList">
                      <Info
                        icon={windspeed}
                        butt={props.ws}
                        variable="Rüzgar Hızı"
                        value={props.WindSpeed+"m/s"}
                        info={props.ws_pre}
                      />
                    </div>
                    <div className="year_AvgYearList">
                      <Info
                        icon={Artboard5}
                        butt={props.pres}
                        variable="Basınç"
                        value={props.Pressure+"p"}
                        info={props.pres_pre}
                      />
                    </div>
                    */}
          <div className="year_AvgYearList">
            <Info
              icon={NO2}
              butt={props.no2}
              variable="NO2"
              value={props.NO2}
              info={props.no2_pre}
              togglePressable={true} //to make pressed seen
              type={"μg/m3"}
            />
          </div>
          <div className="year_AvgYearList">
            <Info
              icon={SO2}
              butt={props.so2}
              variable="SO2"
              value={props.SO2}
              info={props.so2_pre}
              togglePressable={true}
              type={"μg/m3"}
            />
          </div>
          <div className="year_AvgYearList">
            <Info
              icon={Artboard9}
              butt={props.pm2_5}
              variable="Pm 2.5"
              value={props.PM2_5}
              info={props.pm2_5_pre}
              togglePressable={true}
              type={"μg/m3"}
            />
          </div>
          <div className="year_AvgYearList">
            <Info
              icon={PM10}
              butt={props.pm10}
              variable="Pm 10"
              value={props.PM10}
              info={props.pm10_pre}
              togglePressable={true}
              type={"μg/m3"}
            />
          </div>
          <div className="year_AvgYearList">
            <Info
              icon={Artboard6}
              butt={props.co2}
              variable="CO2"
              value={props.avgCO2}
              info={props.co2_pre}
              togglePressable={true}
              type={"PPM"}
            />
          </div>
          <div className="year_AvgYearList">
            <Info
              icon={Artboard10}
              butt={props.co}
              variable="CO"
              value={props.avgCO}
              info={props.co_pre}
              togglePressable={true}
              type={"μg/m3"}
            />
          </div>
          <div className="year_AvgYearList">
            <Info
              icon={Artboard11}
              variable="VOCs"
              value={props.VOCs}
              butt={props.vocs}
              info={props.vocs_pre}
              togglePressable={true}
              type={"NM"}
            />
          </div>
          <div className="year_AvgYearList">
            <Info
              icon={NH3}
              variable="NH3"
              value={props.NH3}
              butt={props.nh3}
              info={props.nh3_pre}
              togglePressable={true}
              type={"μg/m3"}
            />
          </div>
          <div className="year_AvgYearList">
            <Info
              icon={NO}
              variable="NO"
              value={props.NO}
              butt={props.no}
              info={props.no_pre}
              togglePressable={true}
              type={"μg/m3"}
            />
          </div>
          <div className="year_AvgYearList">
            <Info
              icon={O3}
              variable="O3"
              value={props.O3}
              butt={props.o3}
              info={props.o3_pre}
              togglePressable={true}
              type={"μg/m3"}
            />
          </div>
          <br />
          <br />
          <br />
          {/* {names.map((names) => <Info key={data._id.day} variable={names} value={data.avgCO.toFixed(0)} />)}
           */}
        </div>
      </div>
    </Fragment>
  );
};

export default MomentList1;
