import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { ButtonComponent } from "../../../../components/Shared/ButtonComponent/component";
import { CardGroupComponent } from "../../../../components/Shared/CardComponent/component";
import { FullImageContentComponent } from "../../../../components/Shared/FullImageContent/component";
import { ImageComponent } from "../../../../components/Shared/ImageComponent/component";
import { SliderProductsComponent } from "../../../../components/Shared/SliderProductsComponent/component";
import { TabComponent } from "../../../../components/Shared/TabComponent/component";
import { TableComponent } from "../../../../components/Shared/TableComponent/component";
import { TextContainerComponent } from "../../../../components/Shared/TextContainerComponent/component";
import { SliderComponent } from "../../../../components/SliderComponent";
import {
  HealthyBoxContent,
  MobileSliderPureSeriesOverview,
  MobileTextContainerPureHealthySeries,
  PureHealthySeriesTechnicalData,
  SliderPureHealthySeries,
  SliderPureSeriesOverview,
  TextContainerPureHealthySeries,
  UnderSecondHeaderMobileTextContainerPureSeries,
} from "../../../../constants/datas";
import theme from "../../../../constants/theme";
import { TranslationService } from "../../../../services/translation.service";
import { store } from "../../../../store";
import { AppTabPage } from "../../app/component";
import "./index.css";
import { Props } from "./types";

export function ScreenComponent(props: Props) {
  const t = TranslationService.translate;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);

  const [lang, setLang] = useState(TranslationService.getLang().code);

  useEffect(() => {
    setLang(TranslationService.getLang().code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TranslationService.getLang().code]);

  const button = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: isMobile ? "start" : "start",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
          marginLeft: 10,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"left"}
        />
      </div>
    );
  };
  const buttonApp = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: isMobile ? "center" : "start",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={false}
          position={"center"}
          isTabSwitcher={true}
          isComp={false}
        />
      </div>
    );
  };

  const children = [
    {
      title: t("PureHealthySeries", "tabTitle1"),
      body: (
        <div
          className="mobile-margin"
          style={{
            backgroundColor: "white",
            marginTop: props.isMobile ? 68 : 0,
          }}
        >
          <SliderComponent sliderItems={SliderPureHealthySeries} />
          <TextContainerComponent
            texts={TextContainerPureHealthySeries}
            columnNumber={4}
            width="100%"
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureHealthy.halfCleanAir}
            imgAlt={t("PureHealthySeries", "fullImageContentAlt")}
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureHealthy.uvcTech}
            title={t("PureHealthySeries", "UvcTechTitle")}
            text={
              isMobile
                ? t("PureHealthySeries", "UvcTechTextMobile")
                : t("PureHealthySeries", "UvcTechText")
            }
            titleColor={theme.COLORS.blue}
            imgAlt={t("PureHealthySeries", "altProductsAlt")}
            isBtn={false}
            textPosition="left"
          />
          <FullImageContentComponent
            imgUrl={
              lang === "tr"
                ? //ts-ignore
                  theme.IMAGES.products.pure.pureHealthy.appGifTR
                : theme.IMAGES.products.pure.pureHealthy.appGifEN
            }
            textBackgroundImg={theme.IMAGES.gifLine}
            imgAlt={t("PureHealthySeries", "altProductsAlt")}
            isBtn={true}
            textPosition="left"
            title={t("PureHealthySeries", "appTechnologyTitle")}
            text={t("PureHealthySeries", "appTechnologyText")}
            btnText={t("PureHealthySeries", "btnMoreInfo")}
            btnUrl="/contact"
            btnIsLong={true}
            buttonComponent={buttonApp}
          />
          <div
            style={{ padding: 60, backgroundColor: theme.COLORS.blue40 }}
            className="text-center"
          >
            <h1 style={{ margin: 20 }}>
              {t("OverViewPureSlider", "sliderMainTitle")}
            </h1>
            <p
              dangerouslySetInnerHTML={{
                __html: t("OverViewPureSlider", "sliderMainText"),
              }}
            ></p>
            <div
              style={
                isMobile
                  ? { paddingInline: 0, marginTop: 25 }
                  : {
                      paddingInline: window.screen.width < 1500 ? 75 : 200,
                      marginTop: 25,
                    }
              }
            >
              <SliderProductsComponent
                sliderRightItems={SliderPureSeriesOverview}
                textPosition="right"
              />
            </div>
          </div>
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureHealthy.practicalCare}
            imgAlt={t("PureHealthySeries", "altProductsAlt")}
            isBtn={false}
            textBackgroundImg={theme.IMAGES.waweLine2}
            hStyle={{ marginTop: 120 }}
            textPosition="right"
            title={t("PureHealthySeries", "practicalCareTitle")}
            text={t("PureHealthySeries", "practicalCareText")}
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureHealthy.changeFilter}
            imgAlt={t("PureHealthySeries", "altProductsAlt")}
            isBtn={false}
            hStyle={{ marginTop: 120 }}
            textBackgroundImg={theme.IMAGES.waweLine3}
            textPosition="left"
            title={t("PureHealthySeries", "easyFilterChangeTitle")}
            text={t("PureHealthySeries", "easyFilterChangeText")}
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureHealthy.adminPanel}
            imgAlt={t("PureHealthySeries", "altProductsAlt")}
            isBtn={false}
            textPosition="left"
            title={t("PureHealthySeries", "adminPanelTitle")}
            text={t("PureHealthySeries", "adminPanelText")}
            // textBackgroundImg={theme.IMAGES.products.lines}
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureHealthy.familyKitchen}
            imgAlt={t("PureHealthySeries", "altProductsAlt")}
            isBtn={false}
            textPosition="right"
            title={t("PureHealthySeries", "familySpaceTitle")}
            titleColor={theme.COLORS.blue}
            text={t("PureHealthySeries", "familySpaceText")}
          />
          <div style={{ marginTop: 50 }} />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureHealthy.healthyOtel}
            imgAlt={t("PureHealthySeries", "altProductsAlt")}
            isBtn={false}
            textPosition="left"
            title={t("PureHealthySeries", "workSpaceTitle")}
            titleColor={theme.COLORS.blue}
            text={t("PureHealthySeries", "workSpaceText")}
          />
          <Container
            style={{
              marginBottom: 50,
              marginTop: 50,
              marginLeft: isMobile ? 32 : 167,
            }}
          ></Container>
        </div>
      ),
    },
    {
      title: t("PureHealthySeries", "tabTitle2"),
      body: (
        <div style={{ backgroundColor: "white" }}>
          <ImageComponent
            src={theme.IMAGES.products.pure.pureHealthy.product}
            alt="Teknik Özellikler"
            style={{ marginTop: 110 }}
          />
          <TableComponent
            data={PureHealthySeriesTechnicalData}
            keyComponent={"PureHealthySeries"}
          />
          <hr />
          {!isMobile ? (
            <CardGroupComponent cards={HealthyBoxContent} />
          ) : (
            <div className="d-flex justify-content-center my-4">
              <div style={{ width: isMobile ? "70%" : "80%" }}>
                <SliderProductsComponent
                  textPosition="bottom"
                  sliderBottomItems={HealthyBoxContent}
                  minHeight={402}
                />
              </div>
            </div>
          )}
          <hr />
        </div>
      ),
    },
    {
      title: t("PureHealthySeries", "tabTitle3"),
      body: (
        <div>
          <AppTabPage isMobile={props.isMobile} />
        </div>
      ),
    },
  ];
  const mobilechildren = [
    {
      title: t("PureHealthySeries", "tabTitle1"),
      body: (
        <div
          className="mobile-slider-healty"
          style={{
            backgroundColor: "white",
            marginTop: props.isMobile ? 59 : 0,
          }}
        >
          <SliderComponent sliderItems={SliderPureHealthySeries} />
          <TextContainerComponent
            texts={MobileTextContainerPureHealthySeries}
            columnNumber={4}
            //width="100%"
          />
          <div>
            <FullImageContentComponent
              imgUrl={theme.IMAGES.products.pure.pureHealthy.mobilehalfCleanAir}
              imgAlt={t("PureHealthySeries", "fullImageContentAlt")}
            />
            <TextContainerComponent
              texts={UnderSecondHeaderMobileTextContainerPureSeries}
              columnNumber={3}
            />
          </div>
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureHealthy.uvcTech}
            title={t("PureHealthySeries", "UvcTechTitle")}
            text={
              isMobile
                ? t("PureHealthySeries", "UvcTechTextMobile")
                : t("PureHealthySeries", "UvcTechText")
            }
            pStyle={{ width: 250 }}
            hStyle={{ width: 250 }}
            titleColor={theme.COLORS.blue}
            imgAlt={t("PureHealthySeries", "altProductsAlt")}
            isBtn={false}
            textPosition="left"
          />
          <FullImageContentComponent
            imgUrl={
              lang === "tr"
                ? //ts-ignore
                  theme.IMAGES.products.pure.pureHealthy.appGifTR
                : theme.IMAGES.products.pure.pureHealthy.appGifEN
            }
            // textBackgroundImg={theme.IMAGES.gifLine}
            imgAlt={t("PureHealthySeries", "altProductsAlt")}
            isBtn={true}
            pStyle={{ width: 250 }}
            textPosition="left"
            title={t("PureHealthySeries", "appTechnologyTitle")}
            text={t("PureHealthySeries", "appTechnologyText")}
            btnText={t("PureHealthySeries", "btnMoreInfo")}
            btnUrl="/contact"
            btnIsLong={true}
            buttonComponent={buttonApp}
            isPureComponent={true}
          />
          <div
            style={{ padding: 60, backgroundColor: theme.COLORS.blue40 }}
            className="text-center"
          >
            <h1 style={{ margin: 20 }}>
              {t("OverViewPureSlider", "sliderMainTitle")}
            </h1>
            <p
              dangerouslySetInnerHTML={{
                __html: t("OverViewPureSlider", "sliderMainText"),
              }}
            ></p>
            <div
              style={
                isMobile
                  ? { paddingInline: 0, marginTop: 25 }
                  : {
                      paddingInline: window.screen.width < 1500 ? 75 : 200,
                      marginTop: 25,
                    }
              }
            >
              {isMobile ? (
                <SliderProductsComponent
                  sliderRightItems={MobileSliderPureSeriesOverview}
                  textPosition="right"
                />
              ) : (
                <SliderProductsComponent
                  sliderRightItems={SliderPureSeriesOverview}
                  textPosition="right"
                />
              )}
            </div>
          </div>
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureHealthy.practicalCareMobile}
            imgAlt={t("PureHealthySeries", "altProductsAlt")}
            isBtn={false}
            pStyle={{ width: 250 }}
            hStyle={{ marginTop: 20 }}
            textPosition="left"
            title={t("PureHealthySeries", "practicalCareTitle")}
            text={t("PureHealthySeries", "practicalCareText")}
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureHealthy.changeFilterMobile}
            imgAlt={t("PureHealthySeries", "altProductsAlt")}
            isBtn={false}
            hStyle={{ marginTop: 20 }}
            textPosition="left"
            title={t("PureHealthySeries", "easyFilterChangeTitle")}
            text={t("PureHealthySeries", "easyFilterChangeText")}
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureHealthy.adminPanel}
            imgAlt={t("PureHealthySeries", "altProductsAlt")}
            isBtn={false}
            textPosition="left"
            title={t("PureHealthySeries", "adminPanelTitle")}
            text={t("PureHealthySeries", "adminPanelText")}
          />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureHealthy.familyKitchenMobile}
            imgAlt={t("PureHealthySeries", "altProductsAlt")}
            isBtn={false}
            hStyle={{ width: 200 }}
            textPosition="left"
            title={t("PureHealthySeries", "familySpaceTitle")}
            titleColor={theme.COLORS.blue}
          />
          <div style={{ marginTop: 50 }} />
          <FullImageContentComponent
            imgUrl={theme.IMAGES.products.pure.pureHealthy.healthyOtelMobile}
            imgAlt={t("PureHealthySeries", "altProductsAlt")}
            isBtn={false}
            hStyle={{ width: 200, textAlign: "center" }}
            textPosition="left"
            title={t("PureHealthySeries", "workSpaceTitle")}
            titleColor={theme.COLORS.blue}
          />
          <Container
            style={{
              marginBottom: 50,
              marginTop: 50,
              marginLeft: isMobile ? 32 : 167,
            }}
          ></Container>
        </div>
      ),
    },
    {
      title: t("PureHealthySeries", "tabTitle2"),
      body: (
        <div style={{ backgroundColor: "white" }}>
          <ImageComponent
            src={theme.IMAGES.products.pure.pureHealthy.product}
            alt="Teknik Özellikler"
            style={{ marginTop: 110 }}
          />
          <TableComponent
            data={PureHealthySeriesTechnicalData}
            keyComponent={"PureHealthySeries"}
          />
          <hr />
          {!isMobile ? (
            <CardGroupComponent cards={HealthyBoxContent} />
          ) : (
            <div className="d-flex justify-content-center my-4">
              <div style={{ width: isMobile ? "70%" : "80%" }}>
                <SliderProductsComponent
                  textPosition="bottom"
                  sliderBottomItems={HealthyBoxContent}
                  minHeight={402}
                />
              </div>
            </div>
          )}
          <hr />
        </div>
      ),
    },
    {
      title: t("PureHealthySeries", "tabTitle3"),
      body: (
        <div>
          <AppTabPage isMobile={props.isMobile} />
        </div>
      ),
    },
  ];

  return (
    <div>
      <TabComponent
        children={isMobile ? mobilechildren : children}
        componentKey={"PureSeries"}
      />
    </div>
  );
}
