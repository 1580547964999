import { connect } from "react-redux";
import { Component } from "./component";
import { GlobalState } from "../../../../store/state";

const mapStateToProps = (state: GlobalState) => {
  return {
    lang: state.ui.lang,
    showOpeningModal: state.ui.showOpeningModal,
    imgPath: state.ui.imgPath,
    imgAlt: state.ui.imgAlt,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const OpeningModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
