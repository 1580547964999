import { APIURLs } from "../consts/APIURLs";
import { APIService } from "../../services/api.service";

export class UserGuideService {

    //get Translation code
   

  static async getUserGuide(lang: string): Promise<any> {
    const url = APIURLs.BASE_URL + APIURLs.USER_GUIDE;
    var params = new Map();
    params.set("language", lang);
    return await APIService.getInstance()
      .httpGet<any>(url)
      .setQueryParameters(params)
      .execute()
      .then(response => response.getContent());
  }
}