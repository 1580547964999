import { Props } from "./types";
import { Style } from "./style";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../../services/getWindowSize.service";
export function ButtonComponentPurple(props: Props) {
  const windowSize = useWindowDimensions().width;
  const [style, setStyle] = useState();

  const positionClass =
    props.position === "center"
      ? "d-flex justify-content-center align-items-center"
      : props.position === "left"
      ? "d-flex justify-content-start align-items-center"
      : "d-flex justify-content-start align-items-center";

  function controller(hover: boolean) {
    /*
        //object keys ile kontroll ettir ve style ekle
        const styleKeys = Object.keys(style);
        styleKeys.forEach((key) => {
          if (key === "width") {
            if (hover) {
              setStyle({ ...style, [key]: props.isLong ? "100%" : "auto" });
            } else {
              setStyle({ ...style, [key]: props.isLong ? "auto" : "100%" });
            }
          }
        }
        );
*/
    let xss = {},
      xssHover = {};
    if (windowSize < 768)
      if (hover) {
        xssHover = props.isLong
          ? Style.longButtonHoverMobile
          : Style.buttonMobileHover;
        return { ...xssHover, ...props.styleHover };
      } else {
        xss = props.isLong ? Style.longButtonMobile : Style.buttonMobile;
        return { ...xss, ...props.style };
      }
    else if (hover) {
      xssHover = props.isLong ? Style.longButtonHover : Style.buttonHover;
      return { ...xssHover, ...props.styleHover };
    } else {
      xss = props.isLong ? Style.longButton : Style.button;
      return { ...xss, ...props.style };
    }
  }

  useEffect(() => {
    //@ts-ignore
    setStyle(controller(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return props.isTabSwitcher ? (
    <div style={{ display: "flex" }}>
      <div
        style={{ width: "100%" }}
        className={positionClass + " btnPurple"}
        onClick={() => {
          //click tab by role = tablist > role = presentation > button[0]
          //@ts-ignore
          document.querySelector(".nav-tabs").children[2].children[0].click();
        }}
      >
        <div
          style={style} //@ts-ignore
          onMouseOver={() => setStyle(controller(true))}
          //@ts-ignore
          onMouseOut={() => setStyle(controller(false))}
        >
          {props.text}
        </div>
      </div>
    </div>
  ) : (
    <div style={{ display: "flex" }}>
      <Link
        style={{ width: "100%" }}
        className={positionClass + " btnPurple"}
        to={
          props.url
            ? props.url.match(".com")
              ? window.location.pathname
              : props.url
            : ""
        }
        onClick={() =>
          props.url &&
          props.url.match(".com") &&
          window.open(props.url, "_blank")
        }
      >
        <div
          style={style} //@ts-ignore
          onMouseOver={() => setStyle(controller(true))}
          //@ts-ignore
          onMouseOut={() => setStyle(controller(false))}
        >
          {props.text}
        </div>
      </Link>
    </div>
  );
}
