import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import theme from "../../constants/theme";
import useWindowDimensions from "../../services/getWindowSize.service";
import { TranslationService } from "../../services/translation.service";
import { ModalSwitcher } from "../../utils/modal-switcher";
import { ButtonComponent } from "../Shared/ButtonComponent/component";
import { Style } from "./style";
import { Props } from "./types";
export function Component(props: Props) {


  const [index, setIndex] = useState(0);
  const [fontSize, setFontSize] = useState("fs-24");
  const [multiStyleResponsive, setMultiStyleResponsive] = useState(
    Style.multiContainer
  );
  const screenSize = useWindowDimensions().width;
  const sliderItems = props.sliderItems;
  const [isMobile, setIsMobile] = useState(false);
  const [containerRight, setContainerRight] = useState(Style.containerRight);
  const t = TranslationService.translate;
  const handleSelect = (
    selectedIndex: React.SetStateAction<number>,
    e: any
  ) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const carouselIndicators = document.querySelectorAll("#topSliderSingle");
    carouselIndicators.forEach((item) => {
      item.querySelector(".carousel-indicators")?.remove();
    });
  }, []);

  useEffect(() => {
    if (screenSize < 800) {
      setFontSize("fs-20");
      setMultiStyleResponsive(() => ({
        ...multiStyleResponsive,
        top: "15%",
        padding: "0 0",
        marginTop: 0,
      }));
      setIsMobile(true);
    } else if (screenSize < 1367) {
      setFontSize("fs-24");
      setContainerRight(() => ({
        ...containerRight,
        left: "0",
        width: "1045px",
      }));
      setIsMobile(false);
    } else if (screenSize < 1440) {
      setFontSize("fs-24");
      setContainerRight(() => ({
        ...containerRight,
        left: "0",
        width: "1190px",
      }));
      setIsMobile(false);
    } else if (screenSize < 1540) {
      setFontSize("fs-24");
      setMultiStyleResponsive(() => ({
        ...multiStyleResponsive,
        top: "25%",
        padding: "0 7.25rem",
        marginTop: 0,
        left: -8,
      }));
      setContainerRight(() => ({
        ...containerRight,
        left: "0",
        width: "1240px",
      }));
      setIsMobile(false);
    } else if (screenSize < 1800) {
      setFontSize("fs-24");
      setMultiStyleResponsive(() => ({
        ...multiStyleResponsive,
        top: "25%",
        padding: "0 7.25rem",
        marginTop: 0,
        left: -64,
      }));
      setContainerRight(() => ({
        ...containerRight,
        left: "0",
        width: "1395px",
      }));
      setIsMobile(false);
    } else if (screenSize <= 1880) {
      setFontSize("fs-24");
      setMultiStyleResponsive(() => ({
        ...multiStyleResponsive,
        top: "25%",
        padding: "0 7.25rem",
        marginTop: 0,
        left: -142,
      }));
      setContainerRight(() => ({
        ...containerRight,
        left: "0",
        width: "1532px",
      }));
      setIsMobile(false);
    } else if (screenSize <= 1920) {
      setFontSize("fs-24");
      setMultiStyleResponsive(() => ({
        ...multiStyleResponsive,
        top: "25%",
        padding: "0 7.25rem",
        marginTop: 0,
        left: -158,
      }));
      setIsMobile(false);
      setContainerRight(() => ({
        ...containerRight,
        left: "0",
        width: "1570px",
      }));
    } else if (screenSize < 1980) {
      setFontSize("fs-24");
      setMultiStyleResponsive(() => ({
        ...multiStyleResponsive,
        top: "25%",
        padding: "0 7.25rem",
        marginTop: 0,
        left: -215,
      }));
      setIsMobile(false);
      setContainerRight(() => ({
        ...containerRight,
        left: "0",
        width: "1726px",
      }));
    } else {
      setFontSize("fs-24");
      setMultiStyleResponsive(() => ({
        ...multiStyleResponsive,
        top: "25%",
        padding: "0 7.25rem",
        marginTop: 0,
        left: -250,
      }));
      setIsMobile(false);
      setContainerRight(() => ({
        ...containerRight,
        left: "0",
        width: "1758px",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize]);
 
  return (
    <div id={sliderItems.length === 1 ? "topSliderSingle" : "topSliderMulti"}>
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        pause={"hover"}
        controls={false}
        className={fontSize}
      >
        {sliderItems &&
          sliderItems.map((item, index) => {
         

           
            return (
              <Carousel.Item key={index + "sliderCarousel"}>
                <img
                  // loading="lazy"
                  className="d-block w-100"
                  src={screenSize < 720 ? item.imgForMobile : item.imgUrl}
                  alt={t(item.keyComponent, item.imgAlt)}
                />
                <Carousel.Caption className="col" style={multiStyleResponsive}>
                  <Container
                    style={{
                      marginTop:
                        screenSize < 768 && window.location.pathname === "/"
                          ? 40
                          : 0,
                    }}
                  >
                    <div
                      style={
                        item.containerPosition === "left"
                          ? Style.containerLeft
                          : containerRight
                      }
                    >
                      {window.location.pathname === "/" ? (
                        <h1
                          className="fs-24"
                          style={{
                            color: item.color,
                            textAlign: item.textPosition,
                            marginBottom: item.h2MarginBottom,
                            fontSize: isMobile ? "20px" : "32px",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: isMobile
                              ? t(item.keyComponent, item.title).replace(
                                  /<br\s*[\/]?>/gi,
                                  ""
                                )
                              : t(item.keyComponent, item.title).replace(
                                  /<br\s*[\/]?>/gi,
                                  ""
                                ),
                          }}
                        ></h1>
                      ) : (
                        <h2
                          className="fs-24"
                          style={{
                            color: item.color,
                            textAlign: item.textPosition,
                            marginBottom: item.h2MarginBottom,
                            fontSize: isMobile ? "20px" : "32px",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: isMobile
                              ? t(item.keyComponent, item.title).replace(
                                  /<br\s*[\/]?>/gi,
                                  ""
                                )
                              : t(item.keyComponent, item.title).replace(
                                  /<br\s*[\/]?>/gi,
                                  ""
                                ),
                          }}
                        ></h2>
                      )}

                      <h2
                        className={
                          window.location.pathname === "/"
                            ? isMobile
                              ? "fs-24 Aristotelicadisplaytriallight mt-3"
                              : "fs-20 Aristotelicadisplaytriallight mt-4"
                            : isMobile
                            ? "fs-12 mt-4"
                            : "fs-12 mt-4"
                        }
                        style={{
                          color: item.color,
                          textAlign: item.textPosition,
                        }}
                        dangerouslySetInnerHTML={{
                          //clear html br tags
                          __html: isMobile
                            ? t(item.keyComponent, item.text)
                            : t(item.keyComponent, item.text),
                        }}
                      ></h2>
                      {item.isBtn && isMobile && (
                        <div
                          style={{
                            marginTop: isMobile
                              ? theme.SPACES.xsmall
                              : theme.SPACES.large,
                          }}
                          className="col-3"
                        >
                          {item.videoId ? (
                            <ButtonComponent
                              url={item.btnUrl}
                              text={t(item.keyComponent, item.btnText)}
                              isLong={true}
                              onClick={() => {
                                item.videoId &&
                                  ModalSwitcher.ShowVideoModal(
                                    t(item.keyComponent, item.videoId)
                                  );
                              }}
                            />
                          ) : item.isPDFDownload ? (
                            <ButtonComponent
                              url={item.btnUrl}
                              text={t(item.keyComponent, item.btnText)}
                              isPdfDownload={true}
                              pdfTitle={t(item.keyComponent, item.pdfTitle)}
                              isLong={true}
                            />
                          ) : (
                            <ButtonComponent
                              url={item.btnUrl}
                              text={t(item.keyComponent, item.btnText)}
                              isLong={true}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    {item.isSecondaryContainer && (
                      <div
                        style={
                          item.secondaryContainerPosition === "left"
                            ? Style.containerLeft
                            : containerRight
                        }
                      >
                        {isMobile ? (
                          <h1
                            style={{
                              width: 350,

                              color: item.secondaryContainerColor,
                              textAlign: isMobile
                                ? "left"
                                : item.secondaryTextPosition,
                              marginTop: isMobile ? "4%" : 0,
                            }}
                            dangerouslySetInnerHTML={{
                              __html: isMobile
                                ? t(
                                    item.keyComponent,
                                    item.secondaryContainerTitle
                                  ).replace(/<br\s*[\/]?>/gi, "")
                                : t(
                                    item.keyComponent,
                                    item.secondaryContainerTitle
                                  ),
                            }}
                          ></h1>
                        ) : (
                          <h2
                            style={{
                              color: item.secondaryContainerColor,
                              textAlign: isMobile
                                ? "left"
                                : item.secondaryTextPosition,
                              marginTop: isMobile ? "4%" : 0,
                              fontSize: "20px",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: isMobile
                                ? t(
                                    item.keyComponent,
                                    item.secondaryContainerTitle
                                  ).replace(/<br\s*[\/]?>/gi, "")
                                : t(
                                    item.keyComponent,
                                    item.secondaryContainerTitle
                                  ),
                            }}
                          ></h2>
                        )}

                        <h2
                          className={isMobile ? "fs-11" : "fs-12"}
                          style={{
                            fontSize: isMobile ? "10pt" : "11pt",
                            color: item.secondaryContainerColor,
                            textAlign: item.secondaryTextPosition,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: isMobile
                              ? item.secondaryContainerText &&
                                t(
                                  item.keyComponent,
                                  item.secondaryContainerText
                                ).replace(/<br\s*[\/]?>/gi, "")
                              : t(
                                  item.keyComponent,
                                  item.secondaryContainerText
                                ),
                          }}
                        ></h2>
                        {item.secondaryIsBtn && true && (
                          <div
                            style={{
                              marginTop: isMobile
                                ? theme.SPACES.xsmall
                                : theme.SPACES.large,
                              marginLeft: isMobile ? 10 : 0,
                            }}
                            className="col-5"
                          >
                            {item.videoId ? (
                              <ButtonComponent
                                url={item.btnUrl}
                                text={t(item.keyComponent, item.btnText)}
                                isLong={true}
                                onClick={() => {
                                  item.videoId &&
                                    ModalSwitcher.ShowVideoModal(
                                      t(item.keyComponent, item.videoId)
                                    );
                                }}
                              />
                            ) : item.isPDFDownload ? (
                              <ButtonComponent
                                url={item.btnUrl}
                                text={t(item.keyComponent, item.btnText)}
                                isPdfDownload={true}
                                pdfTitle={t(item.keyComponent, item.pdfTitle)}
                                isLong={true}
                              />
                            ) : (
                              <ButtonComponent
                                url={item.btnUrl}
                                text={t(item.keyComponent, item.btnText)}
                                isLong={true}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </Container>
                </Carousel.Caption>
              </Carousel.Item>
            );
          
          })}
      </Carousel>
    </div>
  );
}
