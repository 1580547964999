import { useState } from "react";
import { MapComponent } from "../../../components/GoogleMapComponent";
import { ButtonComponent } from "../../../components/Shared/ButtonComponent/component";
import { ImageComponent } from "../../../components/Shared/ImageComponent/component";
import { TabComponent } from "../../../components/Shared/TabComponent/component";
import { SliderComponent } from "../../../components/SliderComponent";
import {
  SliderCubeSeriesMobileEN,
  SliderCubeSeriesMobileTR,
  cubeLeftTechicalDataMobile
} from "../../../constants/datas";
import { CubePointsData } from "../../../constants/mock";
import theme from "../../../constants/theme";
import useWindowDimensions from "../../../services/getWindowSize.service";
import { TranslationService } from "../../../services/translation.service";
import { Contact } from "../../Contact";
import ArrowComponent from "./arrowComponent";
import "./cube.css";
import { Style } from "./style";
function MobilePage() {
  const t = TranslationService.translate;
  const TranslationKey = "Cube";
  const [infoContainer, setInfoContainer] = useState(false);
  const [leftOpen, setLeftOpen] = useState(true);
  const screenSize = useWindowDimensions().width;
  const allPoints = CubePointsData.points; // adresler ve şehir bağlantısı burada
  const [cubeSelectedPoints, setCubeSelectedPoints] = useState(allPoints);
  const [selectedPoint, setSelectedPoint] = useState(allPoints[0]);
  const cityes = CubePointsData.city;
  const button = (text: string, url: string, isLong: boolean) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: theme.SPACES.large,
          marginTop: theme.SPACES.large,
        }}
      >
        <ButtonComponent
          url={url}
          text={text}
          isLong={isLong}
          position={"center"}
        />
      </div>
    );
  };
  const firstTabItems = () => {
    return (
      <>
        <div className="cube-slider">
          {t(TranslationKey, "meetSlienceTitle") ==
          "Anında Sessizlik ile Tanışın" ? (
            <SliderComponent sliderItems={SliderCubeSeriesMobileTR} />
          ) : (
            <SliderComponent sliderItems={SliderCubeSeriesMobileEN} />
          )}
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "40%", textAlign: "center" }}>
            <ImageComponent
              alt="sercair"
              src={theme.IMAGES.products.cube.whiteCube}
              width={80}
              style={{ marginLeft: 0 }}
            />
          </div>
          <div
            style={{
              width: "60%",
              textAlign: "right",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "80%" }}>
              <h3
                style={
                  screenSize < 1537
                    ? {
                        ...Style.meetSlienceEchoStyle,
                        "--data-font-size": "18px",
                        "--data-top": "-10px",
                      }
                    : Style.meetSlienceEchoStyle
                }
                data-text={t(TranslationKey, "meetSlienceEcho")}
                className="textEffectRight"
              >
                {t(TranslationKey, "meetSlienceTitle")}
              </h3>
              <div
                style={{
                  backgroundImage: `url(${theme.IMAGES.products.cube.orangeIconsBg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  marginTop: 20,
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 5,
                    left: screenSize < 1537 ? 2 : 62,
                  }}
                >
                  <p style={{ fontSize: 10 }}>
                    {" "}
                    {t(TranslationKey, "meetDbMax")}
                  </p>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 6,
                    right: screenSize < 1537 ? 11 : 80,
                  }}
                >
                  <p style={{ fontSize: 10 }}>
                    {" "}
                    {t(TranslationKey, "meetDbMin")}
                  </p>
                </div>
                <ImageComponent
                  alt="sercair"
                  src={theme.IMAGES.products.cube.orangeIcons}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column" style={{ marginTop: 50 }}>
          <div style={{ textAlign: "center" }}>
            <h3
              style={
                screenSize < 1537
                  ? {
                      ...Style.healthySlienceEchoStyle,
                      "--data-font-size": "18px",
                      "--data-top": "-20px",
                    }
                  : Style.healthySlienceEchoStyle
              }
              data-text={t(TranslationKey, "healthySlienceEcho")}
              className="textEffectCenter"
            >
              {t(TranslationKey, "healthySlienceTitle")}
            </h3>
            <p
              dangerouslySetInnerHTML={{
                __html: t(TranslationKey, "healthySlienceText"),
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ImageComponent
              alt="sercair"
              width={25}
              src={theme.IMAGES.products.cube.healthy1}
            />
            <ImageComponent
              alt="sercair"
              width={25}
              src={theme.IMAGES.products.cube.healthy2}
            />
            <ImageComponent
              alt="sercair"
              width={40}
              src={theme.IMAGES.products.cube.healthy3}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 20,

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              textAlign: "center",
              display: "flex",
              width: "90%",
              flexDirection: "column",
              zIndex: 2,
            }}
          >
            <div
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "85%",
                  height: "40%",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyItems: "center",
                  backgroundColor: theme.COLORS.cubeContainer,
                  padding: 15,
                  borderTopRightRadius: 20,
                  borderTopLeftRadius: 20,
                }}
              >
                <h3>
                  {t(TranslationKey, "praticUsageTitle").replace(
                    /<br\s*[\/]?>/gi,
                    ""
                  )}
                </h3>
                <p
                  style={{ fontSize: 10 }}
                  dangerouslySetInnerHTML={{
                    __html: t(TranslationKey, "praticUsageText").replace(
                      /<br\s*[\/]?>/gi,
                      ""
                    ),
                  }}
                />
              </div>
            </div>
            <div className="d-flex">
              <ImageComponent
                alt="sercair"
                width={33}
                style={{ objectFit: "contain" }}
                src={theme.IMAGES.products.cube.praticUsage1}
              />
              <ImageComponent
                alt="sercair"
                width={37.8}
                style={{ objectFit: "contain" }}
                src={theme.IMAGES.products.cube.praticUsage2}
              />
              <ImageComponent
                alt="sercair"
                width={30}
                style={{ objectFit: "contain" }}
                src={theme.IMAGES.products.cube.praticUsage3}
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: 25, display: "flex", alignItems: "center", justifyContent: "space-around" }}>
          <div
            className="col-3"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ImageComponent
              alt="sercair"
              width={50}
              src={theme.IMAGES.products.cube.con1}
            />
            <p style={{ textAlign: "center"}}>{t(TranslationKey, "ico1")}</p>
          </div>
          <div
            className="col-3"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ImageComponent
              alt="sercair"
              width={50}
              src={theme.IMAGES.products.cube.con2}
            />
            <p style={{ textAlign: "center"}}>{t(TranslationKey, "ico2")}</p>
          </div>
          <div
            className="col-3"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ImageComponent
              alt="sercair"
              width={50}
              src={theme.IMAGES.products.cube.con3}
            />
            <p style={{ textAlign: "center"}}>{t(TranslationKey, "ico3")}</p>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <ImageComponent
            width={80}
            style={{ marginTop: 25 }}
            alt="sercair"
            src={theme.IMAGES.products.cube.laptop}
          />
        </div>
        <div
          className="d-flex flex-column align-items-center"
          style={{ marginTop: 25 }}
        >
          <h3
            style={
              screenSize < 1537
                ? {
                    ...Style.airFlowEchoStyle,
                    "--data-font-size": "18px",
                    "--data-top": "-20px",
                  }
                : Style.airFlowEchoStyle
            }
            data-text={t(TranslationKey, "airFlow")}
            className="textEffectCenter"
          >
            {t(TranslationKey, "airFlowTitle")}
          </h3>
          <div
            style={{
              backgroundImage: `url(${theme.IMAGES.products.cube.airFlow})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: 300,
              height: 150,
              marginTop: 50,
              position: "relative",
              zIndex: 2,
            }}
          >
            {/* <div style={{ position: "absolute", bottom: 0, right: -70 }}>
              {button(t(TranslationKey, "airFlowWatch"), "/contact", true)}
            </div> */}
          </div>
        </div>
        <div style={{ marginTop: 100 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <h3
              style={Style.AirCleaningMobileStyle}
              data-text={t(TranslationKey, "highAirQualityEco")}
              className="textEffectCenter"
              dangerouslySetInnerHTML={{
                __html: t(TranslationKey, "highAirQualityTitle"),
              }}
            />
            <p
              style={{ marginTop: 25, width: 260 }}
              dangerouslySetInnerHTML={{
                __html: t(TranslationKey, "highAirQualityText"),
              }}
            />
          </div>
          <ImageComponent
            alt="sercair"
            style={{
              marginTop: 25,
              width: "85%",
              marginLeft: "7.5%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            src={theme.IMAGES.products.cube.highAirQuality}
          />
        </div>
        <div style={{ marginTop: 50 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <h3
              style={Style.sterillizationMobileStyle}
              data-text={t(TranslationKey, "sterilizationEco")}
              className="textEffectCenter"
              dangerouslySetInnerHTML={{
                __html: t(TranslationKey, "sterilizationTitle"),
              }}
            />
            <p
              style={{ marginTop: 25, paddingLeft: 25, paddingRight: 25 }}
              dangerouslySetInnerHTML={{
                __html: t(TranslationKey, "sterilizationMobile"),
              }}
            />
          </div>
          <ImageComponent
            alt="sercair"
            style={{ marginTop: 25 }}
            src={theme.IMAGES.products.cube.sterilization}
          />
        </div>
        <div>
          <div style={{ textAlign: "center" }}>
            <h3
              style={Style.rezervationMobileStyle}
              data-text={t(TranslationKey, "rezervationEco")}
              className="textEffectCenter"
              dangerouslySetInnerHTML={{
                __html: t(TranslationKey, "rezervationTitle"),
              }}
            />
            <p
              style={{ marginTop: 25 }}
              dangerouslySetInnerHTML={{
                __html: t(TranslationKey, "rezervationText"),
              }}
            />
          </div>
          <ImageComponent
            alt="sercair"
            src={theme.IMAGES.products.cube.rezervation}
            style={{ marginTop: 25 }}
          />
        </div>
        <div className="d-flex container">
          <ImageComponent
            alt="sercair"
            src={theme.IMAGES.products.cube.app1}
            width={33}
          />
          <ImageComponent
            alt="sercair"
            src={theme.IMAGES.products.cube.app2}
            width={33}
          />
          <ImageComponent
            alt="sercair"
            src={theme.IMAGES.products.cube.app3}
            width={33}
          />
        </div>
        <div className="container" style={{ marginBottom: 50 }}>
          <ImageComponent
            alt="sercair"
            src={theme.IMAGES.products.cube.lastImageLeft}
            style={{ marginBottom: 25, marginTop: 15 }}
          />
          <ImageComponent
            alt="sercair"
            src={theme.IMAGES.products.cube.lastImageRight}
          />
        </div>
      </>
    );
  };
  const secondTabItems = () => {
    return (
      <div
        tabIndex={-1}
        className="container"
        style={{ position: "relative", width: "100%" }}
      >
        {/* map top side */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "calc(100% - 24px)",
            margin: "0 12px",
            height: "10%",
            backgroundColor: theme.COLORS.blueContainer,
            zIndex: 50,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ImageComponent
            alt="sercair"
            src={theme.IMAGES.products.cube.mapIcon}
            width={8}
            onClick={() => {
              setLeftOpen(!leftOpen);
            }}
            style={{
              marginTop: 11,
              marginLeft: 5,
              cursor: "pointer",
              rotate: !leftOpen ? "0deg" : "180deg",
              transition: "all 0.5s",
            }}
          />
          <ImageComponent
            onClick={() => {
              //gm-fullscreen-control triggered
              let fullscreenControl = document.getElementsByClassName(
                "gm-fullscreen-control"
              )[0] as HTMLElement;
              //remove background color
              fullscreenControl.style.backgroundColor = "transparent";
              //fullscreenControl change background image as theme.IMAGES.products.cube.mapIcon
              fullscreenControl.style.backgroundImage = `url(${theme.IMAGES.products.cube.fullScreen})`;
              //no repeat
              fullscreenControl.style.backgroundRepeat = "no-repeat";

              //delete all img tag in fullscreenControl
              let imgTag = fullscreenControl.getElementsByTagName("img");
              while (imgTag.length > 0) {
                imgTag[0].parentNode!.removeChild(imgTag[0]);
              }

              //trigger click event
              fullscreenControl.click();
            }}
            alt="sercair"
            src={theme.IMAGES.products.cube.fullScreen}
            width={8}
            style={{ marginTop: 11, marginRight: 5, cursor: "pointer" }}
          />
        </div>
        {/* map left side */}
        {leftOpen && (
          <div
            style={
              infoContainer
                ? {
                    position: "absolute",
                    bottom: 0,
                    left: 12,
                    width: "35%",
                    height: "calc(60%)",
                    backgroundColor: theme.COLORS.white,
                    zIndex: 50,
                    display: "flex",
                    flexDirection: "column",
                    transition: "all 0.5s",
                  }
                : {
                    position: "absolute",
                    top: "40%",
                    left: 12,
                    width: "40%",
                    height: "calc(60%)",
                    backgroundColor: theme.COLORS.white,
                    zIndex: 50,
                    display: "flex",
                    flexDirection: "column",
                  }
            }
          >
            <div
              style={{
                height: infoContainer ? "15%" : "10%",
                backgroundColor: theme.COLORS.blue,
                display: "flex",
                alignItems: "center",
                paddingLeft: infoContainer ? 10 : 10,
                color: "white",
              }}
            >
              <p style={{ textAlign: "center" }}>
                {" "}
                <b>
                  {infoContainer ? (
                    <div className="d-flex">
                      <ImageComponent
                        alt="sercair"
                        src={theme.IMAGES.products.cube.backArrow}
                        width={15}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setInfoContainer(!infoContainer);
                        }}
                      />
                      <div style={{ marginLeft: 10 }}>{selectedPoint.name}</div>
                    </div>
                  ) : (
                    t(TranslationKey, "cubeMapTitle")
                  )}
                </b>
              </p>
            </div>

            {infoContainer ? (
              <div style={{ fontSize: 12 }}>
                <div style={{ marginTop: 25 }}>
                  <h6 style={{ color: "rgb(187 187 187)" }}>
                    {t(TranslationKey, "name")}
                  </h6>
                  <div>{selectedPoint.name}</div>
                </div>
                <div style={{ marginTop: 25 }}>
                  <h6 style={{ color: "rgb(187 187 187)", fontSize: 12 }}>
                    {t(TranslationKey, "location")}
                  </h6>
                  <div>{selectedPoint.address}</div>
                </div>
                <div style={{ marginTop: 25 }}>
                  <h6
                    style={{
                      color: "rgb(187 187 187)",
                      fontSize: "10px !important",
                    }}
                  >
                    {t(TranslationKey, "locationGoogle")}
                  </h6>
                  <a
                    target="_blank"
                    href={
                      "https://google.com/maps/search/Adres:" +
                      selectedPoint.address
                    }
                  >
                    <div style={{ color: theme.COLORS.blue }}>
                      {t(TranslationKey, "locationGoogleText")}
                    </div>
                  </a>
                </div>
              </div>
            ) : (
              cityes.map((city, index) => {
                return (
                  <div key={"mainContainerCity" + index}>
                    <>
                      <div
                        style={{
                          backgroundColor: theme.COLORS.cubeMapBg,
                          height: 45,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            color: theme.COLORS.cubeMapCityColor,
                            paddingLeft: 25,
                          }}
                        >
                          {city.name}
                        </div>
                      </div>
                      {/* get allPoints (array) by cityes */}
                      {allPoints.map((point, index) => {
                        return (
                          point.cityCode === city.code && (
                            <div
                              className={"mainContainerPoints" + index}
                              key={"mainContainerPoints" + index}
                              style={{
                                paddingLeft: 25,
                                fontSize: 12,
                                margin: "5px 0",
                                cursor: "pointer",
                                fontWeight: "normal",
                              }}
                              onMouseOut={() => {
                                let mainContainerPoints =
                                  document.getElementsByClassName(
                                    "mainContainerPoints" + index
                                  )[0] as HTMLElement;
                                mainContainerPoints.style.fontWeight = "normal";
                              }}
                              onMouseOver={() => {
                                let mainContainerPoints =
                                  document.getElementsByClassName(
                                    "mainContainerPoints" + index
                                  )[0] as HTMLElement;
                                mainContainerPoints.style.fontWeight = "bold";
                              }}
                              onClick={() => {
                                setSelectedPoint(point);
                                setInfoContainer(!infoContainer);
                              }}
                            >
                              {point.name}
                            </div>
                          )
                        );
                      })}
                    </>
                  </div>
                );
              })
            )}
          </div>
        )}
        <div style={{ marginTop: 109}}>
        <MapComponent
          isIconSpecial={true}
          pointModel={cubeSelectedPoints}
          pointModelAll={allPoints}
          selectedCity={["", "all"]}
          selectedCountry={["", "all"]}
          selectedDistrict={["", "all"]}
          selectedDarkTheme={true}
          setSelectedPoint={setSelectedPoint}
          setInfoContainer={setInfoContainer}
        />
        </div>
      </div>
    );
  };
  const thirdTabItems = () => {
    return (
      <div style={{ marginTop: 109 }}>
        <Contact />
      </div>
    );
  };
  const techTabItems = () => {
    return (
      <div>
        <div style={{ marginTop: 150, marginBottom: 150 }}>
          <div className="d-flex justify-content-center">
            <div style={{ position: "relative", scale: "0.8" }}>
              <ImageComponent
                alt="sercair"
                src={theme.IMAGES.products.cube.tech1}
                style={{
                  display: "inherit",
                  position: "ralative" as "relative",
                  zIndex: 2,
                  width: 254,
                  height: 420,
                }}
              />
              {/* Component Arrows Here */}

              <ArrowComponent arrowData={cubeLeftTechicalDataMobile} />
            </div>
          </div>
          {/* <div className="d-flex justify-content-center">
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ImageComponent
                alt="sercair"
                src={theme.IMAGES.products.cube.tech2}
                width={40}
                style={{
                  marginTop: 161,
                  display: "inline-block",
                  position: "ralative" as "relative",
                  zIndex: 2,
                  maxHeight: "100%",
                  width: 312,
                  height: 234,
                }}
              />
              <ArrowComponent
                arrowData={cubeRightTechicalData}
                left={-245}
                top={-114}
                scale = {0.7}
              />
            </div>
          </div> */}
        </div>
        <div style={{ marginBottom: 30 }}>
          <div>
            <ImageComponent
              alt="sercair"
              src={theme.IMAGES.products.cube.tech3}
            />
          </div>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ flexDirection: "column" }}
          >
            <h2 style={{ marginBottom: 50 }}>
              {t(TranslationKey, "techProps")}
            </h2>
            <div className="d-flex">
              <div style={{ marginRight: 30 }}>
                <h3>{t(TranslationKey, "techProps1Title")}</h3>
                <div style={{ fontSize: "1rem", marginBottom: 35 }}>
                  {t(TranslationKey, "techProps1Text")}
                </div>
              </div>
              <div>
                <h3>{t(TranslationKey, "techProps2Title")}</h3>
                <div style={{ fontSize: "1rem", marginBottom: 35 }}>
                  {t(TranslationKey, "techProps2Text")}
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div style={{ marginRight: 30 }}>
                <h3>{t(TranslationKey, "techProps3Title")}</h3>
                <div style={{ fontSize: "1rem", marginBottom: 35 }}>
                  {t(TranslationKey, "techProps3Text")}
                </div>
              </div>
              <div style={{ marginRight: 50 }}>
                <h3>{t(TranslationKey, "techProps4Title")}</h3>
                <div style={{ fontSize: "1rem", marginBottom: 35 }}>
                  {t(TranslationKey, "techProps4Text")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const children = [
    {
      title: t("Cube", "tabTitle1"),
      body: <div style={{ backgroundColor: "white" }}>{firstTabItems()}</div>,
    },
    // {
    //   title: t("Cube", "tabTitle2"),
    //   body: <div style={{ backgroundColor: "white" }}>{secondTabItems()}</div>,
    // },
    {
      title: t("Cube", "tabTitle3"),
      body: <div style={{ backgroundColor: "white" }}>{thirdTabItems()}</div>,
    },
    {
      title: t("Cube", "tabTitle4"),
      body: <div style={{ backgroundColor: "white" }}>{techTabItems()}</div>,
    },
  ];
  return (
    <div>
      <TabComponent children={children} componentKey={"PureSeries"} />
    </div>
  );
}

export default MobilePage;
