import { Container } from "react-bootstrap";
import theme from "../../../../constants/theme";
import { store } from "../../../../store";
import { ImageComponent } from "../../ImageComponent/component";
import { Props } from "./types";
import React from "react";
export function LeftTextComponent(props: Props) {
  const hDefStyle = {
    marginBottom: theme.SPACES.xlarge,
    color: props.titleColor ? props.titleColor : "black",
    fontFamily: theme.FONTS.GothamMedium,
    fontSize: "22px"
  };
  const pDefStyle = { marginBottom: theme.SPACES.xlarge, fontSize: "14px" };
  const hStyle = {
    ...hDefStyle,
    ...props.hStyle,
  };
  const pStyle = {
    ...pDefStyle,
    ...props.pStyle,
  };
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
  }, []);
  return (
    <>
      <Container className="row" style={props.style}>
        <div
          className="container col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex text-justify-responsive justify-content-center align-items-center"
          style={
            props.textBackgroundImg
              ? {
                  backgroundImage: "url(" + props.textBackgroundImg + ")",
                  backgroundSize: props.isIcon ?  "contain" : "cover",
                 // backgroundSize: props.isIcon ? (props.isMobileBus ? "cover" : "contain") : ("cover"),
                  backgroundPosition: "50% 50%",
                  backgroundRepeat: props.textBackgroundRepeat
                    ? "no-repeat"
                    : "initial",
                  height: ((props.isPureComponent !== false ) && isMobile) ? "50vw" : isMobile ? "100vw" : "100%",
                  width: isMobile ? '100%' : '',
                   marginBottom: ((props.isPureComponent !== false ) && isMobile) ? -10 : 0,
                   
                   
                   
                   
                }
              : {
                  backgroundColor: props.backgroundColor
                    ? props.backgroundColor
                    : "white",
                }
          }
        >
          <div
            className="card"
            style={{
              width: isMobile ? "fit-content" : "65%",
              borderColor: props.backgroundColor
                ? props.backgroundColor
                : "transparent",
              backgroundColor: props.backgroundColor
                ? props.backgroundColor
                : "transparent",
            }}
          >
            <div className="card-body">
              <h1
                className="card-title"
                style={hStyle}
                dangerouslySetInnerHTML={{
                  __html: isMobile
                    ? props.title && props.title.replace(/<br\s*[\/]?>/gi, "")
                    : (props.title as any),
                }}
              ></h1>
              <p
                style={pStyle}
                className="card-text"
                dangerouslySetInnerHTML={{
                  __html: isMobile
                    ? props.text && props.text.replace(/<br\s*[\/]?>/gi, "")
                    : (props.text as any),
                }}
              ></p>
              {props.textImg && (
                 <ImageComponent src={props.textImg} alt={props.imgAlt} />
              )}
              
              {props.isBtn && (
                <>
                  {" "}
                  
                  <div style={{marginRight: props.isApp ? 40 : 0, marginTop: props.isMobileBus ? -40 : 0}}>
                  {props.buttonComponent(
                    props.btnText,
                    props.btnUrl,
                    props.btnIsLong
                  )}
                  </div>
                </>
              )}
              
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <ImageComponent src={props.imgUrl} alt={props.imgAlt} />
        </div>
      </Container>
    </>
  );
}
