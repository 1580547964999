export const data = [
  {
    title: "",
    items: [
      "txt1",
      "txt2",
      "txt3",
      "txt4",
      "txt5",
      "txt6",
      "txt7",
      "txt8",
      "txt9",
      "txt10",
    ],
  },
  {
    title: "title2",
    items: ["txt11", "txt12", "txt13", "txt14"],
  },
];
