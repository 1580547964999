import React, { useState } from "react";
import theme from "../../../../constants/theme";
import { Link } from "react-router-dom";
import { Style } from "./style";
import {
  SocialMediasTR,
  SocialMediasEN,
  SocialMediasNL,
  SocialMediasRS,
  SocialMediasFR,
} from "../../../../constants/socialMedia";
import { TranslationService } from "../../../../services/translation.service";
import useWindowDimensions from "../../../../services/getWindowSize.service";

const langCheck = localStorage.getItem("lang");

export function Component() {
  const t = TranslationService.translate;
  const screenSize = useWindowDimensions().width;

  var socialMedias = SocialMediasEN;
  TranslationService.getLang().code === "tr"
    ? (socialMedias = SocialMediasTR)
    : "nl"
    ? (socialMedias = SocialMediasNL)
    : "rs"
    ? (socialMedias = SocialMediasRS)
    : "fr"
    ? (socialMedias = SocialMediasFR)
    : (socialMedias = SocialMediasFR);

  const [styleLang, setStyleLang] = useState({
    color: theme.COLORS.black,
    cursor: "pointer",
  });

  return (
    <div
      className="text-justify-responsive d-none"
      style={{ marginLeft: "10%", marginRight: "10%", flexDirection: "row" }}
    >
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
          {screenSize < 500 ? null : (
            <img
              src={theme.ICONS.logoBlue}
              style={theme.ICON_SIZE.size120}
              alt="sercair"
            />
          )}

          <br />
          <div className="d-inline-flex">
            {}
            <Link
              to={
                langCheck === "tr"
                  ? "/tr/iletisim"
                  : langCheck === "nl"
                  ? "/nl/contact"
                  : langCheck === "rs"
                  ? "/rs/kontakt"
                  : langCheck === "fr"
                  ? "/fr/contact"
                  : langCheck === "uk"
                  ? "/uk/contact"
                  : langCheck === "ro"
                  ? "/ro/contactati"
                  : "/contact"
              }
            >
              {t("Footer", "contactUs")}
            </Link>
            {/* <Link to={"/blog"} style={Style.item}>
              {t("Footer", "blog")}
            </Link> */}
            <Link
              to={
                langCheck === "tr"
                  ? "/tr/kataloglar"
                  : langCheck === "nl"
                  ? "/nl/catalogi"
                  : langCheck === "rs"
                  ? "/rs/katalozi"
                  : langCheck === "fr"
                  ? "/fr/catalogues"
                  : langCheck === "uk"
                  ? "/uk/catalogs"
                  : langCheck === "ro"
                  ? "/ro/cataloage"
                  : "/catalogs"
              }
              style={Style.item}
            >
              {t("Footer", "catalogues")}
            </Link>
          </div>
          <Link to={"/Dealership/login"}>
            <div className="mt-2">{t("Footer", "dealerShip")}</div>
          </Link>
          <Link
            to={window.location.pathname}
            onClick={() => {
              window.open("https://devicemanagement.sercair.com/", "_blank");
            }}
          >
            <div className="mt-2">{t("Footer", "managementPanel")}</div>
          </Link>
          <div className="d-inline-flex mt-3 mb-3">
            {socialMedias.map(function (data, index) {
              return (
                <div className="pt-0" key={index + "social"}>
                  <Link to={""} onClick={() => window.open(data.url, "_blank")}>
                    <div
                      className="m-0 p-0 row socialiconContainer "
                      style={Style.socialIcons}
                    >
                      <data.icon
                        size={24}
                        style={Style.socialIcons}
                        className="socialicons"
                      />
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 mt-3">
          <div className="row">
            {screenSize < 500 ? null : (
              <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <img src={theme.IMAGES.footer.mobileApp} alt={"sercair"} />
              </div>
            )}

            {/* <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
              
              <img src={theme.IMAGES.footer.mobileApp} alt="sercair" />
            </div> */}
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex align-items-center justify-content-center">
              <div className="">
                <span className="d-block mt-3 blueTitle fs-12">
                  {t("Footer", "mobileTitle")}
                </span>
                <span className="d-block mt-2">
                  {t("Footer", "mobileText")}
                </span>
                <div className="d-inline-flex mt-2">
                  <Link
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/us/app/sercair/id1522481568",
                        "_blank"
                      )
                    }
                    to={""}
                  >
                    <img
                      className="pe-2 pe-sm-1"
                      src={theme.IMAGES.footer.applestore}
                      alt="sercair"
                      style={Style.storeIcons}
                    />
                  </Link>
                  <Link
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.masscode.Sercair",
                        "_blank"
                      )
                    }
                    to={""}
                  >
                    <img
                      className="pe-2 pe-sm-0"
                      src={theme.IMAGES.footer.playstore}
                      alt="sercair"
                      style={Style.storeIcons}
                    />
                  </Link>
                </div>
                {/* <div className="d-block mt-3 ">
                  <div className="d-flex align-items-center justify-content-start">
                    <img
                      src={TranslationService.getLang().flag}
                      style={{ width: 33, height:43, marginLeft: screenSize<500 ? 40 :0  }}
                      alt="sercair"
                    />
                    <div className="d-block ">
                      <span className="d-block GothamBold">
                        {t("Footer", TranslationService.getLang().country)}
                      </span>
                      <span className="d-block mt-2"
                      style={styleLang}
                      onMouseOver={() => setStyleLang({color: theme.COLORS.blue, cursor: "pointer"})}
                      onMouseLeave={() => setStyleLang({color: theme.COLORS.black, cursor: "pointer"})}
                      onClick={() => ModalSwitcher.ShowCountryModal()}
                      >
                        {t("Footer", "changeLanguageOrCountry")}
                      </span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {screenSize < 500 ? null : (
              <div className="col-sm-12 col-md-12 col-lg-2 col-xl-2 text-sm-center text-md-center justBreathe mt-2">
                <img
                  src={theme.IMAGES.footer.justBreath}
                  style={{ width: 120 }}
                  alt="sercair"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
