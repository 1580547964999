import { Lang } from "../../constants/langs";

export enum ActionType {
  LOADING = "LOADING",
  ERROR_MODAL = "ERROR_MODAL",
  ERROR_MODAL_DATA = "ERROR_MODAL_DATA",
  SUCCESS_MODAL = "SUCCESS_MODAL",
  SUCCESS_MODAL_DATA = "SUCCESS_MODAL_DATA ",
  LANG = "LANG",
  LANGUAGE_MODAL = "LANGUAGE_MODAL",
  VIDEO_MODAL = "VIDEO_MODAL",
  IS_MOBILE = "IS_MOBILE",
  OPENING_MODAL = "OPENING_MODAL",
  COUNTRY_MODAL = "COUNTRY_MODAL",
}

interface loadingAction {
  type: ActionType.LOADING;
  payload: boolean;
}

interface errorModalAction {
  type: ActionType.ERROR_MODAL;
  payload: boolean;
}

interface errorModalDataAction {
  type: ActionType.ERROR_MODAL_DATA;
  payload: string;
}

interface successModalAction {
  type: ActionType.SUCCESS_MODAL;
  payload: boolean;
}

interface successModalDataAction {
  type: ActionType.SUCCESS_MODAL_DATA;
  payload: string;
}

interface langAction {
  type: ActionType.LANG;
  payload: Lang;
}

interface test {
  type: ActionType.LOADING;
  payload: boolean;
}

interface showLanguageModal {
  type: ActionType.LANGUAGE_MODAL;
  payload: boolean;
}

interface showOpeningModal {
  type: ActionType.OPENING_MODAL;
  payload: boolean;
  imgPath: string;
  imgAlt: string;
}

interface showVideoModal {
  type: ActionType.VIDEO_MODAL;
  payload: boolean;
  videoId: string;
}

interface isMobile {
  type: ActionType.IS_MOBILE;
  payload: boolean;
}

interface showCountryModal {
  type: ActionType.COUNTRY_MODAL;
  payload: boolean;
}

export type Action =
  | showCountryModal
  | showOpeningModal
  | showVideoModal
  | loadingAction
  | test
  | errorModalAction
  | errorModalDataAction
  | successModalAction
  | successModalDataAction
  | langAction
  | showLanguageModal
  | isMobile;
