import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MainScreenNavigator } from "./Main/MainScreenNavigator";
import { NavbarComponent } from "../components/NavbarComponent";
import { FooterComponent } from "../components/FooterComponent";
import { useEffect, useState } from "react";
import { allProductsNavigator } from "./Products";
import { SupportScreenNavigator } from "./Support";
import { SalesPointsScreenNavigator } from "./SalesPoints";
import { FootertNavigator } from "./Footers";
import { DealershipScreenNavigator } from "./Footers/Dealership";
import MapScreen from "../screens/WeatherScreens/mapScreen/mapScreen";
import MainScreenW from "../screens/WeatherScreens/mainScreen/mainScreenW";

export function AppRouter() {
  const langCheck = localStorage.getItem("lang");
  const [allNavigator, setAllNavigator] = useState([] as any[]);
  useEffect(() => {
    setAllNavigator(
      MainScreenNavigator.concat(
        allProductsNavigator,
        SupportScreenNavigator,
        SalesPointsScreenNavigator,
        FootertNavigator,
        DealershipScreenNavigator
      )
    );
  }, []);
  return (
    <BrowserRouter>
      <NavbarComponent />
      <main role={"main"}>
        <Routes>
          {allNavigator.map(function (route, index) {
            return (
              <Route
                key={index + "Navi"}
                path={route.path}
                 element={<MapScreen />}
                // {route.screen}
              />
            );
          })}
          {/* <Route path="*" element={<NotFoundPage />} /> */}
          {/* <Route path="/production" element={<Production/>}/>*/}
          <Route path="/MapScreen" element={<MapScreen />} />
          <Route path="/MapScreen/MainScreenW" element={<MainScreenW />} />
        </Routes>
      </main>
      <FooterComponent />
    </BrowserRouter>
  );
}
