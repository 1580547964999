import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { APIURLs } from "../../../API/consts/APIURLs";
import { UserGuideService } from "../../../API/services/UserGuide.service";
import { langs } from "../../../constants/langs";
import theme from "../../../constants/theme";
import { userGuideTable } from "../../../models/userguide.model";
import { TranslationService } from "../../../services/translation.service";
import { store } from "../../../store";
import { dropdownArrayModelGenerator } from "../../../utils/string";
import { Style } from "./style";
import "./style.css";
import { Props } from "./types";

export function ScreenComponent(props: Props) {
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
    window.scrollTo(0, 0);
  }, []);
  const languagesModeled = dropdownArrayModelGenerator(
    langs,
    "display",
    "code"
  );
  const [selectedLang, setSelectedLang] = React.useState(
    TranslationService.getLang().display
  );

  const [userGuides, setUserGuides] = React.useState<userGuideTable[]>([]);

  useEffect(() => {
    let selectedLangCode = langs.find(
      (item) => item.display === selectedLang
    )?.code;
    UserGuideService.getUserGuide(
      selectedLangCode ? selectedLangCode : TranslationService.getLang().code
    ).then((res) => {
      setUserGuides(res.data);
    });
  }, [selectedLang]);
  const t = TranslationService.translate;
  const translationKey = "Userguide";
  const Table = (content: userGuideTable[], lang: string) => {
    return (
      <>
        {content.map((item, index) => {
          return (
            <a
              href={APIURLs.MEDIA_URL + "userGuide/" + item.url}
              target="_blank"
              rel="noopener noreferrer"
              key={"catalogs" + index}
            >
              <div
                style={Style.tableMain}
                className="row m-0 py-3 justify-content-between"
              >
                <p className="col-auto m-0 p-0" style={Style.tabletxt}>
                  {" "}
                  {t(translationKey, item.title) + " (" + lang + ")"}
                </p>
                <a
                  href={item.url}
                  download={t(translationKey, item.title)}
                  className="col-auto m-0 p-0"
                >
                  <img
                    style={{ width: isMobile ? "20px" : "25px" }}
                    src={theme.IMAGES.support.downloadIcon}
                    alt="arrow"
                  />
                </a>
              </div>
            </a>
          );
        })}
      </>
    );
  };

  return (
    <Container
      style={{
        marginTop: 60,
        marginBottom: 100,
        minHeight: "90vh",
        maxWidth: "80vw",
      }}
    >
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <img
            className="mobile-img"
            src={theme.IMAGES.support.userGuide}
            alt={"sercair"}
          />
          <h2
            className="ms-4 gothamMedium mobile-font"
            style={{ color: theme.COLORS.blue }}
          >
            {t(translationKey, "mainTitle")}
          </h2>
        </div>
        {/* <div style={{ marginRight: "5px"}} className="col-sm-12 col-md-12 col-lg-2 col-xl-2">
          <div className="mb-0 mt-2 dropdown-name" style={{ color: theme.COLORS.blue, fontSize: isMobile ? "8pt" : "12pt"  }}>
            {t(translationKey, "search")}
          </div>
          <DropdownComponent
            selectedValue={selectedLang}
            values={languagesModeled}
            onChange={(model) => {
              const selected = langs.find((x) => x.code === model.value);
              setSelectedLang(selected?.display as string);
            }}
          />
        </div> */}
      </div>

      {Table(userGuides, selectedLang)}
    </Container>
  );
}
