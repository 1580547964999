import emailjs from "@emailjs/browser";
import { Fragment, useEffect, useRef, useState } from "react";
import closeIcon from "../../assets/images/Navbar/close-icon.svg";
import callIcon from "../../assets/images/Navbar/tel-icon.svg";
// CSS
import "./callus.scss";

function CallUs() {
  const langCheck = localStorage.getItem("lang");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("serviceSercair", "sercairTemp", e.target, "_Ew3RrH5aia__4uIO")
      .then(
        (result) => {
          localStorage.setItem("sendMail", "Sended");
        },
        (error) => {}
      );
  };

  const callusArticle = {
    title:
      langCheck === "tr"
        ? "Sizi Arayalım"
        : langCheck === "fr"
        ? "Appelons-nous"
        : langCheck === "rs"
        ? "Hajde da te pozovemo"
        : langCheck === "nl"
        ? "Laten we je bellen"
        : langCheck === "ro"
        ? "Să te sunăm"
        : "Let's call you",
    privacy:
      langCheck === "tr"
        ? "KVKK aydınlatma metnini "
        : langCheck === "fr"
        ? "Texte d'éclairage KVKK "
        : langCheck === "rs"
        ? "KVKK svetlosni tekst "
        : langCheck === "nl"
        ? "KVKK lichttekst "
        : langCheck === "ro"
        ? "Conceptul de iluminat KVKK "
        : "KVKK lighting concept ",
    privacyTrust:
      langCheck === "tr"
        ? "okudum."
        : langCheck === "fr"
        ? "j'ai lu."
        : langCheck === "rs"
        ? "Čitam."
        : langCheck === "nl"
        ? "ik lees."
        : langCheck === "ro"
        ? "am citit."
        : "i read.",
    campaings:
      langCheck === "tr"
        ? "Kampanyalardan haberdar olmak için tarafıma elektronik ileti gönderilmesini kabul ederim."
        : langCheck === "fr"
        ? "J'accepte de recevoir des messages électroniques pour être informé des campagnes."
        : langCheck === "rs"
        ? "Slažem se da mi se šalju elektronske poruke radi informisanja o kampanjama."
        : langCheck === "nl"
        ? "Ik ga ermee akkoord om elektronische berichten te ontvangen om op de hoogte gehouden te worden van de campagnes."
        : langCheck === "ro"
        ? "Sunt de acord să primesc mesaje electronice pentru a fi informat despre campanii."
        : "I agree to be sent electronic messages to be informed about the campaigns.",
    send:
      langCheck === "tr"
        ? "Gönder"
        : langCheck === "fr"
        ? "Envoyer"
        : langCheck === "rs"
        ? "Pošalji"
        : langCheck === "nl"
        ? "Versturen"
        : langCheck === "ro"
        ? "Trimite"
        : "Send",
    name:
      langCheck === "tr"
        ? "Adınız"
        : langCheck === "fr"
        ? "Nom"
        : langCheck === "rs"
        ? "Ime"
        : langCheck === "nl"
        ? "Naam"
        : langCheck === "ro"
        ? "Nume"
        : "Name",
    surname:
      langCheck === "tr"
        ? "Soyadınız"
        : langCheck === "fr"
        ? "Nom de famille"
        : langCheck === "rs"
        ? "Prezime"
        : langCheck === "nl"
        ? "Achternaam"
        : langCheck === "ro"
        ? "Prenume"
        : "Surname",
    mail:
      langCheck === "tr"
        ? "E-Posta adresiniz"
        : langCheck === "fr"
        ? "Adresse e-mail"
        : langCheck === "rs"
        ? "Adresa E-pošte"
        : langCheck === "nl"
        ? "E-mailadres"
        : langCheck === "ro"
        ? "Adresă de e-mail"
        : "E-Mail Address",
    tel:
      langCheck === "tr"
        ? "Telefon Numaranız"
        : langCheck === "fr"
        ? "Votre numéro de téléphone"
        : langCheck === "rs"
        ? "Vaš broj telefona"
        : langCheck === "nl"
        ? "Jouw telefoon nummer"
        : langCheck === "ro"
        ? "Număr de telefon"
        : "Telephone Number",
    mailSendInfo:
      langCheck === "tr"
        ? "Mail gönderildi."
        : langCheck === "fr"
        ? "L'email a été envoyé."
        : langCheck === "rs"
        ? "Poruka je poslata."
        : langCheck === "nl"
        ? "De email is verzonden."
        : langCheck === "ro"
        ? "E-mailul a fost trimis."
        : "Email has been sent.",
    privacyLink:
      langCheck === "tr"
        ? "/tr/anlasmalar/gizlilik"
        : langCheck === "fr"
        ? "/fr/accords/vie-privée"
        : langCheck === "rs"
        ? "/rs/sporazumi/privatnost"
        : langCheck === "nl"
        ? "/nl/overeenkomsten/privacy"
        : langCheck === "ro"
        ? "/ro/acorduri/intimitate"
        : "agreements/privacy",
  };

  const [callUs, setCallUs] = useState(false);

  // create a function for call-us

  const callUsHandler = () => {
    setCallUs(!callUs);
  };

  // useEffect for call-us

  useEffect(() => {
    const callComp = document.querySelector(".call-us-section");
    if (
      callUs === true &&
      document.getElementById("call-container") &&
      document.getElementsByClassName("call-us-section")
    ) {
      callComp.classList.add("active");
      callComp.classList.remove("disabled");
    } else {
      callComp.classList.add("disabled");
      callComp.classList.remove("active");
    }
  });

  useEffect(() => {
    const sendMailCheck = document.querySelector(".sendMailCheck");
    if (localStorage.getItem("sendMail") === "Sended") {
      sendMailCheck.classList.remove("hidePopUp");
      sendMailCheck.classList.add("showPopUp");
    }
  }, [sendEmail]);

  return (
    <Fragment>
      {/* <div className="call-button">
        <button onClick={callUsHandler}>
          <img src={callIcon} alt="call" />
        </button>
      </div> */}
      <div id="call-container" className="call-us-section">
        <div className="call-us-section__container">
          <div className="call-us-section__container__close">
            <button className="container-close__button" onClick={callUsHandler}>
              <img src={closeIcon} alt="close" />
            </button>
          </div>
          <div className="call-us-section__container__header">
            <h2>{callusArticle.title}</h2>
          </div>
          <div className="call-us-section__container__body">
            <form ref={form} onSubmit={sendEmail}>
              <div className="call-us-section__container__body__form">
                <div className="call-us-section__container__body__form__input">
                  <input
                    type="text"
                    placeholder={callusArticle.name}
                    required
                    name="name"
                  />
                  <input
                    type="text"
                    placeholder={callusArticle.surname}
                    required
                    name="surname"
                  />
                  <input
                    type="email"
                    placeholder={callusArticle.mail}
                    required
                    name="email"
                  />
                  <input
                    type="tel"
                    placeholder={callusArticle.tel}
                    required
                    name="phone"
                  />
                </div>
                <div className="call-us-section__container__body__form__checkbox">
                  <input type="checkbox" />
                  <label>
                    <p>
                      <a href={callusArticle.privacyLink}>
                        {callusArticle.privacy}
                      </a>
                      {callusArticle.privacyTrust}
                    </p>
                  </label>
                </div>
                <div className="call-us-section__container__body__form__checkbox">
                  <input type="checkbox" />
                  <label>
                    <p>{callusArticle.campaings}</p>
                  </label>
                </div>
                <div className="call-us-section__container__body__form__button">
                  <button type="submit">{callusArticle.send}</button>
                </div>
                <br />
                <div className="sendMailCheck hidePopUp">
                  <p>{callusArticle.mailSendInfo}</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CallUs;
