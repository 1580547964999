import { UIState } from "./UIState";
import { Action, ActionType } from "./actionTypes";
import { defaultLang } from "../../constants/langs";

const initialState: UIState = {
  loading: false,
  errorModal: false,
  error: "",
  successModal: false,
  success: "",
  lang: defaultLang,
  showLanguageModal: false,
  isMobile: false,
  showVideoModal: false,
  showOpeningModal: false,
  imgPath: "",
  imgAlt: "",
  videoId: "",
  showCountryModal: false,
};

export default function uiReducer(
  state: UIState = initialState,
  action: Action
) {
  switch (action.type) {
    case ActionType.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ActionType.ERROR_MODAL:
      return {
        ...state,
        errorModal: action.payload,
      };
    case ActionType.ERROR_MODAL_DATA:
      return {
        ...state,
        error: action.payload,
      };
    case ActionType.SUCCESS_MODAL:
      return {
        ...state,
        successModal: action.payload,
      };
    case ActionType.SUCCESS_MODAL_DATA:
      return {
        ...state,
        success: action.payload,
      };
    case ActionType.LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case ActionType.LANGUAGE_MODAL:
      return {
        ...state,
        showLanguageModal: action.payload,
      };
    case ActionType.OPENING_MODAL:
      return {
        ...state,
        showOpeningModal: action.payload,
        imgPath: action.imgPath,
        imgAlt: action.imgAlt,
      };
    case ActionType.VIDEO_MODAL:
      return {
        ...state,
        showVideoModal: action.payload,
        videoId: action.videoId,
           
      };
    case ActionType.IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };

    case ActionType.COUNTRY_MODAL:
      return {
        ...state,
        showCountryModal: action.payload,
      };
      
      
    default:
      return state;
  }
}
