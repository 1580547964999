import theme from "../../constants/theme";

export const Style = {
  navbar: {
    fontSize: "10pt",
    backgroundColor: theme.COLORS.white,
    height: "75px",
  },
  navbarMobile: {
    fontSize: "10pt",
    backgroundColor: theme.COLORS.white,
    height: "142px",
  },
  navbarIndex: {
    fontSize: "10pt",
    backgroundColor: theme.COLORS.blue10,
    backgroundImage:
      "linear-gradient(to right, " +
      theme.COLORS.blue40 +
      "," +
      theme.COLORS.white80 +
      ") !important",
  },
  navLink: {
    color: theme.COLORS.black,
    cursor: "pointer",
    
  },
  navLinkHover: {
    color: theme.COLORS.blue,
    cursor: "pointer",
  },
  menuRightLinks: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  logoMarginLeft: {
    marginLeft: "7%",
    zIndex:"12",
    marginTop: "-0.5%",
  },
  collapseHeight: { height: "75px" },
  collapseHeightMobile: { height: "130px", marginTop: 0 },
};