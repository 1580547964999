import { Col, Row } from "react-bootstrap";
import theme from "../../../constants/theme";
import { TranslationService } from "../../../services/translation.service";
import { store } from "../../../store";
import { Props } from "./types";
import "./style.css";
import React from "react";
export function IconContainerComponent(props: Props) {
  const t = TranslationService.translate;
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    //@ts-ignore
    setIsMobile(store.getState().ui.isMobile);
  }, []);
  let style = isMobile
    ? props.style
    : {
        ...props.style,
        ...{
          minWidth:
            window.screen.width < 1500
              ? window.location.pathname == "/products/AirQuality/monime"
                ? 190
                : 0
              : 0,
        },
      };
  return (
    <div
      className={
        isMobile
          ? " d-flex justify-content-center container"
          : "d-flex justify-content-center"
      }
      style={style}
    >
      <Row
        className="d-flex justify-content-center"
        style={
          isMobile
            ? { marginBottom: 0, gap: 40 }
            : props.height
            ? {
                marginBottom: 0,
                width: props.width ? props.width : "75%",
                height: props.height,
              }
            : { marginBottom: 0, width: props.width ? props.width : "75%" }
        }
      >
        {props.icons.map((icon, index) => {
          return (
            <Col
              className="text-center py-4 p-2 align-items-center d-flex flex-column col-size"
              style={
                (isMobile && icon.icoWidth && icon.icoWidth < 150) ||
                !icon.icoWidth
                  ? { marginBottom: 10, height: 135 }
                  : (isMobile && icon.icoWidth && icon.icoWidth === 200) ||
                    !icon.icoWidth
                  ? { marginBottom: 150, height: 150 }
                  : isMobile
                  ? { marginBottom: 90, height: 100 }
                  : {
                      margin: "auto",
                      height: props.height ? "auto" : 200,
                    }
              }
              key={index + "Icons"}
              xs={props.isNotResponsive ? 12 : 12}
              sm={props.isNotResponsive ? 12 : 6}
              md={props.isNotResponsive ? 2 : 4}
              lg={props.isNotResponsive ? 2 : 3}
            >
              <img
                src={icon.icoUrl}
                alt={icon.icoAlt}
                style={{
                  width:
                    window.location.pathname ==
                      "/products/AirQuality/moniway" &&
                    window.screen.width < 1500
                      ? 130
                      : icon.icoWidth
                      ? icon.icoWidth
                      : 100,
                }}
              />
              {props.space && <div style={{ marginBottom: props.space }}></div>}
              <h3
                className="icon-title GothamMedium"
                style={{
                  fontSize: icon.titleSize
                    ? icon.titleSize
                    : theme.FONT_SIZE.large,
                  color: icon.titleColor ? icon.titleColor : theme.COLORS.blue,
                }}
              ></h3>
              <p
                style={{
                  fontSize: icon.textSize
                    ? icon.textSize
                    : theme.FONT_SIZE.large,
                  fontFamily: icon.textFontFamily
                    ? icon.textFontFamily
                    : theme.FONTS.GothamBook,
                  color: icon.textColor ? icon.textColor : theme.COLORS.blue,
                  minWidth: isMobile
                    ? 0
                    : props.icons.length == 3
                    ? 200
                    : props.icons.length == 5
                    ? 115
                    : 0,
                }}
                dangerouslySetInnerHTML={{
                  __html: icon.text && t(icon.keyComponent, icon.text),
                }}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
