import React, { Fragment } from "react";
import Close from "../../../assets/images/WeatherImages/Close.svg";
import Location from "../../../assets/images/WeatherImages/Location.svg";
import axios from "axios";
import Button from "../Button/Button";

import "./header.css";

const Header1 = (props) => {
  const [pressed, setPressed] = React.useState(false);
  const [addressStatus, setAddressStatus] = React.useState();
  const animated = pressed ? "pressed" : "unPressed";

  const baseURL = `http://78.186.56.6:9000/getAdress?ke=${props.la}&kb=${props.lo}`;

  const config = {
    headers: {
      Authorization:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6InRlc3RAZ21haWwuY29tIn0.Y-iOGZPutGUODJshuimE-Fi78O77YRdBbcrWOkqKwII",
    },
  };
  const [locations, setLocations] = React.useState();
  React.useEffect(() => {
    axios
      .get(baseURL, config)
      .then((response) => {
        setAddressStatus(response.data.status)(
          addressStatus == 400
            ? setLocations(null)
            : setLocations(response.data.district)
        );

        //   setSituation(response.data[1]);
      })
      .catch((err) => {});
  }, []);
  if (addressStatus !== 400) {
    if (!locations) return null;
  }

  if (!addressStatus) return null;

  return (
    <Fragment>
      {/* <div
        style={{
          backgroundColor: "#FFEF00",
          width: "100%",
          height: 60,
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "16,5%",
            height: 40,
            margin: 10,
            backgroundColor: "transparent",
          }}
        >
          <Button type="Button">
            <div
              onClick={props.ResponsivePage}
              style={{ width: 250, height: 40, backgroundColor: "#FFEF00" }}
            >
              <div style={{ width: "100%" }}></div>
            </div>
          </Button>
        </div>

        {/* <div style={{ width: "16,5%", height: 40, margin: 10 }}>
          <Button type="Button">
            <div onClick={props.click} className="header_component1">
              Hava Kalite Haritası
            </div>
          </Button>
        </div> */}
        {/* <div
          style={{
            width: "16,5%",
            height: 40,
            margin: 10,
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        > */}
          {/* <div className="location1">
            <img className="location_image1" src={Location} />
            {addressStatus !== 400 ? (
              <p className="location_text1">{locations} </p>
            ) : (
              <p className="location_text1"> geçersiz Adress</p>
            )}
          </div> */}

          {/* <Button type="Button">
            <div onClick={props.click} className="close_component1">
              <img className="close" src={Close} />
            </div>
          </Button>
        </div> */}
      {/* </div> */}
    </Fragment>
  );
};

export default Header1;
