import { useEffect } from "react";
import { Accordion, Container } from "react-bootstrap";
import theme from "../../../constants/theme";
import { TranslationService } from "../../../services/translation.service";
import { data } from "./data";
import "./style.css";
import { Props } from "./types";

export function ScreenComponent(props: Props) {
  const t = TranslationService.translate;
  const translationKey = "Frequently";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container
      style={{
        marginTop: 60,
        marginBottom: 100,
        minHeight: "90vh",
        maxWidth: "80vw",
      }}
    >
      <div className="d-flex align-items-center">
        <img
          className="mobile-img"
          src={theme.IMAGES.support.sss}
          alt={"sercair"}
        />
        <h2
          className="ms-4 gothamMedium mobile-font"
          style={{ color: theme.COLORS.blue }}
        >
          {t(translationKey, "mainTitle")}
        </h2>
      </div>

      <Accordion style={{ marginTop: 10 }} defaultActiveKey={["0"]} alwaysOpen>
        {data.map((item, index) => {
          return (
            <Accordion.Item eventKey={"quest" + index.toString()}>
              <Accordion.Header>
                <p
                  className="GothamBook after-button"
                  style={{ color: theme.COLORS.blue, fontSize: "12.7pt" }}
                >
                  {" "}
                  {t(translationKey, item.questionTitle)}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <Accordion defaultActiveKey={["0"]} alwaysOpen>
                  {item.questions &&
                    item.questions.map((qA, i) => {
                      return (
                        <Accordion.Item eventKey={"qA" + i.toString()}>
                          <Accordion.Header>
                            <p
                              className="GothamBook"
                              style={{
                                fontSize: "12pt",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: t(translationKey, qA.question),
                              }}
                            ></p>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div style={{ marginLeft: 15 }}>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: t(translationKey, qA.answer),
                                }}
                              ></p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Container>
  );
}
