const theme = {
  COLORS: {
    black: "#000000",
    blue: "#3c9feb",
    red: "#a94442",
    blue10: "#3c9feb10",
    blue40: "#3c9feb40",
    blue80: "#3c9feb80",
    productBlue: "#007aff99",
    productTeck: "#dff1ff",
    blueContainer: "#e3f0f4",
    blueSupport: "#dff1ff",
    white: "#fff",
    white80: "#fff80",
    gray: "#f2f5f7",
    grayBorder: "#dee2e6",
    cardContainerBottom: "#007aff99",
    purple: "#ba34d1",
    ecoOrange: "#f5871f30",
    cubeContainer: "#E0F1F050",
    ecoBlue: "#5CB4EA20",
    ecoPurple: "#8F459820",
    cubeMapBg: "#f9ebfb",
    cubeMapCityColor: "#bb34d0",
  },
  FONTS: {
    GothamBook: "GothamBook",
    GothamBold: "GothamBold",
    GothamMedium: "GothamMedium",
    GothamLight: "GothamLight",
  },
  FONT_SIZE: {
    xsmall: "0.8rem",
    small: "0.8rem",
    medium: "1rem",
    large: "1.2rem",
    xlarge: "1.5rem",
    xxlarge: "2rem",
  },
  STYLE: {},
  SPACES: {
    xsmall: "1rem",
    small: "0.25rem",
    medium: "0.5rem",
    large: "1rem",
    xlarge: "1.5rem",
    xxlarge: "2rem",
    xxxlarge: "3rem",
  },
  BORDER_RADIUS: {
    small: "5px",
    medium: "10px",
    large: "20px",
  },
  ICONS: {
    logoBlue: require("../assets/images/Navbar/logo_blue.svg").default,
    navbar: {
      logo: require("../assets/images/Navbar/logo.svg").default,
      accountIndex: require("../assets/images/Navbar/account_index.svg")
        .default,
      account: require("../assets/images/Navbar/account.svg").default,
      basketIndex: require("../assets/images/Navbar/basket_index.svg").default,
      basket: require("../assets/images/Navbar/basket.svg").default,
      langIndex: require("../assets/images/Navbar/lang_index.svg").default,
      lang: require("../assets/images/Navbar/lang.svg").default,
      closeIcon: require("../assets/images/Navbar/close-icon.svg").default,
      telIcon: require("../assets/images/Navbar/tel-icon.svg").default,
    },
    main: {
      appControl: require("../assets/images/Main/Icons/AppControl.svg").default,
      filtration: require("../assets/images/Main/Icons/Filtration.svg").default,
      preHeating: require("../assets/images/Main/Icons/PreHeating.svg").default,
    },
    product: {
      piatra: {
        ico1: require("../assets/images/Products/Airpurifiers/Piatra/4-1.svg")
          .default,
        ico2: require("../assets/images/Products/Airpurifiers/Piatra/4-2.svg")
          .default,
        ico3: require("../assets/images/Products/Airpurifiers/Piatra/4-3.svg")
          .default,
        ico4: require("../assets/images/Products/Airpurifiers/Piatra/4-4.svg")
          .default,
        ico5: require("../assets/images/Products/Airpurifiers/Piatra/4-5.svg")
          .default,
      },
      moniMe: {
        ico1: require("../assets/images/Products/AirQuality/MoniMe/svgs/1.svg")
          .default,
        ico2: require("../assets/images/Products/AirQuality/MoniMe/svgs/2.svg")
          .default,
        ico3: require("../assets/images/Products/AirQuality/MoniMe/svgs/3.svg")
          .default,
        ico4: require("../assets/images/Products/AirQuality/MoniMe/svgs/4.svg")
          .default,
        ico5: require("../assets/images/Products/AirQuality/MoniMe/svgs/5.svg")
          .default,
        ico6: require("../assets/images/Products/AirQuality/MoniMe/svgs/6.svg")
          .default,
      },
      spirit: {
        ico1: require("../assets/images/Products/Airpurifiers/Spirit/svgs/1.svg")
          .default,
        ico2: require("../assets/images/Products/Airpurifiers/Spirit/svgs/2.svg")
          .default,
        ico3: require("../assets/images/Products/Airpurifiers/Spirit/svgs/3.svg")
          .default,
      },
    },
    contact: {
      location: require("../assets/images/Contact/location.svg").default,
      mail: require("../assets/images/Contact/mail.svg").default,
      phone: require("../assets/images/Contact/phone.svg").default,
    },
  },
  ICON_SIZE: {
    size120: {
      height: 120,
      width: 120,
    },
    size90: {
      height: 90,
      width: 90,
    },
    size64: {
      height: 64,
      width: 64,
    },
    size48: {
      height: 48,
      width: 48,
    },
    size32: {
      height: 32,
      width: 32,
    },
    size24: {
      height: 24,
      width: 24,
    },
    size18: {
      height: 18,
      width: 18,
    },
  },
  IMAGES: {
    openingModal: {
      fuar_tr: require("../assets/images/Opening/fuarTR.jpg"),
      fuar_en: require("../assets/images/Opening/fuarEN.jpg"),
    },
    bgLinweHalf: require("../assets/images/lines.png"),
    bgLineFull: require("../assets/images/fullLines.png"),
    gifLine: require("../assets/images/gifLines.jpg"),
    waweLine: require("../assets/images/waweLine.jpg"),
    waweLine2: require("../assets/images/waweLine2.jpg"),
    waweLine3: require("../assets/images/waweLine3.jpg"),
    footer: {
      sss: require("../assets/images/Footer/sss.png"),
      phone: require("../assets/images/Footer/phone.png"),
      video: require("../assets/images/Footer/video.png"),
      applestore: require("../assets/images/Footer/applestore.png"),
      playstore: require("../assets/images/Footer/playstore.png"),
      justBreath: require("../assets/images/Footer/justbreathe.png"),
      mobileApp: require("../assets/images/Footer/mobileapp.png"),
    },
    slider: {
      slider1: require("../assets/images/Main/Slider/1-slider.jpg"),
      slider1Fr: require("../assets/images/Main/Slider/1-slider-fr.jpg"),
      slider1Rs: require("../assets/images/Main/Slider/1-slider-rs.jpg"),
      slider2: require("../assets/images/Main/Slider/2-slider.jpg"),
      // slider1Mobile: require("../assets/images/Main/Slider/1-sliderMobile.jpg"),
      slider2Mobile: require("../assets/images/Main/Slider/2-sliderMobile.jpg"),
      slider2MobileFR: require("../assets/images/Main/Slider/2-sliderMobile.jpg"),
      slider2MobileRS: require("../assets/images/Main/Slider/2-sliderMobile.jpg"),
      sliderOther: require("../assets/images/Main/Slider/1-sliderOther.jpg"),
      slider1MobileOther: require("../assets/images/Main/Slider/1-sliderMobileOther.jpg"),
    },
    navbar: {
      map_en: require("../assets/images/Navbar/map.svg").default,
      mapHover_en: require("../assets/images/Navbar/map_hover.svg").default,
      mapHover_tr: require("../assets/images/Navbar/map_hover.svg").default,
      map_tr: require("../assets/images/Navbar/map.svg").default,

      //another lang
      mapHover_de: require("../assets/images/Navbar/map_hover_tr.png"),
      map_de: require("../assets/images/Navbar/map_tr.png"),
      mapHover_fr: require("../assets/images/Navbar/map_hover_tr.png"),
      map_fr: require("../assets/images/Navbar/map_tr.png"),
      mapHover_gr: require("../assets/images/Navbar/map_hover_tr.png"),
      map_gr: require("../assets/images/Navbar/map_tr.png"),
      mapHover_ru: require("../assets/images/Navbar/map_hover_tr.png"),
      map_ru: require("../assets/images/Navbar/map_tr.png"),
      mapHover_sa: require("../assets/images/Navbar/map_hover_tr.png"),
      map_sa: require("../assets/images/Navbar/map_tr.png"),
    },
    main: {
      card1_1: require("../assets/images/Main/Card/1-1.png"),
      card1_2: require("../assets/images/Main/Card/1-2.png"),
      card1_3: require("../assets/images/Main/Card/1-3.png"),
      card2_1: require("../assets/images/Main/Card/2-1.jpg"),
      card2_2: require("../assets/images/Main/Card/2-2.jpg"),
      card2_3: require("../assets/images/Main/Card/2-3.jpg"),
      appPhone: require("../assets/images/Main/appPhone.jpg"),
      moniBus: require("../assets/images/Main/moniBus.jpg"),
      moniWay: require("../assets/images/Main/moniWay.jpg"),
      cleanair: require("../assets/images/Main/cleanAir.png"),
      cleanairMobile: require("../assets/images/Main/cleanairMobile.jpg"),
      Collapse: {
        airPurifiersAndRegulators:
          require("../assets/images/Main/Collapse/1.svg").default,
        airPurifiers: require("../assets/images/Main/Collapse/2.svg").default,
        airQuality: require("../assets/images/Main/Collapse/3.svg").default,
        accessory: require("../assets/images/Main/Collapse/4.svg").default,
        moniCube: require("../assets/images/Main/Collapse/5.svg").default,
      },
      SupportsCollapse: {
        assembly: require("../assets/images/Main/SupportsCollapse/assembly.svg")
          .default,
        request: require("../assets/images/Main/SupportsCollapse/request.svg")
          .default,
        return: require("../assets/images/Main/SupportsCollapse/return.svg")
          .default,
        sss: require("../assets/images/Main/SupportsCollapse/sss.svg").default,
        userGuide:
          require("../assets/images/Main/SupportsCollapse/userguide.svg")
            .default,
        warranty: require("../assets/images/Main/SupportsCollapse/warranty.svg")
          .default,
      },
    },
    campaigns: {
      campaign1: require("../assets/images/Campaigns/1.jpg"),
      campaign1Mobile: require("../assets/images/Campaigns/1_mobile.jpg"),
      campaign2: require("../assets/images/Campaigns/2.jpg"),
      campaign2Mobile: require("../assets/images/Campaigns/2_mobile.jpg"),
      campaign3: require("../assets/images/Campaigns/3.jpg"),
      campaign3Mobile: require("../assets/images/Campaigns/3_mobile.jpg"),
      campaign4: require("../assets/images/Campaigns/4.jpg"),
      campaign4Mobile: require("../assets/images/Campaigns/4_mobile.jpg"),
      campaign5: require("../assets/images/Campaigns/5.jpg"),
      campaign5Mobile: require("../assets/images/Campaigns/5_mobile.jpg"),
      leftSide: require("../assets/images/Campaigns/leftSide.jpg"),
    },
    modals: {
      languageModal: {
        background: require("../assets/images/Modals/LanguageModal/modalbg.png"),
      },
    },
    products: {
      lines: require("../assets/images/Products/lines.png"),
      app: {
        slider: require("../assets/images/Products/App/1.jpg"),
        carouselImg1: require("../assets/images/Products/App/2-1_en.png"),
        carouselImg2: require("../assets/images/Products/App/2-2_en.png"),
        carouselImg3: require("../assets/images/Products/App/2-3_en.png"),
        carouselImg4: require("../assets/images/Products/App/2-4_en.png"),
        carouselImg5: require("../assets/images/Products/App/2-5_en.png"),
        airQuality: require("../assets/images/Products/App/airQuality.jpg"),
        emergency: require("../assets/images/Products/App/emergency.png"),
        sliderMobile: require("../assets/images/Products/App/sliderMobile.jpg"),
        handPhone: require("../assets/images/Products/App/handPhone.jpg"),
        handPhoneWithMount: require("../assets/images/Products/App/handPhoneWithMount.jpg"),
        mount: require("../assets/images/Products/App/mount.jpg"),
      },
      technicalSpecifications: {
        item1: require("../assets/images/Products/TechnicalSpecifications/2-1.jpg"),
        item2: require("../assets/images/Products/TechnicalSpecifications/2-2.jpg"),
        item3: require("../assets/images/Products/TechnicalSpecifications/2-3.jpg"),
        item4: require("../assets/images/Products/TechnicalSpecifications/2-4.png"),
        moniMeUserGuild: require("../assets/images/Products/TechnicalSpecifications/moniMeUserGuild.png"),
        moniMe: require("../assets/images/Products/TechnicalSpecifications/moniMe.jpg"),
        item2White: require("../assets/images/Products/TechnicalSpecifications/2-2-white.jpg"),
        item3White: require("../assets/images/Products/TechnicalSpecifications/2-3-white.jpg"),
        moniMeP1: require("../assets/images/Products/TechnicalSpecifications/moniMe-p-1.png"),
        moniMeP2: require("../assets/images/Products/TechnicalSpecifications/moniMe-p-2.png"),
        moniMeP2png: require("../assets/images/Products/TechnicalSpecifications/moniMe-p-2.png"),

        piatra: require("../assets/images/Products/TechnicalSpecifications/piatra.jpg"),
        piatraP1: require("../assets/images/Products/TechnicalSpecifications/piatra-p-1.png"),
        piatraP2: require("../assets/images/Products/TechnicalSpecifications/piatra-p-2.png"),
        piatraP3: require("../assets/images/Products/TechnicalSpecifications/piatra-p-3.png"),
        spirit: require("../assets/images/Products/TechnicalSpecifications/spirit-p-1.png"),
        spiritP1: require("../assets/images/Products/TechnicalSpecifications/spirit-p-2.png"),
        spiritUserGuide: require("../assets/images/Products/TechnicalSpecifications/spiritUserGuild.png"),
        spiritSlider: require("../assets/images/Products/TechnicalSpecifications/spiritSlider.jpg"),
        piatraUserGuild: require("../assets/images/Products/TechnicalSpecifications/piatraUserGuild.png"),
      },
      overView: {
        sliderImg1: require("../assets/images/Products/Pure/Overview/1.jpg"),
        sliderImg2: require("../assets/images/Products/Pure/Overview/2.jpg"),
        sliderImg3: require("../assets/images/Products/Pure/Overview/3.jpg"),
        sliderIcon1: require("../assets/images/Products/Pure/Overview/slide-bottom-1.png"),
        sliderIcon2: require("../assets/images/Products/Pure/Overview/slide-bottom-2.png"),
        sliderIcon3: require("../assets/images/Products/Pure/Overview/slide-bottom-3.png"),
        sliderIcon4: require("../assets/images/Products/Pure/Overview/slide-bottom-4.png"),
        sliderIcon5: require("../assets/images/Products/Pure/Overview/slide-bottom-5.png"),
      },
      pure: {
        pureSeries: {
          slider1: require("../assets/images/Products/Pure/PureSeries/slider.jpg"),
          slider1Mobile: require("../assets/images/Products/Pure/PureSeries/sliderMobile.jpg"),
          overView: require("../assets/images/Products/Pure/PureSeries/imgPureOverView.jpg"),
          sensors: require("../assets/images/Products/Pure/PureSeries/sensors.jpg"),
          appGifTR: require("../assets/images/Products/Pure/PureSeries/appGifTR.gif"),
          appGifEN: require("../assets/images/Products/Pure/PureSeries/appGifEN.gif"),
          easyFilterChange: require("../assets/images/Products/Pure/PureSeries/easyFilterChange.jpg"),
          easyFilterChangeMobile: require("../assets/images/Products/Pure/PureSeries/easyFilterChangeMobile.jpg"),
          cleanAir: require("../assets/images/Products/Pure/PureSeries/cleanAir.jpg"),
          sensorPhone: require("../assets/images/Products/Pure/PureSeries/sensorPhone.jpg"),
          adminPanel: require("../assets/images/Products/Pure/PureCore/adminPanel.jpg"),
          clearFuture: require("../assets/images/Products/Pure/PureSeries/clearFuture.jpg"),
          workSpace: require("../assets/images/Products/Pure/PureSeries/workSpace.jpg"),
          clearFutureMobile: require("../assets/images/Products/Pure/PureSeries/clearFutureMobile.jpg"),
          workSpaceMobile: require("../assets/images/Products/Pure/PureSeries/workSpaceMobile.jpg"),
          pure: require("../assets/images/Products/Pure/PureSeries/pure.png"),
        },
        pureHealthy: {
          slider1: require("../assets/images/Products/Pure/PureHealthy/slider.jpg"),
          slider1Mobile: require("../assets/images/Products/Pure/PureHealthy/sliderMobile.jpg"),
          halfCleanAir: require("../assets/images/Products/Pure/PureHealthy/halfCleanAir.jpg"),
          mobilehalfCleanAir: require("../assets/images/Products/Pure/PureHealthy/mobilehalfCleanAir.jpg"),
          uvcTech: require("../assets/images/Products/Pure/PureHealthy/uvcTech.jpg"),
          appGifTR: require("../assets/images/Products/Pure/PureHealthy/appGifTR.gif"),
          appGifEN: require("../assets/images/Products/Pure/PureHealthy/appGifEN.gif"),
          practicalCare: require("../assets/images/Products/Pure/PureHealthy/practicalCare.jpg"),
          changeFilter: require("../assets/images/Products/Pure/PureHealthy/changeFilter.jpg"),
          practicalCareMobile: require("../assets/images/Products/Pure/PureHealthy/practicalCareMobile.jpg"),
          changeFilterMobile: require("../assets/images/Products/Pure/PureHealthy/changeFilterMobile.jpg"),
          adminPanel: require("../assets/images/Products/Pure/PureHealthy/adminPanel.jpg"),
          familyKitchen: require("../assets/images/Products/Pure/PureHealthy/familyKitchen.jpg"),
          healthyOtel: require("../assets/images/Products/Pure/PureHealthy/healthyOtel.jpg"),
          familyKitchenMobile: require("../assets/images/Products/Pure/PureHealthy/familyKitchenMobile.jpg"),
          healthyOtelMobile: require("../assets/images/Products/Pure/PureHealthy/healthyOtelMobile.jpg"),
          product: require("../assets/images/Products/Pure/PureHealthy/product.jpg"),
        },
        pureCore: {
          slider1: require("../assets/images/Products/Pure/PureCore/slider.jpg"),
          slider1Mobile: require("../assets/images/Products/Pure/PureCore/sliderMobile.jpg"),
          topSlider: require("../assets/images/Products/Pure/PureCore/topSlider-1.png"),
          topSlider2: require("../assets/images/Products/Pure/PureCore/topSlider-2.png"),
          adminPanel: require("../assets/images/Products/Pure/PureCore/adminPanel.jpg"),
          restaurant: require("../assets/images/Products/Pure/PureCore/restaurant.jpg"),
          restaurantMobile: require("../assets/images/Products/Pure/PureCore/restaurantMobile.jpg"),
          restaurant2: require("../assets/images/Products/Pure/PureCore/restaurant2.jpg"),
          gifCoreTR: require("../assets/images/Products/Pure/PureCore/gifCoreTR.gif"),
          gifCoreEN: require("../assets/images/Products/Pure/PureCore/gifCoreEN.gif"),
          forkSpoon: require("../assets/images/Products/Pure/PureCore/forkSpoon.jpg"),

          techImg: require("../assets/images/Products/Pure/PureCore/techImg.jpg"),
          ico1: require("../assets/images/Products/Pure/PureCore/ico1.jpg"),
          ico2: require("../assets/images/Products/Pure/PureCore/ico2.jpg"),
          ico3: require("../assets/images/Products/Pure/PureCore/ico3.jpg"),
          ico4: require("../assets/images/Products/Pure/PureCore/ico4.jpg"),
          ico5: require("../assets/images/Products/Pure/PureCore/ico5.jpg"),
        },
      },
      airPurifiers: {
        piatra: {
          home: require("../assets/images/Products/Airpurifiers/Piatra/sliderIco/1.png"),
          hospital: require("../assets/images/Products/Airpurifiers/Piatra/sliderIco/2.png"),
          office: require("../assets/images/Products/Airpurifiers/Piatra/sliderIco/3.png"),
          school: require("../assets/images/Products/Airpurifiers/Piatra/sliderIco/4.png"),
          hostel: require("../assets/images/Products/Airpurifiers/Piatra/sliderIco/5.png"),
          mainImg: require("../assets/images/Products/Airpurifiers/Piatra/piatraMainIMG.PNG"),
          babyRoom: require("../assets/images/Products/Airpurifiers/Piatra/babyRoom.jpg"),
          babyRoomMobile: require("../assets/images/Products/Airpurifiers/Piatra/babyRoomMobile.jpg"),
          cleanAir: require("../assets/images/Products/Airpurifiers/Piatra/cleanAir.jpg"),
          cleanAirtr: require("../assets/images/Products/Airpurifiers/Piatra/cleanAirtr.jpg"),
          cleanAirtrMobile: require("../assets/images/Products/Airpurifiers/Piatra/cleanAirtrMobile.jpg"),
          cleanAirenMobile: require("../assets/images/Products/Airpurifiers/Piatra/cleanAirenMobile.jpg"),
          cleanAiren: require("../assets/images/Products/Airpurifiers/Piatra/cleanAiren.jpg"),
          insideRoom: require("../assets/images/Products/Airpurifiers/Piatra/insideRoom.jpg"),
          insideRoom1: require("../assets/images/Products/Airpurifiers/Piatra/insideRoom1.png"),
          insideRoom2: require("../assets/images/Products/Airpurifiers/Piatra/insideRoom2.png"),
          product1: require("../assets/images/Products/Airpurifiers/Piatra/p-1.png"),
          product2: require("../assets/images/Products/Airpurifiers/Piatra/p-2.png"),
          slider1: require("../assets/images/Products/Airpurifiers/Piatra/slider-1.jpg"),
          slider2: require("../assets/images/Products/Airpurifiers/Piatra/slider-2.jpg"),
          slider3: require("../assets/images/Products/Airpurifiers/Piatra/slider-3.jpg"),
          slider4: require("../assets/images/Products/Airpurifiers/Piatra/slider-4.jpg"),
          touch: require("../assets/images/Products/Airpurifiers/Piatra/touch.jpg"),
          touchMobile: require("../assets/images/Products/Airpurifiers/Piatra/touchMobile.jpg"),
        },
        spirit: {
          mainSlider: require("../assets/images/Products/Airpurifiers/Spirit/sliderMain.jpg"),
          mainSliderMobile: require("../assets/images/Products/Airpurifiers/Spirit/mainSliderMobile.jpg"),
          imgPlay: require("../assets/images/Products/Airpurifiers/Spirit/playIco.jpg"),
          imgPlayMobile: require("../assets/images/Products/Airpurifiers/Spirit/playIcoMobile.jpg"),
          spirit: require("../assets/images/Products/Airpurifiers/Spirit/spirit.jpg"),
          spiritMobile: require("../assets/images/Products/Airpurifiers/Spirit/spiritMobile.jpg"),
          insideSpirit: require("../assets/images/Products/Airpurifiers/Spirit/insideSpirit.jpg"),
          insideSpiritMobile: require("../assets/images/Products/Airpurifiers/Spirit/insideSpiritMobile.jpg"),
          spiritCar: require("../assets/images/Products/Airpurifiers/Spirit/spiritCar.jpg"),
          spiritCarMobile: require("../assets/images/Products/Airpurifiers/Spirit/spiritCarMobile.jpg"),
          finger: require("../assets/images/Products/Airpurifiers/Spirit/finger.jpg"),
          fingerMobile: require("../assets/images/Products/Airpurifiers/Spirit/fingerMobile.jpg"),
          productsImg: require("../assets/images/Products/Airpurifiers/Spirit/productsImg.png"),
          art1: require("../assets/images/Products/Airpurifiers/Spirit/artboard-1.svg")
            .default,
          art2: require("../assets/images/Products/Airpurifiers/Spirit/artboard-2.svg")
            .default,
          art3: require("../assets/images/Products/Airpurifiers/Spirit/artboard-3.svg")
            .default,
          art4: require("../assets/images/Products/Airpurifiers/Spirit/artboard-4.svg")
            .default,
          art5: require("../assets/images/Products/Airpurifiers/Spirit/artboard-5.svg")
            .default,
        },
      },
      airQuality: {
        moniWay: {
          mainSlider: require("../assets/images/Products/AirQuality/MoniWay/mainSlider.jpg"),
          mainSliderMobile: require("../assets/images/Products/AirQuality/MoniWay/mainSliderMobile.jpg"),
          mainCardItem1: require("../assets/images/Products/AirQuality/MoniWay/card1Main.jpg"),
          mainCardItem2: require("../assets/images/Products/AirQuality/MoniWay/card2Main.jpg"),
          mainCardItem3: require("../assets/images/Products/AirQuality/MoniWay/card3Main.jpg"),
          moniBad: require("../assets/images/Products/AirQuality/MoniWay/moniBad.png"),
          moniLights: require("../assets/images/Products/AirQuality/MoniWay/moniLights.jpg"),
          moniEmergency: require("../assets/images/Products/AirQuality/MoniWay/moniEmergency.png"),
          moniExcellent: require("../assets/images/Products/AirQuality/MoniWay/moniExcellent.png"),
          moniGood: require("../assets/images/Products/AirQuality/MoniWay/moniGood.png"),
          moniNormal: require("../assets/images/Products/AirQuality/MoniWay/moniNormal.png"),
          moniTooBad: require("../assets/images/Products/AirQuality/MoniWay/moniTooBad.png"),
          moniWayBus: require("../assets/images/Products/AirQuality/MoniWay/moniWayBus.jpg"),
          moniWayBusMobile: require("../assets/images/Products/AirQuality/MoniWay/moniWayBusMobile.jpg"),
          moniParticle: require("../assets/images/Products/AirQuality/MoniWay/moniParticle.jpg"),
          moniParticleMobileTR: require("../assets/images/Products/AirQuality/MoniWay/moniParticleMobileTR.jpg"),
          moniParticleMobileEN: require("../assets/images/Products/AirQuality/MoniWay/moniParticleMobileEN.jpg"),
          moniAfterParticle: require("../assets/images/Products/AirQuality/MoniWay/moniAfterParticle.jpg"),
          filter: require("../assets/images/Products/AirQuality/MoniWay/filter.jpg"),
          icoBus: require("../assets/images/Products/AirQuality/MoniWay/bus.png"),
          icoTrain: require("../assets/images/Products/AirQuality/MoniWay/train.png"),
          icoShip: require("../assets/images/Products/AirQuality/MoniWay/ship.png"),
          icoService: require("../assets/images/Products/AirQuality/MoniWay/service.png"),
          icoCaravan: require("../assets/images/Products/AirQuality/MoniWay/caravan.png"),
          sliderImgtr: require("../assets/images/Products/AirQuality/MoniWay/sliderImgtr.jpg"),
          sliderImgen: require("../assets/images/Products/AirQuality/MoniWay/sliderImgen.jpg"),
          moniSlider1: require("../assets/images/Products/AirQuality/MoniWay/moniSlider1.png"),
          moniSlider2: require("../assets/images/Products/AirQuality/MoniWay/moniSlider2.png"),
          moniSlider3: require("../assets/images/Products/AirQuality/MoniWay/moniSlider3.png"),
          moniSlider4: require("../assets/images/Products/AirQuality/MoniWay/moniSlider4.jpg"),
          moniSlider5: require("../assets/images/Products/AirQuality/MoniWay/moniSlider5.jpg"),
          poisonousGas1: require("../assets/images/Products/AirQuality/MoniWay/poisonousGas1.jpg"),
          poisonousGas2_1: require("../assets/images/Products/AirQuality/MoniWay/poisonousGas2-1.jpg"),
          poisonousGas2_2: require("../assets/images/Products/AirQuality/MoniWay/poisonousGas2-2.jpg"),
          poisonousGas3: require("../assets/images/Products/AirQuality/MoniWay/poisonousGas3.jpg"),
          adminPanel: require("../assets/images/Products/AirQuality/MoniWay/adminPanel.png"),
          mapScreen: require("../assets/images/Products/AirQuality/MoniWay/mapScreen.jpg"),
        },
        moniMe: {
          slider: require("../assets/images/Products/AirQuality/MoniMe/slider.jpg"),
          sliderMobile: require("../assets/images/Products/AirQuality/MoniMe/sliderMobile.jpg"),
          led: require("../assets/images/Products/AirQuality/MoniMe/led.jpg"),
          appTextBg: require("../assets/images/Products/AirQuality/MoniMe/appTextBg.jpg"),
          appRight: require("../assets/images/Products/AirQuality/MoniMe/appRight.jpg"),
          alarmClock: require("../assets/images/Products/AirQuality/MoniMe/alarmClock.jpg"),
          alarmClockMobile: require("../assets/images/Products/AirQuality/MoniMe/alarmClockMobile.jpg"),
          emergency: require("../assets/images/Products/AirQuality/MoniMe/emergency.jpg"),
          emergencyMobile: require("../assets/images/Products/AirQuality/MoniMe/emergencyMobile.jpg"),
          battery: require("../assets/images/Products/AirQuality/MoniMe/battery.png"),
          babyRight: require("../assets/images/Products/AirQuality/MoniMe/babyRight.jpg"),
          babyLeft: require("../assets/images/Products/AirQuality/MoniMe/babyLeft.jpg"),
          fingerLeft: require("../assets/images/Products/AirQuality/MoniMe/fingerLeft.jpg"),
          fingerRight: require("../assets/images/Products/AirQuality/MoniMe/fingerRight.jpg"),
          fingerLeftMobile: require("../assets/images/Products/AirQuality/MoniMe/fingerLeftMobile.jpg"),
          fingerRightMobile: require("../assets/images/Products/AirQuality/MoniMe/fingerRightMobile.jpg"),
          fingerFull: require("../assets/images/Products/AirQuality/MoniMe/fingerFull.jpg"),
          leftSideColorEN: require("../assets/images/Products/AirQuality/MoniMe/moni-me-en.jpg"),
          leftSideColorTR: require("../assets/images/Products/AirQuality/MoniMe/moni-me-tr.jpg"),
          leftSideColorNL: require("../assets/images/Products/AirQuality/MoniMe/moni-me-nl.jpg"),
          leftSideColorRS: require("../assets/images/Products/AirQuality/MoniMe/moni-me-rs.jpg"),
          leftSideColorFR: require("../assets/images/Products/AirQuality/MoniMe/moni-me-fr.jpg"),
          color1: require("../assets/images/Products/AirQuality/MoniMe/colors/1.png"),
          color2: require("../assets/images/Products/AirQuality/MoniMe/colors/2.png"),
          color3: require("../assets/images/Products/AirQuality/MoniMe/colors/3.png"),
          color4: require("../assets/images/Products/AirQuality/MoniMe/colors/4.png"),
          color5: require("../assets/images/Products/AirQuality/MoniMe/colors/5.png"),
          color6: require("../assets/images/Products/AirQuality/MoniMe/colors/6.png"),
          blueCon1: require("../assets/images/Products/AirQuality/MoniMe/blueContainerIco/1.png"),
          blueCon2: require("../assets/images/Products/AirQuality/MoniMe/blueContainerIco/2.png"),
          blueCon3: require("../assets/images/Products/AirQuality/MoniMe/blueContainerIco/3.png"),
          blueCon4: require("../assets/images/Products/AirQuality/MoniMe/blueContainerIco/4.png"),
          blueCon5: require("../assets/images/Products/AirQuality/MoniMe/blueContainerIco/5.png"),
          blueCon6: require("../assets/images/Products/AirQuality/MoniMe/blueContainerIco/6.png"),
          blueCon7: require("../assets/images/Products/AirQuality/MoniMe/blueContainerIco/7.png"),
          blueCon8: require("../assets/images/Products/AirQuality/MoniMe/blueContainerIco/8.png"),
          blueCon9: require("../assets/images/Products/AirQuality/MoniMe/blueContainerIco/9.png"),
          moniMe: require("../assets/images/Products/AirQuality/MoniMe/moniMe.png"),
          moniMeIconsTR: require("../assets/images/Products/AirQuality/MoniMe/moniMeIconsTR.jpg"),
          moniMeIconsEN: require("../assets/images/Products/AirQuality/MoniMe/moniMeIconsEN.jpg"),
          MoniMemeasurementTR: require("../assets/images/Products/AirQuality/MoniMe/MoniMemeasurementTR.jpg"),
          MoniMemeasurementEN: require("../assets/images/Products/AirQuality/MoniMe/MoniMemeasurementEN.jpg"),
        },
      },
      cube: {
        slider: require("../assets/images/Products/Cube/slider.jpg"),
        sliderMobile: require("../assets/images/Products/Cube/sliderMobile.jpg"),
        whiteCube: require("../assets/images/Products/Cube/whiteCube.png"),
        orangeIcons: require("../assets/images/Products/Cube/orangeIcons.svg")
          .default,
        orangeIconsBg:
          require("../assets/images/Products/Cube/orangeIconsBg.svg").default,
        healthy1: require("../assets/images/Products/Cube/healthy1.png"),
        healthy2: require("../assets/images/Products/Cube/healthy2.png"),
        healthy3: require("../assets/images/Products/Cube/healthy3.png"),

        praticUsage1: require("../assets/images/Products/Cube/praticUsage1.jpg"),
        praticUsage2: require("../assets/images/Products/Cube/praticUsage2.jpg"),
        praticUsage3: require("../assets/images/Products/Cube/praticUsage3.jpg"),
        laptop: require("../assets/images/Products/Cube/laptop.jpg"),
        con1: require("../assets/images/Products/Cube/con1.svg").default,
        con2: require("../assets/images/Products/Cube/con2.svg").default,
        con3: require("../assets/images/Products/Cube/con3.svg").default,
        airFlow: require("../assets/images/Products/Cube/airFlow.png"),
        highAirQuality: require("../assets/images/Products/Cube/highAirQuality.png"),
        sterilization: require("../assets/images/Products/Cube/sterilization.jpg"),
        rezervation: require("../assets/images/Products/Cube/rezervation.png"),
        lastImageLeft: require("../assets/images/Products/Cube/lastImageLeft.jpg"),
        lastImageRight: require("../assets/images/Products/Cube/lastImageRight.jpg"),

        tech1: require("../assets/images/Products/Cube/tech_01.png"),
        tech2: require("../assets/images/Products/Cube/tech_02.png"),
        tech3: require("../assets/images/Products/Cube/tech_03.png"),
        arrow1: require("../assets/images/Products/Cube/arrows/1.svg").default,
        arrow2: require("../assets/images/Products/Cube/arrows/2.svg").default,
        arrow3: require("../assets/images/Products/Cube/arrows/3.svg").default,
        arrow4: require("../assets/images/Products/Cube/arrows/4.svg").default,
        arrow5: require("../assets/images/Products/Cube/arrows/5.svg").default,
        arrow6: require("../assets/images/Products/Cube/arrows/6.svg").default,
        arrow7: require("../assets/images/Products/Cube/arrows/7.svg").default,
        arrow8: require("../assets/images/Products/Cube/arrows/8.svg").default,
        arrow9: require("../assets/images/Products/Cube/arrows/9.svg").default,
        arrow10: require("../assets/images/Products/Cube/arrows/10.svg")
          .default,
        arrow11: require("../assets/images/Products/Cube/arrows/11.svg")
          .default,
        arrow12: require("../assets/images/Products/Cube/arrows/12.svg")
          .default,
        arrow13: require("../assets/images/Products/Cube/arrows/13.svg")
          .default,
        arrow14: require("../assets/images/Products/Cube/arrows/14.svg")
          .default,
        arrow15: require("../assets/images/Products/Cube/arrows/15.svg")
          .default,
        arrow16: require("../assets/images/Products/Cube/arrows/16.svg")
          .default,
        arrow1_i: require("../assets/images/Products/Cube/arrows/1_i.svg")
          .default,
        arrow2_i: require("../assets/images/Products/Cube/arrows/2_i.svg")
          .default,
        arrow3_i: require("../assets/images/Products/Cube/arrows/3_i.svg")
          .default,
        arrow4_i: require("../assets/images/Products/Cube/arrows/4_i.svg")
          .default,
        arrow5_i: require("../assets/images/Products/Cube/arrows/5_i.svg")
          .default,
        arrow6_i: require("../assets/images/Products/Cube/arrows/6_i.svg")
          .default,

        app1: require("../assets/images/Products/Cube/1_app.png"),
        app2: require("../assets/images/Products/Cube/2_app.png"),
        app3: require("../assets/images/Products/Cube/3_app.png"),

        backArrow:
          require("../assets/images/Products/Cube/location/backArrow.svg")
            .default,
        fullScreen:
          require("../assets/images/Products/Cube/location/fullScreen.svg")
            .default,
        mapIcon: require("../assets/images/Products/Cube/location/mapIcon.svg")
          .default,
        mapPin: require("../assets/images/Products/Cube/location/mapPin.png"),
      },
    },
    support: {
      returnsImg: require("../assets/images/Support/return.svg").default,
      requestImg: require("../assets/images/Support/request.svg").default,
      warranty: require("../assets/images/Support/warranty.svg").default,
      sss: require("../assets/images/Support/sss.svg").default,
      assemblybg: require("../assets/images/Support/assemblybg.png"),
      userGuide: require("../assets/images/Support/userguide.svg").default,
      downloadIcon: require("../assets/images/Support/downloadIcon.svg")
        .default,
      salespoints: require("../assets/images/Support/salespoints.svg").default,
    },
    salesPoints: {
      titleImg: require("../assets/images/SalesPoints/title.png"),
      containerImg1: require("../assets/images/SalesPoints/ebebek.png"),
      containerImg2: require("../assets/images/SalesPoints/koctas.png"),
      containerImg3: require("../assets/images/SalesPoints/joker.png"),
      containerImg4: require("../assets/images/SalesPoints/ofix.png"),
      containerImg5: require("../assets/images/SalesPoints/shoppingIo.png"),
    },
    contact: {
      slider: require("../assets/images/Contact/contact.jpg"),
      sliderMobile: require("../assets/images/Contact/contactMobile.jpg"),
    },
    videos: {
      tr: {
        crystal: require("../assets/images/Videos/crystalTR.jpg"),
        moniWay: require("../assets/images/Videos/moniWayTR.jpg"),
        moniMe: require("../assets/images/Videos/moniMeTR.jpg"),
      },
      en: {
        //willBeAddedAfter
        crystal: require("../assets/images/Videos/crystalTR.jpg"),
        moniWay: require("../assets/images/Videos/moniWayTR.jpg"),
        moniMe: require("../assets/images/Videos/moniMeTR.jpg"),
      },
      piatra: require("../assets/images/Videos/piatra.jpg"),
      pureHealty: require("../assets/images/Videos/pureHealty.jpg"),
      spirit: require("../assets/images/Videos/spirit.jpg"),
      handy: require("../assets/images/Videos/handy.jpg"),
      handyM: require("../assets/images/Videos/handyM.jpg"),
      crystalEco: require("../assets/images/Videos/crystalEco.jpg"),
      wave: require("../assets/images/Videos/wave.jpg"),
      vision: require("../assets/images/Videos/vision.jpg"),
      aura: require("../assets/images/Videos/aura.jpg"),
      breathe: require("../assets/images/Videos/breathe.jpg"),
      app: require("../assets/images/Videos/app.jpg"),
      title: require("../assets/images/Videos/title.svg").default,
    },
    catalogs: {
      title: require("../assets/images/Catalog/title.svg").default,
      tr: {
        catalog1: require("../assets/images/Catalog//tr/sercairTR.jpg"),
        catalog2: require("../assets/images/Catalog//tr/pureCoreTR.jpg"),
        catalog3: require("../assets/images/Catalog//tr/moniWayTR.jpg"),
        catalog4: require("../assets/images/Catalog//tr/cubeTR.jpg"),
        catalog5: require("../assets/images/Catalog//tr/generalCatalogTR.jpg"),
        catalog6: require("../assets/images/Catalog//tr/cafeTR.jpg"),
        pdf1: require("../assets/images/Catalog//tr/sercairTR.pdf"),
        pdf2: require("../assets/images/Catalog//tr/pureCoreTR.pdf"),
        pdf3: require("../assets/images/Catalog//tr/moniWayTR.pdf"),
        pdf4: require("../assets/images/Catalog//tr/cubeTR.pdf"),
        pdf5: require("../assets/images/Catalog//tr/generalCatalogTR.pdf"),
        pdf6: require("../assets/images/Catalog//tr/cafeTR.pdf"),
      },
      en: {
        catalog1: require("../assets/images/Catalog/en/sercairEN.jpg"),
        catalog2: require("../assets/images/Catalog/en/moniWayEN.jpg"),
        catalog3: require("../assets/images/Catalog/en/generalCatalogEN.jpg"),
        catalog4: require("../assets/images/Catalog/en/cafeEN.jpg"),
        catalog5: require("../assets/images/Catalog/en/technicalCatalogEN.jpg"),
        pdf1: require("../assets/images/Catalog/en/sercairEN.pdf"),
        pdf2: require("../assets/images/Catalog/en/moniWayEN.pdf"),
        pdf3: require("../assets/images/Catalog/en/generalCatalogEN.pdf"),
        pdf4: require("../assets/images/Catalog/en/cafeEN.pdf"),
        pdf5: require("../assets/images/Catalog/en/technicalCatalogEN.pdf"),
      },
    },
  },
  PDFS: {
    UserGuides: {
      pure: require("../assets/PDFs/UserGuides/pureSeriesALL.pdf"),
      pureHealthy: require("../assets/PDFs/UserGuides/pureSeriesALL.pdf"),
      corePure: require("../assets/PDFs/UserGuides/pureSeriesALL.pdf"),
      piatra: require("../assets/PDFs/UserGuides/piatra.pdf"),
      spirit: require("../assets/PDFs/UserGuides/spirit.pdf"),
      moniMe: require("../assets/PDFs/UserGuides/moniMe.pdf"),
      moniWay: require("../assets/PDFs/UserGuides/moniWay.pdf"),
    },
    CubeCatalog: require("../assets/PDFs/Cube/cubeCatalog.pdf"),
  },
};

export type positions = "left" | "right" | "center";
export default theme;
