import theme from "../../../../constants/theme";
export const Style = {
    socialIcons: {
        color: theme.COLORS.blue,
        transition: "all 0.2s ease-in-out",
    },
    storeIcons: {
        height: 32,
        width: 96
    },
    item: {
        marginLeft: 10
    }
};
