import { CardModel } from "../models/card.model";
import { CollapseModel } from "../models/collapse.model";
import { cubeTech } from "../models/cube.model";
import { IconModel } from "../models/icon.model";
import { SliderModel } from "../models/slider.model";
import {
  SliderProductsRightModel,
  SliderProductsTopModel,
} from "../models/sliderProducts.model";
import { tableModel } from "../models/table.model";
import { TextContainerModel } from "../models/textContainer.model";
import theme from "./theme";

const langCheck = localStorage.getItem("lang");

function langHandler(url: string) {
  return langCheck === "en"
    ? url
    : langCheck === "tr"
    ? `tr${url}`
    : langCheck === "nl"
    ? `nl${url}`
    : langCheck === "rs"
    ? `rs${url}`
    : langCheck === "uk"
    ? `uk${url}`
    : langCheck === "ro"
    ? `ro${url}`
    : `fr${url}`;
}

export const CardsContent: CardModel[] = [
  {
    imgUrl: theme.IMAGES.main.card1_1,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "card1Title",
    text: "card1Text",
    isBtn: false,
    isShadow: true,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.gray,
    textColor: "black",
    keyComponent: "MainScreen",
  },
  {
    imgUrl: theme.IMAGES.main.card1_2,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "card2Title",
    text: "card2Text",
    isBtn: false,
    isShadow: true,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.gray,
    textColor: "black",
    keyComponent: "MainScreen",
  },
  {
    imgUrl: theme.IMAGES.main.card1_3,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "card3Title",
    text: "card3Text",
    isBtn: false,
    btnText: "Read More",
    btnUrl:
      langCheck === "tr"
        ? "/tr/iletisim"
        : langCheck === "en"
        ? "/contact"
        : langCheck === "nl"
        ? "/nl/contact"
        : langCheck === "rs"
        ? "/rs/kontakt"
        : langCheck === "uk"
        ? "/uk/contact"
        : langCheck === "ro"
        ? "/ro/contactati"
        : "/fr/contact",
    isShadow: true,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.gray,
    textColor: "black",
    keyComponent: "MainScreen",
  },
];

export const IconContent: IconModel[] = [
  {
    icoUrl: theme.ICONS.main.appControl,
    title: "icon1Text",
    icoAlt: "Sercair",
    keyComponent: "MainScreen",
  },
  {
    icoUrl: theme.ICONS.main.filtration,
    title: "icon2Text",
    icoAlt: "Sercair",
    keyComponent: "MainScreen",
  },
  {
    icoUrl: theme.ICONS.main.preHeating,
    title: "icon3Text",
    icoAlt: "Sercair",
    keyComponent: "MainScreen",
  },
];

export const BlueTitleCardComponent: CardModel[] = [
  {
    imgUrl: theme.IMAGES.main.card2_1,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "blueTitleCard1Title",
    text: "blueTitleCard1Text",
    isBtn: true,
    btnText: "blueTitleButtonText",
    btnUrl:
      langCheck === "tr"
        ? "/tr/iletisim"
        : langCheck === "en"
        ? "/contact"
        : langCheck === "nl"
        ? "/nl/contact"
        : langCheck === "rs"
        ? "/rs/kontakt"
        : langCheck === "uk"
        ? "/uk/contact"
        : langCheck === "ro"
        ? "/ro/contactati"
        : "/fr/contact",
    isShadow: true,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.white,
    textColor: "black",
    titleColor: theme.COLORS.blue,
    keyComponent: "MainScreen",
    titleClass: "GothamMedium",
    titleSize: 30,
  },
  {
    imgUrl: theme.IMAGES.main.card2_2,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "blueTitleCard2Title",
    text: "blueTitleCard2Text",
    isBtn: true,
    btnText: "blueTitleButtonText",
    btnUrl:
      langCheck === "tr"
        ? "/tr/iletisim"
        : langCheck === "en"
        ? "/contact"
        : langCheck === "nl"
        ? "/nl/contact"
        : langCheck === "rs"
        ? "/rs/kontakt"
        : langCheck === "uk"
        ? "/uk/contact"
        : langCheck === "ro"
        ? "/ro/contactati"
        : "/fr/contact",
    isShadow: true,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.white,
    textColor: "black",
    titleColor: theme.COLORS.blue,
    keyComponent: "MainScreen",
    titleClass: "GothamMedium",
    titleSize: 30,
  },
  {
    imgUrl: theme.IMAGES.main.card2_3,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "blueTitleCard3Title",
    text: "blueTitleCard3Text",
    isBtn: true,
    btnText: "blueTitleButtonText",
    btnUrl:
      langCheck === "tr"
        ? "/tr/iletisim"
        : langCheck === "en"
        ? "/contact"
        : langCheck === "nl"
        ? "/nl/contact"
        : langCheck === "rs"
        ? "/rs/kontakt"
        : langCheck === "uk"
        ? "/uk/contact"
        : langCheck === "ro"
        ? "/ro/contactati"
        : "/fr/contact",
    isShadow: true,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.white,
    textColor: "black",
    titleColor: theme.COLORS.blue,
    keyComponent: "MainScreen",
    titleClass: "GothamMedium",
    titleSize: 30,
  },
];
export const SliderProductsApp: SliderModel[] = [
  {
    imgUrl: theme.IMAGES.products.app.slider,
    imgForMobile: theme.IMAGES.products.app.sliderMobile,
    imgAlt: "imgAltSlider",
    imgOnClick: false,
    containerPosition: "left" as "left",
    color: "white",
    title: "sliderTitle",
    text: "",
    webText: "sliderSubTitleWeb",
    isBtn: false,
    textPosition: "left" as "left",
    isSecondaryContainer: true,
    secondaryContainerPosition: "left" as "left",
    secondaryContainerText: "sliderText",
    secondaryContainerTitle: "",
    secondaryIsBtn: false,
    secondaryTextPosition: "left",
    keyComponent: "ProductsApp",
  },
];

export const CarouselProductsApp: SliderProductsTopModel[] = [
  {
    text: "text1",
    imgUrl: theme.IMAGES.products.app.carouselImg1,
    imgAlt: "imgAltCarousel1",
    witdh: 364,
    keyComponent: "ProductsApp",
  },
  {
    text: "text2",
    imgUrl: theme.IMAGES.products.app.carouselImg2,
    imgAlt: "imgAltCarousel1",
    witdh: 364,
    keyComponent: "ProductsApp",
  },
  {
    title: "title3",
    text: "text3",
    imgUrl: theme.IMAGES.products.app.carouselImg3,
    imgAlt: "imgAltCarousel1",
    witdh: 364,
    keyComponent: "ProductsApp",
  },
  {
    title: "title4",
    text: "text4",
    imgUrl: theme.IMAGES.products.app.carouselImg4,
    imgAlt: "imgAltCarousel1",
    witdh: 364,
    keyComponent: "ProductsApp",
  },
  {
    title: "title5",
    text: "text5",
    imgUrl: theme.IMAGES.products.app.carouselImg5,
    imgAlt: "imgAltCarousel1",
    witdh: 364,
    keyComponent: "ProductsApp",
  },
];
export const SliderPureSeries: SliderModel[] = [
  {
    imgUrl: theme.IMAGES.products.pure.pureSeries.slider1,
    imgForMobile: theme.IMAGES.products.pure.pureSeries.slider1Mobile,
    imgAlt: "sliderAlt",
    imgOnClick: false,
    containerPosition: "left" as "left",
    color: "white",
    imgOnClickUrl: "",
    title: "sliderTitle",
    text: "sliderSubTitle",
    webText: "sliderSubTitleWeb",
    isBtn: false,
    textPosition: "left" as "left",
    btnText: "sliderButtonText",
    btnUrl: "",
    btnStyle: "primary",
    isSecondaryContainer: true,
    secondaryContainerPosition: "left" as "left",
    secondaryContainerText: "",
    secondaryContainerTitle: "",
    secondaryIsBtn: true,
    secondaryTextPosition: "left",
    secondaryContainerBtnText: "sliderButtonText",
    secondaryContainerBtnUrl: "https://www.sercair.com/",
    secondaryContainerColor: "white",
    keyComponent: "PureSeries",
    videoId: "videoId",
  },
];
export const SliderPureSeriesMobile: SliderModel[] = [
  {
    imgUrl: theme.IMAGES.products.pure.pureSeries.slider1,
    imgForMobile: theme.IMAGES.products.pure.pureSeries.slider1Mobile,
    imgAlt: "sliderAlt",
    imgOnClick: false,
    containerPosition: "left" as "left",
    color: "white",
    imgOnClickUrl: "",
    title: "sliderTitle",
    text: "sliderSubTitle",
    webText: "sliderSubTitleWeb",
    isBtn: false,
    textPosition: "left" as "left",
    btnText: "sliderButtonText",
    btnUrl: "",
    btnStyle: "primary",
    isSecondaryContainer: true,
    secondaryContainerPosition: "left" as "left",
    secondaryContainerText: "",
    secondaryContainerTitle: "",
    secondaryIsBtn: true,
    secondaryTextPosition: "left",
    secondaryContainerBtnText: "sliderButtonText",
    secondaryContainerBtnUrl: "https://www.sercair.com/",
    secondaryContainerColor: "white",
    keyComponent: "PureSeries",
    videoId: "videoId",
  },
];
export const SliderPureSeriesOverview: SliderProductsRightModel[] = [
  {
    imgUrl: theme.IMAGES.products.overView.sliderImg1,
    imgAlt: "sliderAlt",
    title: "sliderTitle1",
    text: "sliderText1",
    keyComponent: "OverViewPureSlider",
    icons: [
      {
        icoUrl: theme.IMAGES.products.overView.sliderIcon1,
        icoAlt: "sliderAlt",
        text: "sliderIcoText1",
        icoWidth: 64,
        textSize: 13,
        textColor: "white",
        keyComponent: "OverViewPureSlider",
      },
      {
        icoUrl: theme.IMAGES.products.overView.sliderIcon2,
        icoAlt: "sliderAlt",
        text: "sliderIcoText2",
        icoWidth: 64,
        textSize: 13,
        textColor: "white",
        keyComponent: "OverViewPureSlider",
      },
      {
        icoUrl: theme.IMAGES.products.overView.sliderIcon3,
        icoAlt: "sliderAlt",
        text: "sliderIcoText3",
        icoWidth: 64,
        textSize: 13,
        textColor: "white",
        keyComponent: "OverViewPureSlider",
      },
    ],
  },
  {
    imgUrl: theme.IMAGES.products.overView.sliderImg2,
    imgAlt: "sliderAlt",
    title: "sliderTitle2",
    text: "sliderText2",
    keyComponent: "OverViewPureSlider",
    icons: [
      {
        icoUrl: theme.IMAGES.products.overView.sliderIcon1,
        icoAlt: "sliderAlt",
        text: "sliderIcoText1",
        keyComponent: "OverViewPureSlider",
        icoWidth: 64,
        textSize: 13,
        textColor: "white",
      },
      {
        icoUrl: theme.IMAGES.products.overView.sliderIcon2,
        icoAlt: "sliderAlt",
        text: "sliderIcoText2",
        keyComponent: "OverViewPureSlider",
        icoWidth: 64,
        textSize: 13,
        textColor: "white",
      },
      {
        icoUrl: theme.IMAGES.products.overView.sliderIcon3,
        icoAlt: "sliderAlt",
        text: "sliderIcoText3",
        keyComponent: "OverViewPureSlider",
        icoWidth: 64,
        textSize: 13,
        textColor: "white",
      },
      {
        icoUrl: theme.IMAGES.products.overView.sliderIcon4,
        icoAlt: "sliderAlt",
        text: "sliderIcoText4",
        keyComponent: "OverViewPureSlider",
        icoWidth: 64,
        textSize: 13,
        textColor: "white",
      },
      {
        icoUrl: theme.IMAGES.products.overView.sliderIcon5,
        icoAlt: "sliderAlt",
        text: "sliderIcoText5",
        keyComponent: "OverViewPureSlider",
        icoWidth: 64,
        textSize: 13,
        textColor: "white",
      },
    ],
  },
];
export const MobileSliderPureSeriesOverview: SliderProductsRightModel[] = [
 
  {
    imgUrl: theme.IMAGES.products.overView.sliderImg2,
    imgAlt: "sliderAlt",
    title: "sliderTitle2",
    text: "sliderText2",
    keyComponent: "OverViewPureSlider",
    icons: [],
  },
];

export const TextContainerPureSeries: TextContainerModel[] = [
  {
    title: "textContaier1Title",
    text: "textContainer1Text",
    keyComponent: "PureSeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
  {
    title: "textContainer2Title",
    text: "textContainer2Text",
    keyComponent: "PureSeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
  {
    title: "textContainer3Title",
    text: "textContainer3Text",
    keyComponent: "PureSeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
  {
    title: "textContainer4Title",
    text: "textContainer4Text",
    keyComponent: "PureSeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
  {
    title: "textContainer5Title",
    text: "textContainer5Text",
    keyComponent: "PureSeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
  {
    title: "textContainer6Title",
    text: "textContainer6Text",
    keyComponent: "PureSeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
];
export const MobileTextContainerPureSeries: TextContainerModel[] = [
  {
    title: "textContaier1Title",
    text: "textContainer1Text",
    keyComponent: "PureSeries",
    textColor: "black",
    titleSize: "14.3pt",
    textSize: "8.28pt",
  },
  {
    title: "textContainer2Title",
    text: "textContainer2Text",
    keyComponent: "PureSeries",
    textColor: "black",
    titleSize: "14.3pt",
    textSize: "8.28pt",
  },
];
export const UnderSecondHeaderMobileTextContainerPureSeries: TextContainerModel[] =
  [
    {
      title: "textContainer5Title",
      text: "textContainer5Text",
      keyComponent: "PureSeries",
      textColor: "black",
      titleSize: "13pt",
      textSize: "10pt",
    },
  ];

export const PureSeriesTechnicalData: tableModel = {
  bodyHeader: ["Pure 39", "Pure 59", "Pure79"],
  body: [
    {
      bodyTitle: "technicalSpecs5",
      bodyItems: ["technicalSpecs6", "technicalSpecs6", "technicalSpecs6"],
    },
    {
      bodyTitle: "technicalSpecs7",
      bodyItems: [
        "25.27”-41.02” (642-1042 mm)",
        "41.02”-60.71” (1042-1542 mm)",
        "60.71”-80.39” (1542-2042 mm)",
      ],
    },
    {
      bodyTitle: "technicalSpecs8",
      bodyItems: ["6.41” (163 mm)", "6.41” (163 mm)", "6.41” (163 mm)"],
    },
    {
      bodyTitle: "technicalSpecs9",
      bodyItems: ["2,23 lb/4.90 kg", "2,90 lb/6.37 kg", "3,3 lb/7.30 kg"],
    },
    {
      bodyTitle: "technicalSpecs10",
      bodyItems: ["110 / 220 V", "110 / 220 V", "110 / 220 V"],
    },
    {
      bodyTitle: "technicalSpecs22",
      bodyItems: ["370 Watt", "380 Watt", "700 Watt"],
    },
    {
      bodyTitle: "technicalSpecs23",
      bodyItems: ["1000 Watt", "2000 Watt", "2000 Watt"],
    },
    {
      bodyTitle: "technicalSpecs12",
      bodyItems: ["30-55 dBA", "30-56 dBA", "30-59 dBA"],
    },
    {
      bodyTitle: "technicalSpecs21",
      bodyItems: ["technicalSpecs13", "technicalSpecs13", "technicalSpecs13"],
    },
    {
      bodyTitle: "technicalSpecs14",
      bodyItems: ["320 ft ²/30 m² ", "535 ft ²/50 m²", "750 ft ²/70 m²"],
    },
    {
      bodyTitle: "technicalSpecs15",
      bodyItems: [
        "121 CFM / 205 m³/h",
        "227 CFM / 385 m³/h",
        "227 CFM / 385 m³/h",
      ],
    },
    {
      bodyTitle: "technicalSpecs16",
      bodyItems: ["38 CFM / 65 m³/h", "38 CFM / 65 m³/h", "121 CFM / 205 m³/h"],
    },
    {
      bodyTitle: "technicalSpecs17",
      bodyItems: [
        "Wi-Fi<br/>Gsm<br/>Bluetooth",
        "Wi-Fi<br/>Gsm<br/>Bluetooth",
        "Wi-Fi<br/>Gsm<br/>Bluetooth",
      ],
    },
    {
      bodyTitle: "technicalSpecs18",
      bodyItems: ["technicalSpecs19", "technicalSpecs19", "technicalSpecs19"],
    },
  ],
  titleLeft: "technicalSpecs20",
};

export const PureBoxContent: CardModel[] = [
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.item1,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "Pure 39/59/79",
    isBtn: false,
    isShadow: false,
    titleSize: 23,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureSeries",
  },
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.item2,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "filter",
    isBtn: false,
    isShadow: false,
    titleSize: 23,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureSeries",
  },
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.item3,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "userGuide",
    isBtn: false,
    titleSize: 23,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureSeries",
  },
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.item4,
    imgAlt: "Sercair",
    imgPosition: "center" as "center",
    title: "powerCable",
    isBtn: false,
    titleSize: 23,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureSeries",
  },
];
export const HealthyBoxContent: CardModel[] = [
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.item1,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "Pure Healthy  29/39/59/79",
    isBtn: false,
    isShadow: false,
    titleSize: 23,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureHealthySeries",
  },
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.item2,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "filter",
    isBtn: false,
    isShadow: false,
    titleSize: 23,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureHealthySeries",
  },
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.item3,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "userGuide",
    isBtn: false,
    titleSize: 23,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureHealthySeries",
  },
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.item4,
    imgAlt: "Sercair",
    imgPosition: "center" as "center",
    title: "powerCable",
    isBtn: false,
    titleSize: 23,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureHealthySeries",
  },
];
export const SliderPureHealthySeries: SliderModel[] = [
  {
    imgUrl: theme.IMAGES.products.pure.pureHealthy.slider1,
    imgForMobile: theme.IMAGES.products.pure.pureHealthy.slider1Mobile,
    imgAlt: "sliderAlt",
    imgOnClick: false,
    containerPosition: "left" as "left",
    color: "white",
    imgOnClickUrl: "https://www.sercair.com/",
    title: "sliderTitle",
    text: "",
    webText: "sliderSubTitleWeb",
    isBtn: false,
    textPosition: "left" as "left",
    btnText: "sliderButtonText",
    btnUrl: "https://www.sercair.com/",
    btnStyle: "primary",
    isSecondaryContainer: true,
    secondaryContainerPosition: "left" as "left",
    secondaryContainerText: "sliderSubTitle",
    secondaryContainerTitle: "",
    secondaryIsBtn: true,
    secondaryTextPosition: "left",
    secondaryContainerBtnText: "Tanıtım Videosu İzle",
    secondaryContainerBtnUrl: "https://www.sercair.com/",
    secondaryContainerColor: "white",
    keyComponent: "PureHealthySeries",
    videoId: "videoId",
  },
];

export const TextContainerPureHealthySeries: TextContainerModel[] = [
  {
    title: "textContaier1Title",
    text: "textContainer1Text",
    keyComponent: "PureHealthySeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
  {
    title: "textContainer2Title",
    text: "textContainer2Text",
    keyComponent: "PureHealthySeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
  {
    title: "textContainer3Title",
    text: "textContainer3Text",
    keyComponent: "PureHealthySeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
  {
    title: "textContainer4Title",
    text: "textContainer4Text",
    keyComponent: "PureHealthySeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
  {
    title: "textContainer5Title",
    text: "textContainer5Text",
    keyComponent: "PureHealthySeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
  {
    title: "textContainer6Title",
    text: "textContainer6Text",
    keyComponent: "PureHealthySeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
  {
    title: "textContainer7Title",
    text: "textContainer7Text",
    keyComponent: "PureHealthySeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
  {
    title: "textContainer8Title",
    text: "textContainer8Text",
    keyComponent: "PureHealthySeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
];
export const MobileTextContainerPureHealthySeries: TextContainerModel[] = [
  {
    title: "textContaier1Title",
    text: "textContainer1Text",
    keyComponent: "PureHealthySeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
  {
    title: "textContainer2Title",
    text: "textContainer2Text",
    keyComponent: "PureHealthySeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "10pt",
  },
];
export const PureHealthySeriesTechnicalData: tableModel = {
  bodyHeader: ["Pure Healthy 39", "Pure Healthy 59", "Pure Healthy 79"],
  body: [
    {
      bodyTitle: "technicalSpecs3",
      bodyItems: ["technicalSpecs4", "technicalSpecs4", "technicalSpecs4"],
    },

    {
      bodyTitle: "technicalSpecs7",
      bodyItems: [
        "25.27”-41.02” (642-1042 mm)",
        "41.02”-60.71” (1042-1542 mm)",
        "60.71”-80.39” (1542-2042 mm)",
      ],
    },
    {
      bodyTitle: "technicalSpecs8",
      bodyItems: ["6.41” - 163 mm", "6.41” - 163 mm", "6.41” - 163 mm"],
    },
    {
      bodyTitle: "technicalSpecs9",
      bodyItems: [
        "2,23 lb lb - 4.90 kg",
        "2,90 lb lb - 6.37 kg",
        "3,3 lb - 7.30 kg",
      ],
    },
    {
      bodyTitle: "technicalSpecs10",
      bodyItems: ["110 / 220 V", "110 / 220 V", "110 / 220 V"],
    },

    {
      bodyTitle: "technicalSpecs22",
      bodyItems: ["370 Watt", "380 Watt", "700 Watt"],
    },
    {
      bodyTitle: "technicalSpecs23",
      bodyItems: ["1000 Watt", "2000 Watt", "2000 Watt"],
    },
    {
      bodyTitle: "technicalSpecs12",
      bodyItems: ["30-55 dBA", "30-56 dBA", "30-59 dBA"],
    },
    {
      bodyTitle: "technicalSpecs21",
      bodyItems: ["technicalSpecs13", "technicalSpecs13", "technicalSpecs13"],
    },
    {
      bodyTitle: "technicalSpecs14",
      bodyItems: ["320 ft ² - 30 m² ", "535 ft ² - 50 m²", "750 ft ² - 70 m²"],
    },
    {
      bodyTitle: "technicalSpecs15",
      bodyItems: [
        "121 CFM - 205 m³/h",
        "227 CFM - 385 m³/h",
        "227 CFM - 385 m³/h",
      ],
    },
    {
      bodyTitle: "technicalSpecs16",
      bodyItems: ["38 CFM - 65 m³/h", "38 CFM - 65 m³/h", "121 CFM - 205 m³/h"],
    },
    {
      bodyTitle: "technicalSpecs17",
      bodyItems: [
        "Wi-Fi<br/>Gsm<br/>Bluetooth",
        "Wi-Fi<br/>Gsm<br/>Bluetooth",
        "Wi-Fi<br/>Gsm<br/>Bluetooth",
      ],
    },
    {
      bodyTitle: "technicalSpecs18",
      bodyItems: ["technicalSpecs19", "technicalSpecs19", "technicalSpecs19"],
    },
  ],
  titleLeft: "technicalSpecs20",
};

export const SliderPureCoreSeries: SliderModel[] = [
  {
    imgUrl: theme.IMAGES.products.pure.pureCore.slider1,
    imgForMobile: theme.IMAGES.products.pure.pureCore.slider1Mobile,
    imgAlt: "sliderAlt",
    imgOnClick: false,
    containerPosition: "left" as "left",
    color: "white",
    imgOnClickUrl: "https://www.sercair.com/",
    title: "sliderTitle",
    text: "sliderSubTitle",
    webText: "sliderSubTitleWeb",
    isBtn: false,
    textPosition: "left" as "left",
    btnText: "btnVideo",
    btnUrl: "https://www.sercair.com/",
    btnStyle: "primary",
    isSecondaryContainer: true,
    secondaryContainerPosition: "left" as "left",
    secondaryContainerTitle: "",
    secondaryIsBtn: true,
    secondaryTextPosition: "left",
    secondaryContainerBtnText: "Tanıtım Videosu İzle",
    secondaryContainerBtnUrl: "https://www.sercair.com/",
    secondaryContainerColor: "white",
    keyComponent: "PureCoreSeries",
    videoId: "videoId",
  },
];

export const SliderCubeSeries: SliderModel[] = [
  {
    imgUrl: theme.IMAGES.products.cube.slider,
    imgForMobile: theme.IMAGES.products.cube.sliderMobile,
    isPDFDownload: true,
    pdfTitle: "pdfTitle",
    imgAlt: "sliderAlt",
    imgOnClick: false,
    containerPosition: "left" as "left",
    color: "white",
    title: "sliderTitle",
    text: "sliderSubTitle",
    webText: "sliderSubTitleWeb",
    isBtn: false,
    textPosition: "left" as "left",
    btnText: "sliderButton",
    btnUrl: theme.PDFS.CubeCatalog,
    btnStyle: "primary",
    isSecondaryContainer: true,
    secondaryContainerPosition: "left" as "left",
    secondaryContainerText: "sliderText",
    secondaryContainerTitle: "",
    secondaryIsBtn: true,
    secondaryTextPosition: "left",
    secondaryContainerBtnText: "Tanıtım Videosu İzle",
    secondaryContainerBtnUrl: "https://www.sercair.com/",
    secondaryContainerColor: "white",
    keyComponent: "Cube",
  },
];

export const SliderCubeSeriesMobileEN: SliderModel[] = [
  {
    imgUrl: theme.IMAGES.products.cube.slider,
    imgForMobile: theme.IMAGES.products.cube.sliderMobile,
    isPDFDownload: false,
    pdfTitle: "pdfTitle",
    imgAlt: "sliderAlt",
    imgOnClick: false,
    containerPosition: "left" as "left",
    color: "white",
    title: "sliderTitle",
    text: "sliderSubTitle",
    webText: "sliderSubTitleWeb",
    isBtn: false,
    textPosition: "left" as "left",
    btnText: "sliderButton",
    btnUrl: theme.PDFS.CubeCatalog,
    btnStyle: "primary",
    isSecondaryContainer: true,
    secondaryContainerPosition: "left" as "left",
    secondaryContainerText: "",
    secondaryContainerTitle: "",
    secondaryIsBtn: false,
    secondaryTextPosition: "left",
    secondaryContainerBtnText: "Tanıtım Videosu İzle",
    secondaryContainerBtnUrl: "https://www.sercair.com/",
    secondaryContainerColor: "white",
    keyComponent: "Cube",
  },
];

export const SliderCubeSeriesMobileTR: SliderModel[] = [
  {
    imgUrl: theme.IMAGES.products.cube.slider,
    imgForMobile: theme.IMAGES.products.cube.sliderMobile,
    isPDFDownload: true,
    pdfTitle: "pdfTitle",
    imgAlt: "sliderAlt",
    imgOnClick: false,
    containerPosition: "left" as "left",
    color: "white",
    title: "sliderTitle",
    text: "sliderSubTitle",
    webText: "sliderSubTitleWeb",
    isBtn: false,
    textPosition: "left" as "left",
    btnText: "sliderButton",
    sliderText: "sliderText",
    btnUrl: theme.PDFS.CubeCatalog,
    btnStyle: "primary",
    isSecondaryContainer: true,
    secondaryContainerPosition: "left" as "left",
    secondaryContainerText: "",
    secondaryContainerTitle: "",
    secondaryIsBtn: true,
    secondaryTextPosition: "left",
    secondaryContainerBtnText: "Tanıtım Videosu İzle",
    secondaryContainerBtnUrl: "https://www.sercair.com/",
    secondaryContainerColor: "white",
    keyComponent: "Cube",
  },
];

export const SliderMoniWaySeries: SliderModel[] = [
  {
    imgUrl: theme.IMAGES.products.airQuality.moniWay.mainSlider,
    imgForMobile: theme.IMAGES.products.airQuality.moniWay.mainSliderMobile,
    imgAlt: "sliderAlt",
    imgOnClick: false,
    containerPosition: "left" as "left",
    color: "white",
    imgOnClickUrl: "https://www.sercair.com/",
    title: "sliderTitle",
    text: "sliderSubTitle",
    webText: "sliderSubTitleWeb",
    isBtn: false,
    textPosition: "left" as "left",
    btnText: "sliderButtonText",
    btnUrl: "https://www.sercair.com/",
    btnStyle: "primary",
    isSecondaryContainer: true,
    secondaryContainerPosition: "left" as "left",
    secondaryContainerText: "sliderText",
    secondaryContainerTitle: "",
    secondaryIsBtn: false,
    secondaryTextPosition: "left",
    secondaryContainerBtnText: "Tanıtım Videosu İzle",
    secondaryContainerBtnUrl: "https://www.sercair.com/",
    secondaryContainerColor: "white",
    keyComponent: "moniWay",
    videoId: "videoId",
  },
];

export const SliderPureCoreSeriesTop: SliderProductsTopModel[] = [
  {
    imgUrl: theme.IMAGES.products.pure.pureCore.topSlider,
    imgForMobile: theme.IMAGES.products.pure.pureSeries.slider1Mobile,
    imgAlt: "Sercair",
  },
  {
    imgUrl: theme.IMAGES.products.pure.pureCore.topSlider2,
    imgForMobile: theme.IMAGES.products.pure.pureSeries.slider1Mobile,
    imgAlt: "Sercair",
  },
];

export const MoniWayContent: CardModel[] = [
  {
    imgUrl: theme.IMAGES.products.airQuality.moniWay.mainCardItem1,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "card1Text",
    isBtn: false,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.gray,
    titleColor: theme.COLORS.black,
    keyComponent: "moniWay",
  },
  {
    imgUrl: theme.IMAGES.products.airQuality.moniWay.mainCardItem2,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "card2Text",
    isBtn: false,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.gray,
    titleColor: theme.COLORS.black,
    keyComponent: "moniWay",
  },
  {
    imgUrl: theme.IMAGES.products.airQuality.moniWay.mainCardItem3,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "card3Text",
    isBtn: false,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.gray,
    titleColor: theme.COLORS.black,
    keyComponent: "moniWay",
  },
];

export const IconContentMoniWay: IconModel[] = [
  {
    icoUrl: theme.IMAGES.products.airQuality.moniWay.moniLights,
    title: "colorMean1",
    icoAlt: "Sercair",
    keyComponent: "moniWay",
    titleColor: "#D66252",
    titleSize: 14,
    icoWidth: 1300,
    height: 215,
  },
];

export const IconContentMoniWayMobile1: IconModel[] = [
  {
    icoUrl: theme.IMAGES.products.airQuality.moniWay.moniEmergency,
    title: "colorMean1",
    icoAlt: "Sercair",
    keyComponent: "moniWay",
    titleColor: "#D66252",
    titleSize: 9,
    icoWidth: 200,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniWay.moniTooBad,
    title: "colorMean2",
    icoAlt: "Sercair",
    keyComponent: "moniWay",
    titleColor: "#FFC95D",
    titleSize: 9,
    icoWidth: 200,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniWay.moniBad,
    title: "colorMean3",
    icoAlt: "Sercair",
    keyComponent: "moniWay",
    titleColor: "#E9E582",
    titleSize: 9,
    icoWidth: 200,
  },
];

export const IconContentMoniWayMobile2: IconModel[] = [
  {
    icoUrl: theme.IMAGES.products.airQuality.moniWay.moniNormal,
    title: "colorMean4",
    icoAlt: "Sercair",
    keyComponent: "moniWay",
    titleColor: "#C8E982",
    titleSize: 9,
    icoWidth: 200,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniWay.moniGood,
    title: "colorMean5",
    icoAlt: "Sercair",
    keyComponent: "moniWay",
    titleColor: "#91E7FF",
    titleSize: 9,
    icoWidth: 200,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniWay.moniExcellent,
    title: "colorMean6",
    icoAlt: "Sercair",
    keyComponent: "moniWay",
    titleColor: "#48E6FF",
    titleSize: 9,
    icoWidth: 200,
  },
];

export const piatraSeriesTechnicalData: tableModel = {
  bodyHeader: [""],
  body: [
    {
      bodyTitle: "tColorT",
      bodyItems: ["tColor"],
    },
    {
      bodyTitle: "tWeightT",
      bodyItems: ["~3.7 lb / 8,2 kg"],
    },
    {
      bodyTitle: "tWeightB",
      bodyItems: ["~4.18 lb / 9,18 kg"],
    },
    {
      bodyTitle: "tVoltage",
      bodyItems: ["AC220V"],
    },
    {
      bodyTitle: "tPower",
      bodyItems: ["120 W"],
    },
    {
      bodyTitle: "tFrequency",
      bodyItems: ["30-50 dBA"],
    },
    {
      bodyTitle: "tSpace",
      bodyItems: ["Max 861 ft ² (80 m²)"],
    },
    {
      bodyTitle: "tFilterLife",
      bodyItems: ["ttFilterLifeT"],
    },
    {
      bodyTitle: "tUseSpace",
      bodyItems: ["tUseSpaceT"],
    },
  ],
};
export const piatraBoxContent: CardModel[] = [
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.piatraP3,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "Piatra",
    isBtn: false,
    titleSize: 23,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureSeries",
  },
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.piatraUserGuild,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "userGuide",
    isBtn: false,
    titleSize: 23,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureSeries",
  },
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.item4,
    imgAlt: "Sercair",
    imgPosition: "center" as "center",
    title: "powerCable",
    isBtn: false,
    titleSize: 23,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureSeries",
  },
];

export const MoniMeSeriesTechnicalData: tableModel = {
  bodyHeader: [""],
  body: [
    {
      bodyTitle: "tColorT",
      bodyItems: ["tColor"],
    },
    {
      bodyTitle: "tWeightT",
      bodyItems: ["0.11 lb / 250 g"],
    },

    {
      bodyTitle: "tBatteryType",
      bodyItems: ["tBatteryTypeT"],
    },
    {
      bodyTitle: "tPower",
      bodyItems: ["5V 1A"],
    },

    {
      bodyTitle: "tSpace",
      bodyItems: ["Max 323 ft ² (30 m²)"],
    },
    {
      bodyTitle: "tConnectionType",
      bodyItems: ["Wifi"],
    },

    {
      bodyTitle: "Supporting Device",
      bodyItems: ["tSupportingDeviceT"],
    },
  ],
};
export const MoniMeBoxContent: CardModel[] = [
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.moniMeP1,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "Moni me 3",
    isBtn: false,
    isShadow: false,
    titleSize: 23,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureSeries",
  },
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.moniMeUserGuild,
    imgAlt: "Sercair",
    imgPosition: "center" as "center",
    title: "userGuide",
    isBtn: false,
    titleSize: 23,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureSeries",
  },
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.item4,
    imgAlt: "Sercair",
    imgPosition: "center" as "center",
    title: "powerCable",
    isBtn: false,
    isShadow: false,
    titleSize: 23,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureSeries",
  },
];

export const spiritContentTechnicalData: tableModel = {
  bodyHeader: [""],
  body: [
    {
      bodyTitle: "tColorT",
      bodyItems: ["tColor"],
    },
    {
      bodyTitle: "tWeightT",
      bodyItems: ["~0.36 lb / 800 g"],
    },
    {
      bodyTitle: "tWeightB",
      bodyItems: ["~0.41 lb / 900 g"],
    },
    {
      bodyTitle: "tVoltage",
      bodyItems: ["DC 5V"],
    },
    {
      bodyTitle: "tPower",
      bodyItems: ["Max 3W"],
    },
    {
      bodyTitle: "tFrequency",
      bodyItems: ["Max 50 dBA"],
    },
    {
      bodyTitle: "tAirSpace",
      bodyItems: ["15 CFM / 25 m³/h"],
    },
    {
      bodyTitle: "tSpace",
      bodyItems: ["Max 108 ft ² (10 m²)"],
    },
    {
      bodyTitle: "tFilterLife",
      bodyItems: ["ttFilterLifeT"],
    },
    {
      bodyTitle: "tUseSpace",
      bodyItems: ["tUseSpaceT"],
    },
  ],
};
export const spiritContent: CardModel[] = [
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.spiritP1,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "Spirit",
    titleClass: "mx-4",
    isBtn: false,
    titleSize: 23,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.blueSupport,
    titleColor: theme.COLORS.blue,
    keyComponent: "SpiritSeries",
  },
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.spiritUserGuide,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "userGuide",
    isBtn: false,
    titleSize: 23,
    titleClass: "mx-4",
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.blueSupport,
    titleColor: theme.COLORS.blue,
    keyComponent: "SpiritSeries",
  },
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.item4,
    imgAlt: "Sercair",
    imgPosition: "center" as "center",
    title: "powerCord",
    titleSize: 23,
    titleClass: "mx-4",
    isBtn: false,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.blueSupport,
    titleColor: theme.COLORS.blue,
    keyComponent: "SpiritSeries",
  },
];

export const IconContent2MoniWay: IconModel[] = [
  {
    icoUrl: theme.IMAGES.products.airQuality.moniWay.icoBus,
    text: "icoContainer2Text1",
    icoAlt: "Sercair",
    textColor: theme.COLORS.black,
    keyComponent: "moniWay",
    textSize: 14,
    icoWidth: 150,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniWay.icoTrain,
    text: "icoContainer2Text2",
    icoAlt: "Sercair",
    textColor: theme.COLORS.black,
    keyComponent: "moniWay",
    textSize: 14,
    icoWidth: 150,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniWay.icoShip,
    text: "icoContainer2Text3",
    icoAlt: "Sercair",
    textColor: theme.COLORS.black,
    keyComponent: "moniWay",
    textSize: 14,
    icoWidth: 150,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniWay.icoService,
    text: "icoContainer2Text4",
    icoAlt: "Sercair",
    textColor: theme.COLORS.black,
    keyComponent: "moniWay",
    textSize: 14,
    icoWidth: 150,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniWay.icoCaravan,
    text: "icoContainer2Text5",
    icoAlt: "Sercair",
    textColor: theme.COLORS.black,
    keyComponent: "moniWay",
    textSize: 14,
    icoWidth: 150,
  },
];
export const CarouselProductsAppMoniWay: SliderProductsTopModel[] = [
  {
    imgUrl: theme.IMAGES.products.airQuality.moniWay.moniSlider1,
    imgAlt: "sliderAlt",
    witdh: 364,
  },
  {
    imgUrl: theme.IMAGES.products.airQuality.moniWay.moniSlider2,
    imgAlt: "sliderAlt",
    witdh: 364,
  },
  {
    imgUrl: theme.IMAGES.products.airQuality.moniWay.moniSlider3,
    imgAlt: "sliderAlt",
    witdh: 364,
  },
  {
    imgUrl: theme.IMAGES.products.airQuality.moniWay.moniSlider4,
    imgAlt: "sliderAlt",
    witdh: 364,
  },
  {
    imgUrl: theme.IMAGES.products.airQuality.moniWay.moniSlider5,
    imgAlt: "sliderAlt",
    witdh: 364,
  },
];
export const SliderMoniMe: SliderModel[] = [
  {
    imgUrl: theme.IMAGES.products.airQuality.moniMe.slider,
    imgForMobile: theme.IMAGES.products.airQuality.moniMe.sliderMobile,
    imgAlt: "sliderAlt",
    imgOnClick: false,
    containerPosition: "left" as "left",
    color: "white",
    imgOnClickUrl: "https://www.sercair.com/",
    title: "sliderTitle",
    text: "sliderSubTitle",
    webText: "sliderSubTitleWeb",
    isBtn: false,
    textPosition: "left" as "left",
    btnText: "sliderButtonText",
    btnStyle: "primary",
    isSecondaryContainer: true,
    secondaryContainerPosition: "left" as "left",
    secondaryContainerTitle: "",
    secondaryIsBtn: true,
    secondaryTextPosition: "left",
    secondaryContainerBtnText: "sliderAlt",
    secondaryContainerColor: "white",
    keyComponent: "moniMe",
    videoId: "videoId",
  },
];
export const SliderMoniMeEmergency: SliderModel[] = [
  {
    imgUrl: theme.IMAGES.products.airQuality.moniMe.emergency,
    imgForMobile: theme.IMAGES.products.airQuality.moniMe.emergencyMobile,
    imgAlt: "sliderAlt",
    imgOnClick: false,
    containerPosition: "right" as "right",
    color: "black",
    imgOnClickUrl: "https://www.sercair.com/",
    title: "emergencyTitle",
    text: "emergencyText",
    webText: "sliderSubTitleWeb",
    isBtn: false,
    textPosition: "right" as "right",
    isSecondaryContainer: false,
    keyComponent: "moniMe",
  },
];
export const IconContentMoniMe: IconModel[] = [
  {
    icoUrl: theme.ICONS.product.moniMe.ico1,
    text: "ico1",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "black",
    textFontFamily: "Gothamlight",
    textSize: 17,
    icoWidth: 125,
  },
  {
    icoUrl: theme.ICONS.product.moniMe.ico2,
    text: "ico2",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "black",
    textSize: 17,
    textFontFamily: "Gothamlight",
    icoWidth: 125,
  },
  {
    icoUrl: theme.ICONS.product.moniMe.ico3,
    text: "ico3",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "black",
    textSize: 17,
    textFontFamily: "Gothamlight",
    icoWidth: 125,
  },
  {
    icoUrl: theme.ICONS.product.moniMe.ico4,
    text: "ico4",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "black",
    textSize: 17,
    textFontFamily: "Gothamlight",
    icoWidth: 125,
  },
  {
    icoUrl: theme.ICONS.product.moniMe.ico5,
    text: "ico5",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "black",
    textFontFamily: "Gothamlight",
    textSize: 17,
    icoWidth: 125,
  },
  {
    icoUrl: theme.ICONS.product.moniMe.ico6,
    text: "ico6",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "black",
    textSize: 17,
    icoWidth: 125,
    textFontFamily: "Gothamlight",
  },
];
export const IconContentMoniMeColors: IconModel[] = [
  {
    icoUrl: theme.IMAGES.products.airQuality.moniMe.color1,
    text: "colorIco1",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "black",
    textSize: 20,
    icoWidth: 150,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniMe.color2,
    text: "colorIco2",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "black",
    textSize: 20,
    icoWidth: 150,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniMe.color3,
    text: "colorIco3",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "black",
    textSize: 20,
    icoWidth: 150,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniMe.color4,
    text: "colorIco4",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "black",
    textSize: 20,
    icoWidth: 150,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniMe.color5,
    text: "colorIco5",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "black",
    textSize: 20,
    icoWidth: 150,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniMe.color6,
    text: "colorIco6",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "black",
    textSize: 20,
    icoWidth: 150,
  },
];
export const IconContentMoniContainers1: IconModel[] = [
  {
    icoUrl: theme.IMAGES.products.airQuality.moniMe.blueCon1,
    text: "blueContainerText1",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "white",
    textSize: 13,
    icoWidth: 80,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniMe.blueCon2,
    text: "blueContainerText2",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "white",
    textSize: 13,
    icoWidth: 80,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniMe.blueCon3,
    text: "blueContainerText3",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "white",
    textSize: 13,
    icoWidth: 80,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniMe.blueCon4,
    text: "blueContainerText4",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "white",
    textSize: 13,
    icoWidth: 80,
  },
];
export const IconContentMoniContainers2: IconModel[] = [
  {
    icoUrl: theme.IMAGES.products.airQuality.moniMe.blueCon5,
    text: "blueContainerText5",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "white",
    textSize: 13,
    icoWidth: 80,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniMe.blueCon6,
    text: "blueContainerText6",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "white",
    textSize: 13,
    icoWidth: 80,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniMe.blueCon7,
    text: "blueContainerText7",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "white",
    textSize: 13,
    icoWidth: 80,
  },
  {
    icoUrl: theme.IMAGES.products.airQuality.moniMe.blueCon8,
    text: "blueContainerText8",
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "white",
    textSize: 13,
    icoWidth: 80,
  },
];
export const IconContentMoniContainers3: IconModel[] = [
  {
    icoUrl: theme.IMAGES.products.airQuality.moniMe.blueCon9,
    icoAlt: "Sercair",
    keyComponent: "moniMe",
    textColor: "white",
    textSize: 13,
    icoWidth: 80,
  },
];
export const TextContainerPureCoreSeries: TextContainerModel[] = [
  {
    title: "textContainer1Title",
    text: "textContainer1Text",
    keyComponent: "PureCoreSeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "9pt",
  },
  {
    title: "textContainer2Title",
    text: "textContainer2Text",
    keyComponent: "PureCoreSeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "9pt",
  },
  {
    title: "textContainer3Title",
    text: "textContainer3Text",
    keyComponent: "PureCoreSeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "9pt",
  },
  {
    title: "textContainer4Title",
    text: "textContainer4Text",
    keyComponent: "PureCoreSeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "9pt",
  },
  {
    title: "textContainer5Title",
    text: "textContainer5Text",
    keyComponent: "PureCoreSeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "9pt",
  },
  {
    title: "textContainer6Title",
    text: "textContainer6Text",
    keyComponent: "PureCoreSeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "9pt",
  },
  {
    title: "textContainer7Title",
    text: "textContainer7Text",
    keyComponent: "PureCoreSeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "9pt",
  },
  {
    title: "textContainer8Title",
    text: "textContainer8Text",
    keyComponent: "PureCoreSeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "9pt",
  },
  {
    title: "textContainer9Title",
    text: "textContainer9Text",
    keyComponent: "PureCoreSeries",
    textColor: "black",
    titleSize: "13pt",
    textSize: "9pt",
  },
];

export const IconPureCoreContent: IconModel[] = [
  {
    icoUrl: theme.IMAGES.products.pure.pureCore.ico1,
    text: "ico3Text",
    icoAlt: "Sercair",
    textSize: 14,
    textColor: "black",
    icoWidth: 150,
    keyComponent: "PureCoreSeries",
  },
  {
    icoUrl: theme.IMAGES.products.pure.pureCore.ico2,
    text: "ico5Text",
    icoAlt: "Sercair",
    textSize: 14,
    icoWidth: 150,
    textColor: "black",
    keyComponent: "PureCoreSeries",
  },
  {
    icoUrl: theme.IMAGES.products.pure.pureCore.ico3,
    text: "ico2Text",
    icoAlt: "Sercair",
    icoWidth: 150,
    textSize: 14,
    textColor: "black",
    keyComponent: "PureCoreSeries",
  },
  {
    icoUrl: theme.IMAGES.products.pure.pureCore.ico4,
    text: "ico1Text",
    icoAlt: "Sercair",
    textSize: 14,
    icoWidth: 150,
    textColor: "black",
    keyComponent: "PureCoreSeries",
  },
  {
    icoUrl: theme.IMAGES.products.pure.pureCore.ico5,
    text: "ico4Text",
    icoWidth: 150,
    icoAlt: "Sercair",
    textSize: 14,
    textColor: "black",
    keyComponent: "PureCoreSeries",
  },
];

export const PureCoreBoxContent: CardModel[] = [
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.item1,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "Core Pure 2/3/4",
    isBtn: false,
    isShadow: false,
    titleSize: 23,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureCoreSeries",
  },
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.item2,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "filter",
    isBtn: false,
    titleSize: 23,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureCoreSeries",
  },
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.item3,
    imgAlt: "Sercair",
    imgPosition: "left" as "left",
    title: "userGuide",
    isBtn: false,
    titleSize: 23,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureCoreSeries",
  },
  {
    imgUrl: theme.IMAGES.products.technicalSpecifications.item4,
    imgAlt: "Sercair",
    imgPosition: "center" as "center",
    title: "powerCable",
    isBtn: false,
    titleSize: 23,
    isShadow: false,
    isBackgroundColor: true,
    backgroundColor: theme.COLORS.productTeck,
    titleColor: theme.COLORS.blue,
    keyComponent: "PureCoreSeries",
  },
];

export const PureCoreSeriesTechnicalDatav2: tableModel = {
  bodyHeader: ["Core Pure 2", "Core Pure 3", "Core Pure 4"],
  body: [
    {
      bodyTitle: "technicalSpecs5",
      bodyItems: ["technicalSpecs6", "technicalSpecs6", "technicalSpecs6"],
    },
    {
      bodyTitle: "technicalSpecs7",
      bodyItems: [
        "80.39”-119.76” <br/>(2042-3042 mm)",
        "119.76”-159.13” <br/>(3042-4042 mm)",
        "159.13”-198.50” <br/>(4042-5042 mm)",
      ],
    },
    {
      bodyTitle: "technicalSpecs8",
      bodyItems: ["6.41”(163 mm)", "6.41”(163 mm)", "6.41”(163 mm)"],
    },
    {
      bodyTitle: "technicalSpecs9",
      bodyItems: ["9,58 lb - 21 kg", "11,86 lb - 26 kg", "13,68 lb - 30 kg"],
    },
    {
      bodyTitle: "technicalSpecs10",
      bodyItems: ["110 / 220 V", "110 / 220 V", "110 / 220 V"],
    },
    {
      bodyTitle: "technicalSpecs22",
      bodyItems: ["710 Watt", "710 Watt", "720 Watt"],
    },
    {
      bodyTitle: "technicalSpecs23",
      bodyItems: ["1000 Watt", "2000 Watt", "2000 Watt"],
    },
    {
      bodyTitle: "technicalSpecs12",
      bodyItems: ["30-60 dBA", "30-63 dBA", "30-66 dBA"],
    },
    {
      bodyTitle: "technicalSpecs17",
      bodyItems: ["technicalSpecs13", "technicalSpecs13", "technicalSpecs13"],
    },
    {
      bodyTitle: "technicalSpecs14",
      bodyItems: ["965 ft ²/90 m² ", "1184 ft ²/110 m²", "1395 ft ²/130 m²"],
    },
    {
      bodyTitle: "technicalSpecs15",
      bodyItems: [
        "121 CFM / 205 m³/h",
        "239 CFM / 405 m³/h",
        "239 CFM / 405 m³/h",
      ],
    },
    {
      bodyTitle: "technicalSpecs16",
      bodyItems: [
        "359 CFM / 610 m³/h",
        "448 CFM / 760 m³/h",
        "679 CFM / 1150 m³/h",
      ],
    },
    {
      bodyTitle: "technicalSpecs123",
      bodyItems: [
        "Wi-Fi<br/>Gsm<br/>Bluetooth",
        "Wi-Fi<br/>Gsm<br/>Bluetooth",
        "Wi-Fi<br/>Gsm<br/>Bluetooth",
      ],
    },
    {
      bodyTitle: "technicalSpecs18",
      bodyItems: ["technicalSpecs19", "technicalSpecs19", "technicalSpecs19"],
    },
  ],
  titleLeft: "technicalSpecs20",
};

export const IconPiatraSeriesContent: IconModel[] = [
  {
    icoUrl: theme.ICONS.product.piatra.ico1,
    text: "icoContainer1",
    icoAlt: "Sercair",
    textSize: 13,
    textColor: "black",
    keyComponent: "PiatraSeries",
  },
  {
    icoUrl: theme.ICONS.product.piatra.ico2,
    text: "icoContainer2",
    icoAlt: "Sercair",
    textSize: 13,
    textColor: "black",
    keyComponent: "PiatraSeries",
  },
  {
    icoUrl: theme.ICONS.product.piatra.ico3,
    text: "icoContainer3",
    icoAlt: "Sercair",
    textSize: 13,
    textColor: "black",
    keyComponent: "PiatraSeries",
  },
  {
    icoUrl: theme.ICONS.product.piatra.ico4,
    text: "icoContainer4",
    icoAlt: "Sercair",
    textSize: 13,
    textColor: "black",
    keyComponent: "PiatraSeries",
  },
  {
    icoUrl: theme.ICONS.product.piatra.ico5,
    text: "icoContainer5",
    icoAlt: "Sercair",
    textSize: 13,
    textColor: "black",
    keyComponent: "PiatraSeries",
  },
];

export const IconPiatraSeriesContentMobile: IconModel[] = [
  {
    icoUrl: theme.ICONS.product.piatra.ico1,
    icoAlt: "Sercair",
    textSize: 13,
    textColor: "black",
    keyComponent: "PiatraSeries",
  },
  {
    icoUrl: theme.ICONS.product.piatra.ico2,
    icoAlt: "Sercair",
    textSize: 13,
    textColor: "black",
    keyComponent: "PiatraSeries",
  },
  {
    icoUrl: theme.ICONS.product.piatra.ico3,
    icoAlt: "Sercair",
    textSize: 13,
    textColor: "black",
    keyComponent: "PiatraSeries",
  },
  {
    icoUrl: theme.ICONS.product.piatra.ico4,
    icoAlt: "Sercair",
    textSize: 13,
    textColor: "black",
    keyComponent: "PiatraSeries",
  },
  {
    icoUrl: theme.ICONS.product.piatra.ico5,
    icoAlt: "Sercair",
    textSize: 13,
    textColor: "black",
    keyComponent: "PiatraSeries",
  },
];

export const CarouselPiatraProducts: SliderProductsTopModel[] = [
  {
    imgUrl: theme.IMAGES.products.airPurifiers.piatra.slider1,
    imgAlt: "sercair",
    witdh: 1300,
  },
  {
    imgUrl: theme.IMAGES.products.airPurifiers.piatra.slider2,
    imgAlt: "sercair",
    witdh: 1300,
  },
  {
    imgUrl: theme.IMAGES.products.airPurifiers.piatra.slider3,
    imgAlt: "sercair",
    witdh: 1300,
  },
  {
    imgUrl: theme.IMAGES.products.airPurifiers.piatra.slider4,
    imgAlt: "sercair",
    witdh: 1300,
  },
];

export const SliderSpiritSeries: SliderModel[] = [
  {
    imgUrl: theme.IMAGES.products.airPurifiers.spirit.mainSlider,
    imgForMobile: theme.IMAGES.products.airPurifiers.spirit.mainSliderMobile,
    imgAlt: "sliderAlt",
    imgOnClick: false,
    containerPosition: "left" as "left",
    color: theme.COLORS.blue,
    title: "sliderTitle",
    text: "",
    webText: "sliderSubTitleWeb",
    isBtn: false,
    textPosition: "left" as "left",
    btnStyle: "primary",
    isSecondaryContainer: true,
    secondaryContainerPosition: "left" as "left",
    secondaryContainerText: "sliderText",
    secondaryContainerTitle: "sliderSubTitle",
    secondaryIsBtn: true,
    h2MarginBottom: "0px",
    btnText: "sliderButtonText",
    secondaryTextPosition: "left",
    secondaryContainerColor: "black",
    keyComponent: "SpiritSeries",
    videoId: "videoId",
    secondaryContainerBtnText: "sliderButtonText",
  },
];
export const TextContainerSpiritSeries: TextContainerModel[] = [
  {
    title: "textAreaTitle1",
    text: "textAreaText1",
    keyComponent: "SpiritSeries",
    textColor: "black",
    titleSize: "large",
    textSize: "medium",
  },
  {
    title: "textAreaTitle2",
    text: "textAreaText2",
    keyComponent: "SpiritSeries",
    textColor: "black",
    titleSize: "large",
    textSize: "medium",
  },
];
export const TextContainerSpiritSeriesMobile: TextContainerModel[] = [
  {
    title: "textAreaTitle1",
    text: "textAreaText1",
    keyComponent: "SpiritSeries",
    textColor: "black",
    titleSize: "14.3pt",
    textSize: "8.28pt",
  },
  {
    title: "textAreaTitle2",
    text: "textAreaText2",
    keyComponent: "SpiritSeries",
    textColor: "black",
    titleSize: "14.3pt",
    textSize: "8.28pt",
  },
];
export const SliderSpiritCarSeries: SliderModel[] = [
  {
    imgUrl: theme.IMAGES.products.airPurifiers.spirit.spiritCar,
    imgForMobile: theme.IMAGES.products.airPurifiers.spirit.spiritCarMobile,
    imgAlt: "sliderAlt",
    imgOnClick: false,
    containerPosition: "left" as "left",
    color: theme.COLORS.white,
    title: "carTitle",
    text: "",
    webText: "sliderSubTitleWeb",
    isBtn: false,
    textPosition: "left" as "left",
    btnStyle: "primary",
    isSecondaryContainer: true,
    secondaryContainerPosition: "left" as "left",
    secondaryContainerText: "carText",
    secondaryContainerTitle: "",
    secondaryIsBtn: false,
    secondaryTextPosition: "left",
    secondaryContainerColor: "white",
    keyComponent: "SpiritSeries",
  },
];
export const IconSpiritSeriesContent: IconModel[] = [
  {
    icoUrl: theme.ICONS.product.spirit.ico1,
    title: "icoTitle1",
    text: "icoText1",
    icoAlt: "Sercair",
    textSize: 18,
    icoWidth: 200,
    titleColor: "black",
    textColor: "black",
    textFontFamily: "GothamLight",
    keyComponent: "SpiritSeries",
  },
  {
    icoUrl: theme.ICONS.product.spirit.ico2,
    title: "icoTitle2",
    text: "icoText2",
    icoAlt: "Sercair",
    textSize: 18,
    icoWidth: 200,
    titleColor: "black",
    textColor: "black",
    textFontFamily: "GothamLight",
    keyComponent: "SpiritSeries",
  },
  {
    icoUrl: theme.ICONS.product.spirit.ico3,
    title: "icoTitle3",
    text: "icoText3",
    icoAlt: "Sercair",
    textSize: 18,
    icoWidth: 200,
    titleColor: "black",
    textColor: "black",
    textFontFamily: "GothamLight",
    keyComponent: "SpiritSeries",
  },
];
export const SupportsCollapseData: CollapseModel[] = [
  {
    title: "montageDate",
    img: theme.IMAGES.main.SupportsCollapse.assembly,
    imgAlt: "collapseAlt",
    keyComponent: "CollapseHelp",
    items: [
      {
        title: "",
        url:
          langCheck === "tr"
            ? "/tr/destek/montaj"
            : langCheck === "nl"
            ? "/nl/steun/montage"
            : langCheck === "rs"
            ? "/rs/podrska/skupstina"
            : langCheck === "fr"
            ? "/fr/soutien/assemblee"
            : langCheck === "uk"
            ? "/uk/support/assembly"
            : langCheck === "ro"
            ? "/ro/sustine/asamblare"
            : "support/assembly",
      },
    ],
  },
  {
    title: "userGuide",
    img: theme.IMAGES.main.SupportsCollapse.userGuide,
    imgAlt: "collapseAlt",
    keyComponent: "CollapseHelp",
    items: [
      {
        title: "",
        url:
          langCheck === "tr"
            ? "/tr/destek/kullanici-rehberi"
            : langCheck === "nl"
            ? "/nl/steun/gebruikershandleiding"
            : langCheck === "rs"
            ? "/rs/podrska/uputstvo-za-upotrebu"
            : langCheck === "fr"
            ? "/fr/soutien/uputstvo-za-upotrebu"
            : langCheck === "uk"
            ? "/uk/support/userguide"
            : langCheck === "ro"
            ? "/ro/sustine/manualul-utilizatorului"
            : "support/userguide",
      },
    ],
  },
  {
    title: "warranty",
    img: theme.IMAGES.main.SupportsCollapse.warranty,
    imgAlt: "collapseAlt",
    keyComponent: "CollapseHelp",
    items: [
      {
        title: "",
        url:
          langCheck === "tr"
            ? "/tr/destek/garanti"
            : langCheck === "nl"
            ? "/nl/steun/garantie"
            : langCheck === "rs"
            ? "/rs/podrska/garancija"
            : langCheck === "fr"
            ? "/fr/soutien/garantie"
            : langCheck === "uk"
            ? "/uk/support/warranty"
            : langCheck === "ro"
            ? "/ro/sustine/garantie"
            : "support/warranty",
      },
    ],
  },

  {
    title: "return",
    img: theme.IMAGES.main.SupportsCollapse.return,
    imgAlt: "collapseAlt",
    keyComponent: "CollapseHelp",
    items: [
      {
        title: "",
        url:
          langCheck === "tr"
            ? "/tr/destek/iadeler"
            : langCheck === "nl"
            ? "/nl/steun/geeft-terug"
            : langCheck === "rs"
            ? "/rs/podrska/vraca"
            : langCheck === "fr"
            ? "/fr/soutien/retour"
            : langCheck === "uk"
            ? "/uk/support/returns"
            : langCheck === "ro"
            ? "/ro/sustine/intoarce"
            : "support/returns",
      },
    ],
  },
  {
    title: "request",
    img: theme.IMAGES.main.SupportsCollapse.request,
    imgAlt: "collapseAlt",
    keyComponent: "CollapseHelp",
    items: [
      {
        title: "",
        url:
          langCheck === "tr"
            ? "/tr/destek/istekler"
            : langCheck === "nl"
            ? "/nl/steun/aanvragen"
            : langCheck === "rs"
            ? "/rs/podrska/requests"
            : langCheck === "fr"
            ? "/fr/soutien/demandes"
            : langCheck === "uk"
            ? "/uk/support/requests"
            : langCheck === "ro"
            ? "/ro/sustine/cereri"
            : "support/requests",
      },
    ],
  },
];

export const SliderContactApp: SliderModel[] = [
  {
    imgUrl: theme.IMAGES.contact.slider,
    imgForMobile: theme.IMAGES.contact.sliderMobile,
    imgAlt: "imgAltSlider",
    imgOnClick: false,
    containerPosition: "left" as "left",
    color: "white",
    title: "sliderTitle",
    text: "sliderText",
    webText: "sliderSubTitleWeb",
    isBtn: false,
    textPosition: "left" as "left",
    isSecondaryContainer: true,
    secondaryContainerPosition: "left" as "left",
    secondaryContainerText: "",
    secondaryContainerTitle: "",
    secondaryIsBtn: false,
    secondaryTextPosition: "left",
    keyComponent: "Contact",
  },
];

export const cubeLeftTechicalData: cubeTech[] = [
  {
    top: "-62px",
    left: "172px",
    bgUrl: theme.IMAGES.products.cube.arrow1,
    height: "151px",
    width: "30px",
    desp: "techPop1",
  },
  {
    top: "-62px",
    left: "204px",
    bgUrl: theme.IMAGES.products.cube.arrow2,
    height: "300px",
    width: "30px",
    desp: "techPop2",
  },
  {
    top: "-62px",
    left: "237px",
    bgUrl: theme.IMAGES.products.cube.arrow3,
    height: "300px",
    width: "30px",
    desp: "techPop3",
  },
  {
    top: "27px",
    left: "322px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow4,
    height: "30px",
    desp: "techPop4",
  },
  {
    top: "81px",
    left: "231px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow5,
    height: "30px",
    desp: "techPop5",
  },
  {
    top: "140px",
    left: "260px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow6,
    height: "30px",
    desp: "techPop6",
  },
  {
    top: "190px",
    left: "322px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow7,
    height: "30px",
    desp: "techPop7",
  },
  {
    top: "324px",
    left: "322px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow8,
    height: "30px",
    desp: "techPop8",
  },
  {
    top: "573px",
    left: "322px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow9,
    height: "30px",
    desp: "techPop9",
  },
  {
    top: "635px",
    left: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow10,
    height: "120px",
    width: "30px",
    desp: "techPop10",
  },
  {
    top: "635px",
    left: "100px",
    bgUrl: theme.IMAGES.products.cube.arrow11,
    height: "120px",
    width: "30px",
    desp: "techPop11",
  },
  {
    top: "573px",
    left: "-19px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow12,
    height: "30px",
    desp: "techPop12",
  },
  {
    top: "328px",
    left: "-19px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow13,
    height: "30px",
    desp: "techPop13",
  },
  {
    top: "258px",
    left: "-19px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow14,
    height: "30px",
    desp: "techPop14",
  },
  {
    top: "98px",
    left: "-19px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow15,
    height: "45px",
    desp: "techPop15",
  },
  {
    top: "47px",
    left: "-19px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow16,
    height: "30px",
    desp: "techPop16",
  },
];
export const cubeLeftTechicalDataMobile: cubeTech[] = [
  {
    top: "-22px",
    left: "149px",
    bgUrl: theme.IMAGES.products.cube.arrow1,
    height: "151px",
    width: "30px",
    desp: "techPop1",
  },
  {
    top: "-45px",
    left: "181px",
    bgUrl: theme.IMAGES.products.cube.arrow2,
    height: "300px",
    width: "30px",
    desp: "techPop2",
  },
  {
    top: "-38px",
    left: "209px",
    bgUrl: theme.IMAGES.products.cube.arrow3,
    height: "300px",
    width: "30px",
    desp: "techPop3",
  },
  {
    top: "54px",
    left: "275px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow4,
    height: "30px",
    desp: "techPop4",
  },
  {
    top: "96px",
    left: "194px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow5,
    height: "30px",
    desp: "techPop5",
  },
  {
    top: "140px",
    left: "215px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow6,
    height: "30px",
    desp: "techPop6",
  },
  {
    top: "190px",
    left: "256px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow7,
    height: "30px",
    desp: "techPop7",
  },
  {
    top: "246px",
    left: "251px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow8,
    height: "30px",
    desp: "techPop8",
  },
  {
    top: "416px",
    left: "261px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow9,
    height: "30px",
    desp: "techPop9",
  },
  {
    top: "456px",
    left: "225px",
    bgUrl: theme.IMAGES.products.cube.arrow10,
    height: "120px",
    width: "30px",
    desp: "techPop10",
  },
  {
    top: "453px",
    left: "100px",
    bgUrl: theme.IMAGES.products.cube.arrow11,
    height: "120px",
    width: "30px",
    desp: "techPop11",
  },
  {
    top: "420px",
    left: "-19px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow12,
    height: "30px",
    desp: "techPop12",
  },
  {
    top: "328px",
    left: "-19px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow13,
    height: "30px",
    desp: "techPop13",
  },
  {
    top: "258px",
    left: "-19px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow14,
    height: "30px",
    desp: "techPop14",
  },
  {
    top: "98px",
    left: "-19px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow15,
    height: "45px",
    desp: "techPop15",
  },
  {
    top: "47px",
    left: "-19px",
    width: "300px",
    bgUrl: theme.IMAGES.products.cube.arrow16,
    height: "30px",
    desp: "techPop16",
  },
];

export const cubeRightTechicalData: cubeTech[] = [
  {
    top: "238px",
    left: "335px",
    width: "30px",
    bgUrl: theme.IMAGES.products.cube.arrow1_i,
    height: "205px",
    desp: "techPop1_i",
  },
  {
    top: "226px",
    left: "419px",
    width: "30px",
    bgUrl: theme.IMAGES.products.cube.arrow2_i,
    height: "170px",
    desp: "techPop2_i",
  },
  {
    top: "214px",
    left: "547px",
    width: "30px",
    bgUrl: theme.IMAGES.products.cube.arrow3_i,
    height: "230px",
    desp: "techPop3_i",
  },
  {
    top: "240px",
    left: "598px",
    width: "30px",
    bgUrl: theme.IMAGES.products.cube.arrow4_i,
    height: "210px",
    desp: "techPop4_i",
  },
  {
    top: "544px",
    left: "572px",
    bgUrl: theme.IMAGES.products.cube.arrow5_i,
    height: "30px",
    width: "210px",
    desp: "techPop5_i",
  },
  {
    top: "483px",
    left: "186px",
    bgUrl: theme.IMAGES.products.cube.arrow6_i,
    height: "30px",
    width: "210px",
    desp: "techPop6_i",
  },
];
