import { CubeScreenComponent } from "../../screens/Procucts/Cube";

export const CubeScreenNavigator = [
  {
    path: "/products/cube",
    exact: true,
    screen: <CubeScreenComponent />,
  },
  {
    path: "/tr/urunler/cube",
    exact: true,
    screen: <CubeScreenComponent />,
  },
  {
    path: "nl/producten/cube",
    exact: true,
    screen: <CubeScreenComponent />,
  },
  {
    path: "rs/proizvodi/cube",
    exact: true,
    screen: <CubeScreenComponent />,
  },
  {
    path: "fr/produits/cube",
    exact: true,
    screen: <CubeScreenComponent />,
  },
  {
    path: "uk/products/cube",
    exact: true,
    screen: <CubeScreenComponent />,
  },
  {
    path: "ro/produse/cube",
    exact: true,
    screen: <CubeScreenComponent />,
  },
];
