import { CollapseModel } from "../models/collapse.model";
import { SupportsCollapseData } from "./datas";
import { ProductsCollapseData } from "./mock";

export type type = "products" | "support";

const langCheck = localStorage.getItem("lang");

export interface Navbar {
  key: string;
  url: any;
  onCollapse: boolean;
  collapse?: CollapseModel[];
  type?: type;
  controls?: string;
  textSize?: number;
}

export const NavBars: Navbar[] = [
  {
    key: "products",
    url: "/products",
    onCollapse: true,
    collapse: ProductsCollapseData,
    type: "products",
    controls: "products-collapse-text",
  },
  // {
  //   key: "shopping",
  //   url: "/shopping",
  //   onCollapse: false,
  // },
  // {
  //   key: "campaigns",
  //   url: langCheck === "en" ? "/campaigns" : langCheck === "tr" ? "/tr/kampanyalar" : langCheck === "nl" ? "/nl/campagnes" : langCheck === "fr" ? "/fr/campagnes" : langCheck === "uk" ? "/uk/campaigns" : "/rs/kampanje",
  //   onCollapse: false,
  // },
  {
    key: "support",
    url: "/support",
    onCollapse: true,
    collapse: SupportsCollapseData,
    type: "support",
    controls: "support-collapse-text",
  },
  {
    key: "ourSales",
    url:
      langCheck === "en"
        ? "/salespoints"
        : langCheck === "tr"
        ? "/tr/satis-noktalari"
        : langCheck === "nl"
        ? "/nl/verkooppunten"
        : langCheck === "fr"
        ? "/fr/points-de-vente"
        : langCheck === "uk"
        ? "/uk/salespoints"
        : langCheck === "ro"
        ? "/ro/puncte-de-vanzare"
        : "/rs/prodajna-mesta",
    onCollapse: false,
  },
  // {
  //   key: "pricing",
  //   url: langCheck === "en" ? "/pricing" : langCheck === "tr" ? "/tr/pricing" : langCheck === "nl" ? "/nl/pricing" : "/rs/pricing",
  //   onCollapse: false,
  // },
];

export const NavBarsTR: Navbar[] = [
  {
    key: "products",
    url: "/products",
    onCollapse: true,
    collapse: ProductsCollapseData,
    type: "products",
    controls: "products-collapse-text",
  },
  // {
  //   key: "shopping",
  //   url: "/shopping",
  //   onCollapse: false,
  // },
  // {
  //   key: "campaigns",
  //   url: langCheck === "en" ? "/campaigns" : langCheck === "tr" ? "/tr/kampanyalar" : langCheck === "nl" ? "/nl/campagnes" : langCheck === "fr" ? "/fr/campagnes" : langCheck === "uk" ? "/uk/campaigns" : "/rs/kampanje",
  //   onCollapse: false,
  // },
  {
    key: "support",
    url: "/support",
    onCollapse: true,
    collapse: SupportsCollapseData,
    type: "support",
    controls: "support-collapse-text",
  },
  {
    key: "ourSales",
    url:
      langCheck === "en"
        ? "/salespoints"
        : langCheck === "tr"
        ? "/tr/satis-noktalari"
        : langCheck === "nl"
        ? "/nl/verkooppunten"
        : langCheck === "fr"
        ? "/fr/points-de-vente"
        : langCheck === "uk"
        ? "/uk/salespoints"
        : langCheck === "ro"
        ? "/ro/puncte-de-vanzare"
        : "/rs/prodajna-mesta",
    onCollapse: false,
  },
  // {
  //   key: "pricing",
  //   url: langCheck === "en" ? "/pricing" : langCheck === "tr" ? "/tr/pricing" : langCheck === "nl" ? "/nl/pricing" : "/rs/pricing",
  //   onCollapse: false,
  // },
];
