import React from "react";
import Down from "../../assets/images/WeatherImages/SVG/Down.svg";
import "./Detail.css";
import Button from "./Button/Button";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Detail = (props) => {
  const headerstyle = {
    color: props.headerstyle,
  };

  return (
    <div className="component">
      <div className="header">{props.header}</div>

      <div className="inner_container">
        <div className="image_container">
          <CircularProgressbar
            //  value={!props.t ? props.in : (100 * (props.value / props.max1)) }
            value={props.value}
            text={
              props.change
                ? props.value !== props.value
                  ? props.initial
                  : props.value + " " + props.unit
                : props.unit + props.value
            }
            maxValue={props.max1}
            minValue={props.min}
            //value={100}
            size={120}
            strokeWidth={5}
            progresWidth={15}
            disabled={true}
            knobRadius={1}
            circleColor={"#25e4db"}
            circleWidth={2}
            // maxValue={props.max1}
            // minValue={props.min}
            // text={props.value}
            styles={buildStyles({
              // Rotation of path and trail, in number of turns (0-1)
              rotation: 0.5,

              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "round",

              // Text size
              textSize: "20px",
              textColor: "#fff",

              // How long animation takes to go from one percentage to another, in seconds
              pathTransitionDuration: 0.5,

              // Can specify path transition in more detail, or remove it entirely
              // pathTransition: 'none',

              // Colors
              //${ (1000 * (props.value / props.max1)) / 100}
              pathColor: props.color,
              textColor: "#fff",
              trailColor: "#757575",
              backgroundColor: "#3e98c7",
              textSize: "15",
            })}
          />
        </div>

        <div className="info_container">
          <Button type="button" onClick={props.click}>
            <div className="down_close">
              <img className="downButton" src={Down} />
            </div>
          </Button>
          <div className="header_info">
            <div style={headerstyle}>{props.situation}</div>
            <div className="time" style={headerstyle}>
              {props.time}
            </div>
          </div>
          <div className="text">{props.text}</div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
